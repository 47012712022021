import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.css';

const Loader = ({ variant, small, className }) => {
  const color = variant === 'primary' ? '#99d2d9' : '#DA8BBA';
  const extraClasses = className && className !== '' ? className : '';
  return (
    <div
      className={`${styles.loader} ${
        small ? styles.isSmall : ''
      } ${extraClasses}`}
    >
      <div style={{ backgroundColor: color }} />
      <div style={{ backgroundColor: color }} />
      <div style={{ backgroundColor: color }} />
    </div>
  );
};

Loader.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  small: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  small: false,
  className: '',
};

export default Loader;
