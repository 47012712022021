/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { formatRespondents } from 'lib/antal';
import styles from './Checkbox.module.css';

const Checkbox = ({
  checked,
  disabled,
  item,
  loading,
  onChange,
  variant,
  className,
}) => {
  const { antal, description, value, sel_var } = item;

  return (
    <label
      htmlFor={`${sel_var}-${description}`}
      className={`${styles.customCheckbox} ${
        variant === 'primary' ? styles.primary : styles.secondary
      } ${loading ? styles.loading : ''} ${disabled ? styles.disabled : ''} ${
        className || ''
      }`}
    >
      <div className={styles.optionTextWrapper}>
        <div className={styles.optionName}>{description}</div>
        <div className={styles.filterCount}>
          <p>
            {variant === 'secondary' && 'Antal uppgifter: '}
            {formatRespondents(antal)}
          </p>
        </div>
      </div>
      <input
        aria-label={description}
        checked={checked}
        disabled={disabled}
        id={`${sel_var}-${description}`}
        name={sel_var}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <span className={styles.checkmarkWrapper}>
        <span className={styles.customCheckmark} />
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    antal: PropTypes.string.isRequired,
    description: PropTypes.string,
    sel_var: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
};

Checkbox.defaultProps = {
  className: '',
  checked: undefined,
  loading: false,
};

export default Checkbox;
