import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import styles from '../VisitorStatistics.module.css';
import { Colors } from '../../../../assets/Colors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  title: {
    display: true,
    text: 'Antal besök per år',
    fontSize: 16,
    fontColor: Colors.black,
  },
  responsive: true,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: 'black',
      formatter: value => {
        if (value === 0) {
          return '';
        } else {
          return value.toLocaleString();
        }
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: value => value.toLocaleString().replace(',', ' '),
        },
        afterDataLimits(scale) {
          var range = scale.max - scale.min;
          var grace = range * 0.05;
          scale.max += grace;
        },
      },
    ],
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
};

const YearChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const mapStatsToChartData = statsMap => {
    const labels = Array.from(statsMap.keys());
    const datasets = [
      {
        label: 'Medlem',
        data: Array.from(statsMap.values()).map(year => year.medlem),
        backgroundColor: Colors.turquoise,
        maxBarThickness: 70,
      },
      {
        label: 'Förtroendevald',
        data: Array.from(statsMap.values()).map(year => year.fortroendevald),
        backgroundColor: Colors.yellow,
        maxBarThickness: 70,
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      const currentYear = new Date().getFullYear();
      const yearsBack = 4;

      const entries = Object.entries(stats);
      entries.sort(([yearA], [yearB]) => Number(yearA) - Number(yearB));

      const recentYears = entries.filter(
        ([year]) =>
          Number(year) >= currentYear - yearsBack && Number(year) <= currentYear
      );

      const sortedStats = new Map(recentYears);

      mapStatsToChartData(sortedStats);
    }
  }, [stats]);

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  return (
    <div>
      {chartData && (
        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
      {chartData && (
        <div className={styles.chartStats}>
          <div className={styles.tableToggle} onClick={toggleTable}>
            {isTableExpanded ? 'Dölj tabell' : 'Visa som tabell'}
          </div>
          {isTableExpanded && (
            <table>
              <thead>
                <tr>
                  <th></th>
                  {chartData.labels.map((label, index) => (
                    <th key={index}>År {label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {chartData.datasets.map((dataset, index) => (
                  <tr key={dataset.label}>
                    <td>{dataset.label}</td>
                    {dataset.data.map((dataPoint, index) => (
                      <td key={index}>{dataPoint.toLocaleString()}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default YearChart;

YearChart.propTypes = {
  stats: PropTypes.any,
};
