import PropTypes from 'prop-types';
import React from 'react';
import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconChevronsLeft,
  IconChevronsRight,
  IconCopy,
  IconDownload,
  IconEdit,
  IconPlus,
  IconRefresh,
  IconTrash,
} from 'tabler-icons';
import styles from './Button.module.css';

const Button = ({ className, disabled, label, altText, onClick, style, type, variant, icon, flipIcon }) => {
  const extraClasses = className && className !== '' ? className : '';
  const computedAltText = altText && altText !== '' ? altText : label;
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${
        typeof label !== 'undefined' && label !== '' ? styles.hasText : ''
      } ${typeof icon !== 'undefined' ? styles.hasIcon : ''} ${flipIcon ? styles.flipIcon : ''}  ${extraClasses}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {icon && (
        <span className={styles.buttonIcon}>
          {icon === 'ArrowDown' && <IconArrowNarrowDown aria-label={computedAltText} role="img" size={20} />}
          {icon === 'ArrowUp' && <IconArrowNarrowUp aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Copy' && <IconCopy aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Download' && <IconDownload aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Edit' && <IconEdit aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Next' && <IconChevronsRight aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Plus' && <IconPlus aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Previous' && <IconChevronsLeft aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Refresh' && <IconRefresh aria-label={computedAltText} role="img" size={20} />}
          {icon === 'Trash' && <IconTrash aria-label={computedAltText} role="img" size={20} />}
        </span>
      )}
      <span className={styles.buttonText} alt={computedAltText}>
        {label}
      </span>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  flipIcon: PropTypes.bool,
  label: PropTypes.string.isRequired,
  altText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  style: PropTypes.shape({}),
  type: PropTypes.oneOf(['submit', 'button']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'delete']).isRequired,
};

Button.defaultProps = {
  altText: '',
  className: undefined,
  disabled: false,
  flipIcon: false,
  style: undefined,
  icon: undefined,
  type: 'button',
};

export default Button;
