import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../services/UseAuth';
import ApiService from '../../../../services/ApiService';
import DayChart from './DayChart';
import WeekChart from './WeekChart';
import MonthChart from './MonthChart';
import YearChart from './YearChart';
import styles from '../VisitorStatistics.module.css';
import Loader from '../../../../atoms/loader/Loader';

const BarCharts = () => {
  const { user } = useAuth();
  const { token } = user;
  const [statsPerDay, setStatsPerDay] = useState();
  const [statsPerWeek, setStatsPerWeek] = useState();
  const [statsPerMonth, setStatsPerMonth] = useState();
  const [statsPerYear, setStatsPerYear] = useState();
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const lastYear = currentYear - 1;

  const request = {
    yearFrom: lastYear,
    monthFrom: currentMonth,
    yearTo: currentYear,
    monthTo: currentMonth,
    forbundskodSas: user.forbund
  };

  useEffect(() => {
    setLoading(true);
    ApiService.getVisitorStatsNew(request, token)
      .then(data => {
        const {
          visitorsPerDay,
          visitorsPerWeek,
          visitorsPerMonth,
          visitorsPerYear,
        } = data.periodsStatistics;

        setStatsPerDay(visitorsPerDay);
        setStatsPerWeek(visitorsPerWeek);
        setStatsPerMonth(visitorsPerMonth);
        setStatsPerYear(visitorsPerYear);
      })
      .finally(() => setLoading(false));
  }, [token]);

  return (
    <div className={styles.chartContainer}>
      <h2 className={styles.header}>
        Besöksstatistik
      </h2>
      {loading ? (
        <Loader variant="primary" />
      ) : (
        <>
          <MonthChart stats={statsPerMonth} />
          <WeekChart stats={statsPerWeek} />
          <DayChart stats={statsPerDay} />
          <YearChart stats={statsPerYear} />
        </>
      )}
    </div>
  );
};

export default BarCharts;