import React from 'react';
import PropTypes from 'prop-types';
import Section from 'atoms/section/Section';
import ActiveFilters from 'components/advanced/activefilters/ActiveFilters';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import CheckboxSelectGroup from '../checkboxselectgroup/CheckboxSelectGroup';
import styles from './SalaryProfile.module.css';

const SalaryProfile = ({
  loading,
  dataSources,
  selectedValues,
  selectionList,
  setSelectedValues,
}) => {
  const forbundToString = str => {
    if (!str) return '';
    if (str.toLowerCase() === 'sa') {
      return 'samtliga Sacoförbund';
    }

    return 'ditt förbund';
  };

  return (
    <Section title="Din löneprofil">
      {dataSources.length > 0 && (
        <p className={styles.summary}>
          {`Statistiken du ser baseras på ${
            dataSources[0].YEAR
          } års uppgifter från ${forbundToString(dataSources[0].FORBUND)}.`}
        </p>
      )}
      {selectionList.map((listItem, index) => (
        <div key={listItem.id} className={styles.subSection}>
          <div className={styles.selectionRow}>
            <CheckboxSelectGroup
              itemIndex={index}
              loading={loading}
              options={listItem.options}
              sel_var={listItem.sel_var}
              selectedValues={selectedValues}
              SELECTION_DATATYPE={listItem.SELECTION_DATATYPE}
              SELECTION_TEXT={listItem.SELECTION_TEXT}
              setSelectedValues={setSelectedValues}
            />
          </div>
        </div>
      ))}
      {selectedValues.length > 0 && (
        <>
          <ActiveFilters
            activeFilters={selectedValues}
            setActiveFilters={setSelectedValues}
            disableMenuselection={loading}
            variant="simple"
          />
          <ButtonLink
            label="Rensa urval"
            altText="Rensa valda filter"
            className={styles.resetFiltersBtn}
            onClick={() => {
              setSelectedValues([]);
            }}
          />
        </>
      )}
    </Section>
  );
};

SalaryProfile.propTypes = {
  dataSources: PropTypes.arrayOf(Object),
  loading: PropTypes.bool.isRequired,
  selectedValues: PropTypes.arrayOf(String).isRequired,
  selectionList: PropTypes.arrayOf(Object).isRequired,
  setSelectedValues: PropTypes.func.isRequired,
};

SalaryProfile.defaultProps = {
  dataSources: [],
};

export default SalaryProfile;
