import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Colors } from '../../../../assets/Colors';
import styles from '../VisitorStatistics.module.css';

const options = {
  title: {
    display: true,
    text: 'Antal procent av besöken uppdelat på webbläsare',
    fontSize: 16,
    fontColor: Colors.black,
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
  plugins: {
    datalabels: {
      anchor: 'center',
      align: 'center',
      color: 'white',
      font: {
        size: 14,
        weight: 'bold',
      },
      formatter: value => {
        if (value === 0) {
          return '';
        }
        return `${value.toLocaleString()} %`;
      },
    },
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, d) {
        let label = d.labels[tooltipItem.index] || '';
        if (label) {
          label += ': ';
        }
        const value =
          d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
        label += Number(value).toLocaleString('sv');
        return `${label} %`;
      },
    },
  },
};

const browserKeyLabels = {
  Chrome: 'Chrome',
  'Microsoft Edge': 'Microsoft Edge',
  Safari: 'Safari',
  FireFox: 'Firefox',
  Opera: 'Opera',
  Samsung: 'Samsung Internet',
  Unknown: 'Okänd',
  Other: 'Annan webbläsare',
};

const browserSortOrder = [
  'Chrome',
  'Microsoft Edge',
  'Safari',
  'FireFox',
  'Samsung',
  'Opera',
  'Other',
  'Unknown',
];

const BrowserChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const { turquoise, pink, lightGrey, grey, darkGrey, yellow, orange } = Colors;

  const sortStats = () => {
    return Object.entries(stats).sort(
      (browserA, browserB) =>
        browserSortOrder.indexOf(browserA[0]) -
        browserSortOrder.indexOf(browserB[0])
    );
  };

  const mapStatsToChartData = () => {
    const sortedStats = sortStats();
    const labels = sortedStats.map(browser => browserKeyLabels[browser[0]]);
    const data = sortedStats.map(browser => browser[1]);

    const datasets = [
      {
        data,
        backgroundColor: [
          turquoise,
          pink,
          lightGrey,
          grey,
          darkGrey,
          yellow,
          orange,
        ],
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      mapStatsToChartData();
    }
  }, [stats]);

  return (
    <div>
      {chartData && (
        <Pie data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
    </div>
  );
};

export default BrowserChart;

BrowserChart.propTypes = {
  stats: PropTypes.any,
};
