import React from 'react';
import PropTypes from 'prop-types';
import useScrollTo from 'hooks/useScrollTo';
import styles from './PrivacyPolicy.module.css';
import Accordion from '../../../atoms/accordion/Accordion';

const TechnicalInformation = ({ id }) => {
  const ref = useScrollTo(id);

  return (
    <div className={styles.policyWrapper}>
      <div ref={ref} className={styles.policyInnerWrapper}>
        <Accordion title="Teknisk info och GDPR" dark>
          <h2>Integritetspolicy</h2>
          <p>
            Uppgifterna i Saco lönesök består av anonymiserad data. Det innebär
            att det inte finns några personuppgifter i underlaget som används
            för lönestatistiken. För att visa statistik måste det finnas minst
            30 observationer per förbund. Lönesök visar aldrig enskilda löner.
          </p>
          <p>
            För att du ska kunna vara inloggad i Saco lönesök och för att
            lönesök ska kunna hålla reda på vilket förbund besökaren tillhör,
            används cookies.
          </p>
          <p>Besöksstatistiken som Saco lönesök sparar:</p>
          <ul>
            <li>Vilket förbund besökaren tillhör.</li>
            <li>Vilken tid besökaren använder tjänsten.</li>
            <li>Om besökaren är medlem eller förtroendevald.</li>
            <li>Vilken del av tjänsten besökaren använder.</li>
            <li>Vilken webbläsare besökaren använder.</li>
            <li>Vilken enhet besökaren använder (desktop eller mobil).</li>
          </ul>
          <p>Saco lönesök sparar inga personuppgifter.</p>
          <h2>Teknisk information</h2>
          <p>Rekommenderade webbläsare för Saco lönesök:</p>
          <div>
            Microsoft Edge (rekommenderad minimum version 107.0.1418.35)
          </div>
          <div>Google Chrome (rekommenderad minimum version 107.0.5304)</div>
          <div>Apple Safari (rekommenderad minimum version 15)</div>
          <p>
            Har du tekniska frågor eller hittat fel i systemet? Hör gärna av dig
            till{' '}
            <u>
              <a href="mailto:lonesok@saco.se">lonesok@saco.se</a>
            </u>
            .
          </p>
        </Accordion>
      </div>
    </div>
  );
};

TechnicalInformation.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TechnicalInformation;
