import ButtonLink from 'atoms/buttonlink/ButtonLink';
import Panel from 'atoms/panel/Panel';
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Section from 'atoms/section/Section';
import Const from 'const';
import useScrollTo from 'hooks/useScrollTo';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import Modal from 'atoms/modal/Modal';
import Accordion from 'atoms/accordion/Accordion';
import ActiveSelections from 'components/admin/copyconfiguration/activeselections/ActiveSelections';
import ActiveUnionSelections from 'components/admin/copyconfiguration/activeselections/ActiveUnionSelections';
import DataSource from 'components/admin/copyconfiguration/datasource/DataSource';
import UnionSelect from 'components/admin/copyconfiguration/unionselect/UnionSelect';
import Loader from 'atoms/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HelpBox from './helpbox/HelpBox';
import StatusBox from './statusbox/StatusBox';
import styles from './CopyConfiguration.module.css';

const CopyConfiguration = () => {
  const history = useHistory();
  const ref = useScrollTo('hjalp');
  const { user, signout, autoRefreshToken } = useAuth();
  const { token, forbund } = user;
  const [, setState] = useState();
  const [
    isLoadingSimpleConfiguration,
    setIsLoadingSimpleConfiguration,
  ] = useState(false);
  const [
    isLoadingAdvancedConfiguration,
    setIsLoadingAdvancedConfiguration,
  ] = useState(false);

  const [simpleConfiguration, setSimpleConfiguration] = useState();
  const [advancedConfiguration, setAdvancedConfiguration] = useState();
  const [previewConfiguration, setPreviewConfiguration] = useState();
  const [unionList, setUnionList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [jobStatus, setJobStatus] = useState();
  const [hasAnySelectionsSimple, setHasAnySelectionsSimple] = useState(false);
  const [hasAnySelectionsPreview, setHasAnySelectionsPreview] = useState(false);
  const [hasAnySelectionsAdvanced, setHasAnySelectionsAdvanced] = useState(
    false
  );

  const jobStatusRef = useRef();

  useEffect(() => {
    jobStatusRef.current = jobStatus;
  }, [jobStatus]);

  const HasAnySelectionsSimple = () => {
    setHasAnySelectionsSimple(false);
    if (
      simpleConfiguration &&
      simpleConfiguration.dslConfigs &&
      simpleConfiguration.dslConfigs.length > 0
    ) {
      simpleConfiguration.dslConfigs.forEach(dslConfig => {
        dslConfig.dsL2Configs.forEach(dsl2Config => {
          if (dsl2Config.copyFromTestToProd === true) {
            setHasAnySelectionsSimple(true);
          }
        });
      });
    }
  };

  const HasAnySelectionsPreview = () => {
    setHasAnySelectionsPreview(false);

    if (
      previewConfiguration &&
      previewConfiguration.dslConfigs &&
      previewConfiguration.dslConfigs.length > 0
    ) {
      previewConfiguration.dslConfigs.forEach(dslConfig => {
        dslConfig.dsL2Configs.forEach(dsl2Config => {
          if (dsl2Config.copyFromTestToProd === true) {
            setHasAnySelectionsPreview(true);
          }
        });
      });
    }
  };

  const HasAnySelectionsAdvanced = () => {
    setHasAnySelectionsAdvanced(false);

    if (
      advancedConfiguration &&
      advancedConfiguration.dslConfigs &&
      advancedConfiguration.dslConfigs.length > 0
    ) {
      advancedConfiguration.dslConfigs.forEach(dslConfig => {
        dslConfig.dsL2Configs.forEach(dsl2Config => {
          if (dsl2Config.copyFromTestToProd === true) {
            setHasAnySelectionsAdvanced(true);
          }
        });
      });
    }
  };

  const triggerCopyConfiguration = () => {
    let advancedConfig = {
      configurationType: 'advanced',
      forbund: [],
      dslConfigs: [],
    };

    if (advancedConfiguration !== null) {
      advancedConfig = advancedConfiguration;
    }
    const copyConfigurationJobRequest = {
      copySimpleConfigurationJobRequest: simpleConfiguration,
      copyAdvancedConfigurationJobRequest: advancedConfig,
      copyPreviewConfigurationJobRequest: previewConfiguration,
    };

    ApiService.copyConfigurationExecute(copyConfigurationJobRequest, token)
      .then(() => {
        toast.success('Kopiering av konfiguration startad.');
      })
      .catch(error => {
        if (error.status === 501) {
          toast.error(
            'Ett jobb för kopiering av konfiguration körs redan. Det är inte tillåtet att köra mer än ett jobb samtidigt.'
          );
        } else if (error.status === 502) {
          toast.error(error.message);
        } else {
          toast.error('Något gick fel vid kopiering av konfiguration.');
        }
      });
  };

  const handleUpdateUnionSelection = unions => {
    setUnionList(unions);
  };

  const handleUpdateConfiguration = (dslConfiguration, configurationType) => {
    let existingConfiguration = {};

    if (configurationType === 'Simple') {
      existingConfiguration = simpleConfiguration;
    } else if (configurationType === 'Advanced') {
      existingConfiguration = advancedConfiguration;
    } else if (configurationType === 'Preview') {
      existingConfiguration = previewConfiguration;
    }

    const newdslConfigList = existingConfiguration.dslConfigs.map(item => {
      if (item.dsL1Config.DSL1 === dslConfiguration.dsL1Config.DSL1) {
        const updateddslConfig = {
          ...item,
          dsL2Configs: dslConfiguration.dsL2Configs,
        };
        return updateddslConfig;
      }
      return item;
    });

    const newConfiguration = { ...existingConfiguration };
    newConfiguration.dslConfigs = newdslConfigList;

    if (configurationType === 'Simple') {
      setSimpleConfiguration(newConfiguration);
    } else if (configurationType === 'Advanced') {
      setAdvancedConfiguration(newConfiguration);
    } else if (configurationType === 'Preview') {
      setPreviewConfiguration(newConfiguration);
    }
  };

  const handleModalClose = resultOk => {
    if (resultOk === 'true') {
      setShowModal(false);
      triggerCopyConfiguration();
    } else {
      setShowModal(false);
    }
  };

  const unSelectAllUnions = () => {
    const newList = unionList.map(union => {
      const updatedUnion = {
        ...union,
        isSelected: false,
      };
      return updatedUnion;
    });
    setUnionList(newList);
  };

  const unSelectAllDataSources = configuration => {
    if (!configuration) return;
    if (!configuration.dslConfigs) return;
    const newdslConfigList = configuration.dslConfigs.map(dslConfig => {
      const newdsL2ConfigList = dslConfig.dsL2Configs.map(dsl2Config => {
        const updateddsl2Config = {
          ...dsl2Config,
          copyFromTestToProd: false,
        };
        return updateddsl2Config;
      });

      const updateddslConfig = {
        dsL1Config: dslConfig.dsL1Config,
        dsL2Configs: newdsL2ConfigList,
      };

      return updateddslConfig;
    });

    const newConfiguration = { ...configuration };
    newConfiguration.dslConfigs = newdslConfigList;

    if (configuration.configurationType === 'Simple') {
      setSimpleConfiguration(newConfiguration);
    } else if (configuration.configurationType === 'Advanced') {
      setAdvancedConfiguration(newConfiguration);
    } else if (configuration.configurationType === 'Preview') {
      setPreviewConfiguration(newConfiguration);
    }
  };

  const handleError = useCallback(error => {
    if (error.status === 401) {
      console.log('error 401');
      signout();
      history.push('/logga-ut');
    } else {
      setState(() => {
        throw error;
      });
    }
  }, []);

  const handleJobStatusUpdate = jobStatusUpdateMsg => {
    try {
      const jsonJobStatusUpdate = JSON.parse(jobStatusUpdateMsg);

      console.log('JobStatusUpdate:');
      console.log(jsonJobStatusUpdate);
      setJobStatus(prevState => jsonJobStatusUpdate);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log('Efter setJobStatus:');
    console.log(jobStatus);
  }, [jobStatus]);

  // INIT.
  useEffect(() => {
    ApiService.createEventSource(
      'CopyConfigurationJob/status',
      handleJobStatusUpdate
    );

    setIsLoadingSimpleConfiguration(true);

    const simpleConfigurationRequest = {
      fds: {
        forbund: [forbund],
        dsl1: null,
        dsl2: null,
        year: null,
      },
      configurationtype: 'simple',
    };

    const previewConfigurationRequest = {
      fds: {
        forbund: [forbund],
        dsl1: null,
        dsl2: null,
        year: null,
      },
      configurationtype: 'preview',
    };

    const promiseUnions = new Promise((resolve, reject) =>
      ApiService.getUnions(user.token)
        .then(data => resolve(data))
        .catch(error => reject(handleError(error)))
    );

    const promiseSimpleConfiguration = new Promise((resolve, reject) =>
      ApiService.getConfiguration(simpleConfigurationRequest, token)
        .then(data => resolve(data))
        .catch(error => reject(handleError(error)))
    );

    const promisePreviewConfiguration = new Promise((resolve, reject) =>
      ApiService.getConfiguration(previewConfigurationRequest, token)
        .then(data => resolve(data))
        .catch(error => reject(handleError(error)))
    );

    Promise.all([
      promiseUnions,
      promiseSimpleConfiguration,
      promisePreviewConfiguration,
    ])
      .then(
        ([unionData, simpleConfigurationData, previewConfigurationData]) => {
          setUnionList(
            unionData
              .filter(a => a.forbundskodSas !== 'XX')
              .sort((a, b) => `${a.name}`.localeCompare(b.name, 'sv'))
              .map(union => ({ ...union, isSelected: false }))
          );
          setSimpleConfiguration(simpleConfigurationData);
          setPreviewConfiguration(previewConfigurationData);
          autoRefreshToken(token);
        }
      )
      .finally(() => setIsLoadingSimpleConfiguration(false));
  }, [token, forbund]);

  useEffect(() => {
    HasAnySelectionsSimple();
  }, [simpleConfiguration]);

  useEffect(() => {
    HasAnySelectionsPreview();
  }, [previewConfiguration]);

  useEffect(() => {
    HasAnySelectionsAdvanced();
  }, [advancedConfiguration]);

  useEffect(() => {
    if (unionList && unionList.filter(union => union.isSelected).length > 0) {
      const selectedUnions = unionList
        .filter(union => union.isSelected)
        .map(union => {
          return union.forbundskodSas;
        });

      const advancedConfigurationRequest = {
        fds: {
          forbund: selectedUnions,
          dsl1: null,
          dsl2: null,
          year: null,
        },
        configurationtype: 'advanced',
      };
      setIsLoadingAdvancedConfiguration(true);
      ApiService.getConfiguration(advancedConfigurationRequest, token)
        .then(data => setAdvancedConfiguration(data))
        .then(() => {
          autoRefreshToken(token);
        })
        .catch(error => {
          if (error.status === 401) {
            signout();
            history.push('/logga-ut');
          }
        })
        .finally(() => setIsLoadingAdvancedConfiguration(false));
    } else {
      setAdvancedConfiguration(null);
    }
  }, [unionList]);

  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      <Panel>
        <Section title={Const.admin.copyConfigurationHeader}>
          <p>
            Här kan du kopiera konfiguration från testmiljö till
            produktionsmiljö. Välj datakällor för respektive vy och klicka på
            kopiera konfiguration.
          </p>

          <Accordion
            title="Enkel sökning"
            key="Accordion_Simple"
            boxShadow
            marginBottom
            className=""
          >
            <div>
              <br />
              <h4>Välj datakällor</h4>

              {isLoadingSimpleConfiguration ? (
                <div className={styles.loaderAnimation}>
                  <Loader variant="primary" />
                </div>
              ) : (
                <>
                  {simpleConfiguration &&
                    simpleConfiguration.dslConfigs.map((dslConfig, index) => (
                      <React.Fragment key={index}>
                        <DataSource
                          key={`${simpleConfiguration.configurationType}_${dslConfig.dsL1Config.dSL1}`}
                          configurationType={
                            simpleConfiguration.configurationType
                          }
                          dslConfig={dslConfig}
                          handleUpdateConfiguration={handleUpdateConfiguration}
                        />
                        <br />
                      </React.Fragment>
                    ))}

                  <br />
                  {simpleConfiguration && (
                    <ActiveSelections
                      key={`ActiveSelections_${simpleConfiguration.configurationType}`}
                      configurationType={simpleConfiguration.configurationType}
                      dslConfigurations={simpleConfiguration.dslConfigs}
                    />
                  )}
                  <ButtonLink
                    label="Rensa urval"
                    altText="Rensa valda datakällor"
                    className={styles.resetFiltersBtn}
                    onClick={() => {
                      unSelectAllDataSources(simpleConfiguration);
                    }}
                  />
                </>
              )}
            </div>
          </Accordion>

          <Accordion
            title="Avancerad sökning"
            key="Accordion_Advanced"
            boxShadow
            marginBottom
            className=""
          >
            <br />
            <div>
              <h4>Välj förbund</h4>
              <p>
                Välj ett eller flera förbund för vilka inställningarna för
                avancerad sökning ska kopieras.
              </p>
              <Accordion
                title="Förbund"
                boxShadow
                marginBottom
                className=""
                initialOpen
              >
                {unionList && (
                  <UnionSelect
                    key="UnionSelect"
                    unions={unionList}
                    handleUpdateUnionSelection={handleUpdateUnionSelection}
                  />
                )}

                <br />
              </Accordion>

              <br />
              {unionList && (
                <ActiveUnionSelections
                  key="ActiveUnionSelections"
                  unions={unionList}
                />
              )}
              <ButtonLink
                label="Rensa urval"
                altText="Rensa valda förbund"
                className={styles.resetFiltersBtn}
                onClick={() => {
                  unSelectAllUnions();
                }}
              />

              <h4>Välj datakällor</h4>
              {isLoadingAdvancedConfiguration ? (
                <div className={styles.loaderAnimation}>
                  <Loader variant="primary" />
                </div>
              ) : (
                <>
                  {advancedConfiguration &&
                    advancedConfiguration.dslConfigs.map((dslConfig, index) => (
                      <React.Fragment key={index}>
                        <DataSource
                          key={`${advancedConfiguration.configurationType}_${dslConfig.dsL1Config.dSL1}`}
                          configurationType={
                            advancedConfiguration.configurationType
                          }
                          dslConfig={dslConfig}
                          handleUpdateConfiguration={handleUpdateConfiguration}
                        />
                        <br />
                      </React.Fragment>
                    ))}
                  <br />

                  {advancedConfiguration && (
                    <>
                      <ActiveSelections
                        key={`ActiveSelections_${advancedConfiguration.configurationType}`}
                        configurationType={
                          advancedConfiguration.configurationType
                        }
                        dslConfigurations={advancedConfiguration.dslConfigs}
                      />
                    </>
                  )}
                  <ButtonLink
                    label="Rensa urval"
                    altText="Rensa valda datakällor"
                    className={styles.resetFiltersBtn}
                    onClick={() => {
                      unSelectAllDataSources(advancedConfiguration);
                    }}
                  />
                </>
              )}
            </div>
          </Accordion>

          <div>
            <br />
            <ButtonBar>
              {user.role === 'admin' && (
                <Button
                  variant="primary"
                  icon="Copy"
                  onClick={() => setShowModal(true)}
                  label="Kopiera konfiguration"
                  altText="Starta jobb för att kopiera konfiguration"
                  disabled={
                    !(
                      hasAnySelectionsSimple ||
                      hasAnySelectionsPreview ||
                      hasAnySelectionsAdvanced
                    )
                  }
                />
              )}
            </ButtonBar>
          </div>

          <div ref={ref} id="status">
            <StatusBox jobStatus={jobStatus} />
          </div>

          <div ref={ref} id="hjalp">
            <HelpBox />
          </div>

          <Modal
            show={showModal}
            title="Bekräfta"
            // eslint-disable-next-line max-len
            message="All befintlig konfiguration för valda förbund, vyer och datakällor kommer att tas bort i produktionsmiljön och ersättas av konfiguration gjord i testmiljön. (Gäller konfiguration för samtliga år). Vill du kopiera konfigurationen?"
            class="modal"
            id="modal"
            handleClose={handleModalClose}
          >
            {simpleConfiguration && hasAnySelectionsSimple && (
              <>
                <h4>Enkel sökning</h4>
                <ActiveSelections
                  key={`ActiveSelections_${simpleConfiguration.configurationType}`}
                  configurationType={simpleConfiguration.configurationType}
                  dslConfigurations={simpleConfiguration.dslConfigs}
                />
              </>
            )}

            {advancedConfiguration && hasAnySelectionsAdvanced && (
              <>
                <h4>Avancerad sökning</h4>
                Förbund
                {unionList && (
                  <ActiveUnionSelections
                    key="ActiveUnionSelections"
                    unions={unionList}
                  />
                )}
                Datakällor
                <ActiveSelections
                  key={`ActiveSelections_${advancedConfiguration.configurationType}`}
                  configurationType={advancedConfiguration.configurationType}
                  dslConfigurations={advancedConfiguration.dslConfigs}
                />
              </>
            )}

            {previewConfiguration && hasAnySelectionsPreview && (
              <>
                <h4>Öppen sökning</h4>
                <ActiveSelections
                  key={`ActiveSelections_${previewConfiguration.configurationType}`}
                  configurationType={previewConfiguration.configurationType}
                  dslConfigurations={previewConfiguration.dslConfigs}
                />
              </>
            )}
          </Modal>
        </Section>
      </Panel>
    </>
  );
};

export default CopyConfiguration;
