import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import isEmpty from 'lib/emptyValues';
import { Child } from 'pym.js';

import {
  calculatePercentBelowSalary,
  generateOptions,
  handleSelectionData,
  mapStatsToChartData,
  handleSalaryChange,
  getSalaryMarkerIndex,
  getSalaryForFirstMarker,
} from 'lib/preview';

import ApiService from 'services/ApiService';
import Loader from 'atoms/loader/Loader';
import Input from 'atoms/input/Input';
import Select from 'atoms/selectPreview/SelectPreview';
import SVGComponent from 'atoms/svg/SVGComponent';

import { IconChevronDown, IconChevronUp } from 'tabler-icons';

import { InputLabel } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import styles from './Preview.module.css';
import { Colors } from '../../assets/Colors';
import { style } from '@mui/system';

const Preview = () => {
  const location = useLocation();
  const [, setState] = useState();

  const [salary, setSalary] = useState({
    displayValue: '',
    actualNumberValue: 35000,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [open] = useState(false);
  const chartRef = useRef();
  const ref = useRef();

  const [selectedUnion, setSelectedUnion] = useState({});
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [firstSelection, setFirstSelection] = useState({});
  const [secondSelection, setSecondSelection] = useState({});
  const [firstSelectedOption, setFirstSelectedOption] = useState();
  const [secondSelectedOption, setSecondSelectedOption] = useState();

  const [chartData, setChartData] = useState();
  const [salaryStat, setSalaryStat] = useState([]);
  const [salaryPercentage, setSalaryPercentage] = useState('0');
  const [textColor, setTextColor] = useState();

  const handleSelectionDataWrapper = selectionData => {
    handleSelectionData(
      selectionData,
      setSelectedDataSource,
      setFirstSelection,
      setSecondSelection,
      setFirstSelectedOption,
      setSecondSelectedOption
    );
  };

  const mobile = window.innerWidth <= 600;
  const path = `${process.env.PUBLIC_URL}/preview`;

  const applyPreviewFonts = previewConfiguration => {
    if (previewConfiguration !== null) {
      const format = 'opentype';

      const styleElement = document.createElement('style');
      styleElement.innerHTML = `
      @font-face {
        font-family: 'CustomFont';
        src: url('/preview/${previewConfiguration.font}') format('${format}');
      }
      @font-face {
        font-family: 'CustomFont2';
        src: url('/preview/${previewConfiguration.font2}') format('${format}');
      }
      `;

      document.head.appendChild(styleElement);
    }
  };

  const applyPreviewConfiguration = previewConfiguration => {
    if (previewConfiguration !== null) {
      document.documentElement.style.setProperty(
        '--color-label',
        previewConfiguration.labelColor
      );
      document.documentElement.style.setProperty(
        '--color-text',
        previewConfiguration.textColor
      );

      setTextColor(previewConfiguration.selectionColor);

      document.documentElement.style.setProperty(
        '--color-selection',
        previewConfiguration.selectionColor
      );
      document.documentElement.style.setProperty(
        '--color-background',
        previewConfiguration.background
      );
      document.documentElement.style.setProperty(
        '--color-backgroundHeader',
        previewConfiguration.backgroundHeader
      );
    }
  };

  const mapStatsToChartDataWrapper = () => {
    mapStatsToChartData(selectedUnion, salaryStat, salary, setChartData);
  };

  const handleSalaryChangeWrapper = e => {
    handleSalaryChange(e, setSalary);
  };

  const handleError = useCallback(error => {
    if (error.status === 401) {
      console.log('error 401');
    } else {
      setState(() => {
        throw error;
      });
    }
  }, []);

  const updateTextPosition = () => {
    console.log('updateTextPosition 1');
    const userSalaryTextContainerId = 'userSalaryTextContainer';
    const existingDivElement = document.getElementById(
      userSalaryTextContainerId
    );

    console.log('updateTextPosition 2');
    console.log('salary', salary);
    console.log('salary.actualNumberValue', salary.actualNumberValue);
    // Om inte finns lön eller lön är 0 -> ta bort din lön rutan och avbryt
    if (!salary || salary.actualNumberValue === 0) {
      console.log('updateTextPosition 2.1');
      if (existingDivElement) {
        console.log('updateTextPosition 2.2');
        existingDivElement.remove();
      }
      return;
    }

    console.log('updateTextPosition 3');
    console.log('salaryStat.length', salaryStat.length);

    if (!salaryStat || salaryStat.length <= 0) return;

    const salaryForFirstMarker = getSalaryForFirstMarker(salaryStat);
    console.log('salaryForFirstMarker', salaryForFirstMarker);
    // Om det saknas diagramdata eller angiven lön är mindre än första stapelvärdet -> ta bort din lön rutan och avbryt
    if (
      salaryStat.length <= 0 ||
      salary.actualNumberValue < salaryForFirstMarker
    ) {
      console.log('updateTextPosition 3.1');
      if (existingDivElement) {
        console.log('updateTextPosition 3.2');
        existingDivElement.remove();
      }
      return;
    }

    console.log('updateTextPosition 4');
    // Ta bort befintlig din lön ruta innan den nya visas
    if (existingDivElement) {
      console.log('updateTextPosition 4.1');
      existingDivElement.remove();
    }

    console.log('updateTextPosition 5');
    console.log('chartRef', chartRef);
    if (chartRef.current?.chartInstance) {
      console.log('updateTextPosition 5.1');
      const { chartInstance } = chartRef.current;
      console.log('updateTextPosition 5.2');
      const salaryMarkerIndex = getSalaryMarkerIndex(
        salaryStat,
        salary.actualNumberValue
      );

      console.log('salaryMarkerIndex', salaryMarkerIndex);

      if (salaryMarkerIndex === -1) return;

      console.log('updateTextPosition 6');

      const meta = chartInstance.getDatasetMeta(0);
      const userSpecifiedSalaryModel = meta.data[salaryMarkerIndex - 1]._model;

      console.log('meta', meta);
      console.log('userSpecifiedSalaryModel', userSpecifiedSalaryModel);

      console.log('updateTextPosition 7');
      console.log('chartInstance.chartArea', chartInstance.chartArea);
      if (chartInstance.chartArea) {
        console.log('updateTextPosition 7.1');
        const canvasElement = document.querySelector('#chartWrapper canvas');
        const canvasRect = canvasElement.getBoundingClientRect();
        const divElement = document.createElement('div');
        divElement.id = userSalaryTextContainerId;
        divElement.style.position = 'absolute';
        divElement.style.display = 'inline-block';
        divElement.style.width = '58.98px';
        divElement.style.height = '31.95px';

        const leftMargin = mobile ? 40 : 63;
        const userSpecifiedSalaryBarXPosition = userSpecifiedSalaryModel.x;
        const svgArrowXPosition = 37;

        const leftPosition =
          userSpecifiedSalaryBarXPosition + leftMargin - svgArrowXPosition;
        divElement.style.left = `${leftPosition}px`;

        const topPosition = canvasRect.top + userSpecifiedSalaryModel.y - 35;
        divElement.style.top = `${topPosition}px`;
        divElement.style.zIndex = 9999;

        divElement.innerHTML = ReactDOMServer.renderToString(
          <SVGComponent fill={textColor} />
        );

        console.log('updateTextPosition 7.2');
        console.log('divElement.innerHTML', divElement.innerHTML);
        document.body.appendChild(divElement);
      }
    }
  };

  useEffect(() => {
    const pymChild = new Child({ polling: 1000, sendHeight: true });
    pymChild.sendHeight();

    const intervalId = setInterval(() => {
      pymChild.sendHeight();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Initial setup.
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const forbund = searchParams.get('Forbund');
    const chef = searchParams.get('Chef');

    const unionPromise = forbund
      ? ApiService.getUnionByUnionId(forbund)
      : ApiService.getUnionByForbundsKod(forbund);

    unionPromise
      .then(unionData => {
        // console.log('2');
        setSelectedUnion(unionData);
        applyPreviewConfiguration(unionData.previewConfiguration);
        applyPreviewFonts(unionData.previewConfiguration);

        const selectionRequest = {
          UnionId: unionData.id,
          Chef: chef ? JSON.parse(chef) : false,
        };

        return ApiService.getPreviewMenuSelection(selectionRequest);
      })
      .then(handleSelectionDataWrapper)
      .then(() => {
        //console.log('3');
      });

    window.scrollTo(0, 0);

    const handleResize = () => {
      // Kör din kod för att uppdatera textpositionen här
      if (chartData) {
        updateTextPosition();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchSecondOptions = () => {
      if (firstSelectedOption) {
        const filterArray = [
          `${firstSelectedOption.sel_var} in (${firstSelectedOption.value})`,
        ];
        const request = {
          Forbund: selectedUnion.forbundskodSas,
          Dsl1: selectedDataSource.DSL1,
          Dsl2: selectedDataSource.DSL2,
          Year: selectedDataSource.year,
          filter: filterArray,
        };

        // console.log(
        //   'fetchSecondOptions.selectedDataSource',
        //   selectedDataSource
        // );
        //console.log('fetchSecondOptions.request', request);

        // Sortera selectedDataSource.selections baserat på SELECTION_ORDER
        const sortedSelections = selectedDataSource.selections.sort(
          (a, b) => b.SELECTION_ORDER - a.SELECTION_ORDER
        );

        // Välj den första selektionen efter sortering
        const selectedSelection = sortedSelections[0];
        console.log('fetchSecondOptions.selectedSelection', selectedSelection);
        return ApiService.getPreviewMenuSelections(request)
          .then(data => {
            // Processera alternativen för secondSelectedOption
            const secondSelectionOptions = data
              .filter(option => option.sel_var === selectedSelection.sel_var)
              .filter(opt => !isEmpty(opt.value))
              .filter(opt => opt.selectable)
              .map(option => ({
                ...option,
                description: option.desc,
              }));

            // console.log('secondSelection', secondSelection);
            // console.log('secondSelectionOptions', secondSelectionOptions);
            // console.log('secondSelection.sel_var', secondSelection.sel_var);

            setSecondSelection({
              list: secondSelectionOptions,
              name: secondSelection.name,
              sel_var: secondSelection.sel_var,
            });

            if (secondSelectionOptions.length > 0) {
              setSecondSelectedOption(secondSelectionOptions[0]);
            } else {
              setSecondSelectedOption(null);
            }
          })
          .catch(error => handleError(error))
          .finally();
      }
    };

    fetchSecondOptions();
  }, [firstSelectedOption]);

  // useEffect(() => {
  //   console.log('firstSelectedOption', firstSelectedOption);
  //   console.log('secondSelectedOption', secondSelectedOption);

  //   let selVar = '';
  //   if (firstSelectedOption) {
  //     let firstSelectionOptionValue;

  //     if (firstSelectedOption.SELECTION_DATATYPE === 'C') {
  //       firstSelectionOptionValue = `'${firstSelectedOption.value}'`;
  //     } else {
  //       firstSelectionOptionValue = firstSelectedOption.value;
  //     }

  //     selVar = `${firstSelectedOption.sel_var} = ${firstSelectionOptionValue}`;
  //   }

  //   if (secondSelectedOption) {
  //     let secondSelectionOptionValue;

  //     if (secondSelectedOption.SELECTION_DATATYPE === 'C') {
  //       secondSelectionOptionValue = `'${secondSelectedOption.value}'`;
  //     } else {
  //       secondSelectionOptionValue = secondSelectedOption.value;
  //     }

  //     selVar += ` and ${secondSelectedOption.sel_var} = ${secondSelectionOptionValue}`;
  //   }

  //   selVar = selVar.trimLeft(' and ');

  //   console.log('selVar', selVar);

  //   // Om det finns ett firstSelectedOption eller secondSelectedOption, hämta salaryStatData
  //   if (firstSelectedOption || secondSelectedOption) {
  //     const salaryStatRequest = {
  //       Forbund: `'${selectedUnion.forbundskodSas}'`,
  //       Dsl1: selectedDataSource.DSL1,
  //       Dsl2: selectedDataSource.DSL2,
  //       Year: selectedDataSource.year,
  //       Sel_var: selVar,
  //     };

  //     // console.log('salaryStatRequest:', salaryStatRequest);

  //     const promiseSalaryStat = new Promise((resolve, reject) =>
  //       ApiService.getBinstat(salaryStatRequest)
  //         .then(data => resolve(data))
  //         .catch(error => reject(handleError(error)))
  //     );
  //     // Ladda salaryStatData och uppdatera state
  //     Promise.all([promiseSalaryStat])
  //       .then(([salaryStatData]) => {
  //         setSalaryStat(salaryStatData);
  //       })
  //       .finally(() => setIsLoading(false));
  //   }
  // }, [secondSelectedOption]);

  useEffect(() => {
    console.log('firstSelectedOption', firstSelectedOption);
    console.log('secondSelectedOption', secondSelectedOption);

    const buildSelectionVar = selectedOption => {
      if (!selectedOption) return '';

      const { SELECTION_DATATYPE, value, sel_var } = selectedOption;
      const optionValue = SELECTION_DATATYPE === 'C' ? `'${value}'` : value;

      return `${sel_var} = ${optionValue}`;
    };

    const firstSelectionVar = buildSelectionVar(firstSelectedOption);
    const secondSelectionVar = buildSelectionVar(secondSelectedOption);

    let selVar = '';
    if (firstSelectionVar && secondSelectionVar) {
      selVar = `${firstSelectionVar} and ${secondSelectionVar}`;
    } else {
      selVar = firstSelectionVar || secondSelectionVar;
    }

    console.log('selVar', selVar);

    // Om det finns ett firstSelectedOption eller secondSelectedOption, hämta salaryStatData
    if (firstSelectedOption || secondSelectedOption) {
      const salaryStatRequest = {
        Forbund: `'${selectedUnion.forbundskodSas}'`,
        Dsl1: selectedDataSource.DSL1,
        Dsl2: selectedDataSource.DSL2,
        Year: selectedDataSource.year,
        Sel_var: selVar,
      };

      // console.log('salaryStatRequest:', salaryStatRequest);

      const promiseSalaryStat = new Promise((resolve, reject) =>
        ApiService.getBinstat(salaryStatRequest)
          .then(data => resolve(data))
          .catch(error => reject(handleError(error)))
      );
      // Ladda salaryStatData och uppdatera state
      Promise.all([promiseSalaryStat])
        .then(([salaryStatData]) => {
          setSalaryStat(salaryStatData);
        })
        .finally(() => setIsLoading(false));
    }
  }, [secondSelectedOption]);

  useEffect(() => {
    //console.log('6');
    if (salaryStat) {
      mapStatsToChartDataWrapper();

      if (salary) {
        const result = calculatePercentBelowSalary(
          salaryStat,
          salary.actualNumberValue
        );
        setSalaryPercentage(result);
      }
    }
  }, [salaryStat]);

  useEffect(() => {
    //console.log('8');
    mapStatsToChartDataWrapper();
    const result = calculatePercentBelowSalary(
      salaryStat,
      salary.actualNumberValue
    );
    setSalaryPercentage(result);
  }, [salary]);

  const GetSrc = IsfirstSelection => {
    if (IsfirstSelection) {
      //console.log('Preview firstSelection.name', firstSelection.name);
      return `preview/${firstSelection.name}.png`;
    }
    //console.log('Preview secondselection.name', secondSelection.name);
    return `preview/${secondSelection.name}.png`;
  };

  useEffect(() => {
    if (chartData) {
      updateTextPosition();
    }
  }, [chartData]);

  useEffect(() => {
    //console.log('secondSelection useEffect: ', secondSelection);
  }, [secondSelection]);

  const GetTitle = title => {
    if (title === 'Arbetsmarknadssektor') return 'Sektor';

    if (title === 'Titel') return 'Yrkestitel';

    return title;
  };

  return (
    <div className={styles.outerContainer}>
      {isLoading ? (
        <div className={styles.loaderAnimation}>
          <Loader variant="primary" />
        </div>
      ) : (
        <div className={styles.container} ref={ref}>
          <div className={styles.header}>
            <p>Saco Lönesök</p>
          </div>
          <>
            <div>
              {firstSelection.list && secondSelection.list && (
                <div>
                  <div className={styles.speechBubbleContent}>
                    <div className={styles.speechBubbleBoxLeft}>
                      <div>
                        <img alt="alt1" src={GetSrc(true)} />
                      </div>
                    </div>
                    <div className={styles.speechBubbleBox}>
                      <div className={styles.selectBoxRight}>
                        <img alt="alt2" src={GetSrc(false)} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.selectBox}>
                    <div
                      className={`${styles.selectBoxLeft} ${styles.marginBottom}`}
                    >
                      <InputLabel
                        key="firstSelectionLabel"
                        id="firstSelectionLabel"
                      >
                        {GetTitle(firstSelection.name)}
                      </InputLabel>
                      <Select
                        key="firstSelectionSelect"
                        labelId="firstSelectionLabel"
                        id="firstSelectionSelect"
                        onChange={event =>
                          setFirstSelectedOption(JSON.parse(event.target.value))
                        }
                        options={firstSelection.list.map(opt => ({
                          key: opt.description,
                          value: JSON.stringify(opt),
                          desc: opt.description,
                        }))}
                        value={JSON.stringify(firstSelectedOption)}
                      />
                      <div className={styles.activeIcon}>
                        {open ? (
                          <IconChevronUp size={35} role="img" aria-label="" />
                        ) : (
                          <IconChevronDown size={35} role="img" aria-label="" />
                        )}
                      </div>
                    </div>
                    <div
                      className={`${styles.selectBoxRight} ${styles.marginBottom}`}
                    >
                      <InputLabel
                        key="secondSelectionLabel"
                        id="secondSelectionLabel"
                      >
                        {GetTitle(secondSelection.name)}
                      </InputLabel>
                      <Select
                        key="secondSelectionSelect"
                        labelId="secondSelectionLabel"
                        id="secondSelectionSelect"
                        onChange={event =>
                          setSecondSelectedOption(
                            JSON.parse(event.target.value)
                          )
                        }
                        options={secondSelection.list.map(opt => ({
                          key: opt.description,
                          value: JSON.stringify(opt),
                          desc: opt.description,
                        }))}
                        value={JSON.stringify(secondSelectedOption)}
                      ></Select>
                      <div className={styles.activeIcon}>
                        {open ? (
                          <IconChevronUp size={35} role="img" aria-label="" />
                        ) : (
                          <IconChevronDown size={35} role="img" aria-label="" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.selectBox}>
              <div className={styles.selectBoxLeft}>
                <InputLabel>Månadslön</InputLabel>
                <Input
                  className={styles.input}
                  id="salary"
                  placeholder="35 000"
                  type="text"
                  value={salary.displayValue}
                  onChange={handleSalaryChangeWrapper}
                />
              </div>
              <div className={styles.selectBoxRight}>
                <InputLabel>{''}</InputLabel>
                <div className={styles.arrowleft}>
                  <img alt="lon" src="preview/manadslon.png" />
                </div>
              </div>
            </div>
            {chartData && (
              <div className={styles.chart}>
                {salary && !mobile && (
                  <p className={styles.salaryPercentage}>
                    Du tjänar mer än <span>{salaryPercentage} %</span> i samma
                    urvalsgrupp!
                  </p>
                )}
                {salary && mobile && (
                  <p className={styles.salaryPercentage}>
                    Du tjänar mer än <span>{salaryPercentage} %</span>
                    <br /> i samma urvalsgrupp!
                  </p>
                )}
                <div id="chartWrapper">
                  <Bar
                    ref={chartRef}
                    data={chartData}
                    options={generateOptions(
                      salaryStat,
                      salary,
                      selectedUnion.previewConfiguration
                    )}
                  />
                  {''}
                </div>
                <p className={styles.source}>
                  Källa: Sacoförbundens löneenkäter{' '}
                  {selectedDataSource?.year ? selectedDataSource.year : '2022'}
                </p>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default Preview;
