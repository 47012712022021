import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './Unemploymentspin.module.css';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import TabRow from 'atoms/tabrow/TabRow';
import UnemploymentspinTab from './UnemploymentspinTab.js';
import GroupedBarChart from './chart.js';
import ChartAlder from './chartAlder.js';
import ChartUtland from './chartUtland.js';
import ChartKon from './chartKon.js';
import UnemploymentIcon from '../../images/icons/svg/unemployment.svg';
import BarchartIcon from '../../images/icons/svg/barchart.svg';
import DownloadIcon from '../../images/icons/svg/download.svg';
import ArrowIcon from '../../images/icons/svg/arrow2.svg';
import downloadExcel from 'lib/excel';
import ApiService from 'services/ApiService';
import { Child } from 'pym.js';

const mobile = window.innerWidth <= 600;

const Unemploymentspin = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [activeTab, setActiveTab] = useState('utbildning');
  const [data, setData] = useState([]);
  const [hoverText, setHoverText] = useState([]);
  const [yearText, setYearText] = useState([]);
  const [textData, setTextData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // This page is included as an iframe
  useEffect(() => {
    const pymChild = new Child({ polling: 1000, sendHeight: true });
    pymChild.sendHeight();

    const intervalId = setInterval(() => {
      pymChild.sendHeight();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true); // Sätt isLoading till true innan du börjar ladda data
    ApiService.getUnemploymentData()
      .then(mainData => {
        let newData = [];
        switch (activeTab) {
          case 'alder':
            newData = mainData.alderData;
            break;
          case 'utbildning':
            newData = mainData.utbildningData;
            break;
          case 'kon':
            newData = mainData.konData;
            break;
          case 'utlandsfodda':
            newData = mainData.utlandData;
            break;
          default:
            newData = []; // Eller någon annan standardvärde om du vill
            break;
        }

        setData(newData);
        setTextData(mainData.textData);
        setYearText(mainData.yearText);
        setHoverText(mainData.hoverTextData)
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
         // Sätt isLoading till false vid fel
      });
  }, [activeTab, activeButton]);

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  const handleError = error => {};

  const handleDownloadClick = () => {
    downloadExcel(
      'Statitik_arbetslösa_akademiker',
      ApiService.getUnemploymentStaticExcel,
      handleError,
      null,
      null
    );
  };

  return (
    <div className={styles.UnemploymentspinContainer}>
      <div className={styles.UnemploymentspinSummary}>
        <div className={styles.UnemploymentspinHeader}>
          <img src={BarchartIcon} alt="barchart" className={styles.icon} />
          <h2>Saco fakta</h2>
        </div>
        <div className={styles.Baseline}>
          <div className={styles.UnemploymentspinLogo}>
            <img src={UnemploymentIcon} alt="unemployment" />
          </div>
          <div className={styles.PT24px}>
            <h1>Andel arbetslösa akademiker</h1>
            <p className={styles.FirstParagraph}>{yearText.current}</p>
            <div className={styles.UnemploymentspinAbout}>
              <div><p className={styles.SecondParagraph}>{yearText.currentTotal} %</p></div>
              <div><p className={styles.ThirdParagraph}>{textData.header_text}</p></div>
            </div>
          </div>
        </div>
      </div>
      <h1 className={styles.UnemploymentspinSacoGreen}>Fördjupning</h1>
      <TabRow className={styles.UnemploymentspinTabRow}>
        <UnemploymentspinTab
          active={activeTab === 'utbildning'}
          label="Utbildning"
          onClick={() => handleTabClick('utbildning')}
        />
        <UnemploymentspinTab
          active={activeTab === 'alder'}
          label="Ålder"
          onClick={() => handleTabClick('alder')}
        />
        <UnemploymentspinTab
          active={activeTab === 'kon'}
          label="Kön"
          onClick={() => handleTabClick('kon')}
        />
        <UnemploymentspinTab
          active={activeTab === 'utlandsfodda'}
          label={mobile ? 'Födelsereg.' : 'Födelseregion'}
          onClick={() => handleTabClick('utlandsfodda')}
        />
      </TabRow>

      <div className={styles.UnemploymentspinMain}>
        <div className={styles.textContent}>
          {activeTab === 'utbildning' && (
            <p>
              {textData.utbildning_text}{' '}
            </p>
          )}
          {activeTab === 'alder' && (
            <p>
              {textData.alder_text}
            </p>
          )}
          {activeTab === 'kon' && (
            <p>
              {textData.kon_text}
            </p>
          )}
          {activeTab === 'utlandsfodda' && (
            <p>
              {textData.fodelseregion_text}
            </p>
          )}
        </div>
        <div className={styles.tableHead}>
          <h1>Diagram</h1>

          <div className={styles.buttonsContainer}>
            <button className={styles.activeButton}>{yearText.current}</button>
            <button className={styles.button}>{yearText.previous}</button>
          </div>
        </div>
        <div className={styles.unemploymentBarChart}>
          {isLoading ? (
            <p></p>
          ) : (
            <>
              {activeTab === 'utlandsfodda' && (
                <ChartUtland data={data} yearText={yearText} />
              )}
              {activeTab === 'alder' && (
                <ChartAlder data={data} yearText={yearText} />
              )}
              {activeTab === 'kon' && (
                <ChartKon data={data} yearText={yearText} />
              )}
              {activeTab === 'utbildning' && (
                <GroupedBarChart data={data} yearText={yearText} hoverText={Object.values(hoverText)} />
              )}
            </>
          )}
        </div>
        <h1>Tabell</h1>
        <div className={styles.tableContent}>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Utbildning</th>
                  <th>Arbetslöshet</th>
                  <th>Förändring</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.category.toLocaleString('sv-SE')}</td>
                      <td>{item.currentYear.toLocaleString('sv-SE')}</td>
                      <td>
                        {(item.currentYear - item.previousYear)
                          .toFixed(1)
                          .replace('.', ',')}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <p className={styles.UnemploymentspinSource}>
          Källor: Arbetsförmedlingen och SCB. Statistiken visar andelen som är
          öppet arbetslösa eller deltar i något arbetsmarknadsprogram.
        </p>
      </div>
    </div>
  );
};

export default Unemploymentspin;
