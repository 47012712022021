import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'atoms/button/Button';
import Loader from 'atoms/loader/Loader';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import styles from './LinksConfig.module.css';
import LinkCreate from './linkcreate/LinkCreate';
import LinkCard from './linkcard/LinkCard';
import 'react-toastify/dist/ReactToastify.css';

const LinksConfig = ({ configureLvl1 }) => {
  const [createNewLink, setCreateNewLink] = useState(false);
  const [linkCreated, setLinkCreated] = useState(false);
  const [linkUpdated, setLinkUpdated] = useState(false);
  const [linkDeleted, setLinkDeleted] = useState(false);
  const [links, setLinks] = useState();

  const { user } = useAuth();
  useEffect(() => {
    const request = [
      {
        groupId: configureLvl1 ? 'level1link' : 'level2link',
      },
    ];
    ApiService.getTexts(request, user.token).then(data => setLinks(data));
  }, [user.token, configureLvl1]);

  useEffect(() => {
    if (!linkCreated && !linkUpdated && !linkDeleted) return;

    if (linkCreated) {
      toast.success('Länk Skapad');
      setLinkCreated(false);
    }
    if (linkUpdated) {
      toast.success('Länk Uppdaterad');
      setLinkUpdated(false);
    }
    if (linkDeleted) {
      toast.success('Länk Borttagen');
      setLinkDeleted(false);
    }
  }, [linkCreated, linkUpdated, linkDeleted]);

  return (
    <div className={styles.linksConfig}>
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      {links ? (
        links.map(link => (
          <LinkCard
            key={link.id}
            link={link}
            setLinks={setLinks}
            setLinkUpdated={setLinkUpdated}
            setLinkDeleted={setLinkDeleted}
            configureLvl1={configureLvl1}
          />
        ))
      ) : (
        <div className={styles.loaderAnimation}>
          <Loader variant="primary" />
        </div>
      )}
      <section className={styles.addNew}>
        {!createNewLink ? (
          <ButtonBar marginBottom>
            <Button
              className={styles.addLinkButton}
              variant="primary"
              onClick={() => setCreateNewLink(prevState => !prevState)}
              icon="Plus"
              label="Lägg till"
              altText="Lägg till länk"
            />
          </ButtonBar>
        ) : (
          <LinkCreate
            setLinks={setLinks}
            configureLvl1={configureLvl1}
            setCreateNewLink={setCreateNewLink}
            setLinkCreated={setLinkCreated}
          />
        )}
      </section>
    </div>
  );
};

LinksConfig.propTypes = {
  configureLvl1: PropTypes.bool,
};

LinksConfig.defaultProps = {
  configureLvl1: false,
};

export default LinksConfig;
