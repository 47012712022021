import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SimpleMdeReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

export default function MarkdownEditor({
  value,
  onChange,
  autofocus,
  status,
  toolbar,
}) {
  const options = useMemo(() => {
    return {
      autofocus,
      spellChecker: false,
      status,
      toolbar,
    };
  }, [autofocus, status, toolbar]);

  return <SimpleMdeReact value={value} onChange={onChange} options={options} />;
}

MarkdownEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  status: PropTypes.bool,
  // eslint-disable-next-line
  toolbar: PropTypes.any,
};

MarkdownEditor.defaultProps = {
  value: '',
  autofocus: false,
  status: false,
  toolbar: [
    'bold',
    'italic',
    'strikethrough',
    '|',
    'quote',
    'unordered-list',
    'ordered-list',
    '|',
    'undo',
    'redo',
    '|',
    'guide',
    'preview',
  ],
};
