import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTo = (id, callback) => {
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    if (ref.current && location.hash === `#${id}`) {
      ref.current.scrollIntoView({ behavior: 'smooth' });

      if(callback) {
        callback();
      }
    }
    // eslint-disable-next-line
  }, [location, id]);

  return ref;
};

export default useScrollTo;
