/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import Section from 'atoms/section/Section';
import SelectionCard from '../selectioncard/SelectionCard';
import styles from './Situation.module.css';

const Situation = ({
  loading,
  situations,
  setSelectedSituation,
  selectedSituation,
}) => {
  return (
    <Section title="Välkommen till Saco lönesök">
      <fieldset className={styles.subSection}>
        <legend className={styles.subSectionTitle}>
          Välj den situation som passar dig bäst
        </legend>
        <div className={styles.subSectionContent}>
          {situations
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => {
              if (a.DSL2.toLowerCase() === 'df') return -1;
              if (b.DSL2.toLowerCase() !== 'df') return 1;
              return 0;
            })
            .map((situation, index) => (
              <SelectionCard
                description={situation.description}
                index={index}
                key={situation.id}
                label={situation.name}
                loading={loading}
                name="situation"
                selectedSituation={selectedSituation}
                selectionChange={() =>
                  setSelectedSituation({
                    index,
                    DSL1: situation.DSL1,
                    DSL2: situation.DSL2,
                    year: situation.year,
                  })
                }
                value={`wage${index}`}
              />
            ))}
        </div>
      </fieldset>
    </Section>
  );
};

Situation.propTypes = {
  selectedSituation: PropTypes.shape({ index: PropTypes.number }),
  loading: PropTypes.bool.isRequired,
  situations: PropTypes.arrayOf(Object).isRequired,
  setSelectedSituation: PropTypes.func.isRequired,
};

Situation.defaultProps = {
  selectedSituation: undefined,
};
export default Situation;
