/* eslint-disable camelcase */
import React from 'react';
import styles from './HelpBox.module.css';

const HelpBox = () => {
  return (
    <div className={styles.helpBox}>
      <h3>Hjälp</h3>
      <p>För att konfigurera förbundsanpassningar gör följande:</p>
      <ul className="generalList">
        <li>Skapa situationer</li>
        <ul>
          <li>1..* (en till många)</li>
          <li>Om det bara finns en situation så visas den inte i Enkel vy.</li>
          <li>
            Tjänsten anropar automatiskt SAS och hämtar de år som är inlagda i
            SAS.
          </li>
        </ul>
        <li>Välj och ordna filter/urval</li>
        <ul>
          <li>
            Tjänsten anropar automatiskt SAS och hämtar den data (urval) som
            finns där.
          </li>
          <li>Om det bara finns en situation så visas den inte i Enkel vy.</li>
        </ul>
        <li>Länkar</li>
        <ul>
          <li>Lägg till flera länkar till förbundets sajt för mer läsning.</li>
        </ul>
      </ul>
    </div>
  );
};

HelpBox.propTypes = {};

HelpBox.defaultProps = {};

export default HelpBox;
