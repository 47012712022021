import React from 'react';
import Panel from '../../../atoms/panel/Panel';
import BarCharts from './barCharts/BarCharts';
import PieCharts from "./pieCharts/PieCharts";

const VisitorStatistics = () => {
  return (
    <Panel>
      <BarCharts />
      <PieCharts />
    </Panel>
  );
};

export default VisitorStatistics;
