import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActiveSelections.module.css';

const FilterString = (item, index, length) => {
  let filterString = item.DSL2_DESC.trimStart().trimEnd();

  if (filterString === 'Okänt') {
    filterString = 'Samtliga';
  }

  if (length === 1 || index === 0) {
    return filterString;
  }
  if (index === length - 1) {
    return ` & ${filterString}`;
  }
  return `,  ${filterString}`;
};

const ActiveSelections = ({ configurationType, dslConfigurations }) => {
  return (
    <div id="active-filters" className={styles.activeFilters}>
      <span className={styles.activeFiltersHeading}>{'Gjorda urval: '}</span>
      {dslConfigurations.length > 0 &&
        dslConfigurations
          .filter(
            dslConfiguration =>
              dslConfiguration.dsL2Configs.filter(
                dsL2Config => dsL2Config.copyFromTestToProd === true
              ).length > 0
          )
          .map(dslConfiguration => (
            <div
              style={{ display: 'inline-block' }}
              key={`${configurationType}_${dslConfiguration.dsL1Config.DSL1}`}
            >
              <span className={styles.filterName}>
                {`${dslConfiguration.dsL1Config.DSL1_DESC}:`}
              </span>

              <span className={styles.filterButtonWrapper}>
                {dslConfiguration.dsL2Configs
                  .filter(dsL2Config => dsL2Config.copyFromTestToProd === true)
                  .map((dsL2Config, index, { length }) => (
                    <React.Fragment
                      key={`${configurationType}_${dsL2Config.DSL2}`}
                    >
                      {FilterString(dsL2Config, index, length)}
                    </React.Fragment>
                  ))}
              </span>
            </div>
          ))}
    </div>
  );
};

ActiveSelections.propTypes = {
  configurationType: PropTypes.string.isRequired,
  dslConfigurations: PropTypes.arrayOf(Object).isRequired,
};

export default ActiveSelections;
