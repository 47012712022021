import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PageErrorBoundary from 'pages/errorboundary/PageErrorBoundary';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

import styles from './App.module.css';
import { ProvideAuth } from './services/UseAuth';

import AppRouter from './AppRouter';

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <PageErrorBoundary>
          <Header />
          <div className={`${styles.contentContainer} ${styles.scaledContent}`}>
            <AppRouter />
          </div>
          <Footer />
        </PageErrorBoundary>
      </Router>
    </ProvideAuth>
  );
}
