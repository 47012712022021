import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Level2Simple from 'components/admin/level2simple/Level2Simple';
import Level3Advanced from 'components/admin/level3advanced/Level3Advanced';
import PreviewConfig from 'components/admin/previewconfig/PreviewConfig';
import FilterExplanations from 'components/admin/filterexplanations/FilterExplanations';
import Accounts from 'components/admin/accounts/Accounts';
import ApplicationLog from 'components/admin/applicationlog/ApplicationLog';
import MonitoringLog from 'components/admin/monitoringlog/MonitoringLog';
import Select from 'atoms/select/Select';
import GeneralTexts from 'components/admin/generaltexts/GeneralTexts';
import NavigationItem from 'components/admin/navigationItem/navigationItem';
import ApiService from 'services/ApiService';
import { useAuth, AdminRoute } from 'services/UseAuth';
import UnionConfig from 'components/admin/unionConfig/UnionConfig';
import CopyConfiguration from 'components/admin/copyconfiguration/CopyConfiguration';
import styles from './PageAdmin.module.css';
import Const from '../../const';
import Notifications from '../../components/admin/notifications/Notifications';
import VisitorStatistics from '../../components/admin/visitorstatistics/VisitorStatistics';
import AppSettings from '../../appsettings.json';

const PageAdmin = () => {
  document.title = 'Admin - Saco lönesök';
  const { user, storeUser } = useAuth();
  const { forbund, token } = user;
  const [adminForbund, setAdminForbund] = useState(forbund);
  const [forbundList, setForbundList] = useState([]);

  const ENVIRONMENT = AppSettings.APP_ENVIRONMENT;

  // todo: redaktör refresh
  const refreshToken = forbundsKod => {
    const req = {
      token,
      forbundsKodAdmin: forbundsKod.toUpperCase(),
    };
    ApiService.refreshAdminToken(req, token).then(data => {
      storeUser(data);
    });
  };

  // Initially, get list of forbund
  useEffect(() => {
    if (token === null) return;
    ApiService.getUnions(token).then(data => {
      setForbundList(
        data
          .filter(a => a.forbundskodSas !== 'XX')
          .sort((a, b) => {
            if (a.forbundskodSas === 'SA') return -1;
            if (b.forbundskodSas === 'SA') return 1;
            return `${a.name}`.localeCompare(b.name, 'sv');
          })
      );
    });
  }, [token]);

  console.log(user.role);
  console.log(AppSettings.ENABLE_COPY_CONFIGURATION);
  
  return (
    <div className={styles.pageAdmin}>
      <div className={styles.leftColumn}>
        <h1 className={styles.adminH1}>Inställningar</h1>
        {user.role === 'admin' && (
          <div className={styles.forbundSelectWrapper}>
            <Select
              id="login-forbund-input"
              admin
              onChange={e => {
                setAdminForbund(e.target.value);
                refreshToken(e.target.value);
              }}
              options={forbundList.map(({ id, name, forbundskodSas }) => ({
                key: id,
                value: forbundskodSas ? forbundskodSas.toLowerCase() : '',
                desc: name,
              }))}
              value={adminForbund.toLowerCase()}
            />
          </div>
        )}
        <nav>
          <div className={styles.toolBar}>
            <NavigationItem
              to="/admin/nivå2"
              title={Const.admin.level2Header}
              active={useLocation().pathname === '/admin/nivå2'}
            />
            <NavigationItem
              to="/admin/nivå3"
              title={Const.admin.level3Header}
              active={useLocation().pathname === '/admin/nivå3'}
              hidden={user.role === 'redaktor'}
            />
            <NavigationItem
              to="/admin/previewconfig"
              title="Öppen sökning"
              active={useLocation().pathname === '/admin/previewconfig'}
              hidden
            />
            <NavigationItem
              to="/admin/filters"
              title={Const.admin.filterExplanations}
              active={useLocation().pathname === '/admin/filters'}
            />

            <NavigationItem
              to="/admin/kopiera-konfiguration"
              title={Const.admin.copyConfigurationHeader}
              active={useLocation().pathname === '/admin/kopiera-konfiguration'}
              hidden={
                !(
                  user.role === 'admin' &&
                  AppSettings.ENABLE_COPY_CONFIGURATION === 'true'
                )
              }
            />
            <NavigationItem
              to="/admin/text"
              title={Const.admin.commonTexts}
              active={useLocation().pathname === '/admin/text'}
            />
            <NavigationItem
              to="/admin/union"
              title={Const.admin.union}
              active={useLocation().pathname === '/admin/union'}
            />
            <NavigationItem
              to="/admin/notifications"
              title={Const.admin.notifications}
              active={useLocation().pathname === '/admin/notifications'}
            />
            <NavigationItem
              to="/admin/accounts"
              title={Const.admin.accounts}
              active={useLocation().pathname === '/admin/accounts'}
            />
          </div>

          <h2 className={styles.navHeader}>Övrigt</h2>
          <div className={styles.toolBar}>
            <NavigationItem
              to="/admin/stats"
              title={Const.admin.visitiorStatistics}
              active={useLocation().pathname === '/admin/stats'}
            />
            <NavigationItem
              to="/admin/log"
              title={Const.admin.applicationLog}
              active={useLocation().pathname === '/admin/log'}
              hidden={user.role === 'redaktor'}
            />
          </div>
        </nav>
      </div>
      <div className={styles.mainContent}>
        <AdminRoute
          component={UnionConfig}
          path="/admin/union"
          forbundList={forbundList}
        />
        <AdminRoute component={Level2Simple} path="/admin/nivå2" />
        <AdminRoute component={PreviewConfig} path="/admin/previewconfig" />
        <AdminRoute component={Level3Advanced} path="/admin/nivå3" restrict />
        <AdminRoute component={GeneralTexts} path="/admin/text" />
        <AdminRoute component={FilterExplanations} path="/admin/filters" />
        <AdminRoute component={VisitorStatistics} path="/admin/stats" />
        <AdminRoute component={ApplicationLog} path="/admin/log" restrict />
        <AdminRoute
          component={MonitoringLog}
          path="/admin/monitoringlog"
          restrict
        />
        <AdminRoute
          component={MonitoringLog}
          path="/admin/monitoringlog/:Id"
          restrict
        />
        <AdminRoute component={Accounts} path="/admin/accounts" />
        <AdminRoute component={Notifications} path="/admin/notifications" />
        <AdminRoute
          component={CopyConfiguration}
          path="/admin/kopiera-konfiguration"
        />
      </div>
    </div>
  );
};

export default PageAdmin;
