import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import Input from 'atoms/input/Input';
import Panel from 'atoms/panel/Panel';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';

const EditAccount = ({
  userAccount,
  setUserList,
  setAccountDeleted,
  setAccountUpdated,
  setEditAccount,
}) => {
  const [isAdmin, setIsAdmin] = useState(userAccount.isAdmin);
  const [isSaving, setIsSaving] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const { isRedaktor, isFortroendevald } = userAccount;

  // Refs
  const passwordRef = useRef();

  const { user } = useAuth();
  const handleUpdateAccount = () => {
    setIsSaving(true);
    // FIXME: Borde göra en promise kedja här så vi kan vara säkra på att saker sker.
    // Nu är isSaving och toaster states inte 100% rätt.
    if (userAccount.isAdmin !== isAdmin) {
      ApiService.updateAccount(
        userAccount.id,
        { isAdmin, isRedaktor, isFortroendevald },
        user.token
      ).then(() => null);
    }
    if (newPassword.length > 0) {
      if (!passwordRef.current.reportValidity()) {
        setIsSaving(false);
        return;
      }

      ApiService.updatePassword(
        userAccount.id,
        { password: newPassword },
        user.token
      ).then(() => null);
    }

    setUserList(prevState =>
      prevState.map(usr => {
        if (usr.id === userAccount.id) {
          return {
            ...userAccount,
            isAdmin,
          };
        }
        return usr;
      })
    );
    setAccountUpdated(true);
    setIsSaving(false);
    setEditAccount(false);
  };

  const handleDeleteAccount = () => {
    setIsSaving(true);
    ApiService.deleteSubscriber(userAccount.id, user.token)
      .then(() => {
        setAccountDeleted(true);
        setUserList(prevState =>
          prevState.filter(usr => usr.id !== userAccount.id)
        );
      })
      .finally(() => {
        setEditAccount(false);
        setIsSaving(false);
      });
  };

  return (
    <Panel card legend="Redigera konto">
      {user.role === 'admin' && (
        <form>
          <CheckboxAdmin
            id="isAdmin"
            label="Administratör"
            checked={isAdmin}
            onChange={() => setIsAdmin(prevState => !prevState)}
          />
          <br />
          <br />
          <Input
            autoComplete="new-password"
            disabled={isSaving}
            id="user-edit-password"
            label="Lösenord"
            minLength={10}
            onChange={evt => setNewPassword(evt.target.value)}
            placeholder="Nytt lösenord..."
            ref={passwordRef}
            type="password"
            value={newPassword}
          />
        </form>
      )}
      <br />
      <ButtonBar>
        <Button
          variant="delete"
          onClick={() => handleDeleteAccount()}
          label="Ta bort"
          icon="Trash"
          altText="Ta bort konto"
          disabled={isSaving}
        />
        {user.role === 'admin' && (
          <Button
            variant="primary"
            onClick={() => handleUpdateAccount()}
            label="Spara"
            altText="Spara konto"
            disabled={
              isSaving || (isAdmin === userAccount.isAdmin && !newPassword)
            }
          />
        )}
      </ButtonBar>
    </Panel>
  );
};

EditAccount.propTypes = {
  userAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isFortroendevald: PropTypes.bool,
    isRedaktor: PropTypes.bool,
    isAdmin: PropTypes.bool,
    label: PropTypes.string,
  }).isRequired,
  setUserList: PropTypes.func.isRequired,
  setAccountDeleted: PropTypes.func.isRequired,
  setAccountUpdated: PropTypes.func.isRequired,
  setEditAccount: PropTypes.func.isRequired,
};

export default EditAccount;
