import { Margin } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';


const ChartKon = ({ data, yearText }) => {
  const currentYearSeries = data.map(x => x.currentYear);
  const previousYearSeries = data.map(x => x.previousYear);

  const series = [
    { data: currentYearSeries, color: '#058FA2' },
    { data: previousYearSeries, color: '#005561' },
  ];

  const categories = ['Män', 'Kvinnor'];

  const annotations = categories.map((category, index) => ({
    y: index, // Använd index för varje kategori som x-position

    label: {
      text: category,
      offsetY: 40 + index * 40, // Justera vertikal förskjutning för att placera kategorierna ovanför staplarna
      style: {
        background: 'transparent',
        color: '#000',
        fontSize: '14px',
        stroke: 'transparent',
        borderWidth: 0, // Ta bort ramen runt titlarna
      },
    },
  }));

  const options = {
    stroke: {
      colors: ['transparent'],
      width: 0,
    },
    chart: {
      type: 'bar',
      height: 20,
      horizontal: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: { position: 'top' },
        barHeight: '50%', // Ändra här för att justera höjden på staplarna
        barWidth: '200%', // Justera bredden på staplarna
        borderWidth: 0, // Ta bort ramen runt staplarna.
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
        fontFamily: 'ProximaNova-SemiBold', // Lägg till din font här
      },
      formatter: function (val) {
        return `${val.toLocaleString()} %`;
      },
    },
    tooltip: {
      shared: false, // Använd en tooltip för varje serie
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = categories[dataPointIndex]; // Hämta kategorin för den aktuella stapeln
        const currentYearValue = series[0][dataPointIndex]; // Hämta värdet för den aktuella stapeln
        const previousYearValue = series[1][dataPointIndex]; // Hämta värdet för den aktuella stapeln

        const previousYearDate = yearText.previous;
        const currentYearDate = yearText.current;

        const tooltipText = `<div class="custom-tooltip">
                              <div class="custom-tooltip-container">
                                <div class="tooltip-header">${category}</div>
                                <div class="tooltip-content-current-year">${currentYearDate} ${currentYearValue.toLocaleString()} %</div>
                                <div class="tooltip-content-previous-year">${previousYearDate} ${previousYearValue.toLocaleString()} %</div>
                                </div>
                            </div>`;
        return tooltipText;
      },
    },
    yaxis: {
      categories,
      show: false,
    },
    xaxis: {
      max: 10, // Ange maxvärde för x-axeln
      min: 0,
      show: false, // Göm x-axeln
      tickAmount: 10, // Ange antalet tickar på x-axeln
      labels: {
        formatter: function (val) {
          return val + ' %';
        },
      },
    },
    annotations: {
      position: 'front',
      yaxis: [
        {
          y: 0,
          label: {
            text: 'Män',
            position: 'left',
            offsetX: 5,
            textAnchor: 'start',
            offsetY: 26,
            style: {
              fontFamily: 'Proxima Nova',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#000000',
              background: 'transparent',
            },
          },
        },
        {
          y: 0,
          label: {
            text: 'Kvinnor',
            position: 'left',
            offsetX: 5,
            textAnchor: 'start',
            offsetY: 118,
            zIndex: 2,
            style: {
              fontFamily: 'Proxima Nova',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#000000',
              background: 'transparent',
            },
          },
        },
      ],
      // xaxis: categories.map((category, index) => ({
      //   x: index, // Placera linjen vid varje kategori på x-axeln
      //   borderWidth: 1,
      //   offsetY: 0, // Justera vertikalt för att passa diagrammet
      //   strokeDashArray: 0,
      //   borderColor: '#c2c2c2',
      //   fillColor: '#c2c2c2',
      // })),
    },

    legend: {
      show: false, // Göm legenden
    },
  };

  ChartKon.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    yearText: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  return (
    <div>
      <style>
        {`.apexcharts-toolbar {
      display: none !important; 
      }
      rect[stroke="#c2c2c2"][fill="transparent"] {
        stroke-width: 0 !important; /* Sätt stroke-width till 0 för att ta bort ramen */
        z-index: 200 !important;
      }
      
      line[stroke-dasharray="1"][y1^="-"], line[stroke-dasharray="1"][y2^="-"] {
        display: none;
      }

      .apexcharts-xaxis-tick {
        stroke: none !important;
      }

      .apexcharts-annotation-label {
        stroke-width: 0 !important;
        border: none !important;
        box-shadow: none !important;
        z-index: 2 !important;
      }

      .apexcharts-xaxis-annotation-label {
        z-index: 2 !important;
      }

      .custom-tooltip {
        position: relative;
        border-radius: 8px;
        background-color: #FFFFFF;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));
      }

      .custom-tooltip-container {
        position: relative;
        border-radius: 8px;
        padding: 16px 40px 20px 14px;
        background-color: #FFFFFF;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));
      }
      .tooltip-header {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        max-width: 150px;
        padding-bottom: 10px;
        white-space: break-spaces;
      }
      
      .tooltip-content-current-year {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #058FA2;
        padding-bottom: 8px;
      }
      
      .tooltip-content-previous-year {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #005561;
      }
      
      .triangle {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 778.32px;
        top: 1830.32px;
        background: #FFFFFF;
        border-radius: 2px;
        transform: rotate(-90.77deg);
      }
      
      
      `}
      </style>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={250}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ChartKon;
