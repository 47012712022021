import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Select from 'atoms/select/Select';
import Const from 'const';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import AccountRow from './accountrow/AccountRow';
import styles from './Accounts.module.css';
import CreateAccount from './createaccount/CreateAccount';

const Accounts = () => {
  const [userList, setUserList] = useState([]);
  const [addAccount, setAddAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState('');

  const [accountUpdated, setAccountUpdated] = useState(false);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  useEffect(() => {
    setIsLoading(true);
    ApiService.getSubscribers(token)
      .then(data => {
        data.sort((a, b) => `${a.name}`.localeCompare(b.name, 'sv'));
        setUserList(data);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (!accountUpdated && !accountDeleted && !accountCreated) return;

    if (accountCreated) {
      toast.success('Konto Skapat');
      setAccountCreated(false);
    }
    if (accountUpdated) {
      toast.success('Konto Uppdaterat');
      setAccountUpdated(false);
    }
    if (accountDeleted) {
      toast.error('Konto Borttaget');
      setAccountDeleted(false);
    }
  }, [accountUpdated, accountDeleted, accountCreated]);

  const filteredUserList = userList.filter(
    userObj => filter === '' || userObj.forbundsKodSas === filter
  );

  return (
    <Panel>
      <Section title={Const.admin.accounts}>
        <ToastContainer position="top-center" autoClose={3000} closeOnClick />
        {isLoading ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            {user.role === 'admin' && (
              <div className={styles.filter}>
                <Select
                  compact
                  disabled={userList.length < 1}
                  label="Filtrera på förbund"
                  id="account_filter"
                  onChange={e => setFilter(e.target.value)}
                  options={[
                    { key: 'user_null', value: '', desc: 'Samtliga förbund' },
                    ...userList
                      .filter(
                        (a, b, arr) =>
                          arr.findIndex(obj => obj.name === a.name) === b
                      )
                      .map(
                        ({ id: key, forbundsKodSas: value, name: desc }) => ({
                          key,
                          value,
                          desc,
                        })
                      ),
                  ]}
                  value={filter}
                />
              </div>
            )}
            <div className={styles.accountTable}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Användarnamn</th>
                    <th>Förbund</th>
                    <th>Roll</th>
                    <th>Etikett</th>
                    <th>Hantera</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUserList.length > 0 &&
                    filteredUserList.map(usr => (
                      <AccountRow
                        key={usr.id}
                        userAccount={usr}
                        setUserList={setUserList}
                        setAccountDeleted={setAccountDeleted}
                        setAccountUpdated={setAccountUpdated}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            {!addAccount && (
              <ButtonBar>
                <Button
                  variant="primary"
                  onClick={() => setAddAccount(prevState => !prevState)}
                  icon="Plus"
                  altText="Lägg till konto"
                  label="Lägg till"
                />
              </ButtonBar>
            )}

            <div className={styles.createAccountBox}>
              {addAccount && (
                <CreateAccount
                  setAccountCreated={setAccountCreated}
                  setAddAccount={setAddAccount}
                  setUserList={setUserList}
                />
              )}
            </div>
          </>
        )}
      </Section>
    </Panel>
  );
};

Accounts.propTypes = {};

export default Accounts;
