import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import EditAccount from '../editaccount/EditAccount';
import styles from './AccountRow.module.css';

const AccountRow = ({
  userAccount,
  setUserList,
  setAccountDeleted,
  setAccountUpdated,
}) => {
  const [editAccount, setEditAccount] = useState(false);

  const { id, name, label } = userAccount;

  const getRole = account => {
    if (!account) return '';
    if (account.isAdmin) return 'Admin';
    if (account.isRedaktor) return 'Redaktör';
    if (account.isFortroendevald) return 'Förtroendevald';
    return 'Medlem';
  };

  return (
    <>
      <tr className={`${userAccount.isAdmin ? styles.admin : ''}`}>
        <td>{id}</td>
        <td>{name}</td>
        <td>{getRole(userAccount)}</td>
        <td>{label}</td>
        <td>
          {editAccount ? (
            <Button
              variant="secondary"
              onClick={() => setEditAccount(prevState => !prevState)}
              style={{ marginLeft: 'auto' }}
              label="Avbryt"
            />
          ) : (
            <Button
              variant="primary"
              onClick={() => setEditAccount(prevState => !prevState)}
              style={{ marginLeft: 'auto' }}
              label="Redigera"
              icon="Edit"
            />
          )}
        </td>
      </tr>
      {editAccount && (
        <tr>
          <td colSpan={5}>
            <EditAccount
              userAccount={userAccount}
              setUserList={setUserList}
              setAccountDeleted={setAccountDeleted}
              setAccountUpdated={setAccountUpdated}
              setEditAccount={setEditAccount}
            />
          </td>
        </tr>
      )}
    </>
  );
};

AccountRow.propTypes = {
  userAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isFortroendevald: PropTypes.bool,
    isRedaktor: PropTypes.bool,
    isAdmin: PropTypes.bool,
    label: PropTypes.string,
  }).isRequired,
  setUserList: PropTypes.func.isRequired,
  setAccountDeleted: PropTypes.func.isRequired,
  setAccountUpdated: PropTypes.func.isRequired,
};

export default AccountRow;
