import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'atoms/select/Select';
import styles from './DataSourceSelect.module.css';

const DataSourceSelect = ({
  dataSources,
  dsl1,
  dsl2,
  role,
  setDsl1,
  setDsl2,
  setRole,
  setYear,
  year,
}) => {
  const [availableYears, setAvailableYears] = useState([]);
  const [availableDSL1, setAvailableDSL1] = useState([]);
  const [availableDSL2, setAvailableDSL2] = useState([]);

  useEffect(() => {
    if (dataSources == null) return;
    setAvailableYears(
      dataSources
        .reduce((acc, curr) => {
          if (!acc.includes(curr.YEAR)) acc.push(curr.YEAR);
          return acc;
        }, [])
        .sort()
        .reverse()
    );
  }, [dataSources]);

  useEffect(() => {
    // TODO: Något sätt att hämta ut DSL1/2 baserat på år.
    if (year == null) {
      // Nollställ
      setAvailableDSL1([]);
      setAvailableDSL2([]);
      return;
    }

    const dsl1Temp = dataSources
      .filter(dataSource => dataSource.YEAR === year)
      .reduce((acc, curr) => {
        if (!acc.some(a => a.dsl === curr.DSL1))
          acc.push({ dsl: curr.DSL1, desc: curr.DSL1_DESC });
        return acc;
      }, []);
    setAvailableDSL1(dsl1Temp);
  }, [year, dataSources]);

  useEffect(() => {
    if (dsl1 === '') {
      setDsl2('');
      return;
    }

    setAvailableDSL2(
      dataSources
        .filter(dataSource => dataSource.YEAR === year)
        .filter(dataSource => dataSource.DSL1 === dsl1)
        .map(dataSource => {
          return { dsl: dataSource.DSL2, desc: dataSource.DSL2_DESC };
        })
        .filter(datasource => datasource.dsl.length)
    );
    // eslint-disable-next-line
  }, [dsl1, dataSources, year]);

  useEffect(() => {
    if (dsl2 !== '' && !availableDSL2.some(d => d.dsl === dsl2)) setDsl2('');
    if (dsl1 !== '' && !availableDSL1.some(d => d.dsl === dsl1)) setDsl1('');
  }, [availableDSL1, availableDSL2]);

  return (
    <div className={styles.searchInput}>
      <Select
        disabled={availableYears.length < 1}
        label="År"
        id="year"
        compact
        marginBottom
        value={year}
        onChange={e => {
          e.persist();
          setYear(Number(e.target.value));
        }}
        options={[
          { key: 'year_null', value: '', desc: 'Inget valt' },
          ...availableYears
            .map(availableYear => ({
              key: availableYear,
              value: availableYear,
              desc: availableYear,
            }))
            .reverse(),
        ]}
      />
      <Select
        disabled={availableDSL1.length < 1}
        label="DSL1"
        id="dsl1"
        value={dsl1}
        compact
        marginBottom
        onChange={e => {
          e.persist();
          setDsl1(e.target.value);
        }}
        options={[
          { key: 'dsl1_null', value: '', desc: 'Inget valt' },
          ...availableDSL1.map(({ dsl, desc }) => ({
            key: dsl,
            value: dsl,
            desc,
          })),
        ]}
      />
      <Select
        disabled={availableDSL2.length < 1}
        label="DSL2"
        id="dsl2"
        value={dsl2}
        compact
        marginBottom
        onChange={e => {
          e.persist();
          setDsl2(e.target.value);
        }}
        options={[
          { key: 'dsl2_null', value: '', desc: 'Inget valt' },
          ...availableDSL2.map(({ dsl, desc }) => ({
            key: dsl,
            value: dsl,
            desc,
          })),
        ]}
      />
      {role !== null && (
        <Select
          label="Roll"
          id="role"
          value={role}
          compact
          marginBottom
          onChange={e => {
            e.persist();
            setRole(e.target.value);
          }}
          options={[
            { key: 'medlem', value: 'medlem', desc: 'Medlem' },
            {
              key: 'fortroendevald',
              value: 'fortroendevald',
              desc: 'Förtroendevald',
            },
          ]}
        />
      )}
    </div>
  );
};

DataSourceSelect.propTypes = {
  dataSources: PropTypes.arrayOf(Object),
  dsl1: PropTypes.string.isRequired,
  dsl2: PropTypes.string.isRequired,
  role: PropTypes.string,
  setDsl1: PropTypes.func.isRequired,
  setDsl2: PropTypes.func.isRequired,
  setRole: PropTypes.func,
  setYear: PropTypes.func.isRequired,
  year: PropTypes.number,
};

DataSourceSelect.defaultProps = {
  dataSources: null,
  role: null,
  setRole: null,
  year: undefined,
};

export default DataSourceSelect;
