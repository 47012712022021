import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Select from 'atoms/select/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import SortableList from '../level3advanced/datasourceconfig/sortablelist/SortableList';
import styles from './PreviewSalaryProfileConfig.module.css';

const PreviewSalaryProfileConfig = ({ situations, setSituations }) => {
  const [selectionList, setSelectionList] = useState();
  const [currentSituation, setCurrentSituation] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth();

  const handleSubmit = () => {
    setIsSaving(true);
    const situationId = currentSituation.id; // TODO: Hämta upp situationsid

    const request = {
      selections: selectionList.map(selection => {
        return {
          id: selection.id,
          isActive: selection.isActive,
          selectionOrder: selection.SELECTION_ORDER,
          sortOptionsByNumber: selection.sortOptionsByNumber,
        };
      }),
    };

    ApiService.updateSelections(situationId, request, user.token)
      .then(data => {
        const tempData = [...situations];
        const index = tempData.findIndex(
          situation => situation.id === situationId
        );

        if (index > -1) {
          tempData[index] = data;
        }

        setSituations(tempData);
        toast.success('Statistikkälla uppdaterad');
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <section className={styles.main}>
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      <div>
        <div className={styles.wrapForm}>
          <Select
            id="situation"
            label="Statistikkälla"
            compact
            onChange={evt => {
              const { value } = evt.target;
              if (value) {
                setSelectionList(situations[Number(value)].selections);
                setCurrentSituation({ id: situations[Number(value)].id });
              } else {
                setSelectionList(null);
                setCurrentSituation(null);
              }
            }}
            options={
              situations && [
                { desc: 'Ingen vald', key: '', value: '' },
                ...situations
                  .filter(situation => situation.isActive)
                  .map((situation, i) => ({
                    key: situation.id,
                    value: i,
                    desc: `${situation.name} - ${situation.year}`,
                  })),
              ]
            }
          />
        </div>
      </div>
      {selectionList && (
        <SortableList
          data={selectionList}
          isSaving={isSaving}
          updateFunction={setSelectionList}
          variant="selection"
        />
      )}
      {currentSituation && (
        <ButtonBar marginBottom>
          {/* TODO: Om man gör ändringar borde knappen bli enabled */}
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            label="Spara"
            altText="Spara vald ordning"
            disabled={isSaving}
          />
          {/* <Button type="Button" variant="secondary" label="AVBRYT" /> */}
        </ButtonBar>
      )}
    </section>
  );
};

PreviewSalaryProfileConfig.propTypes = {
  situations: PropTypes.arrayOf(Object).isRequired,
  setSituations: PropTypes.func.isRequired,
};

export default PreviewSalaryProfileConfig;
