import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import { useHistory } from 'react-router-dom';
import downloadExcel from 'lib/excel';
import styles from './DownloadExcel.module.css';

const DownloadExcel = ({ 
    selectedPresentation, 
    tableData, 
    activeFilters, 
    activeDataSource, 
    dataSources, 
    measureVar
}) => {
  // TODO: Accept null for fields?
  // Would reflect the actual table html better
  const { DSL1_DESC, DSL2_DESC, FORBUND, YEAR } = activeDataSource && dataSources.find(
    source =>
    source.FORBUND === activeDataSource.forbund &&
    source.DSL1 === activeDataSource.dsl1 &&
    source.DSL2 === activeDataSource.dsl2 &&
    source.YEAR === activeDataSource.year
  );

  const request = {
    breakby1Header: selectedPresentation[0]
      ? selectedPresentation[0]?.REPORT_TEXT
      : null,
    breakby2Header: selectedPresentation[1]
      ? selectedPresentation[1]?.REPORT_TEXT
      : null,
    lonereport: tableData
    .sort((row1, row2) => row2._TYPE_ - row1._TYPE_)               // eslint-disable-line no-underscore-dangle
    .map(row => row._TYPE_ === 0 ? {...row, breakby1: "Samtliga"} : row),   // eslint-disable-line no-underscore-dangle

    dataSource: {
        forbund: FORBUND,
        dsl1: DSL1_DESC,
        dsl2: DSL2_DESC,
        year: YEAR
    },
    filters: activeFilters.map(f => { return {name: f.name, values: f.values.map(x => x.desc)}}),
    measureVar
  }
  const isData = request.lonereport.length > 0;

  const [, setState] = useState();

  const { user, signout } = useAuth();

  const history = useHistory();

  // TODO: Better error handling?
  const handleError = error => {
    if (error.status === 401) {
      signout();
      history.push('/logga-ut');
    } else {
      setState(() => {
        throw error;
      });
    }
  };

  return (
    <ButtonLink
      label="Spara som Excel"
      altText="Ladda ner Excel"
      className={styles.optionBtn}
      disabled={!isData}
      menuItem
      lastChild
      onClick={() => {
        downloadExcel(
          'Report',
          ApiService.getReportExcel,
          handleError,
          request,
          user.token
        );
      }}
    />
  );
};

DownloadExcel.propTypes = {
  selectedPresentation: PropTypes.arrayOf(Object),
  tableData: PropTypes.arrayOf(Object),
  activeDataSource: PropTypes.shape({
      forbund: PropTypes.string,
      dsl1: PropTypes.string,
      dsl2: PropTypes.string,
      year: PropTypes.number,
  }),
  dataSources: PropTypes.arrayOf(Object),
  activeFilters: PropTypes.arrayOf(Object),
  measureVar: PropTypes.string.isRequired
};

DownloadExcel.defaultProps = {
  selectedPresentation: null,
  tableData: [],
  dataSources: [],
  activeDataSource: {},
  activeFilters: [],
};

export default DownloadExcel;
