import React from 'react';
import PropTypes from 'prop-types';
import styles from './Statisticsometer.module.css';

const ROUND_TO = 5;
const LIMIT = 97;

const Statisticsometer = ({
  currentRespondents,
  hasPartiallyFilledFilters,
  totalRespondents,
  variant,
  marginBottom,
}) => {
  const percent = (currentRespondents / totalRespondents) * 100;
  const roundedPercent = Math.ceil(percent / ROUND_TO) * ROUND_TO;

  const presentationText = () => {
    if (roundedPercent < 10) {
      return 'Visar statistik som baseras på mindre än 10 % av tillgängligt dataunderlag';
    }

    if (roundedPercent >= LIMIT) {
      return `Visar statistik som baseras på ${
        hasPartiallyFilledFilters ? 'ca' : ''
      } 100 % av tillgängligt dataunderlag`;
    }

    return `Visar statistik som baseras på ca ${roundedPercent} % av tillgängligt dataunderlag`;
  };

  return (
    <div
      className={`${styles.statisticsometer}  ${
        marginBottom ? styles.hasMarginBottom : ''
      }`}
    >
      <div className={styles.statisticsText}>{presentationText()}</div>
      <div
        className={`${styles.meter} ${percent >= LIMIT ? styles.full : ''} ${
          variant === 'advanced' ? styles.advanced : styles.overview
        }`}
        style={{ width: `${roundedPercent >= 100 ? 100 : roundedPercent}%` }}
      />
    </div>
  );
};

Statisticsometer.propTypes = {
  currentRespondents: PropTypes.number.isRequired,
  hasPartiallyFilledFilters: PropTypes.bool.isRequired,
  totalRespondents: PropTypes.number.isRequired,
  variant: PropTypes.string,
  marginBottom: PropTypes.bool,
};

Statisticsometer.defaultProps = {
  variant: 'overview',
  marginBottom: false,
};

export default Statisticsometer;
