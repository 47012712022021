
import InstructionsVideo from 'components/advanced/explanations/explanationgroup/instructionsvideo/InstructionsVideo';
import React, { useState, useEffect } from 'react';
import { Route, Redirect, useParams, useHistory } from 'react-router-dom';

const Instructionvideo = ({ ...rest }) => {
    const { level } = useParams();

    return (
        <div>
            <InstructionsVideo level={level} />
        </div>
    );
};

export default Instructionvideo;

