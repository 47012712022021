import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from 'atoms/breadcrumbs/Breadcrumbs';
import styles from './ToolBar.module.css';
import DownloadSection from './downloadsection/DownloadSection';

const ToolBar = ({
  selectedPresentation,
  tableData,
  activeFilters,
  activeDataSource,
  dataSources,
  measureVar,
}) => {
  return (
    <div className={styles.toolBar}>
      <div>
        <Breadcrumbs />
      </div>
      <div>
        <div className={styles.menuItem}>
          <DownloadSection
            selectedPresentation={selectedPresentation}
            tableData={tableData}
            activeFilters={activeFilters}
            activeDataSource={activeDataSource}
            dataSources={dataSources}
            measureVar={measureVar}
          />
        </div>
      </div>
    </div>
  );
};

ToolBar.propTypes = {
  selectedPresentation: PropTypes.arrayOf(Object),
  tableData: PropTypes.arrayOf(Object),
  activeDataSource: PropTypes.shape({
    forbund: PropTypes.string,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
    year: PropTypes.number,
  }),
  dataSources: PropTypes.arrayOf(Object),
  activeFilters: PropTypes.arrayOf(Object),
  measureVar: PropTypes.string.isRequired,
};

ToolBar.defaultProps = {
  selectedPresentation: null,
  tableData: [],
  dataSources: [],
  activeDataSource: {},
  activeFilters: [],
};

export default ToolBar;
