import React, { useState } from 'react';
import Button from 'atoms/button/Button';
import PropTypes from 'prop-types';
import styles from './Modal.module.css';

const Modal = ({ show, title, message, children, handleClose }) => {
  // const handleOkClick = () => {
  //   console.log('Hej innifrån modal');
  //   handleClose();
  // };

  if (show === false) {
    return null;
  }
  return (
    <>
      <div className={styles.modaldarkBG} />
      <div className={styles.modalCentered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.modalHeading}>{title}</h5>
          </div>
          <div className={styles.modalBody}>
            <h5 className={styles.modalMessage}>{message}</h5>
          </div>
          <div className={styles.modalContent}>{children}</div>
          <div className={styles.modalButtons}>
            <Button
              className={styles.modalBtn}
              onClick={() => handleClose('true')}
              variant="primary"
              label="    Ok    "
              altText="Ok"
              disabled={false}
            />
            <Button
              className={styles.modalBtn}
              variant="primary"
              onClick={() => handleClose('false')}
              label="Avbryt"
              altText="Avbryt"
              disabled={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func,
};

Modal.defaultProps = {
  // setShow: PropTypes.func,
};

export default Modal;
