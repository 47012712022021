import React from 'react';
import PropTypes from 'prop-types';
import styles from './Slider.module.css';

function Slider({ id, max, min, changeFunction, step, value }) {
  return (
    <input
      className={styles.slider}
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={e => changeFunction(e.target.value)}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      onInput={e => changeFunction(e.target.value)}
      name={id}
      id={id}
      aria-label="Löneslider"
      onKeyUp={e => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          changeFunction(e.target.value);
        }
      }}
      tabIndex="0"
    />
  );
}

Slider.propTypes = {
  id: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  changeFunction: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default Slider;