import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Select from 'atoms/select/Select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import DefaultSourceConfig from './defaultsourceconfig/DefaultSourceConfig';
import styles from './StandardConfig.module.css';

const StandardYearConfig = ({ availableYears, toastMessage, dataSources }) => {
  const [
    defaultYearFromDeepAnalysis,
    setDefaultYearFromDeepAnalysis,
  ] = useState();
  const [defaultYearToDeepAnalysis, setDefaultYearToDeepAnalysis] = useState();
  const [defaultDataSource, setDefaultDataSource] = useState();
  const [allowCustomPresentation, setAllowCustomPresentation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth();

  const handleChangeStandardConfig = () => {
    setIsSaving(true);
    const request = {
      defaultDataSource,
      defaultYearFromDeepAnalysis:
        defaultYearFromDeepAnalysis < defaultYearToDeepAnalysis
          ? defaultYearFromDeepAnalysis
          : 0,
      defaultYearToDeepAnalysis,
      allowCustomPresentation,
    };

    ApiService.postConfiguredYear(user.unionId, request, user.token)
      .then(() => toastMessage('success', 'Standardkonfiguration uppdaterad'))
      .catch(err => {
        toastMessage('error', 'Fel vid uppdatering av standardkonfiguration');
        throw err;
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    setIsLoading(true);
    ApiService.getUnion(user.token)
      .then(data => {
        setDefaultDataSource(data.defaultDataSource);
        setDefaultYearFromDeepAnalysis(data.defaultYearFromDeepAnalysis);
        setDefaultYearToDeepAnalysis(data.defaultYearToDeepAnalysis);
        setAllowCustomPresentation(data?.allowCustomPresentation);
      })
      .finally(() => setIsLoading(false));
  }, [user]);

  return (
    <SectionAdmin title="Standardkonfiguration">
      <p>Ställ in standardvärden för nivå 3</p>
      <h4>Konfigurera standardår</h4>
      <p>
        Utöka medlemmars befogenheter i nivå 3 genom att ange ett årsintervall
        inom vilket medlemmar får se lönestatistik. Begränsa medlemmars rätt att
        hämta rapporter genom att inte alls ange ett &quot;år från&quot; eller
        välja året innan &quot;år till&quot; (ex. år från: 2020, år till: 2021).
      </p>
      <p>
        Förtroendevalda ser alla urval och presentationssätt och kan också välja
        olika år.
      </p>
      <div className={styles.grid}>
        <Select
          label="År från"
          id="arfran"
          compact
          loading={isLoading}
          value={defaultYearFromDeepAnalysis}
          onChange={evt => {
            return setDefaultYearFromDeepAnalysis(Number(evt.target.value));
          }}
          options={[
            {
              key: 'arfran_null',
              value: '',
              desc: 'Välj ett startår för intervallet...',
            },
            ...availableYears
              .filter(y => y < defaultYearToDeepAnalysis)
              .sort()
              .map(year => ({
                key: year,
                value: year,
                desc: year,
              })),
          ]}
        />
        <Select
          label="År till"
          id="artill"
          compact
          loading={isLoading}
          value={defaultYearToDeepAnalysis}
          onChange={evt => {
            return setDefaultYearToDeepAnalysis(Number(evt.target.value));
          }}
          options={[
            {
              key: 'artill_null',
              value: '',
              desc: 'Välj ett slutår för intervallet...',
            },
            ...availableYears.sort().map(year => ({
              key: year,
              value: year,
              desc: year,
            })),
          ]}
        />
      </div>
      <h4>Konfigurera standardkälla</h4>
      <p>
        Välj en källa inom intervallet för standardår som är förvald för
        användare av nivå 3
      </p>
      <DefaultSourceConfig
        dataSources={dataSources}
        defaultDataSource={defaultDataSource}
        defaultYearFromDeepAnalysis={defaultYearFromDeepAnalysis}
        defaultYearToDeepAnalysis={defaultYearToDeepAnalysis}
        setDefaultDataSource={setDefaultDataSource}
      />
      <h4>Tillåt egen rapport</h4>
      <p>
        Tillåt medlemmar att välja &quot;egen rapport&quot; under presentationer
        för att skapa en egen lönerapport.
      </p>
      <CheckboxAdmin
        id="customPresentation"
        label="Egen rapport"
        checked={allowCustomPresentation}
        disabled={isSaving || isLoading}
        onChange={() => setAllowCustomPresentation(prevState => !prevState)}
      />
      <br />
      <ButtonBar>
        <Button
          color="#99d2d9"
          onClick={() => handleChangeStandardConfig()}
          variant="primary"
          label="Spara"
          altText="Spara sektion"
          disabled={
            defaultYearFromDeepAnalysis === null ||
            defaultYearToDeepAnalysis === null ||
            isSaving
          }
        />
      </ButtonBar>
      <br />
    </SectionAdmin>
  );
};

StandardYearConfig.propTypes = {
  availableYears: PropTypes.arrayOf(PropTypes.number).isRequired,
  toastMessage: PropTypes.func.isRequired,
  dataSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

StandardYearConfig.defaultProps = {};

export default StandardYearConfig;
