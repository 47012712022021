import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styles from './Input.module.css';

const Input = forwardRef(
  (
    {
      autoComplete,
      className,
      disabled,
      id,
      label,
      onChange,
      onBlur, // Lägg till onBlur här
      placeholder,
      type,
      value,
      max,
      min,
      minLength,
      step,
      title,
      pattern,
      displayInline,
    },
    ref
  ) => {
    return (
      <div
        className={`${styles.inputWrapper} ${
          displayInline ? styles.displayInline : ''
        }`}
      >
        {label && (
          <label
            htmlFor={id}
            className={`${styles.inputLabel} ${
              disabled ? styles.disabled : ''
            }`}
          >
            {`${label}:`}
          </label>
        )}
        <input
          autoComplete={autoComplete}
          className={`${styles.input} ${styles[type] || ''} ${className}`}
          disabled={disabled}
          id={id}
          max={max}
          min={min}
          minLength={minLength}
          onChange={onChange}
          onBlur={onBlur} // Lägg till onBlur här
          pattern={pattern}
          placeholder={placeholder}
          ref={ref}
          step={step}
          title={title}
          type={type}
          value={value}
        />
      </div>
    );
  }
);

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  displayInline: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func, // Lägg till onBlur här
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  autoComplete: 'on',
  className: '',
  displayInline: false,
  disabled: false,
  label: '',
  placeholder: null,
  type: 'text',
  value: '',
  max: null,
  min: null,
  minLength: null,
  step: null,
  title: null,
  pattern: null,
  onChange: () => {},
  onBlur: () => {}, // Lägg till onBlur här
};

export default Input;
