import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './PageErrorBoundary.module.css';

class PageErrorBoundary extends Component {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.main}>
          <h1 className={styles.header}> Ånej! Saco Lönesök fungerar inte.</h1>

          <p className={styles.text}>
            Tyvärr verkar det som att vår webbplats inte är tillgänglig för
            tillfället. Testa gärna igen senare, vi jobbar med problemet och
            hoppas att allt fungerar snart igen.
          </p>

          <p className={styles.text}>
            Har du akuta frågor om lön eller statistik? Kontakta ditt förbund.
          </p>

          <p className={styles.text}>
            Har du tekniska frågor om Lönesök på en övergripande nivå? Hör av
            dig till{' '}
            <a href="mailto:lonesok@saco.se" className={styles.link}>
              lonesok@saco.se
            </a>{' '}
            och ange vilket förbund du tillhör.
          </p>

          <p className={styles.text}>Tack för ditt tålamod!</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(PageErrorBoundary);
