import React, { useRef, useState } from 'react';
import styles from './PreviewLab.module.css';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Bar } from 'react-chartjs-2';

const dummyData = [
  {
    percentage: 1,
    profession: 'ekonom',
    sector: 'public',
    salary: 13000,
  },
  {
    percentage: 2,
    profession: 'ekonom',
    sector: 'public',
    salary: 15000,
  },
  {
    percentage: 2,
    profession: 'ekonom',
    sector: 'public',
    salary: 16000,
  },
  {
    percentage: 3,
    profession: 'ekonom',
    sector: 'public',
    salary: 17000,
  },
  {
    percentage: 4,
    profession: 'ekonom',
    sector: 'public',
    salary: 17500,
  },
  {
    percentage: 5,
    profession: 'ekonom',
    sector: 'public',
    salary: 18000,
  },
  {
    percentage: 6,
    profession: 'ekonom',
    sector: 'public',
    salary: 22000,
  },
  {
    percentage: 6,
    profession: 'ekonom',
    sector: 'public',
    salary: 27000,
  },
  {
    percentage: 7,
    profession: 'ekonom',
    sector: 'public',
    salary: 29000,
  },
  {
    percentage: 8,
    profession: 'ekonom',
    sector: 'public',
    salary: 32000,
  },
  {
    percentage: 8,
    profession: 'ekonom',
    sector: 'public',
    salary: 33000,
  },
  {
    percentage: 6,
    profession: 'ekonom',
    sector: 'public',
    salary: 34000,
  },
  {
    percentage: 4,
    profession: 'ekonom',
    sector: 'public',
    salary: 36000,
  },
  {
    percentage: 3,
    profession: 'ekonom',
    sector: 'public',
    salary: 37000,
  },
  {
    percentage: 3,
    profession: 'ekonom',
    sector: 'public',
    salary: 38000,
  },
  {
    percentage: 2,
    profession: 'ekonom',
    sector: 'public',
    salary: 38500,
  },
  {
    percentage: 1,
    profession: 'ekonom',
    sector: 'public',
    salary: 39000,
  },
  {
    percentage: 1,
    profession: 'ekonom',
    sector: 'public',
    salary: 40000,
  },
  {
    percentage: 1,
    profession: 'ekonom',
    sector: 'public',
    salary: 41500,
  },
];

const PreviewLab = () => {
  const [education, setEducation] = useState('');
  const [sector, setSector] = useState('');
  const [salary, setSalary] = useState(0);
  const chartRef = useRef();

  const barColorBasedOnPercentage = chart => {
    const indexSalary = chart.dataset.data[chart.dataIndex];
    return salary > indexSalary.salary ? '#0049a1' : '#9c9b9b';
  };

  const mapData = () => {
    var result = dummyData.map(salaryStatistic => {
      return { y: salaryStatistic.percentage, ...salaryStatistic };
    });

    console.log('result: ' + JSON.stringify(result));

    return result;
  };

  const chartData = {
    labels: [
      '13000',
      '15000',
      '16000',
      '17000',
      '18000',
      '22000',
      '27000',
      '28000',
      '29000',
      '30500',
      '31000',
      '32000',
      '33000',
      '34000',
      '35500',
      '36000',
      '37200',
      '39000',
      '42000',
    ],
    datasets: [
      {
        datasetIdKey: 'percentage',
        label: 'Procent',
        backgroundColor: c => barColorBasedOnPercentage(c),
        data: mapData(),
      },
    ],
  };

  const options = {
    responsive: true,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    parsing: {
      xAxisKey: 'percentage',
      yAxisKey: 'percentage',
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            maxTicksLimit: chartData.labels.length / 3,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  console.log('LAB Chartdata: ' + JSON.stringify(chartData));
  console.log('LAB Options: ' + JSON.stringify(options));

  return (
    <div className={styles.container}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <FormControl variant="standard" sx={{ m: 1, width: 135 }}>
          <InputLabel id="educationLabel">Utbildning</InputLabel>
          <Select
            labelId="educationLabel"
            id="education"
            value={education}
            onChange={event => setEducation(event.target.value)}
          >
            <MenuItem value="ekonom">Ekonom</MenuItem>
            <MenuItem value="civilingenjör">Civilingenjör</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: 135 }}>
          <InputLabel id="sectorLabel">Sektor</InputLabel>
          <Select
            labelId="sectorLabel"
            id="sector"
            value={sector}
            onChange={event => setSector(event.target.value)}
          >
            <MenuItem value="offentlig sektor">Offentlig sektor</MenuItem>
            <MenuItem value="privat sektor">Privat sektor</MenuItem>
          </Select>
        </FormControl>
        <NumericFormat
          allowLeadingZeros={false}
          allowNegative={false}
          customInput={TextField}
          id="formatted-numberformat-input"
          label="Månadslön"
          onValueChange={values => {
            setSalary(values.floatValue);
          }}
          onChange={e => console.log(e.target.value)}
          sx={{ m: 1, width: 135 }}
          thousandSeparator=" "
          variant="standard"
          value={salary}
          InputProps={{
            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
          }}
        />
      </div>
      {education && sector && salary ? (
        <p style={{ textAlign: 'center' }}>
          Du tjänar mer än <b>49 procent</b> i samma urvalsgrupp.
        </p>
      ) : (
        <p style={{ textAlign: 'center' }}>
          Ange <b>utbildning</b>, <b>sektor</b> och <b>månadslön</b> i
          ovanstående formulär.
        </p>
      )}
      <Bar ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default PreviewLab;
