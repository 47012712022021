const STARTING_YEAR = 2021;
export const today = new Date();
export const oneYearFromNow = new Date(today.getTime() + 86400000 * 365);

export const months = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];

// "Valid" is a year from 100 years ago up until this year
function isValidHistoricalYear(num) {
  return num > today.getFullYear() - 100 && num <= today.getFullYear();
}

export function isTimeUnit(breakbyValue) {
  if (!breakbyValue) return false;
  // The breakby value might be a year in a string
  // Like so: "1965"
  const year = Number(breakbyValue);

  // The breakby value might be a span of years which we also want to consider a unit of time
  // Like this: "1940-1945"
  const firstYear = !year && breakbyValue.split('-')[0];

  if (year && isValidHistoricalYear(year)) {
    return true;
  }

  if (firstYear && isValidHistoricalYear(firstYear)) {
    return true;
  }

  return false;
}

// Returns a list of all years from set of data or from STARTING_YEAR
export function getAvailableYears(data) {
  const thisYear = today.getFullYear();
  let yearsPassed;
  let list;

  if (data) {
    const dates = [...data]
      .map(({ created }) => created)
      .sort((a, b) => Date.parse(a) - Date.parse(b));

    const minYear = new Date(dates[0]).getFullYear();
    const maxYear = new Date(dates.reverse()[0]).getFullYear();
    yearsPassed = maxYear - minYear;
    list = [minYear];
  } else {
    yearsPassed = thisYear - STARTING_YEAR;
    list = [STARTING_YEAR];
  }

  if (yearsPassed > 0) {
    for (let i = 1; i < yearsPassed + 1; i += 1) {
      list.push(list[0] + i);
    }
  }

  return list;
}

// Returns a list of all available months from set of data
export function getAvailableMonths(data, year) {
  if (year === '' || year === 'all') return [];
  const dates = [...data]
    .map(({ created }) => created)
    .filter(date => {
      return new Date(date).getFullYear() === year;
    })
    .sort((a, b) => Date.parse(a) - Date.parse(b));

  const minMonth = new Date(dates[0]).getMonth();
  const maxMonth = new Date(dates.reverse()[0]).getMonth();
  const monthsPassed = maxMonth - minMonth;

  const res = [minMonth];

  if (monthsPassed > 0) {
    for (let i = 1; i < monthsPassed + 1; i += 1) {
      res.push(minMonth + i);
    }
  }

  return res;
}
