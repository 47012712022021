import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import ExplanationText from 'atoms/explanationtext/ExplanationText';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Accordion from 'atoms/accordion/Accordion';
import styles from './FilterExplanationItem.module.css';
import FilterExplanationEdit from '../filterexplanationedit/FilterExplanationEdit';

const FilterExplanationItem = ({ text, setFilterTexts }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Accordion
      title={
        text != null &&
        `${text.header} - ${text.textId} ${
          text.unionId ? ' - förbundsspecifik' : ''
        }`
      }
      slim
      border
      marginBottom
    >
      {!editMode && (
        <div className={styles.optionsList}>
          {!text.unionId && (
            <span className={styles.subHeader}>Delad text:</span>
          )}
          <ExplanationText textItem={text} />
          <ButtonBar>
            <Button
              variant="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => setEditMode(prevState => !prevState)}
              label="Redigera"
              altText="Redigera filtertext"
              icon="Edit"
            />
          </ButtonBar>
        </div>
      )}
      {editMode && (
        <div className={styles.optionsList}>
          <FilterExplanationEdit
            text={text}
            setEditMode={setEditMode}
            setFilterTexts={setFilterTexts}
          />
        </div>
      )}
    </Accordion>
  );
};

FilterExplanationItem.propTypes = {
  text: PropTypes.shape({
    id: PropTypes.string,
    groupId: PropTypes.string,
    header: PropTypes.string,
    textId: PropTypes.string,
    value: PropTypes.string,
    unionId: PropTypes.string,
    isDefault: PropTypes.bool,
  }).isRequired,
  setFilterTexts: PropTypes.func.isRequired,
};

export default FilterExplanationItem;
