import React from 'react';
import PropTypes from 'prop-types';
import { IconX} from 'tabler-icons';
import styles from './ButtonActiveFilter.module.css';

const ButtonActiveFilter = ({
  label,
  className,
  disabled,
  onClick,
  style,
}) => {
  const buttonClassName = className && className !== '' ? `${styles[className]}` : '';

  return (
    <button
      alt={`Ta bort filter ${label}`}
      className={`${styles.buttonActiveFilter} ${buttonClassName}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type="button"
    >
      <span className={styles.buttonText}>{label}</span>
      <IconX className={styles.buttonIcon} aria-label="" size={16} role="img" />
    </button>
  );
};

ButtonActiveFilter.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

ButtonActiveFilter.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: () => {},
  style: undefined,
};

export default ButtonActiveFilter;
