import React from 'react';
import PropTypes from 'prop-types';
import ExplanationText from 'atoms/explanationtext/ExplanationText';
import styles from './ExplanationItem.module.css';
import boxPlotImage from '../../../../../images/box_plot_image.png';
import Accordion from '../../../../../atoms/accordion/Accordion';

const ExplanationItem = ({ header, textId, textItem }) => {
  const { groupId } = textItem;
  const renderImage = groupId === 'graph' && textId === 'box';

  return (
    <div className={styles.explanationItem}>
      {textId !== 'main' && <h3>{header}</h3>}
      <ExplanationText textItem={textItem} />

      {renderImage && (
        <Accordion title={'Infografik om lådagram'} border>
          <img
            src={boxPlotImage}
            className={styles.image}
            alt="Box plot infographic"
          />
        </Accordion>
      )}
    </div>
  );
};

ExplanationItem.propTypes = {
  header: PropTypes.string,
  textId: PropTypes.string.isRequired,
  textItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

ExplanationItem.defaultProps = {
  header: '',
};

export default ExplanationItem;
