import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getWeek } from 'date-fns';
import styles from '../VisitorStatistics.module.css';
import { Colors } from '../../../../assets/Colors';

const options = {
  title: {
    display: true,
    text: 'Antal besök per vecka',
    fontSize: 16,
    fontColor: Colors.black,
  },
  responsive: true,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: 'black',
      formatter: value => {
        if (value === 0) {
          return '';
        }
        return value.toLocaleString();
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: value => value.toLocaleString().replace(',', ' '),
        },
        afterDataLimits(scale) {
          var range = scale.max - scale.min;
          var grace = range * 0.05;
          scale.max += grace;
        },
      },
    ],
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
};

const WeekChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [tableData, setTableData] = useState();
  const currentYear = new Date().getFullYear().toString();
  const lastYear = (currentYear - 1).toString();

  const mapStatsToChartData = statsMap => {
    const datasets = [
      {
        label: 'Medlem',
        data: Array.from(statsMap.values()).map(weekData => weekData.medlem),
        backgroundColor: Colors.turquoise,
        maxBarThickness: 70,
      },
      {
        label: 'Förtroendevald',
        data: Array.from(statsMap.values()).map(
          weekData => weekData.fortroendevald
        ),
        backgroundColor: Colors.yellow,
        maxBarThickness: 70,
      },
    ];
    const currentDate = new Date();
    const currentWeek = getWeek(currentDate);

    const labels = [];
    const statsMapKeysArray = Array.from(statsMap.keys());

    for (let i = 0; i < statsMapKeysArray.length; i += 1) {
      if (Number(statsMapKeysArray[i]) > currentWeek) {
        labels[i] = `${`v.${statsMapKeysArray[i]}`} ${lastYear.substring(
          0,
          4
        )}`;
      } else {
        labels[i] = `${`v.${statsMapKeysArray[i]}`} ${currentYear.substring(
          0,
          4
        )}`;
      }
    }

    setChartData({ labels, datasets });
  };

  const mapStatsToTableData = statsMap => {
    const currentDate = new Date();
    const currentWeek = getWeek(currentDate);
    const labels = [];
    const statsMapKeysArray = Array.from(statsMap.keys());

    for (let i = 0; i < statsMapKeysArray.length; i += 1) {
      if (Number(statsMapKeysArray[i]) > currentWeek) {
        labels[i] = `${`${statsMapKeysArray[i]}`} ${lastYear.substring(0, 4)}`;
      } else {
        labels[i] = `${`${statsMapKeysArray[i]}`} ${currentYear.substring(
          0,
          4
        )}`;
      }
    }

    const datasets = [
      {
        label: 'Medlem',
        data: Array.from(statsMap.values()).map(weekData => weekData.medlem),
        backgroundColor: Colors.turquoise,
        maxBarThickness: 70,
      },
      {
        label: 'Förtroendevald',
        data: Array.from(statsMap.values()).map(
          weekData => weekData.fortroendevald
        ),
        backgroundColor: Colors.yellow,
        maxBarThickness: 70,
      },
    ];
    setTableData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      const currentDate = new Date();
      const currentWeek = getWeek(currentDate);

      const entries = Object.entries(stats);
      entries.sort(([weekA], [weekB]) => Number(weekA) - Number(weekB));

      const lastYearWeeks = entries.filter(
        ([week]) => Number(week) > currentWeek
      );
      const currentYearWeeks = entries.filter(
        ([week]) => Number(week) <= currentWeek
      );

      const sortedEntries = [...lastYearWeeks, ...currentYearWeeks];
      const sortedStats = new Map(sortedEntries);

      const weeksHalfyearBackEntries = sortedEntries.slice(-26);
      const weeksHalfyearBackStats = new Map(weeksHalfyearBackEntries);
      mapStatsToChartData(weeksHalfyearBackStats);
      mapStatsToTableData(weeksHalfyearBackStats);
    }
  }, [stats]);

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  return (
    <div>
      {chartData && (
        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
      {tableData && (
        <div className={styles.chartStats}>
          <div className={styles.tableToggle} onClick={toggleTable}>
            {isTableExpanded ? 'Dölj tabell' : 'Visa som tabell'}
          </div>
          {isTableExpanded && (
            <div style={{ display: 'flex' }}>
              <table style={{ marginRight: '20px' }}>
                <thead>
                  <tr>
                    <th></th>
                    {chartData.datasets.map((dataset, index) => (
                      <th key={index}>{dataset.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.labels.slice(0, 13).map((label, index) => (
                    <tr key={index}>
                      <td>{`Vecka ${label}`}</td>
                      {tableData.datasets.map((dataset, i) => (
                        <td key={i}>
                          {dataset.data[index]?.toLocaleString() ?? ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {chartData.datasets.map((dataset, index) => (
                      <th key={index}>{dataset.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.labels.slice(13, 26).map((label, index) => (
                    <tr key={index}>
                      <td>{`Vecka ${label}`}</td>
                      {tableData.datasets.map((dataset, i) => (
                        <td key={i}>
                          {dataset.data[index + 13]?.toLocaleString() ?? ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default WeekChart;

WeekChart.propTypes = {
  stats: PropTypes.any,
};
