import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'atoms/accordion/Accordion';
import Section from 'atoms/section/Section';
import ExplanationGroup from './explanationgroup/ExplanationGroup';
import InstructionsVideo from './explanationgroup/instructionsvideo/InstructionsVideo';
import styles from './Explanations.module.css';

const Explanations = ({ filterList, forbund, id }) => {
  var newFilterList = filterList
    .map(filterItem => filterItem.sel_var)
    .concat('LON');

  if (forbund === 'SN' || 'LA' || 'CF') {
    newFilterList = newFilterList.concat('FLON');
  }

  const explanationGroups = [
    {
      groupId: 'statistics',
      title: 'Var kommer statistiken ifrån?',
    },

    {
      groupId: 'graph',
      title: 'Olika diagram',
      textId: ['line', 'bar', 'box'],
    },

    {
      groupId: 'measurements',
      title: 'Statistiska mått',
    },

    {
      groupId: 'menuselection',
      title: 'Förklaring av filter och begrepp',
      textId: newFilterList,
      available: newFilterList.length > 0,
    },
  ];
  return (
    <Section
      className={styles.helpSection}
      id={id}
      title="Förklaring och hjälp"
    >
      <div className={styles.helpItemWrapper}>
        <Accordion
          id="instructionalVideo"
          title="Instruktionsfilmer om Saco lönesök"
          boxShadow
          marginBottom
        >
          <div title="Instruktionsfilm om Saco lönesök - avancerad sökning (öppnas i nytt fönster)">
            <a onClick={() => startVideoAdvance(true)}>
              Instruktionsfilm om Saco lönesök - avancerad sökning
            </a>
            <br />
          </div>
          <br />
          <div title="Instruktionsfilm om Saco lönesök - enkel sökning (öppnas i nytt fönster)">
            <a onClick={() => startVideoAdvance(false)}>
              Instruktionsfilm om Saco lönesök - enkel sökning
            </a>
          </div>
        </Accordion>
      </div>

      {explanationGroups
        .filter(explanationGroup => explanationGroup.available !== false)
        .map(explanationGroup => (
          <div className={styles.helpItemWrapper} key={explanationGroup.title}>
            <ExplanationGroup
              available={explanationGroup.available}
              data={explanationGroup.data}
              groupId={explanationGroup.groupId}
              textId={explanationGroup.textId}
              title={explanationGroup.title}
            />
          </div>
        ))}
    </Section>
  );
};

const startVideoAdvance = isAdvance => {
  if (isAdvance) {
    window.open(`Instruktionsfilm/avancerad`, '', 'height=500,width=900');
  } else {
    window.open(`Instruktionsfilm/enkel`, '', 'height=500,width=900');
  }
};

Explanations.propTypes = {
  filterList: PropTypes.arrayOf(Object).isRequired,
  id: PropTypes.string.isRequired,
};

export default Explanations;
