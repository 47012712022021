/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'atoms/checkbox/Checkbox';
import Accordion from 'atoms/accordion/Accordion';
import styles from './CheckboxSelectGroup.module.css';

const CheckboxSelectGroup = ({
  loading,
  itemIndex,
  options,
  sel_var,
  SELECTION_DATATYPE,
  SELECTION_TEXT,
  setSelectedValues,
  selectedValues,
}) => {
  const [values, setValues] = useState(
    options.map(option => ({
      ...option,
      checked: false,
    }))
  );
  console.log('setValues');
  console.log(values);
  console.log('options');
  console.log(options);

  useEffect(() => {
    if (
      options.some(
        option =>
          option.antal !== values.find(v => v.value === option.value).antal
      )
    ) {
      console.log('useEffect options');
      setValues(prevState => {
        return options.map(option => {
          const matching = prevState.find(prev => prev.value === option.value);
          return {
            ...option,
            checked: matching.checked || false,
          };
        });
      });
    }
  }, [options]);

  const handleChange = event => {
    event.persist();
    const { value, checked } = event.target;
    console.log('handleChange Event value:');
    console.log(value);
    setValues(prevState => [
      ...prevState.map(option => {
        if (option.value === value) {
          return { ...option, checked };
        }
        return option;
      }),
    ]);
  };

  // FIXME: The checkbox values are a little too decoupled from selectedValues
  useEffect(() => {
    // Reset if nothing selected
    if (!selectedValues.length) {
      setValues(prevState =>
        prevState.map(option => ({ ...option, checked: false }))
      );
    }
  }, [selectedValues]);

  useEffect(() => {
    const tempValues = values
      .filter(option => option.checked)
      .map(option => ({
        value: option.value,
        antal: option.antal,
        desc: option.description,
      }));

    setSelectedValues(prevState => {
      const item = prevState.find(o => o.valKey === sel_var);
      const tempState = [...prevState];

      if (item && tempValues.length > 0) {
        tempState[prevState.indexOf(item)] = {
          ...item,
          values: tempValues,
        };
        return tempState;
      }

      if (!item && tempValues.length > 0) {
        return [
          ...prevState,
          {
            name: SELECTION_TEXT,
            valKey: sel_var,
            sel_var,
            selection_datatype: SELECTION_DATATYPE,
            values: tempValues,
          },
        ];
      }

      if (item && tempValues.length < 1) {
        return [
          ...prevState.filter(o => JSON.stringify(o) !== JSON.stringify(item)),
        ];
      }
      return prevState;
    });

    console.log('Selected values:');
    console.log(selectedValues);
    // eslint-disable-next-line
  }, [values]);

  return (
    <Accordion
      title={SELECTION_TEXT}
      boxShadow
      marginBottom
      initialOpen={itemIndex === 0}
      className={
        values.some(option => option.antal !== ' < 5') ? '' : styles.mobileEmpty
      }
    >
      <div className={styles.expandable}>
        <div className={styles.header}>
          <span>Antal uppgifter</span>
        </div>
        {values.map(item => (
          <Checkbox
            disabled={loading || !item.selectable || item.antal === ' < 5'}
            checked={item.checked}
            item={item}
            key={item.value}
            loading={loading}
            onChange={handleChange}
            variant="primary"
            value={`${
              SELECTION_DATATYPE === 'N' ? item.value : `'${item.value}'`
            }`}
            className={item.antal === ' < 5' ? styles.greyed : ''}
          />
        ))}
      </div>
    </Accordion>
  );
};

CheckboxSelectGroup.propTypes = {
  loading: PropTypes.bool.isRequired,
  itemIndex: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  sel_var: PropTypes.string.isRequired,
  SELECTION_DATATYPE: PropTypes.string.isRequired,
  SELECTION_TEXT: PropTypes.string.isRequired,
  setSelectedValues: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(Object),
};

CheckboxSelectGroup.defaultProps = {
  selectedValues: [],
};

export default CheckboxSelectGroup;
