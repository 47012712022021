import React from 'react';
import PropTypes from 'prop-types';
import ButtonTransparent from 'atoms/buttontransparent/ButtonTransparent';
import RadioButton from 'atoms/radiobutton/RadioButton';
import styles from './SelectionCard.module.css';

const SelectionCard = ({
  description,
  index,
  label,
  loading,
  name,
  selectedSituation,
  selectionChange,
  value,
}) => {
  const selected = index === selectedSituation?.index;
  const disabled = selected || loading;
  const describedby = `${name}-${value}`;

  return (
    <div className={styles.selectionCard}>
      <ButtonTransparent
        altText={`Välj ${label}`}
        onKeyUp={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            selectionChange(e.target.value);
          }
        }}
        disabled={loading}
      >
        <label
          aria-describedby={describedby}
          className={`${disabled ? styles.disabled : ''} ${
            selected ? styles.selected : ''
          }`}
          htmlFor={value}
        >
          <div className={styles.inputContainer}>
            <RadioButton
              onChange={e => selectionChange(e.target.value)}
              disabled={disabled}
              value={value}
              name={name}
              checked={selected}
            />
          </div>
          <div className={styles.selectionCardContent}>
            <span id={describedby}>{label}</span>
            <p>{description}</p>
          </div>
        </label>
      </ButtonTransparent>
    </div>
  );
};

SelectionCard.propTypes = {
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  selectedSituation: PropTypes.shape({ index: PropTypes.number }),
  selectionChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

SelectionCard.defaultProps = {
  selectedSituation: undefined,
};

export default SelectionCard;
