import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Const from 'const';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import DataSourceConfig from './datasourceconfig/DataSourceConfig';
import styles from './Level3Advanced.module.css';
import StandardConfig from './standardconfig/StandardConfig';

const Level3Advanced = () => {
  const { user, signout, autoRefreshToken } = useAuth();
  const { token, forbund } = user;

  const history = useHistory();

  // Datasources.
  const [dataSources, setDataSources] = useState();
  const [availableYears, setAvailableYears] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // INIT.
  useEffect(() => {
    setIsLoading(true);
    const request = {
      forbund: [forbund],
    };
    ApiService.getFds(request, token)
      .then(data => setDataSources(data))
      .then(() => {
        autoRefreshToken(token);
      })
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [token, forbund]);

  useEffect(() => {
    if (dataSources == null) return;
    setAvailableYears(
      dataSources
        .reduce((acc, curr) => {
          if (!acc.includes(curr.YEAR)) acc.push(curr.YEAR);
          return acc;
        }, [])
        .sort()
    );
  }, [dataSources]);

  const toastMessage = (type, msg) => {
    if (type === 'success') {
      toast.success(msg);
    }
    if (type === 'error') {
      toast.error(msg);
    }
    if (type === 'warning') {
      toast.warning(msg);
    }
    if (type === 'info') {
      toast.info(msg);
    }
  };

  return (
    <Panel>
      <Section title={Const.admin.level3Header}>
        <ToastContainer position="top-center" autoClose={3000} closeOnClick />
        {isLoading ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            {availableYears && (
              <StandardConfig
                availableYears={availableYears}
                dataSources={dataSources}
                toastMessage={toastMessage}
              />
            )}
            <DataSourceConfig
              dataSources={dataSources}
              availableYears={availableYears}
              toastMessage={toastMessage}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            />
          </>
        )}
      </Section>
    </Panel>
  );
};

export default Level3Advanced;
