import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'atoms/button/Button';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import styles from './SituationConfig.module.css';
import SituationCreate from './situationcreate/SituationCreate';
import SituationCard from './situationcard/SituationCard';

const SituationConfig = ({ configureLvl1, setSituations, situations }) => {
  const [createNewSituation, setCreateNewSituation] = useState(false);
  const [dataSources, setDataSources] = useState();
  const [situationToCopy, setSituationToCopy] = useState();

  const [situationCopied, setSituationCopied] = useState(false);
  const [situationCreated, setSituationCreated] = useState(false);
  const [situationDeleted, setSituationDeleted] = useState(false);
  const [situationUpdated, setSituationUpdated] = useState(false);

  const { user } = useAuth();
  const { token, forbund } = user;

  const deleteSituation = situationToDelete => {
    ApiService.deleteSituation(situationToDelete.id, token).then(data => {
      const remainingSituations = situations.filter(
        situation => situation.id !== data
      );
      setSituations(remainingSituations);
      setSituationDeleted(true);
    });
  };

  const updateSituation = updatedSituation => {
    ApiService.updateSituation(
      updatedSituation.id,
      updatedSituation,
      token
    ).then(data => {
      const index = situations.findIndex(situation => situation.id === data.id);
      const tempSituations = [...situations];
      if (index !== -1) {
        tempSituations[index] = data;
      }
      setSituations(tempSituations);
      setSituationUpdated(true);
    });
  };

  const getDataSources = () => {
    const request = { forbund: configureLvl1 ? ['sa'] : [forbund] };
    ApiService.getFds(request, token).then(data => setDataSources(data));
  };

  useEffect(() => {
    getDataSources();
    // eslint-disable-next-line
  }, [token, forbund, configureLvl1]);

  useEffect(() => {
    if (
      !situationCopied &&
      !situationCreated &&
      !situationDeleted &&
      !situationUpdated
    )
      return;

    if (situationCopied) {
      toast.success('Situation duplicerad');
      setSituationCopied(false);
    }
    if (situationCreated) {
      toast.success('Situation skapad');
      setSituationCreated(false);
    }
    if (situationDeleted) {
      toast.success('Situation borttagen');
      setSituationDeleted(false);
    }
    if (situationUpdated) {
      toast.success('Situation uppdaterad');
      setSituationUpdated(false);
    }
  }, [situationCopied, situationCreated, situationDeleted, situationUpdated]);

  return (
    <div>
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      {situations
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => {
          if (a.DSL2.toLowerCase() === 'df') return -1;
          if (b.DSL2.toLowerCase() !== 'df') return 1;
          return 0;
        })
        .map(situation => (
          <SituationCard
            copySituation={s => {
              setCreateNewSituation(true);
              setSituationToCopy(s);
            }}
            deleteSituation={deleteSituation}
            key={situation.id}
            setSituationUpdated={setSituationUpdated}
            situation={situation}
            updateSituation={updateSituation}
          />
        ))}
      <section className={styles.addNew}>
        {!createNewSituation ? (
          <ButtonBar>
            <Button
              icon="Plus"
              label="Lägg till"
              altText="Lägg till situation"
              onClick={() => {
                setCreateNewSituation(prevState => !prevState);
                setSituationToCopy(null);
              }}
              style={{ marginLeft: 'auto' }}
              variant="primary"
            />
          </ButtonBar>
        ) : (
          <SituationCreate
            configureLvl1={configureLvl1}
            dataSources={dataSources}
            setCreateNewSituation={setCreateNewSituation}
            setSituationCopied={setSituationCopied}
            setSituationCreated={setSituationCreated}
            setSituations={setSituations}
            situationToCopy={situationToCopy}
          />
        )}
      </section>
    </div>
  );
};

SituationConfig.propTypes = {
  configureLvl1: PropTypes.bool,
  setSituations: PropTypes.func.isRequired,
  situations: PropTypes.arrayOf(Object).isRequired,
};

SituationConfig.defaultProps = {
  configureLvl1: false,
};

export default SituationConfig;
