import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Const from 'const';
import Section from 'atoms/section/Section';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import GeneralTextItem from './generaltextitem/GeneralTextItem';

/* 
  TEXTER SOM SKA FINNAS:
  (about/main)
  (statistics/main)
  (statistics/survey)
  (statistics/employer)
  (presentation/main)
  (salary/main)
  (salary/fixed)
  (salary/total)
  (measurements/main)
  (measurements/average)
  (measurements/10perc)
  (measurements/lowquartile)
  (measurements/median)
  (measurements/highquartile)
  (measurements/90perc)
  (measurements/averagemedian)
  (graph/main)
  (graph/box)
  (graph/line)
  (graph/bar)
*/

const GeneralTexts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [generalTexts, setGeneralTexts] = useState();

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  useEffect(() => {
    setIsLoading(true);
    const request = [
      {
        groupId: 'about',
      },
      {
        groupId: 'statistics',
      },
      {
        groupId: 'measurements',
      },
      {
        groupId: 'graph',
        textId: ['main', 'line', 'bar', 'box'],
      },
    ];

    // Dom här ska ALLTID finnas (även om value kan vara tomt (?)).
    // (about/main)
    // (statistics/main)
    // (statistics/survey)
    // (statistics/employer)
    // (presentation/main)
    // (salary/main)
    // (salary/fixed)
    // (salary/total)
    // (measurements/main)
    // (measurements/10percentile)
    // (graph/main)
    // (graph/box)
    // (graph/line)
    // (graph/bar)

    ApiService.getTexts(request, token)
      .then(data => {
        const sortedTexts = data.reduce((acc, curr) => {
          if (acc[curr.groupId] == null) {
            acc[curr.groupId] = [curr];
          } else {
            acc[curr.groupId].push(curr);
          }

          return acc;
        }, []);

        setGeneralTexts(sortedTexts);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [token]);

  const updateTexts = groupId => {
    const request = [{ groupId }];
    ApiService.getTexts(request, token).then(response => {
      setGeneralTexts({ ...generalTexts, [groupId]: response });
    });
  };

  const renderGeneralTextItem = (txtGroup, groupName) => (
    <GeneralTextItem
      key={groupName}
      txtGroup={txtGroup}
      updateCb={updateTexts}
    />
  );

  return (
    <Panel>
      <Section title={Const.admin.commonTexts}>
        {isLoading && <Loader variant="primary" />}
        {/* FIXME: renderar 2 gånger. */}
        {!isLoading &&
          generalTexts &&
          Object.keys(generalTexts).map(group =>
            renderGeneralTextItem(generalTexts[group], group)
          )}
      </Section>
    </Panel>
  );
};

export default GeneralTexts;
