import React from 'react';
import PropTypes from 'prop-types';
import styles from './Banner.module.css';

const Banner = ({ user }) => {
  document.title = 'Saco lönesök';
  return (
    <section>
      <div className={styles.banner}>
        <div className={styles.main}>
          <h1>Välkommen till Saco lönesök!</h1>
          {user?.role === 'fortroendevald' ? (
            <p>
              Som förtroendevald har du tillgång till tre års lönestatistik och
              flera uppgifter.
            </p>
          ) : (
            <p>
              Lönesök finns i två olika varianter, en enkel och en avancerad.
              Den enkla sökningen är till för dig som vill ha en snabb överblick
              av ditt löneläge, medan du i den avancerade sökningen kan fördjupa
              dig i all tillgänglig lönestatistik.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
};

export default Banner;
