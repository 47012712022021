import React from 'react';
import PropTypes from 'prop-types';

const SVGComponent = ({ fill }) => (
  <svg
    id="Lager_2"
    data-name="Lager 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58.98 31.95"
  >
    <defs>
      <style>
        {`
          .cls-1 {
            letter-spacing: -.02em;
          }
  
          .cls-2 {
            fill: ${fill || '#c13d8c'};
            stroke-width: 0px;
          }
  
          .cls-3 {
            letter-spacing: -.02em;
          }
  
          .cls-4 {
            fill: #fff;
            font-family: ProximaNova-Semibold, 'Proxima Nova';
            font-size: 12.2px;
            font-weight: 600;
          }
  
          .cls-5 {
            letter-spacing: -.02em;
          }
  
          .cls-6 {
            letter-spacing: -.02em;
          }
        `}
      </style>
    </defs>
    <g id="Lager_1-2" data-name="Lager 1">
      <g>
        <path
          className="cls-2"
          d="m52.66,0H6.32C2.83,0,0,2.83,0,6.32v13.57c0,3.49,2.83,6.32,6.32,6.32h31.7l4.66,5.74,4.3-5.74h5.67c3.49,0,6.32-2.83,6.32-6.32V6.32c0-3.49-2.83-6.32-6.32-6.32Z"
        />
        <text className="cls-4" transform="translate(10.95 17.11)">
          <tspan className="cls-3" x="0" y="0">
            D
          </tspan>
          <tspan className="cls-5" x="8.44" y="0">
            i
          </tspan>
          <tspan className="cls-6" x="11.1" y="0">
            n{' '}
          </tspan>
          <tspan className="cls-1" x="20.66" y="0">
            lö
          </tspan>
          <tspan className="cls-6" x="30.07" y="0">
            n
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

SVGComponent.propTypes = {
  fill: PropTypes.string,
};

SVGComponent.defaultProps = {
  fill: "#c13d8c",
};

export default SVGComponent;
