import React, { useEffect, useState } from 'react';
import ApiService from '../../../../services/ApiService';
import { useAuth } from '../../../../services/UseAuth';
import ViewChart from './ViewChart';
import UnitChart from './UnitChart';
import BrowserChart from './BrowserChart';
import styles from '../VisitorStatistics.module.css';
import Loader from '../../../../atoms/loader/Loader';

const PieCharts = () => {
  const { user } = useAuth();
  const { token } = user;
  const [statsPerView, setStatsPerView] = useState();
  const [statsPerUnit, setStatsPerUnit] = useState();
  const [statsPerBrowser, setStatsPerUnitBrowser] = useState();
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();

  const request = {
    yearFrom: currentYear,
    monthFrom: 1,
    yearTo: currentYear,
    monthTo: 12,
    forbundskodSas: user.forbund,
  };

  useEffect(() => {
    setLoading(true);
    ApiService.getUserStatistics(request, token)
      .then(data => {
        const { views, devices, browsers } = data.userStatistics;
        setStatsPerView(views);
        setStatsPerUnit(devices);
        setStatsPerUnitBrowser(browsers);
      })
      .finally(() => setLoading(false));
  }, [token]);

  return (
    <div className={styles.chartContainer}>
      <h2 className={styles.header}>Sökning, enhet och webbläsare</h2>
      {loading ? (
        <Loader variant={'primary'} />
      ) : (
        <>
          <ViewChart stats={statsPerView} />
          <br />
          <UnitChart stats={statsPerUnit} />
          <br />
          <BrowserChart stats={statsPerBrowser} />
        </>
      )}
    </div>
  );
};

export default PieCharts;
