import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Select from 'atoms/select/Select';
import Const from 'const';
import { getAvailableYears, months, today } from 'lib/time';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import styles from './MonitoringLog.module.css';
import LogItem from './logitem/LogItem';
import { useLocation } from 'react-router-dom';

const MonitoringLog = () => {
  const [monitoringrun, setMonitoringRun] = useState({});
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const logsPerPage = 50;
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [monthFrom, setMonthFrom] = useState(today.getMonth() + 1);
  const [monthTo, setMonthTo] = useState(today.getMonth() + 1);
  const [yearFrom, setYearFrom] = useState(today.getFullYear());
  const [yearTo, setYearTo] = useState(today.getFullYear());
  const [logLevel, setLogLevel] = useState('');

  const [refresh, setRefresh] = useState(true);

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  const location = useLocation();

  const logLevels = [
    { key: 'all', value: '', desc: 'Alla' },
    { key: 'warning', value: 'Warning', desc: 'Varning' },
    { key: 'error', value: 'Error', desc: 'Fel' },
    { key: 'info', value: 'Information', desc: 'Information' },
  ];

  useEffect(() => {
    if (!refresh) return;
    setLoading(true);

    const request = {
      level: logLevel || null,
      yearFrom: yearFrom || null,
      yearTo: yearTo || null,
      monthFrom: monthFrom || null,
      monthTo: monthTo || null,
    };

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    console.log('monitoringRunId');
    console.log(id);

    ApiService.getMonitoringLogsById(
      {
        monitoringRunId: id,
      },
      token
    )
      .then(data => {
        setCurrentPage(0);

        console.log(data); // Log the entire response

        // Now you can set the logResponses to your state or wherever needed
        setMonitoringRun(data);
        setLogs(data.logResponses || []); // Ensure logs is an array
      })
      .catch(error => {})
      .finally(() => {
        setLoading(false);
        setRefresh(false);
      });
  }, [token, logLevel, yearFrom, yearTo, monthFrom, monthTo, refresh]);

  useEffect(() => {
    setPages(Math.ceil(logs.length / logsPerPage));
  }, [logs]);

  const filteredLogs = logs.slice(
    currentPage * logsPerPage,
    currentPage * logsPerPage + logsPerPage
  );

  return (
    <Panel>
      <Section title="Monitorering">
        <SectionAdmin title="Körningsinformation">
          {loading ? (
            <div className={styles.loaderAnimation}>
              <Loader variant="primary" />
            </div>
          ) : (
            <div className={styles.logInfo}>
              <p>
                <strong>JobbId:</strong> {monitoringrun.id}
              </p>
              <p>
                <strong>Jobbtyp:</strong> {monitoringrun.jobType}
              </p>
              <p>
                <strong>Jobbstatus:</strong> {monitoringrun.monitoringStatus}
              </p>
              <p>
                <strong>Starttid:</strong> {monitoringrun.startTime}
              </p>
              <p>
                <strong>Sluttid:</strong> {monitoringrun.endTime}
              </p>
              <p>
                <strong>Åtgärdad tid:</strong> {monitoringrun.resolvedTime}
              </p>
              <p>
                <strong>Felmeddelande:</strong> {monitoringrun.errorMessage}
              </p>
              <p>
                <strong>Felfunktion:</strong> {monitoringrun.errorFunction}
              </p>
              <p>
                <strong>Antal fel:</strong> {monitoringrun.errorsCount}
              </p>
            </div>
          )}
        </SectionAdmin>
        <SectionAdmin title="Fellogg för aktuell körning">
          <div style={{ padding: '20px' }}>
            {loading ? (
              <div className={styles.loaderAnimation}>
                <Loader variant="primary" />
              </div>
            ) : (
              <div className={styles.logPanel}>
                {filteredLogs.length > 0 ? (
                  filteredLogs.map(log => (
                    <LogItem
                      log={log.log}
                      union={log.union ? log.union.forbundskodSas || '' : '-'}
                      key={log.log.id}
                    />
                  ))
                ) : (
                  <span className={styles.errorText}>
                    Hittade inga loggar, prova att justera filtret
                  </span>
                )}
              </div>
            )}

            {pages > 0 && (
              <ButtonBar prevNext>
                <Button
                  disabled={currentPage === 0}
                  variant="primary"
                  icon="Previous"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => setCurrentPage(prevState => prevState - 1)}
                  label="Föregående sida"
                />
                <span className={styles.pageIndicator}>
                  {`${currentPage + 1} / ${pages}`}
                </span>
                <Button
                  disabled={currentPage === pages - 1}
                  variant="primary"
                  icon="Next"
                  flipIcon
                  style={{ marginLeft: 'auto' }}
                  onClick={() => setCurrentPage(prevState => prevState + 1)}
                  label="Nästa sida"
                />
              </ButtonBar>
            )}
          </div>
        </SectionAdmin>
      </Section>
    </Panel>
  );
};

export default MonitoringLog;
