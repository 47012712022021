import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Input from 'atoms/input/Input';
import Panel from 'atoms/panel/Panel';
import InputReadOnly from 'atoms/inputreadonly/InputReadOnly';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import styles from './LinkCard.module.css';

const LinkCard = ({
  link,
  setLinks,
  setLinkUpdated,
  setLinkDeleted,
  configureLvl1,
}) => {
  const { header, value, id, groupId, textId } = link;

  const [editMode, setEditMode] = useState(false);

  const [linkName, setLinkName] = useState(header);
  const [linkUrl, setLinkUrl] = useState(value);
  const [isSaving, setIsSaving] = useState(false);

  const { user } = useAuth();

  const handleUpdate = () => {
    setIsSaving(true);
    const request = {
      id,
      groupId,
      textId,
      unionId: configureLvl1 ? null : user.unionId,
      header: linkName,
      value: linkUrl,
    };

    ApiService.postText(request, user.token)
      .then(data => {
        setLinks(prevState => {
          const index = prevState.findIndex(
            situation => situation.id === data.id
          );
          const tempLinks = [...prevState];
          if (index !== -1) {
            tempLinks[index] = data;
          }

          return tempLinks;
        });
        setLinkUpdated(true);
      })
      .finally(() => {
        setIsSaving(false);
        setEditMode(false);
      });
  };

  const handleDelete = () => {
    ApiService.deleteText(id, user.token).then(data => {
      setLinks(prevState => [...prevState.filter(l => l.id !== data)]);
      setLinkDeleted(true);
    });
  };

  return (
    <Panel card legend={`${editMode ? 'Redigera länk' : 'Länk'}`}>
      <form>
        <div className={styles.linkBox}>
          <div className={styles.linkCol}>
            {editMode ? (
              <Input
                id="edit-link-text"
                label="Länktext"
                onChange={evt => setLinkName(evt.target.value)}
                placeholder="Länktext"
                type="text"
                value={linkName}
              />
            ) : (
              <InputReadOnly label="Länktext" text={header} />
            )}
          </div>
          <div className={styles.linkCol}>
            {editMode ? (
              <Input
                id="edit-http-link"
                label="HTTP-länk"
                onChange={evt => setLinkUrl(evt.target.value)}
                placeholder="HTTP-länk"
                type="text"
                value={linkUrl}
              />
            ) : (
              <InputReadOnly label="HTTP-länk" text={value} />
            )}
          </div>
        </div>
      </form>
      <div>
        <ButtonBar>
          {editMode && (
            <>
              <Button
                variant="delete"
                onClick={() => handleDelete()}
                label="Ta bort"
                icon="Trash"
                altText="Ta bort länk"
                disabled={isSaving}
              />
              <Button
                variant="primary"
                onClick={() => handleUpdate()}
                label="Spara"
                altText="Spara länk"
                disabled={isSaving}
              />
              <Button
                variant="secondary"
                onClick={() => setEditMode(prevState => !prevState)}
                label="Avbryt"
                disabled={isSaving}
              />
            </>
          )}
          {!editMode && (
            <>
              <Button
                variant="primary"
                onClick={() => setEditMode(prevState => !prevState)}
                label="Redigera"
                icon="Edit"
                altText="Redigera länk"
              />
            </>
          )}
        </ButtonBar>
      </div>
    </Panel>
  );
};

LinkCard.propTypes = {
  link: PropTypes.shape({
    header: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    textId: PropTypes.string.isRequired,
  }).isRequired,
  setLinks: PropTypes.func.isRequired,
  setLinkUpdated: PropTypes.func.isRequired,
  setLinkDeleted: PropTypes.func.isRequired,
  configureLvl1: PropTypes.bool.isRequired,
};

export default LinkCard;
