import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import MarkdownEditor from 'atoms/markdowneditor/MarkdownEditor';
import Panel from 'atoms/panel/Panel';
import Select from 'atoms/select/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import styles from './FilterExplanationCreate.module.css';

const FilterExplanationCreate = ({ selvars, setFilterTexts, setEditMode }) => {
  const { user } = useAuth();

  const [filterText, setFilterText] = useState('');
  const [textId, setTextId] = useState(selvars[0].sel_var);
  const isDefault = user.forbund === 'SA';

  const handleSubmit = () => {
    const request = {
      groupId: 'menuselection',
      textId,
      header: selvars.find(selvar => selvar.sel_var === textId).desc,
      value: filterText,
      isDefault,
      unionId: isDefault ? null : user.unionId,
    };

    ApiService.postText(request, user.token).then(data => {
      setFilterTexts(prevState => [...prevState, data]);

      if (setEditMode) setEditMode(prevState => !prevState);
    });
  };

  return (
    <Panel
      card
      legend={`Skapa ${isDefault ? 'delad' : 'förbundsspecifik'} text`}
    >
      <Select
        id="filtervariabel"
        label="Filtervariabel"
        compact
        disabled={selvars && selvars.length < 2}
        onChange={evt => setTextId(evt.target.value)}
        value={textId}
        options={
          selvars &&
          selvars.map(selvar => ({
            key: selvar.sel_var,
            value: selvar.sel_var,
            desc: selvar.desc,
          }))
        }
      />
      <br />
      <MarkdownEditor
        value={filterText}
        onChange={value => setFilterText(value)}
        autofocus
      />
      <br />
      <ButtonBar>
        <Button
          variant="primary"
          style={{ marginLeft: 'auto' }}
          onClick={() => handleSubmit()}
          label="Spara"
          altText="Spara filtertext"
          marginBottom
        />
      </ButtonBar>
    </Panel>
  );
};

FilterExplanationCreate.propTypes = {
  selvars: PropTypes.arrayOf(String),
  setEditMode: PropTypes.func,
  setFilterTexts: PropTypes.func.isRequired,
};

FilterExplanationCreate.defaultProps = {
  selvars: PropTypes.arrayOf(String),
  setEditMode: PropTypes.null,
};

export default FilterExplanationCreate;
