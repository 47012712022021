import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { IconChevronRight } from 'tabler-icons';
import styles from './NavigationItem.module.css';

const NavigationItem = ({ title, to, active, disabled, hidden }) => {
  return (
    <Link
      className={`${styles.navigationItem} ${active ? styles.isActive : ''} ${
        disabled ? styles.isDisabled : ''
      } ${hidden ? styles.isHidden : ''}
      `}
      to={to}
    >
      {title}
      {active && (
        <span className={styles.activeIcon}>
          <IconChevronRight aria-label="" role="img" size={20} />
        </span>
      )}
    </Link>
  );
};

NavigationItem.propTypes = {
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

NavigationItem.defaultProps = {
  hidden: false,
  disabled: false,
  active: false,
};

export default NavigationItem;
