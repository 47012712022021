import UnionLink from 'atoms/unionlink/UnionLink';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import Logo from '../../testdata/saco-lonesok_logotype.svg';
import styles from './Footer.module.css';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { user } = useAuth();
  const token = user?.token;
  const location = useLocation();
  const { pathname } = location;
  const showLogo =
    pathname === '/inloggning-info' ||
    pathname === '/logga-ut' ||
    pathname === '/logga-in';

  const [unionLink, setUnionLink] = useState();

  useEffect(() => {
    if (!token) return;
    const request = [
      {
        groupId: 'unionLink',
        textId: ['unionLink'],
      },
    ];

    ApiService.getTexts(request, token).then(data => {
      if (data && data[0]) return setUnionLink(data[0]);
      return setUnionLink();
    });
  }, [token]);

  return (
    <>
      {location.pathname.includes('Instruktionsfilm') ||
      location.pathname.includes('oppensokning') ||
      location.pathname.toLowerCase().includes('unemploymentspin') ||
      location.pathname.includes('careerspin') ||
      location.pathname.toLowerCase().includes('parentalleavespin') ? (
        <></>
      ) : (
        <footer className={styles.main}>
          <div className={styles.mainSection}>
            <div className={styles.innerContainerFull}>
              <div>
                <h2>Har du frågor?</h2>
                <p>
                  <UnionLink unionLink={unionLink} capitalize /> om du har
                  frågor om uppgifterna i lönestatistiken.
                </p>
              </div>
              {!showLogo && (
                <div className={styles.subSection}>
                  <div className={styles.subSectionContent}>
                    <img
                      className={styles.logo}
                      src={Logo}
                      alt="SACO Lönesök, Sveriges Akademiker"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
