import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import ExplanationText from 'atoms/explanationtext/ExplanationText';
import styles from './About.module.css';
import Accordion from '../../../atoms/accordion/Accordion';
import useScrollTo from '../../../hooks/useScrollTo';

const About = ({ id }) => {
  const [aboutText, setAboutText] = useState('');
  const ref = useScrollTo(id);

  const { user } = useAuth();

  useEffect(() => {
    const request = [
      {
        groupId: 'about',
      },
    ];

    ApiService.getTexts(request, user.token).then(data => {
      setAboutText(
        data.find(txt => txt.groupId === 'about' && txt.textId === 'main')
      );
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div id={id} className={styles.aboutWrapper}>
      <div ref={ref} className={styles.aboutInnerWrapper}>
        <Accordion initialOpen title={aboutText.header} dark>
          {aboutText && <ExplanationText textItem={aboutText} />}
        </Accordion>
      </div>
    </div>
  );
};

About.propTypes = {
  id: PropTypes.string.isRequired,
};

export default About;
