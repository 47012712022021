import React from 'react';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import styles from './UnionSelect.module.css';

const UnionSelect = ({ unions, handleUpdateUnionSelection }) => {
  const handleItemUpdate = id => {
    const newList = unions.map(union => {
      if (union.id === id) {
        const updatedUnion = {
          ...union,
          isSelected: !union.isSelected,
        };
        return updatedUnion;
      }

      return union;
    });
    handleUpdateUnionSelection(newList);
  };

  const selectAllUnions = () => {
    const newList = unions.map(union => {
      const updatedUnion = {
        ...union,
        isSelected: true,
      };
      return updatedUnion;
    });
    handleUpdateUnionSelection(newList);
  };

  const unSelectAllUnions = () => {
    const newList = unions.map(union => {
      const updatedUnion = {
        ...union,
        isSelected: false,
      };
      return updatedUnion;
    });
    handleUpdateUnionSelection(newList);
  };

  return (
    <div className={styles.expandable}>
      {unions.map(union => (
        <div key={union.id}>
          <CheckboxAdmin
            id={union.id}
            label={union.name}
            checked={union.isSelected}
            onChange={() => handleItemUpdate(union.id)}
          />
          <br />
        </div>
      ))}
      <br />
      <ButtonBar>
        <Button
          key="Button_SelectAll"
          variant="primary"
          onClick={() => selectAllUnions()}
          label="Markera alla"
          altText="Markera alla förbund"
          disabled={false}
        />
        <Button
          key="Button_UnSelectAll"
          variant="primary"
          onClick={() => unSelectAllUnions()}
          label="Avmarkera alla"
          altText="Avmarkera alla förbund"
          disabled={false}
        />
      </ButtonBar>
    </div>
  );
};

UnionSelect.propTypes = {
  unions: PropTypes.arrayOf(Object).isRequired,
  handleUpdateUnionSelection: PropTypes.func.isRequired,
};

export default UnionSelect;
