import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabRow.module.css';

const TabRow = ({ children, className }) => {
  return (
    <div className={`${styles.tabRow} ${className || ''}`}>{children}</div>
  );
};

TabRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

TabRow.defaultProps = {
  className: '',
};

export default TabRow;
