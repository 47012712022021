import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Const from 'const';
import { ToastContainer } from 'react-toastify';
import UnionLinkConfig from './unionlinkconfig/UnionLinkConfig';

export default function UnionConfig({ forbundList }) {
  return (
    <Panel>
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      <Section title={Const.admin.union}>
        <UnionLinkConfig forbundList={forbundList} />
      </Section>
    </Panel>
  );
}

UnionConfig.propTypes = {
  forbundList: PropTypes.arrayOf(PropTypes.shape({})),
};

UnionConfig.defaultProps = {
  forbundList: [],
};
