import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Colors } from '../../../../assets/Colors';
import styles from '../VisitorStatistics.module.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  title: {
    display: true,
    text: 'Antal procent av besöken uppdelat på enhet',
    fontSize: 16,
    fontColor: Colors.black,
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
  plugins: {
    datalabels: {
      anchor: 'center',
      align: 'center',
      color: 'white',
      font: {
        size: 14,
        weight: 'bold',
      },
      formatter: value => {
        if (value === 0) {
          return '';
        } else {
          return `${value.toLocaleString()} %`;
        }
      },
    },
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, d) {
        let label = d.labels[tooltipItem.index] || '';
        if (label) {
          label += ': ';
        }
        const value =
          d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
        label += Number(value).toLocaleString('sv');
        return `${label} %`;
      },
    },
  },
};

const unitKeyLabels = {
  unknown: 'Okänd',
  desktop: 'Stationär',
  mobile: 'Mobil',
  tablet: 'Surfplatta',
};

const unitSortOrder = ['desktop', 'mobile', 'tablet', 'unknown'];

const UnitChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const { turquoise, pink, lightGrey, grey } = Colors;

  const sortStats = () => {
    return Object.entries(stats)
      .filter(([key]) => key in unitKeyLabels)
      .sort(
        (unitA, unitB) =>
          unitSortOrder.indexOf(unitA[0]) - unitSortOrder.indexOf(unitB[0])
      );
  };

  const mapStatsToChartData = () => {
    const sortedStats = sortStats();
    const labels = sortedStats.map(unit => unitKeyLabels[unit[0]]);
    const data = sortedStats.map(unit => unit[1]);

    const datasets = [
      {
        data,
        backgroundColor: [turquoise, pink, lightGrey, grey],
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      mapStatsToChartData();
    }
  }, [stats]);

  return (
    <div>
      {chartData && (
        <Pie data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
    </div>
  );
};

export default UnitChart;

UnitChart.propTypes = {
  stats: PropTypes.any,
};
