/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionAdmin.module.css';

const SectionAdmin = ({title, children}) => {
  return (
    <section className={styles.sectionAdmin}>
      <h3 className={styles.sectionHeading}>
        {title}
      </h3>
      <div className={styles.sectionContent}>
        {children}
      </div>
    </section>
  );
};

SectionAdmin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired
};

SectionAdmin.defaultProps = {
 
};

export default SectionAdmin;
