import React from 'react';
import PropTypes from 'prop-types';
import Input from 'atoms/input/Input';
import styles from './SalaryMarker.module.css';
import { IconInfoCircle } from 'tabler-icons';

const SalaryMarker = ({ salaryMarkerValue, setSalaryMarkerValue }) => {
  const formatNumberForDisplay = number => {
    return number.toLocaleString('sv-SE');
  };

  const handleChange = e => {
    const unformattedValue = e.target.value.replace(/\s/g, '');
    setSalaryMarkerValue(Number(unformattedValue));
  };

  return (
    <div className={styles.salaryMarker}>
      <div className={styles.infoLabelWrapper}>
        <div className={styles.information}>
          <div className={styles.tooltip}>
            <IconInfoCircle
              className={styles.icon}
              size={32}
              role="img"
              aria-label="chart-limit-clarification"
            />
            <p className={styles.tooltipText}>
              Ange din egen månadslön och jämför med värdena i diagrammet.
            </p>
            <p className={styles.tooltipText}>
              Ligger din lön utanför skalan syns den inte i diagrammet.
            </p>
          </div>
          Information
        </div>
        <label className={styles.label} htmlFor="salary-marker">
          Din lön per månad:
        </label>
      </div>
      <Input
        id="salary-marker"
        type="text"
        altText="Ange din lön, lönen markeras i grafen"
        displayInline
        onChange={handleChange}
        className={styles.input}
        value={
          salaryMarkerValue ? formatNumberForDisplay(salaryMarkerValue) : ''
        }
      />
    </div>
  );
};

SalaryMarker.propTypes = {
  salaryMarkerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSalaryMarkerValue: PropTypes.func.isRequired,
};

SalaryMarker.defaultProps = {
  salaryMarkerValue: '',
};

export default SalaryMarker;
