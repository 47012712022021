import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import BreadcrumbsItem from './breadcrumbsitem/BreadcrumbsItem';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = () => {
  const getPaths = str => {
    const pathList = ['/'];

    if (str === '/') return pathList;

    str.split('/').reduce((prev, curr) => {
      const currPath = `${prev}/${curr}`;
      pathList.push(currPath);
      return currPath;
    });

    return pathList;
  };

  const { pathname } = useLocation();
  const paths = getPaths(pathname);

  return (
    <nav aria-label="Breadcrumb">
      <ol className={styles.breadcrumbs}>
        {paths.map((path, index) => (
          <Route
            path={path}
            key={path}
            render={props => (
              <BreadcrumbsItem match={props.match} index={index} />
            )}
          />
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
