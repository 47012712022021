import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../services/UseAuth';
import ApiService from '../../services/ApiService';
import styles from './ErrorNotification.module.css';

const ErrorNotification = ({ token, forbund, niva, dataSource }) => {
  const [errornotifications, setErrorNotifications] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    console.log('Errornotification');
    ApiService.getMonitoringLogs(
      {
        niva: niva,
        unionId: user.unionId,
      },
      token
    )
      .then(data => setErrorNotifications(data))
      .catch(error => {});
  }, [token]);

  useEffect(() => {
    console.log('Errornotifications:');
    console.log(errornotifications);
  }, [errornotifications]);

  // return (
  //   <div className={styles.container}>
  //     <div className={styles.notification}>
  //       <p>Error notification content</p>
  //     </div>
  //   </div>
  // );

  return (
    <div className={styles.container}>
      {errornotifications && errornotifications.hasErrors && (
        <div className={styles.notification}>
          {/* Visa din felnotis här */}
          <p>
            Ett tillfälligt systemfel har uppstått. Vi jobbar på att lösa det så
            snabbt som möjligt. Vänligen försök igen senare.
          </p>
        </div>
      )}
    </div>
  );
};

ErrorNotification.propTypes = {
  token: PropTypes.string.isRequired,
  forbund: PropTypes.string,
  niva: PropTypes.string.isRequired,
  dataSource: PropTypes.shape({
    forbund: PropTypes.string,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
    year: PropTypes.number,
  }),
};

ErrorNotification.defaultProps = {
  forbund: '',
  dataSource: {
    forbund: 'SA',
    dsl1: null,
    dsl2: '',
    year: null,
  },
};

export default ErrorNotification;
