import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './NotificationRow.module.css';
import Button from '../../../../atoms/button/Button';

const NotificationRow = ({
  id,
  textId,
  unionId,
  value,
  deleteCb,
  updateCb,
  forbund,
}) => {
  const [expand, setExpand] = useState(false);
  const [message, setMessage] = useState(value);
  const [editable, setEditable] = useState(false);

  const handleExpand = () => {
    if (!editable) setExpand(!expand);
  };

  const handleText = e => {
    // Return if user presses the enter key
    if (e.nativeEvent.inputType === 'insertLineBreak') return;

    setMessage(e.target.value);
  };

  const parseDataSources = json => {
    let dataSources;

    try {
      dataSources = Object.entries(JSON.parse(json)).map(
        ([k, v]) => ` ${k}:${v} `
      );
    } catch (e) {
      dataSources = [];
    }

    return dataSources.length ? dataSources : 'samtliga';
  };

  return (
    <>
      <button
        type="button"
        className={styles.rowContainer}
        onClick={handleExpand}
      >
        <div className={styles.header}>
          {(unionId && <p>{`endast förbund: ${forbund}`}</p>) || (
            <p>samtliga förbund</p>
          )}
          <p className={styles.source}>
            {`Källa: ${parseDataSources(textId)}`}
          </p>
        </div>
        <p className={styles.textTitle}>meddelande</p>
        {!editable ? (
          <p>{`${message}`}</p>
        ) : (
          <textarea value={message} onChange={handleText} />
        )}
        {expand && <div className={styles.bottomWrapper} />}
      </button>
      {expand && !editable && (
        <div className={styles.buttonContainer}>
          <Button
            variant="delete"
            label="Delete"
            icon="Trash"
            onClick={() => deleteCb(id)}
          />
          <Button
            label="Edit"
            onClick={() => setEditable(!editable)}
            variant="secondary"
            icon="Edit"
          />
        </div>
      )}
      {expand && editable && (
        <div className={styles.buttonContainer}>
          <Button
            label="Cancel"
            onClick={() => {
              setMessage(value);
              setEditable(false);
            }}
            variant="delete"
            icon="Trash"
          />
          <Button
            label="Save"
            onClick={() => {
              updateCb('', '{}', message, id);
              setEditable(false);
            }}
            variant="secondary"
            icon="Edit"
          />
        </div>
      )}
    </>
  );
};

NotificationRow.propTypes = {
  id: PropTypes.string.isRequired,
  forbund: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  unionId: PropTypes.string,
  value: PropTypes.string.isRequired,
  deleteCb: PropTypes.func.isRequired,
  updateCb: PropTypes.func.isRequired,
};

NotificationRow.defaultProps = {
  unionId: '',
};

export default NotificationRow;
