export function formatIncomeValue(value, unit = 'kr') {
  if (value) {
    return `${value.toLocaleString('sv-SE', {
      maximumFractionDigits: 0,
    })} ${unit}`;
  }
  return '-';
}

export function getIncomeMarkerIndex(lineData, parent2Months) {
  // console.log('getIncomeMarkerIndex');
  // console.log('lineData:', lineData);
  // console.log('lineData.datasets:', lineData.datasets);
  // console.log('lineData.labels:', lineData.labels);
  // console.log('parent2Months:', parent2Months);

  // Kontrollera om lineData är definierat och innehåller etiketter och dataset
  if (lineData && lineData.datasets && lineData.labels) {
    const incomeMarkerIndex = parent2Months - 2;
    return incomeMarkerIndex;
  }

  // Om ingen matchande lön hittades eller om data var ofullständig
  console.log('Ingen matchande månad hittades eller ofullständiga data.');
  return -1;
}

export function getMaxSumIndex(lineData) {
  // Kontrollera om lineData är definierat och innehåller etiketter och dataset
  if (
    lineData &&
    lineData.datasets &&
    lineData.datasets.length > 0 &&
    lineData.labels
  ) {
    // Hämta det första datasetet från lineData
    const firstDataset = lineData.datasets[0];
    const secondDataset = lineData.datasets[1];
    // console.log('firstDataset', firstDataset);
    // console.log('secondDataset', secondDataset);

    // Kontrollera om datasetet innehåller datapunkter
    if (firstDataset && firstDataset.data && firstDataset.data.length > 0) {
      let maxSum = -Infinity;
      let maxIndex = -1;
      // Loopa genom datapunkterna för att hitta indexet där värdet är störst
      for (let i = 0; i < firstDataset.data.length; i++) {
        const value = firstDataset.data[i];
        if (value > maxSum) {
          maxSum = value;
          maxIndex = i;
        }
      }

      // Returnera indexet för den punkt där värdet är störst
      return maxIndex;
    }
  }

  // Om något av kraven inte är uppfyllt, returnera -1 för att indikera att ingen punkt hittades
  return -1;
}

export function setIncomeDistribution(lineData) {
  // Anropa getMaxSumIndex för att få index för den månad där summan är störst
  const maxSumIndex = getMaxSumIndex(lineData);

  // Om getMaxSumIndex returnerar -1 (ingen punkt hittades) eller indexet är ogiltigt, returnera en standardfördelning
  if (maxSumIndex === -1 || maxSumIndex < 0 || maxSumIndex >= 12) {
    return { parent1MaxMonths: 6, parent2MaxMonths: 6 };
  }

  // Annars, sätt fördelningen baserat på indexet
  const parent1MaxMonths = maxSumIndex + 2; // Månaden där index är 0 motsvarar månad 2 i fördelningen
  const parent2MaxMonths = 12 - parent1MaxMonths;

  // Returnera fördelningen
  return { parent1MaxMonths, parent2MaxMonths };
}

export function getMaxSumForDatasetsIndex(dataset1, dataset2) {
  if (!dataset1 || !dataset2 || dataset1.length !== dataset2.length) {
    console.error('Invalid datasets');
    return -1; // Eller något annat som indikerar att det inte finns något giltigt index
  }

  // Skapa en array med summan av punkterna för varje index i båda dataseten
  const sums = dataset1.map((value, index) => value + dataset2[index]);

  // Hitta indexet för maxvärdet i sums-arrayen
  // eslint-disable-next-line no-shadow
  const maxIndex = sums.reduce(
    (maxIndex, currentValue, currentIndex, array) => {
      return currentValue > array[maxIndex] ? currentIndex : maxIndex;
    },
    0
  );

  return maxIndex;
}

export function generatePointBackgroundColors(
  dataset,
  lineData,
  defaultBackgroundColor,
  targetBackgroundColor
) {
  const maxSumIndex = getMaxSumIndex(lineData);
  // console.log('maxSumIndex', maxSumIndex);

  return dataset.map((value, index) => {
    if (index === maxSumIndex) {
      return targetBackgroundColor;
    }
    return defaultBackgroundColor;
  });
}

function calculateStepSize(data) {
  if (!data || data.length === 0) {
    return 1; // Returnera ett standardvärde om det inte finns någon data
  }

  // Hitta det högsta och lägsta värdet i datan
  const minValue = Math.min(...data);
  const maxValue = Math.max(...data);

  // Beräkna det totala datavärdet
  const totalRange = maxValue - minValue;

  // Antal steg du vill ha i diagrammet
  const desiredSteps = 5; // Du kan ändra detta till det önskade antalet steg

  // Beräkna steglängden
  const stepSize = totalRange / desiredSteps;

  return stepSize;
}

export function addChartOptions(lineOptions, lineData) {
  const newConfig = {
    ...lineOptions,
  };
  //console.log('newConfig:', newConfig);
  //console.log('lineData:', lineData);
  // Check and initialize plugins
  if (!newConfig.plugins) {
    newConfig.plugins = {};
  }

  // Check and initialize legend
  if (!newConfig.plugins.legend) {
    newConfig.plugins.legend = {};
  }

  // Set display property for legend to hide it
  newConfig.plugins.legend.display = false;

  // Check and initialize scales
  if (!newConfig.scales) {
    newConfig.scales = {};
  }

  // Inaktivera datalabels-pluginen för att dölja värdena vid punkterna
  newConfig.plugins.datalabels = {
    display: false,
  };

  // Check and initialize xAxes
  if (!newConfig.scales.xAxes) {
    newConfig.scales.xAxes = [{}];
  }

  // Check and initialize yAxes
  if (!newConfig.scales.yAxes) {
    newConfig.scales.yAxes = [{}];
  }
  newConfig.maintainAspectRatio = false;
  // Hide scale labels for both x and y axes
  newConfig.scales.xAxes[0].scaleLabel = {
    display: false,
  };
  newConfig.scales.yAxes[0].scaleLabel = {
    display: false,
  };

  // Hide grid lines for both x and y axes
  newConfig.scales.xAxes[0].gridLines = {
    display: false,
  };
  newConfig.scales.yAxes[0].gridLines = {
    display: false,
  };

  // Hide ticks (numbers) on both x and y axes
  newConfig.scales.xAxes[0].ticks = {
    display: false,
  };

  const firstDataset = lineData.datasets[0];
  const secondDataset = lineData.datasets[1];
  const minValue = Math.min(...secondDataset.data);
  const maxValue = Math.max(...firstDataset.data);
  const calculatedStepSize = calculateStepSize(firstDataset.data);

  // console.log('firstDataset:', firstDataset);
  // console.log('secondDataset:', secondDataset);
  // console.log('minValue:', minValue);
  // console.log('maxValue:', maxValue);
  // console.log('stepSize:', calculatedStepSize);

  // newConfig.scales.yAxes[0].ticks = {
  //   display: false,
  //   min: 300000, // Minimivärdet för y-axeln
  //   max: 1000000, // Maximalt värde för y-axeln
  //   stepSize: 50000, // Steget mellan varje tick på y-axeln
  // };

  newConfig.scales.yAxes[0].ticks = {
    display: false,
    min: minValue * 0.95, // Minimivärdet för y-axeln
    max: maxValue * 1.45, // Maximalt värde för y-axeln
    stepSize: calculatedStepSize, // Steget mellan varje tick på y-axeln
  };

  return newConfig;
}

const positionMaximizeIncomeChatBubble = (
  maximizeIncomeChatBubbleDivElement,
  incomeMarkerIndex,
  meta
) => {
  //console.log('positionMaximizeIncomeChatBubble');

  let userSpecifiedSalaryModel = {};
  let adjustLeft = 0;
  if (incomeMarkerIndex === 0) {
    //console.log('Flyttat på snibb 1');
    userSpecifiedSalaryModel = meta.data[1]._model;
    adjustLeft = 25;
  } else if (incomeMarkerIndex === 1) {
    userSpecifiedSalaryModel = meta.data[1]._model;
    // adjustLeft = 35;
  } else if (incomeMarkerIndex === 8) {
    //console.log('Flyttat på snibb 2');
    userSpecifiedSalaryModel = meta.data[7]._model;
    adjustLeft = -25;
  } else {
    //console.log('Flyttat på snibb');
    userSpecifiedSalaryModel = meta.data[incomeMarkerIndex]._model;
  }

  const x1 = userSpecifiedSalaryModel.x - 190 / 2 + adjustLeft;
  const y1 = userSpecifiedSalaryModel.y - (156 + 15 + 20);

  maximizeIncomeChatBubbleDivElement.style.left = `${x1}px`;
  maximizeIncomeChatBubbleDivElement.style.top = `${y1}px`;
  maximizeIncomeChatBubbleDivElement.style.display = 'block';

  //console.log('incomeMarkerIndex', incomeMarkerIndex);
};

const positionMaximizeIncomeMarker = (
  maximizeIncomeMarkerDivElement,
  userSpecifiedSalaryModel
) => {
  const x2 = userSpecifiedSalaryModel.x - 18 / 2 - 2;
  const y2 = 468;

  maximizeIncomeMarkerDivElement.style.left = `${x2}px`;
  maximizeIncomeMarkerDivElement.style.top = `${y2}px`;
  maximizeIncomeMarkerDivElement.style.display = 'block';
};

const positionMaximizeIncomeMaxMarker = (
  maximizeIncomeMaxMarkerDivElement,
  maxSalaryModel
) => {
  const x3 = maxSalaryModel.x - 18 / 2 - 12;
  const y3 = 468;

  maximizeIncomeMaxMarkerDivElement.style.left = `${x3}px`;
  maximizeIncomeMaxMarkerDivElement.style.top = `${y3}px`;
  maximizeIncomeMaxMarkerDivElement.style.display = 'flex';
  maximizeIncomeMaxMarkerDivElement.style.flexDirection = 'column';
  maximizeIncomeMaxMarkerDivElement.style.alignItems = 'center';
  maximizeIncomeMaxMarkerDivElement.style.justifyContent = 'center';
};

export const positionMaximizeIncomeElements = (
  parent2Months,
  lineData,
  chartRef
) => {
  // console.log('positionMaximizeIncomeElements 1');
  const maximizeIncomeChatBubbleDivId = 'maximizeIncomeChatBubbleDiv';
  const maximizeIncomeMarkerDivId = 'maximizeIncomeMarkerDiv';
  const maximizeIncomeMaxMarkerDivId = 'maximizeIncomeMaxMarkerDiv';

  if (chartRef !== undefined) {
    if (chartRef.current?.chartInstance) {
      const { chartInstance } = chartRef.current;

      const incomeMarkerIndex = getIncomeMarkerIndex(lineData, parent2Months);

      const maxIndex = getMaxSumIndex(lineData);

      // console.log('maxIndex:', maxIndex);

      const meta = chartInstance.getDatasetMeta(0);
      // console.log('meta:', meta);
      const userSpecifiedSalaryModel = meta.data[incomeMarkerIndex]._model;
      // console.log('userSpecifiedSalaryModel:', userSpecifiedSalaryModel);

      const maxSalaryModel = meta.data[maxIndex]._model;
      // console.log('maxSalaryModel:', maxSalaryModel);

      if (chartInstance.chartArea) {
        const maximizeIncomeChatBubbleDivElement = document.getElementById(
          maximizeIncomeChatBubbleDivId
        );
        const maximizeIncomeMarkerDivElement = document.getElementById(
          maximizeIncomeMarkerDivId
        );
        const maximizeIncomeMaxMarkerDivElement = document.getElementById(
          maximizeIncomeMaxMarkerDivId
        );

        // console.log(
        //   'maximizeIncomeChatBubbleDivElement:',
        //   maximizeIncomeChatBubbleDivElement
        // );
        // console.log(
        //   'maximizeIncomeMarkerDivElement:',
        //   maximizeIncomeMarkerDivElement
        // );
        // console.log(
        //   'maximizeIncomeMaxMarkerDivElement:',
        //   maximizeIncomeMaxMarkerDivElement
        // );

        positionMaximizeIncomeChatBubble(
          maximizeIncomeChatBubbleDivElement,
          incomeMarkerIndex,
          meta
        );
        positionMaximizeIncomeMarker(
          maximizeIncomeMarkerDivElement,
          userSpecifiedSalaryModel
        );
        positionMaximizeIncomeMaxMarker(
          maximizeIncomeMaxMarkerDivElement,
          maxSalaryModel
        );
      }
    }
  }
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function handleSalaryChange(e, setSalary) {
  const strNumber = e.target.value
    .replace(/[^0-9]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  setSalary({
    displayValue: strNumber,
    actualNumberValue: Number(strNumber.replace(/ /g, '')),
  });
}

function formatLabelNumber(value) {
  if (typeof value === 'number') {
    return value.toLocaleString('sv');
  }
  return value;
}

export const lineOptions = {
  responsive: true,
  title: {
    display: false,
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: true },
        scaleLabel: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 32,
          callback: value => formatLabelNumber(value),
        },
      },
    ],
    yAxes: [
      {
        gridLines: { display: true },
        ticks: {
          min: 0, // Minimivärdet för y-axeln
          max: 0.6, // Maximalt värde för y-axeln
          stepSize: 0.1, // Steget mellan varje tick på y-axeln
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  events: ['hover'],
  onHover: null,
};

export function calculateDisposableIncome(
  monthlyIncome,
  parentalLeaveMonths,
  parentalSalaryMonths
) {
  const PBB = 57300; // Prisbasbelopp
  const IBB = 76200; // Inkomstbasbelopp
  const monthsWorked = 12 - parentalLeaveMonths;
  const yearlyIncome = monthlyIncome * monthsWorked;
  const yearlyIncomeTwelveMonths = 12 * monthlyIncome;

  let parentalAllowance = 0;
  let parentalSalary = 0;
  let stateIncomeTax = 0;
  let municipalTax = 0;
  let burialFee = 0;
  let publicServiceFee = 0;
  let jobTaxCredit = 0;
  let workDeduction = 0;
  let childAllowance = 0;

  let parentalAllowanceFormula = '';
  let parentalSalaryFormula = '';
  let stateIncomeTaxFormula = '';
  let burialFeeFormula = '';
  let publicServiceFeeFormula = '';
  let jobTaxCreditFormula = '';
  let workDeductionFormula = '';

  // Formel för beräkning av föräldrapenning
  if (yearlyIncomeTwelveMonths <= 10 * PBB) {
    parentalAllowance =
      ((yearlyIncomeTwelveMonths * 0.97 * 0.8) / 365) *
      30.4 *
      parentalLeaveMonths;
    parentalAllowanceFormula =
      '((yearlyIncomeTwelveMonths * 0.97 * 0.8) / 365) * 30.4 * parentalLeaveMonths';
  } else {
    parentalAllowance =
      ((10 * PBB * 0.97 * 0.8) / 365) * 30.4 * parentalLeaveMonths;
    parentalAllowanceFormula =
      '((10 * PBB * 0.97 * 0.8) / 365) * 30.4 * parentalLeaveMonths';
  }

  // Formel för beräkning av föräldralön
  if (yearlyIncomeTwelveMonths <= 10 * PBB) {
    parentalSalary = monthlyIncome - ((0.9 * monthlyIncome * 12) / 365) * 30;
    parentalSalaryFormula =
      'monthlyIncome - ((0.9 * monthlyIncome * 12) / 365) * 30';
  } else if (yearlyIncomeTwelveMonths > 10 * PBB) {
    parentalSalary =
      monthlyIncome -
      ((0.8 * 10 * PBB) / 365) * 30 -
      ((0.1 * monthlyIncome * 12) / 365) * 30;
    parentalSalaryFormula =
      'monthlyIncome - ((0.8 * 10 * PBB) / 365) * 30 - ((0.1 * monthlyIncome * 12) / 365) * 30';
  }

  parentalSalary *= Math.min(parentalLeaveMonths, parentalSalaryMonths);

  const FFI = yearlyIncome + parentalAllowance + parentalSalary;

  // Beräkning av grundavdraget
  let GA = 0;
  if (FFI < 0.99 * PBB) {
    GA = 0.423 * PBB;
  } else if (FFI >= 0.99 * PBB && FFI < 2.72 * PBB) {
    GA = 0.423 * PBB + 0.2 * (FFI - 0.99 * PBB);
  } else if (FFI >= 2.72 * PBB && FFI < 3.11 * PBB) {
    GA = 0.77 * PBB;
  } else if (FFI >= 3.11 * PBB && FFI < 7.88 * PBB) {
    GA = 0.77 * PBB - 0.1 * (FFI - 3.11 * PBB);
  } else if (FFI >= 7.88 * PBB) {
    GA = 0.293 * PBB;
  }

  const BFI = FFI - GA;

  // Beräkning av kommunalskatt
  const municipalTaxRate = 0.3237;
  municipalTax = municipalTaxRate * BFI;

  // Beräkning av statlig inkomstskatt
  const stateIncomeTaxThreshold = 598500;
  const TI = (FFI / monthlyIncome) * 12;
  const KI = municipalTaxRate;

  if (BFI > stateIncomeTaxThreshold) {
    stateIncomeTax =
      (TI - GA) * KI + Math.max(0, (TI - GA - stateIncomeTaxThreshold) * 0.2);
    stateIncomeTaxFormula =
      '(TI - GA) * KI + Math.max(0, (TI - GA - stateIncomeTaxThreshold) * 0.2)';
  }

  // Beräkning av begravningsavgift
  burialFee = 0.00277 * BFI;
  burialFeeFormula = '0.00277 * BFI';

  // Beräkning av Public Service-avgift
  if (BFI < 1.6 * IBB) {
    publicServiceFee = 0.01 * BFI;
    publicServiceFeeFormula = '0.01 * BFI';
  } else if (BFI > 1.6 * IBB) {
    publicServiceFee = 1219; // Fast avgift
    publicServiceFeeFormula = 'Fast avgift: 1219';
  }

  const AI = yearlyIncome + parentalSalary;

  // Beräkning av jobbskatteavdrag
  if (AI < 0.91 * PBB) {
    jobTaxCredit = (AI - GA) * KI;
    jobTaxCreditFormula = '(AI - GA) * KI';
  } else if (AI >= 0.91 * PBB && AI < 3.24 * PBB) {
    jobTaxCredit = (0.91 * PBB + 0.3874 * (AI - 0.91 * PBB) - GA) * KI;
    jobTaxCreditFormula = '(0.91 * PBB + 0.3874 * (AI - 0.91 * PBB) - GA) * KI';
  } else if (AI >= 3.24 * PBB && AI < 8.08 * PBB) {
    jobTaxCredit = (1.813 * PBB + 0.1643 * (AI - 3.24 * PBB) - GA) * KI;
    jobTaxCreditFormula =
      '(1.813 * PBB + 0.1643 * (AI - 3.24 * PBB) - GA) * KI';
  } else if (AI >= 8.08 * PBB && AI < 13.54 * PBB) {
    jobTaxCredit = (2.608 * PBB - GA) * KI;
    jobTaxCreditFormula = '(2.608 * PBB - GA) * KI';
  } else if (AI >= 13.54 * PBB) {
    jobTaxCredit = (2.608 * PBB - GA) * KI - 0.03 * (AI - 13.54 * PBB);
    jobTaxCreditFormula = '(2.608 * PBB - GA) * KI - 0.03 * (AI - 13.54 * PBB)';
  }

  // Beräkning av förvärvsavdrag
  if (BFI >= 40000 && BFI <= 240000) {
    workDeduction = 0.0075 * (BFI - 40000);
    workDeductionFormula = '0.0075 * (BFI - 40000)';
  } else if (BFI >= 240001) {
    workDeduction = 1500;
    workDeductionFormula = 'Fast avgift: 1500';
  }

  // Beräkning av barnbidrag
  childAllowance = 12 * (1250 / 2);

  // Beräkning av total disponibel inkomst
  const disposableIncome =
    FFI -
    municipalTax -
    stateIncomeTax -
    burialFee -
    publicServiceFee +
    jobTaxCredit +
    workDeduction +
    childAllowance;

  return {
    FFI,
    BFI,
    GA,
    AI,
    yearlyIncome,
    disposableIncome,
    parentalSalary,
    parentalAllowance,
    stateIncomeTax,
    municipalTax,
    burialFee,
    publicServiceFee,
    jobTaxCredit,
    workDeduction,
    childAllowance,
    formulas: {
      parentalAllowance: parentalAllowanceFormula,
      parentalSalary: parentalSalaryFormula,
      stateIncomeTax: stateIncomeTaxFormula,
      burialFee: burialFeeFormula,
      publicServiceFee: publicServiceFeeFormula,
      jobTaxCredit: jobTaxCreditFormula,
      workDeduction: workDeductionFormula,
    },
  };
}

function formatTotalDisposableIncomesToTable(totalDisposableIncomes) {
  // Skapa rubriker för tabellen inklusive formulärnamn
  const headers = [
    'Förälder 1 Antal månader föräldraledig',
    'Förälder 1 Månadslön',
    'Förälder 1 Antal månader med föräldralön',
    'Förälder 1 FFI',
    'Förälder 1 BFI',
    'Förälder 1 Årslön',
    'Förälder 1 GA',
    'Förälder 1 AI',
    'Förälder 1 Disponibel inkomst',
    'Förälder 1 Föräldralön',
    'Förälder 1 Föräldrapenning',
    'Förälder 1 Statlig inkomstskatt',
    'Förälder 1 Kommunalskatt',
    'Förälder 1 Begravningsavgift',
    'Förälder 1 Public service-avgift',
    'Förälder 1 Jobbskatteavdrag',
    'Förälder 1 Förvärvsavdrag',
    ...Object.keys(totalDisposableIncomes[0].parent1Formulas),
    'Förälder 2 Antal månader föräldraledig',
    'Förälder 2 Månadslön',
    'Förälder 2 Antal månader med föräldralön',
    'Förälder 2 FFI',
    'Förälder 2 BFI',
    'Förälder 2 Årslön',
    'Förälder 2 GA',
    'Förälder 2 AI',
    'Förälder 2 Disponibel inkomst',
    'Förälder 2 Föräldralön',
    'Förälder 2 Föräldrapenning',
    'Förälder 2 Statlig inkomstskatt',
    'Förälder 2 Kommunalskatt',
    'Förälder 2 Begravningsavgift',
    'Förälder 2 Public service-avgift',
    'Förälder 2 Jobbskatteavdrag',
    'Förälder 2 Förvärvsavdrag',
    ...Object.keys(totalDisposableIncomes[0].parent2Formulas),
    'Total Disponibel inkomst',
    'Total Föräldralön',
    'Nettoinkomst (Disponibel inkomst - föräldralön)',
  ];

  // Skapa rader för tabellen inklusive formelvärden
  const rows = totalDisposableIncomes.map(income => {
    const parent1Formulas = Object.values(income.parent1Formulas);
    const parent2Formulas = Object.values(income.parent2Formulas);

    return [
      income.parent1LeaveMonths,
      income.parent1MonthlyIncome,
      income.parent1ParentalSalaryMonths,
      income.parent1FFI,
      income.parent1BFI,
      income.parent1YearlyIncome,
      income.parent1GA,
      income.parent1AI,
      income.parent1DisposableIncome,
      income.parent1ParentalSalary,
      income.parent1ParentalAllowance,
      income.parent1StateIncomeTax,
      income.parent1MunicipalTax,
      income.parent1BurialFee,
      income.parent1PublicServiceFee,
      income.parent1JobTaxCredit,
      income.parent1WorkDeduction,
      ...parent1Formulas,
      income.parent2LeaveMonths,
      income.parent2MonthlyIncome,
      income.parent2ParentalSalaryMonths,
      income.parent2FFI,
      income.parent2BFI,
      income.parent2YearlyIncome,
      income.parent2GA,
      income.parent2AI,
      income.parent2DisposableIncome,
      income.parent2ParentalSalary,
      income.parent2ParentalAllowance,
      income.parent2StateIncomeTax,
      income.parent2MunicipalTax,
      income.parent2BurialFee,
      income.parent2PublicServiceFee,
      income.parent2JobTaxCredit,
      income.parent2WorkDeduction,
      ...parent2Formulas,
      income.totalDisposableIncome,
      income.totalParentalSalary,
      income.netIncome,
    ];
  });

  // Slå samman rubriker och rader till en tabell
  const table = [headers, ...rows];

  // Omvandla tabellen till textformat
  const tableText = table.map(row => row.join('\t')).join('\n');

  return tableText;
}

export function calculateTotalDisposableIncomes(
  parent1MonthlyIncome,
  parent2MonthlyIncome,
  parent1ParentalSalaryMonths,
  parent2ParentalSalaryMonths
) {
  const totalDisposableIncomes = [];

  // Loop genom alla kombinationer av månadsfördelningar
  for (
    let parent2LeaveMonths = 2;
    parent2LeaveMonths <= 10;
    parent2LeaveMonths++
  ) {
    const parent1LeaveMonths = 12 - parent2LeaveMonths; // Komplementet av parent1LeaveMonths för att totalt bli 12 månader

    // console.log('-----------------------------------------------------');
    // console.log('parent1');
    // console.log('parent1MonthlyIncome', parent1MonthlyIncome);
    // console.log('parent1LeaveMonths', parent1LeaveMonths);
    // console.log('parent1ParentalSalaryMonths', parent1ParentalSalaryMonths);
    // console.log('-----------------------------------------------------');
    const parent1Results = calculateDisposableIncome(
      parent1MonthlyIncome,
      parent1LeaveMonths,
      parent1ParentalSalaryMonths
    );

    // console.log('-----------------------------------------------------');

    // console.log('-----------------------------------------------------');
    // console.log('parent2');
    // console.log('parent2MonthlyIncome', parent2MonthlyIncome);
    // console.log('parent2LeaveMonths', parent2LeaveMonths);
    // console.log('parent2ParentalSalaryMonths', parent2ParentalSalaryMonths);
    // console.log('-----------------------------------------------------');

    const parent2Results = calculateDisposableIncome(
      parent2MonthlyIncome,
      parent2LeaveMonths,
      parent2ParentalSalaryMonths
    );

    // console.log('-----------------------------------------------------');

    const totalDisposableIncome = {
      parent1MonthlyIncome,
      parent1LeaveMonths,
      parent1ParentalSalaryMonths,
      parent1FFI: parent1Results.FFI,
      parent1BFI: parent1Results.BFI,
      parent1YearlyIncome: parent1Results.yearlyIncome,
      parent1GA: parent1Results.GA,
      parent1AI: parent1Results.AI,
      parent1DisposableIncome: parent1Results.disposableIncome,
      parent1ParentalSalary: parent1Results.parentalSalary,
      parent1ParentalAllowance: parent1Results.parentalAllowance,
      parent1StateIncomeTax: parent1Results.stateIncomeTax,
      parent1MunicipalTax: parent1Results.municipalTax,
      parent1BurialFee: parent1Results.burialFee,
      parent1PublicServiceFee: parent1Results.publicServiceFee,
      parent1JobTaxCredit: parent1Results.jobTaxCredit,
      parent1WorkDeduction: parent1Results.workDeduction,
      parent1ChildAllowance: parent1Results.childAllowance,
      parent1Formulas: parent1Results.formulas,

      parent2MonthlyIncome,
      parent2LeaveMonths,
      parent2ParentalSalaryMonths,
      parent2FFI: parent2Results.FFI,
      parent2BFI: parent2Results.BFI,
      parent2YearlyIncome: parent2Results.yearlyIncome,
      parent2GA: parent2Results.GA,
      parent2AI: parent2Results.AI,
      parent2DisposableIncome: parent2Results.disposableIncome,
      parent2ParentalSalary: parent2Results.parentalSalary,
      parent2ParentalAllowance: parent2Results.parentalAllowance,
      parent2StateIncomeTax: parent1Results.stateIncomeTax,
      parent2MunicipalTax: parent2Results.municipalTax,
      parent2BurialFee: parent2Results.burialFee,
      parent2PublicServiceFee: parent2Results.publicServiceFee,
      parent2JobTaxCredit: parent2Results.jobTaxCredit,
      parent2WorkDeduction: parent2Results.workDeduction,
      parent2ChildAllowance: parent2Results.childAllowance,
      parent2Formulas: parent2Results.formulas,

      totalDisposableIncome:
        parent1Results.disposableIncome + parent2Results.disposableIncome,
      totalParentalSalary:
        parent1Results.parentalSalary + parent2Results.parentalSalary,
      netIncome:
        parent1Results.disposableIncome +
        parent2Results.disposableIncome -
        (parent1Results.parentalSalary + parent2Results.parentalSalary),
    };

    totalDisposableIncomes.push(totalDisposableIncome);
  }

  //console.log('Total Disposable Incomes:', totalDisposableIncomes);

  // Använd funktionen för att formatera totalDisposableIncomes till en tabell

  const formattedTable = formatTotalDisposableIncomesToTable(
    totalDisposableIncomes
  );

  // console.log(formattedTable); // Skriv ut tabellen i konsolen för att sedan klistra in i Excel

  return totalDisposableIncomes;
}

export function calculateHouseholdIncome(
  parent1Salary,
  parent2Salary,
  parent1Months,
  parent2Months,
  setLineData,
  setHouseholdIncomeData,
  setHasData
) {
  // console.log('calculateHouseholdIncome');
  const totalDisposableIncomes = calculateTotalDisposableIncomes(
    parent1Salary,
    parent2Salary,
    parent1Months,
    parent2Months
  );

  // console.log('Total Disposable Incomes:', totalDisposableIncomes);

  const lineChartData = {
    labels: Array.from({ length: 9 }, (_, index) => `${index + 1}`),
    datasets: [
      {
        label: 'Total Disposable Income',
        data: [],
        fill: '+1',
        borderColor: '#66BBC7',
        backgroundColor: '#66BBC7',
        pointBorderColor: '#FFFFFF',
        tension: 0.1,
      },
      {
        label: 'Net Income',
        data: [],
        fill: 'origin',
        borderColor: '#005561',
        backgroundColor: '#005561',
        pointBorderColor: '#FFFFFF',
        tension: 0.1,
      },
    ],
  };

  for (let i = 0; i < totalDisposableIncomes.length; i++) {
    lineChartData.datasets[0].data.push(
      totalDisposableIncomes[i].totalDisposableIncome
    );
    lineChartData.datasets[1].data.push(totalDisposableIncomes[i].netIncome);
  }

  lineChartData.datasets[0].pointBackgroundColor = generatePointBackgroundColors(
    lineChartData.datasets[0].data,
    lineChartData,
    '#66BBC7',
    '#C13D8C'
  );

  lineChartData.datasets[1].pointBackgroundColor = generatePointBackgroundColors(
    lineChartData.datasets[1].data,
    lineChartData,
    '#005561',
    '#C13D8C'
  );

  setHouseholdIncomeData(totalDisposableIncomes);
  setLineData(lineChartData);
  setHasData(true);
}
