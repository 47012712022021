import React from 'react';
import styles from './Welcome.module.css';

const Welcome = () => {
  return (
    <section className={styles.main}>
      <h1>Välkommen!</h1>
      <p>
        Har du koll på ditt löneläge? Med Saco lönesök får du tillgång till
        Sveriges bästa lönestatistik för akademiker. Saco lönesök är flexibelt
        och ger dig stora möjligheter att anpassa lönestatistik utifrån ditt
        unika behov. Använd Saco lönesök inför ditt lönesamtal, innan du tackar
        ja till ett nytt jobb och när du funderar på att byta bransch, roll
        eller yrke. Lönestatistik är ditt främsta verktyg i löneförhandlingen.
      </p>
      <p>
        Nedan kan du göra en grov uppskattning av ditt löneläge. För att få
        tillgång till hela Saco lönesök behöver du vara medlem i ett
        Sacoförbund.
      </p>
    </section>
  );
};

export default Welcome;
