import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Section from 'atoms/section/Section';
import { censorReponsondants } from 'lib/antal';
import { Link } from 'react-router-dom';
import { IconChevronRight } from 'tabler-icons';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import UnionLink from 'atoms/unionlink/UnionLink';
import SalaryInterval from './salaryinterval/SalaryInterval';
import ExternalLink from '../externallink/ExternalLink';
import styles from './SalaryAnalysis.module.css';

const SalaryAnalysis = ({
  currentRespondents,
  salaryData,
  selectedSalary,
  salaryLimit,
  links,
  variant,
}) => {
  const {
    user: { token },
  } = useAuth();

  const [unionLink, setUnionLink] = useState();

  useEffect(() => {
    const request = [
      {
        groupId: 'unionLink',
        textId: ['unionLink'],
      },
    ];

    ApiService.getTexts(request, token).then(data => {
      if (data && data[0]) return setUnionLink(data[0]);
      return setUnionLink();
    });
  }, [token]);

  const salary = {
    number: selectedSalary,
    string: <b>{Number(selectedSalary).toLocaleString('sv')}</b>,
  };

  const average = {
    number: salaryData.lon_medel,
    string: (
      <>
        <b>{salaryData.lon_medel.toLocaleString('sv')}</b> kronor per månad.
      </>
    ),
  };

  const overAverage = salary.number > average.number;

  const SalaryDisclaimerString = () => {
    return (
      <p>
        Det finns många faktorer som påverkar din lön. Använd{' '}
        <Link to="/avancerad">Saco lönesöks avancerade sökning</Link> för att se
        mer detaljerad statistik eller <UnionLink unionLink={unionLink} /> för
        en personlig lönerådgivning.{' '}
      </p>
    );
  };

  return (
    <div className={styles.salaryAnalysis}>
      <Section title="Din överblick">
        <SalaryInterval
          salaryLimit={salaryLimit}
          salaryMid={average.number}
          selectedSalary={selectedSalary}
        />
        <p>
          Medellönen för urvalet är {average.string}{' '}
          {selectedSalary && (
            <>
              Din lön om {salary.string} kronor ligger{' '}
              {overAverage ? 'över' : 'under'} medellönen.
            </>
          )}
        </p>
        <ul>
          <li>
            80 % har en lön mellan{' '}
            <b>
              {`${salaryLimit[0].toLocaleString(
                'sv'
              )}–${salaryLimit[1].toLocaleString('sv')}`}
            </b>{' '}
            kronor.
          </li>
          <li>
            10 % har en lön som är lägre än{' '}
            <b>{salaryLimit[0].toLocaleString('sv')}</b> kronor.
          </li>
          <li>
            10 % har en lön som är högre än{' '}
            <b>{salaryLimit[1].toLocaleString('sv')}</b> kronor.
          </li>
        </ul>
        {currentRespondents && (
          <p>
            Ditt urval består av uppgifter från{' '}
            {censorReponsondants(currentRespondents)} medlemmar.
          </p>
        )}
        {variant === 'simple' && SalaryDisclaimerString()}
      </Section>

      {variant === 'simple' && (
        <Section>
          {links &&
            links[0] &&
            links.map(({ header, id, value }) => (
              <ExternalLink key={id} value={value} header={header} />
            ))}
          <Link to="/avancerad" className={styles.externalLink}>
            Jag vill veta mer, ta mig till Saco lönesöks avancerade sökning!
            <IconChevronRight role="img" aria-label="" />
          </Link>
        </Section>
      )}
    </div>
  );
};

SalaryAnalysis.propTypes = {
  currentRespondents: PropTypes.number,
  salaryData: PropTypes.shape(Object).isRequired,
  selectedSalary: PropTypes.string,
  salaryLimit: PropTypes.arrayOf(Number).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      textId: PropTypes.string.isRequired,
      unionId: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      isDefault: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  variant: PropTypes.oneOf(['free', 'simple']),
};

SalaryAnalysis.defaultProps = {
  currentRespondents: null,
  selectedSalary: null,
  links: [],
  variant: 'free',
};

export default SalaryAnalysis;
