import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import InputReadOnly from 'atoms/inputreadonly/InputReadOnly';
import Input from 'atoms/input/Input';
import Textarea from 'atoms/textarea/Textarea';
import Panel from 'atoms/panel/Panel';
import styles from './SituationCard.module.css';

const SituationCard = ({
  copySituation,
  deleteSituation,
  situation,
  updateSituation,
}) => {
  const [editMode, setEditMode] = useState(false);

  const [situationName, setSituationName] = useState(situation.name);
  const [situationDescription, setSituationDescription] = useState(
    situation.description
  );
  const [situationIsActive, setSituationIsActive] = useState(
    situation.isActive
  );

  const handleDelete = () => {
    const situationToDelete = {
      id: situation.id,
      name: situationName,
      description: situationDescription,
      isActive: situationIsActive,
    };

    deleteSituation(situationToDelete);
    setEditMode(prevState => !prevState);
  };

  const handleUpdate = () => {
    const updatedSituation = {
      id: situation.id,
      name: situationName,
      description: situationDescription,
      isActive: situationIsActive,
    };

    updateSituation(updatedSituation);
    setEditMode(prevState => !prevState);
  };

  return (
    <Panel
      card
      key={situation.id}
      legend={`${editMode ? 'Redigera situation' : 'Situation'}`}
    >
      <div className={styles.situationCardInner}>
        <section className={styles.textInput}>
          <form>
            {!editMode && (
              <InputReadOnly label="Namn" text={situation.name} useHyphens />
            )}
            {editMode && (
              <Input
                id="situation-name-input"
                label="Namn"
                onChange={e => setSituationName(e.target.value)}
                placeholder="Namn"
                type="text"
                value={situationName}
              />
            )}
            {!editMode && (
              <InputReadOnly
                label="Beskrivning"
                text={situation.description}
                useHyphens
              />
            )}
            {editMode && (
              <Textarea
                id="situation-description-input"
                label="Beskrivning"
                value={situationDescription}
                classTextArea={styles.situationDescription}
                onChange={e => setSituationDescription(e.target.value)}
              />
            )}
          </form>
        </section>

        <section className={styles.dataSource}>
          <InputReadOnly label="DSL1" text={situation.DSL1} />
          <InputReadOnly label="DSL2" text={situation.DSL2} />
          <InputReadOnly label="År" text={situation.year.toString()} />
        </section>
      </div>

      <div>
        <ButtonBar>
          <CheckboxAdmin
            id={`situationActive-${situation.id}`}
            label="Aktiv"
            disabled={!editMode}
            checked={situationIsActive}
            onChange={() => setSituationIsActive(prevState => !prevState)}
          />
          {editMode ? (
            <>
              <Button
                variant="delete"
                onClick={handleDelete}
                label="Ta bort"
                icon="Trash"
                altText="Ta bort situation"
              />
              <Button
                variant="primary"
                onClick={handleUpdate}
                label="Spara"
                altText="Spara situation"
              />
              <Button
                variant="secondary"
                onClick={() => setEditMode(prevState => !prevState)}
                label="Avbryt"
              />
            </>
          ) : (
            <>
              <Button
                variant="primary"
                label="Duplicera"
                icon="Copy"
                altText="Duplicera situation"
                onClick={() => copySituation(situation)}
              />
              <Button
                variant="primary"
                onClick={() => setEditMode(prevState => !prevState)}
                label="Redigera"
                icon="Edit"
                altText="Redigera situation"
              />
            </>
          )}
        </ButtonBar>
      </div>
    </Panel>
  );
};

SituationCard.propTypes = {
  deleteSituation: PropTypes.func.isRequired,
  copySituation: PropTypes.func.isRequired,
  situation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool,
    description: PropTypes.string,
    DSL1: PropTypes.string,
    DSL2: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  updateSituation: PropTypes.func.isRequired,
};

export default SituationCard;
