import React from 'react';
import PropTypes from 'prop-types';
import {
  IconX,
  IconChevronDown,
  IconPlus,
  IconFileDownload,
  IconHelp,
  IconMinus,
} from 'tabler-icons';
import styles from './ButtonLink.module.css';

const ButtonLink = ({
  className,
  label,
  altText,
  icon,
  large,
  menuItem,
  lastChild,
  accordionItem,
  disabled,
  onClick,
  style,
}) => {
  const buttonClassName = className && className !== '' ? className : '';
  const buttonIsLarge = large ? `${styles.isLarge}` : '';
  const buttonIsMenuItem = menuItem ? `${styles.isMenuItem}` : '';
  const buttonIsAccordionItem = accordionItem
    ? `${styles.isAccordionItem}`
    : '';
  const buttonIsLastChild = lastChild ? `${styles.isLastChild}` : '';
  const computedAltText = altText && altText !== '' ? altText : label;

  let buttonIcon;
  if (icon && icon === 'Help') {
    buttonIcon = (
      <IconHelp
        aria-label=""
        role="img"
        size={20}
        color="#F8F8F8"
        fill="#277C7C"
      />
    );
  } else if (icon && icon === 'Download') {
    buttonIcon = <IconFileDownload aria-label="" role="img" size={20} />;
  } else if (icon && icon === 'Plus') {
    buttonIcon = <IconPlus aria-label="" role="img" size={18} />;
  } else if (icon && icon === 'Minus') {
    buttonIcon = <IconMinus aria-label="" role="img" size={18} />;
  } else if (icon && icon === 'ChevronDown') {
    if (buttonIsLarge) {
      buttonIcon = (
        <IconChevronDown aria-label="" size={20} role="img" color="black" />
      );
    } else {
      buttonIcon = <IconChevronDown aria-label="" size={20} role="img" />;
    }
  } else if (icon && icon === 'X') {
    buttonIcon = <IconX aria-label="" size={20} role="img" />;
  }

  return (
    <button
      className={`${styles.buttonLink} ${buttonClassName} ${buttonIsLarge} ${buttonIsMenuItem} ${buttonIsAccordionItem} ${buttonIsLastChild}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      alt={computedAltText}
      type="button"
    >
      <span className={styles.buttonIcon}>{buttonIcon}</span>
      <span className={styles.buttonText}>{label}</span>
    </button>
  );
};

ButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  altText: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  large: PropTypes.bool,
  menuItem: PropTypes.bool,
  lastChild: PropTypes.bool,
  accordionItem: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

ButtonLink.defaultProps = {
  altText: '',
  className: '',
  icon: '',
  large: false,
  menuItem: false,
  lastChild: false,
  accordionItem: false,
  disabled: false,
  onClick: () => {},
  style: undefined,
};

export default ButtonLink;
