export function resetMeasureVarToLon(dataSource, setMeasureVar) {
  if (dataSource.measureVar != null && dataSource.measureVar.length <= 1) {
    setMeasureVar('LON');
  }
}

export function logGetReportRequest(request) {
  console.log('----------------------------');
  console.log(`getReport Forbund: ${request.Forbund}`);
  console.log(`getReport DSL1: ${request.Dsl1}`);
  console.log(`getReport DSL2: ${request.Dsl2}`);
  console.log(`getReport Measurevar: ${request.measure_var}`);
  console.log(`getReport year: ${request.Year}`);
  console.log(`getReport breakby1: ${request.breakby1}`);
  console.log(`getReport breakby2: ${request.breakby2}`);
  console.log(`getReport filter: ${request.filter}`);
  console.log('----------------------------');
}
