import React, { useState } from "react";
import styles from "./ButtonGroup.module.css";

const ButtonGroup = ({ buttons, doSomethingAfterClick, color, initialSelectedIndex = 0, whiteText }) => {

    const [clickedId, setClickedId] = useState(initialSelectedIndex);

    const handleClick = (event, id) => {
        setClickedId(id);
        doSomethingAfterClick(id);
    };

    return (
        <>
            <div className={styles.container}>
                {buttons.map((buttonLabel, i) => (
                    <button
                        key={i}
                        style={i === clickedId ? { backgroundColor: color } : { backgroundColor: '#cce8ec' }}
                        name={buttonLabel}
                        onClick={(event) => handleClick(event, i)}
                        className={`${styles.customButton} ${whiteText ? styles.textcolorWhite : ''} ${i === 0 ? styles.firstButton : ''} ${i === buttons.length - 1 ? styles.lastButton : ''}`}
                    >
                        {buttonLabel}
                    </button>
                ))}
            </div>
        </>
    );
};

export default ButtonGroup;