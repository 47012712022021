/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import Banner from './banner/Banner';
import styles from './Header.module.css';
import Menu from './menu/Menu';

const fallbackUnion = {
  name: 'Saco lönesök',
  forbundskodSas: 'default',
};

const Header = () => {
  const [imageLoadError, setImageLoadError] = useState(true);
  const [forbundList, setForbundList] = useState([]);
  const [union, setUnion] = useState(fallbackUnion);

  const location = useLocation();
  const { user } = useAuth();

  const path = `${process.env.PUBLIC_URL}/union_logotypes`;

  const getLogoSrc = str => `${path}/${str.toLowerCase()}_logo.svg`;

  const setFallbackSrc = e => {
    const { forbundskodSas, name } = fallbackUnion;
    if (imageLoadError) {
      setImageLoadError(false);
      e.target.src = getLogoSrc(forbundskodSas);
      e.target.title = name;
      e.target.alt = `${name} logotyp`;
    }
  };

  const getUnion = useCallback(() => {
    if (user) {
      const foundUnion = forbundList.find(
        d => d.forbundskodSas.toLowerCase() === user.forbund.toLowerCase()
      );

      if (foundUnion) {
        return foundUnion;
      }
    }
    return fallbackUnion;
  }, [user, forbundList]);

  useEffect(() => {
    if (forbundList.length === 0) return;
    setUnion(getUnion(forbundList));
  }, [forbundList, getUnion]);

  // Initially, get list of forbund
  useEffect(() => {
    if (user == null || forbundList.length !== 0) return;
    ApiService.getUnions(user.token).then(data => {
      setForbundList(
        data.sort((a, b) => `${a.name}`.localeCompare(b.name, 'sv'))
      );
    });
  }, [user, forbundList]);

  return (
    <>
      {location.pathname.includes('Instruktionsfilm') ||
      location.pathname.includes('oppensokning') ||
      location.pathname.toLowerCase().includes('unemploymentspin') ||
      location.pathname.includes('careerspin') ||
      location.pathname.toLowerCase().includes('parentalleavespin') ? (
        <></>
      ) : (
        <header className={styles.headerElement}>
          <div className={styles.headerInner}>
            <div className={styles.headerDropshadow}>
              <div className={styles.headerClippath}>
                <div className={styles.headerContent}>
                  <div className={styles.subMain}>
                    <div className={styles.headerLogotype}>
                      <img
                        src={getLogoSrc(union.forbundskodSas)}
                        onError={setFallbackSrc}
                        title={union.name}
                        alt={`${union.name} logotyp`}
                      />
                    </div>
                    <Menu />
                  </div>
                </div>
                {location.pathname === '/' && <Banner user={user} />}
              </div>
            </div>
          </div>

          <div className={styles.bannerCurveBackground}>
            <div className={styles.bannerDropshadow}>
              <div className={styles.bannerClippath} />
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
