import ButtonLink from 'atoms/buttonlink/ButtonLink';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Tab from 'atoms/tab/Tab';
import TabRow from 'atoms/tabrow/TabRow';
import Const from 'const';
import useScrollTo from 'hooks/useScrollTo';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import SituationConfig from 'components/admin/situationconfig/SituationConfig';
import LinksConfig from '../linksconfig/LinksConfig';
import SalaryProfileConfig from '../salaryprofileconfig/SalaryProfileConfig';
import HelpBox from './helpbox/HelpBox';
import styles from './Level2Simple.module.css';

const Level2Simple = () => {
  const history = useHistory();
  const ref = useScrollTo('hjalp');
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  const [activeTab, setActiveTab] = useState('situation');
  const [situations, setSituations] = useState();
  const [loadingSituations, setLoadingSituations] = useState(false);

  useEffect(() => {
    if (user.role === 'redaktor') return;
    setLoadingSituations(true);
    ApiService.getSituations(token)
      .then(data => {
        setSituations(data);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setLoadingSituations(false));
    // eslint-disable-next-line
  }, [token, signout, history]);

  if (user.role === 'redaktor')
    return (
      <Panel>
        <Section title={Const.admin.level2Header}>
          <LinksConfig />
        </Section>
      </Panel>
    );

  // admins
  return (
    <Panel>
      <Section title={Const.admin.level2Header}>
        <TabRow className={styles.tabRow}>
          <Tab
            active={activeTab === 'situation'}
            label="1. Situationer"
            onClick={() => setActiveTab('situation')}
          />
          <Tab
            active={activeTab === 'statistics'}
            label="2. Statistik & urval"
            onClick={() => setActiveTab('statistics')}
          />
          <Tab
            active={activeTab === 'links'}
            label="3. Länkar"
            onClick={() => setActiveTab('links')}
          />

          <ButtonLink
            label="Hjälp"
            altText="Hjälpinformation"
            className={styles.helpLink}
            icon="Help"
            onClick={() => history.push('#hjalp')}
          />
        </TabRow>

        {loadingSituations ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            {situations && (
              <>
                {activeTab === 'situation' && situations && (
                  <SituationConfig
                    situations={situations}
                    setSituations={setSituations}
                  />
                )}
                {activeTab === 'statistics' && situations && (
                  <SalaryProfileConfig
                    situations={situations}
                    setSituations={setSituations}
                  />
                )}
                {activeTab === 'links' && <LinksConfig />}
              </>
            )}
          </>
        )}
        <div ref={ref} id="hjalp">
          <HelpBox />
        </div>
      </Section>
    </Panel>
  );
};

export default Level2Simple;
