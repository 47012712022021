import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tab.module.css';

const Tab = ({ active, label, altText, onClick }) => {
  const computedAltText = altText && altText !== '' ? altText : label;
  return (
    <button
      className={`${styles.tab} ${active ? styles.active : styles.passive}`}
      onClick={onClick}
      alt={computedAltText}
      type="button"
    >
      {label}
    </button>
  );
};

Tab.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  altText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  altText: '',
};

export default Tab;
