import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import ApiService from 'services/ApiService';

const MESSAGES = {
  '/': 'Start',
  '/enkel': 'Nivå 2',
  '/avancerad': 'Nivå 3',
};

const getRandomSeconds = (low, high) =>
  (Math.floor(Math.random() * high) + low) * 1000;

const VisitorCounter = ({ path, user }) => {
  const [cookies] = useCookies(['statistics']);

  useEffect(() => {
    let active = true;
    const request = { message: MESSAGES[path] || path };

    setTimeout(() => {
      // Only send statistic data if user allowed it
      if (active && cookies.statistics === 'allow') {
        ApiService.sendVistitorStats(request, user.token).then(() => {});
      }
    }, getRandomSeconds(10, 15));

    return () => {
      active = false;
    };
  }, [path, user, cookies.statistics]);
  return null;
};

VisitorCounter.propTypes = {
  path: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default VisitorCounter;
