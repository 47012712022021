import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './BreadcrumbsItem.module.css';

const BreadcrumbsItem = ({ match, index, ...rest }) => {
  const routes = {
    '/': 'Start',
    '/enkel': 'Enkel',
    '/avancerad': 'Avancerad',
    '/for-utvecklare': 'För utvecklare',
    '/byte-av-port': 'För utvecklare - byte av port',
    '/bli-medlem': 'Enkel (gratis)',
  };

  const routeName = routes[match.url];
  if (match.isExact) {
    document.title = `${routeName} - Saco lönesök`;
  }

  return (
    <li className={styles.breadcrumb}>
      {index !== 0 && ' / '}
      {match.isExact ? (
        <h1 className={styles.breadcrumbActive}>{routeName}</h1>
      ) : (
        <Link className={styles.breadcrumbInactive} to={match.url || ''}>
          {routeName}
        </Link>
      )}
    </li>
  );
};

BreadcrumbsItem.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({}),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default BreadcrumbsItem;
