/* eslint-disable camelcase */
import React from 'react';
import Loader from 'atoms/loader/Loader';
import { IconCircleCheck } from 'tabler-icons';
import styles from './StatusBox.module.css';

const StatusBox = ({ jobStatus }) => {
  return (
    <>
      <div className={styles.statusBox}>
        <h3>Status</h3>

        {jobStatus !== null && jobStatus && jobStatus.isJobRunning ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" small className={styles.selectLoader} />
          </div>
        ) : (
          <p>Inget jobb för att kopiera konfiguration körs för tillfället.</p>
        )}
        {jobStatus !== null &&
          jobStatus &&
          jobStatus.isJobRunning &&
          jobStatus.currentSummaryStatus &&
          jobStatus.currentSummaryStatus !== null && (
            <div>
              <p>{`[${jobStatus.currentSummaryStatus.date}] ${jobStatus.currentSummaryStatus.statusMessage}`}</p>
            </div>
          )}

        {jobStatus !== null &&
        jobStatus &&
        jobStatus.summaryStatuses &&
        jobStatus.summaryStatuses.length > 0 ? (
          <ul className={styles.ul}>
            {jobStatus &&
              jobStatus.summaryStatuses.length > 0 &&
              jobStatus.summaryStatuses.map((
                status,
                index // Lägg till 'key' prop
              ) => (
                <li key={index} className={styles.li}>
                  <div className={styles.filterHeading}>
                    <span>
                      <IconCircleCheck
                        className={styles.indicatorSymbol}
                        role="img"
                        aria-label=""
                        color={`${status.success ? 'green' : 'red'}`}
                      />
                      {`[${status.date}] ${status.statusMessage} `}{' '}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

StatusBox.propTypes = {};

StatusBox.defaultProps = {};

export default StatusBox;
