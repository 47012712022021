import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Accordion from 'atoms/accordion/Accordion';
import styles from './DataSource.module.css';

const DataSource = ({
  configurationType,
  dslConfig,
  handleUpdateConfiguration,
}) => {
  const handleItemUpdate = (dsl1, dsl2) => {
    const newList = dslConfig.dsL2Configs.map(dsl2Config => {
      if (dsl2Config.DSL2 === dsl2) {
        const updateddsl2Config = {
          ...dsl2Config,
          copyFromTestToProd: !dsl2Config.copyFromTestToProd,
        };

        return updateddsl2Config;
      }

      return dsl2Config;
    });
    dslConfig.dsL2Configs = newList;
    handleUpdateConfiguration(dslConfig, configurationType);
  };

  return (
    <>
      <Accordion
        id={`${configurationType}_${dslConfig.dsL1Config.DSL1}`}
        key={`${configurationType}_${dslConfig.dsL1Config.DSL1}`}
        title={dslConfig.dsL1Config.DSL1_DESC}
        boxShadow
        marginBottom
        className=""
        initialOpen
      >
        <div className={styles.expandable}>
          {dslConfig.dsL2Configs.map(dsl2Config => (
            <React.Fragment key={dsl2Config.DSL2}>
              <CheckboxAdmin
                id={`${configurationType}_${dsl2Config.DSL2}`}
                key={`${configurationType}_${dsl2Config.DSL2}`}
                label={
                  dsl2Config.DSL2_DESC === 'Okänt'
                    ? 'Samtliga'
                    : dsl2Config.DSL2_DESC
                }
                checked={dsl2Config.copyFromTestToProd}
                onChange={() =>
                  handleItemUpdate(dslConfig.dsL1Config.DSL1, dsl2Config.DSL2)
                }
              />
              <br />
            </React.Fragment>
          ))}
        </div>
      </Accordion>
    </>
  );
};

DataSource.defaultProps = {
  dslConfig: null,
};

export default DataSource;
