/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'atoms/checkbox/Checkbox';
import styles from './FilterItem.module.css';

const FilterItem = ({
  activeFilters,
  disableMenuselection,
  handleSelectedFilters,
  listItem,
}) => {
  return (
    <div className={styles.filterItem}>
      {listItem.list.map(item => {
        const { sel_var, value, selectable, antal } = item;
        return (
          <Checkbox
            checked={activeFilters
              .filter(activeFilter => activeFilter.sel_var === sel_var)
              .some(activeFilter =>
                activeFilter.values.some(
                  filterValue => filterValue.value === value
                )
              )}
            disabled={disableMenuselection || !selectable || antal === ' < 5'}
            item={item}
            key={sel_var + value}
            onChange={() => handleSelectedFilters(item)}
            variant="secondary"
            className={antal === ' < 5' ? styles.greyed : ''}
          />
        );
      })}
    </div>
  );
};

FilterItem.propTypes = {
  activeFilters: PropTypes.arrayOf(Object).isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
  handleSelectedFilters: PropTypes.func.isRequired,
  listItem: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        sel_var: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        selectable: PropTypes.bool,
        antal: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default FilterItem;
