/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import TabRow from 'atoms/tabrow/TabRow';
import Tab from 'atoms/tab/Tab';
import { formatRespondents } from 'lib/antal';
import {
  formatAccessor,
  formatNumber,
  generateCustomColumns,
  generateCustomData,
  getTotalCol,
  isTotalRow,
} from 'lib/salaryTable';
import styles from './SalaryTable.module.css';

const BREAKPOINT = 1024;

const SalaryTable = ({
  activeDataSource,
  measureVar,
  selectedPresentation,
  setMeasureVar,
  tableData,
}) => {
  const customReport = selectedPresentation.length === 2;

  // Get a reference to the table
  const tableRef = ref => {
    if (!ref) return;
    const table = ref;
  };

  // Set up columns
  const columns = useMemo(
    () =>
      !customReport
        ? [
            {
              Header: selectedPresentation[0].REPORT_TEXT,
              accessor: 'breakby1',
              Footer: 'Samtliga',
              className: styles.sticky,
            },
            {
              Header: 'Antal',
              accessor: 'antal',
              Cell: ({ value }) => formatRespondents(value),
              Footer: ({ rows }) =>
                formatRespondents(getTotalCol(rows, 'antal')),
            },
            {
              Header: 'Medel',
              accessor: 'lon_medel',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_medel'),
            },
            {
              Header: '10:e perc',
              accessor: 'lon_p10',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_p10'),
            },
            {
              Header: 'Undre kvartil',
              accessor: 'lon_p25',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_p25'),
            },
            {
              Header: 'Median',
              accessor: 'lon_p50',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_p50'),
            },
            {
              Header: 'Övre kvartil',
              accessor: 'lon_p75',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_p75'),
            },
            {
              Header: '90:e perc',
              accessor: 'lon_p90',
              Cell: ({ value }) => formatNumber(value),
              Footer: ({ rows }) => getTotalCol(rows, 'lon_p90'),
            },
            {
              Header: 'Totalindikator',
              accessor: 'totalind',
            },
            {
              Header: 'Typ',
              accessor: '_TYPE_',
            },
          ]
        : [
            {
              Header: selectedPresentation[1].REPORT_TEXT,
              accessor: 'breakby2',
              className: styles.center,
              columns: [
                {
                  Header: '',
                  accessor: 'spacer-0',
                  className: styles.sticky,
                  columns: [
                    {
                      Header: selectedPresentation[0].REPORT_TEXT,
                      accessor: 'breakby1',
                      Footer: 'Samtliga',
                      className: styles.sticky,
                    },
                  ],
                },
                ...tableData
                  .filter(
                    row =>
                      row.breakby2 &&
                      !['okänd', 'inget värde'].includes(
                        row.breakby2.toLowerCase()
                      )
                  )
                  .reduce(
                    (acc, curr) => generateCustomColumns(acc, curr, 'breakby2'),
                    []
                  )
                  // .sort((a, b) => a.breakby2.localeCompare(b.breakby2, 'sv'))
                  .map(({ breakby2 }) => ({
                    Header: breakby2,
                    accessor: formatAccessor(breakby2),
                    columns: [
                      {
                        Header: 'Antal',
                        accessor: formatAccessor(`antal-${breakby2}`),
                        Cell: ({ value }) => formatRespondents(value),
                        Footer: ({ rows }) =>
                          formatRespondents(
                            getTotalCol(
                              rows,
                              formatAccessor(`total-antal-${breakby2}`)
                            )
                          ),
                      },
                      {
                        Header: 'Medel',
                        accessor: formatAccessor(`lon_medel-${breakby2}`),
                        Cell: ({ value }) => formatNumber(value),
                        Footer: ({ rows }) =>
                          getTotalCol(
                            rows,
                            formatAccessor(`total-lon_medel-${breakby2}`)
                          ),
                      },
                    ],
                  })),
                {
                  Header: 'Samtliga',
                  accessor: 'samtliga-col',
                  columns: [
                    {
                      Header: 'Antal',
                      accessor: formatAccessor('antal-samtliga'),
                      Cell: ({ value }) => formatRespondents(value),
                      Footer: ({ rows }) =>
                        formatRespondents(
                          getTotalCol(
                            rows,
                            formatAccessor(`total-antal-samtliga`)
                          )
                        ),
                    },
                    {
                      Header: 'Medel',
                      accessor: formatAccessor('lon_medel-samtliga'),
                      Cell: ({ value }) => formatNumber(value),
                      Footer: ({ rows }) =>
                        getTotalCol(
                          rows,
                          formatAccessor(`total-lon_medel-samtliga`)
                        ),
                    },
                  ],
                },
              ],
            },
            {
              Header: 'Totalindikator',
              accessor: 'totalind',
            },
            {
              Header: 'Typ',
              accessor: '_TYPE_',
            },
          ],
    [selectedPresentation, customReport, tableData]
  );

  // Setup data
  const data = useMemo(
    () =>
      !customReport
        ? tableData
        : tableData.reduce((acc, curr) => generateCustomData(acc, curr), []),
    [customReport, tableData]
  );

  // Set up table
  const table = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['totalind', '_TYPE_'],
    },
  });

  const {
    footerGroups,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = table;

  return (
    <div className={styles.container}>
      {activeDataSource.measureVar.length > 1 && (
        <TabRow className={styles.tabRow}>
          <Tab
            active={measureVar === 'LON'}
            label="Total lön"
            onClick={() => setMeasureVar('LON')}
          />
          <Tab
            active={measureVar === 'FLON'}
            label="Fast lön"
            onClick={() => setMeasureVar('FLON')}
          />
        </TabRow>
      )}
      <div className={styles.tableContainer}>
        <div
          className={styles.tableWrapperPrint}
          style={{ marginBottom: `${rows.length}mm` }}
        >
          <table {...getTableProps()} className={styles.table} ref={tableRef}>
            <thead>
              {headerGroups.map(group => (
                <tr {...group.getHeaderGroupProps()}>
                  {group.headers.map(column => (
                    <React.Fragment key={column.id}>
                      {column.className === styles.center && (
                        <th className={styles.sticky} />
                      )}
                      <th
                        className={column.className}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .filter(row => !isTotalRow(row.values) && row.values.breakby1)
                .map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>
                          {cell.value ? cell.render('Cell') : '-'}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              {footerGroups
                // Dont print rows that are completely empty in the footer
                .filter(group => group.headers.every(header => header.Footer))
                .map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td
                        className={styles.tablefoot}
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

SalaryTable.propTypes = {
  activeDataSource: PropTypes.shape({
    forbund: PropTypes.string,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
    measureVar: PropTypes.arrayOf(PropTypes.string),
    year: PropTypes.number,
  }).isRequired,
  measureVar: PropTypes.string.isRequired,
  selectedPresentation: PropTypes.arrayOf(Object).isRequired,
  setMeasureVar: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SalaryTable;
