import React from 'react';
import PropTypes from 'prop-types';
import ButtonActiveFilter from 'atoms/buttonactivefilter/ButtonActiveFilter';
import styles from './ActiveFilters.module.css';

const FilterString = (item, index, length) => {
  if (length === 1 || index === 0) {
    return item.desc;
  }
  if (index === length - 1) {
    return ` & ${item.desc}`;
  }
  return `, ${item.desc}`;
};

const ActiveFilters = ({
  activeFilters,
  disableMenuselection,
  setActiveFilters,
  variant,
}) => {
  const removeFilterOption = (f, filterOption) => {
    const index = activeFilters.findIndex(
      filter => filter.sel_var === f.sel_var
    );

    const filterToUpdate = activeFilters[index];

    filterToUpdate.values = filterToUpdate.values.filter(
      filter => filter.value !== filterOption.value
    );

    if (filterToUpdate) {
      setActiveFilters(prevState => {
        const tempState = [...prevState];
        tempState[index] = filterToUpdate;
        return tempState.filter(element => element.values.length > 0);
      });
      return;
    }

    setActiveFilters(prevState => [
      ...prevState.filter(prev => prev.sel_var !== f.sel_var),
      filterToUpdate,
    ]);
  };

  return (
    <div id="active-filters" className={styles.activeFilters}>
      <span className={styles.activeFiltersHeading}>
        {variant === 'simple' ? 'Gjorda urval: ' : 'Valda filter: '}
      </span>
      {activeFilters.map(f => (
        <div style={{ display: 'inline-block' }} key={f.name}>
          <span className={styles.filterName}>{`${f.name}:`}</span>
          {variant === 'simple' ? (
            <span className={styles.filterButtonWrapper}>
              {f.values.map((s, index, { length }) => (
                <React.Fragment key={s.desc}>
                  {FilterString(s, index, length)}
                </React.Fragment>
              ))}
            </span>
          ) : (
            f.values.map(s => {
              return (
                <span className={styles.filterButtonWrapper} key={s.desc}>
                  <ButtonActiveFilter
                    label={s.desc}
                    className={styles.optionName}
                    disabled={disableMenuselection}
                    onClick={() => removeFilterOption(f, s)}
                  />
                </span>
              );
            })
          )}
        </div>
      ))}
    </div>
  );
};

ActiveFilters.propTypes = {
  activeFilters: PropTypes.arrayOf(Object).isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['simple', 'advanced']),
};

ActiveFilters.defaultProps = {
  variant: 'advanced',
};

export default ActiveFilters;
