import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataSourceSelect from 'components/admin/datasourceselect/DataSourceSelect';

export default function DefaultSourceConfig({
  dataSources,
  defaultDataSource,
  defaultYearFromDeepAnalysis,
  defaultYearToDeepAnalysis,
  setDefaultDataSource,
}) {
  const [year, setYear] = useState(defaultDataSource?.year || 0);
  const [dsl1, setDsl1] = useState(defaultDataSource?.DSL1 || '');
  const [dsl2, setDsl2] = useState(defaultDataSource?.DSL2 || '');

  useEffect(() => {
    if (year && dsl1 && dsl2) {
      setDefaultDataSource({ year, DSL1: dsl1, DSL2: dsl2 });
      return;
    }

    if (year && dsl1) {
      setDefaultDataSource({ year, DSL1: dsl1, DSL2: null });
    }
    // eslint-disable-next-line
  }, [year, dsl1, dsl2]);

  useEffect(() => {
    if (!defaultDataSource) return;
    const { year: YEAR, DSL1, DSL2 } = defaultDataSource;

    if (year !== YEAR) setYear(YEAR);
    if (dsl1 !== DSL1) setDsl1(DSL1);
    if (dsl2 !== DSL2 && DSL2) setDsl2(DSL2);
    // eslint-disable-next-line
  }, [
    defaultDataSource?.year,
    defaultDataSource?.DSL1,
    defaultDataSource?.DSL2,
  ]);

  return (
    <DataSourceSelect
      dataSources={
        // Administrator must first configure a standard year
        !defaultYearFromDeepAnalysis && !defaultYearToDeepAnalysis
          ? []
          : dataSources.filter(ds => {
              // Administrator might not have configured the "from year"
              if (!defaultYearFromDeepAnalysis) {
                return (
                  ds.YEAR >= defaultYearToDeepAnalysis - 1 &&
                  ds.YEAR <= defaultYearToDeepAnalysis
                );
              }

              return (
                ds.YEAR >= defaultYearFromDeepAnalysis &&
                ds.YEAR <= defaultYearToDeepAnalysis
              );
            })
      }
      year={year}
      setYear={setYear}
      dsl1={dsl1}
      setDsl1={setDsl1}
      dsl2={dsl2}
      setDsl2={setDsl2}
    />
  );
}

DefaultSourceConfig.propTypes = {
  dataSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultYearFromDeepAnalysis: PropTypes.number,
  defaultYearToDeepAnalysis: PropTypes.number,
  defaultDataSource: PropTypes.shape({
    year: PropTypes.number,
    DSL1: PropTypes.string,
    DSL2: PropTypes.string,
  }),
  setDefaultDataSource: PropTypes.func.isRequired,
};

DefaultSourceConfig.defaultProps = {
  defaultYearFromDeepAnalysis: null,
  defaultYearToDeepAnalysis: null,
  defaultDataSource: null,
};
