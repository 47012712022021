/* eslint-disable react/no-array-index-key, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked/lib/marked';
import DOMPurify from 'dompurify';
import styles from './ExplanationText.module.css';

const getMarkdownText = value => {
  const rawMarkup = marked.parse(value.replace(/\n/, '<br />'));
  return { __html: DOMPurify.sanitize(rawMarkup) };
};

const ExplanationText = ({ textItem: { value } }) => (
  <div
    className={styles.explanationText}
    dangerouslySetInnerHTML={getMarkdownText(value)}
  />
);

ExplanationText.propTypes = {
  textItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default ExplanationText;
