import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'chartjs-plugin-annotation';
import Chart from 'atoms/chart/Chart';
import { isTimeUnit } from 'lib/time';
import TabRow from 'atoms/tabrow/TabRow';
import Tab from 'atoms/tab/Tab';
import {
  barOptions,
  boxOptions,
  chartHasData,
  genData,
  genLabels,
  getAnnotations,
  lineOptions,
} from 'lib/salaryGraph';
import styles from './SalaryGraph.module.css';

const SalaryGraph = ({
  customPresentation,
  datasetLabel,
  salaryData,
  selectedPresentation,
  salaryMarkerValue,
}) => {
  const isCustomPresentation =
    customPresentation && selectedPresentation.length === 2;
  const [activeTab, setActiveTab] = useState('box');
  const [xMin, setXMin] = useState(0);
  const [xMax, setXMax] = useState(0);
  const [yMin, setYMin] = useState(0);
  const [yMax, setYMax] = useState(0);

  // Labels
  const barLabels = genLabels(salaryData, 'bar');
  const lineLabels = genLabels(salaryData, 'line');
  const boxLabels = genLabels(salaryData, 'box');

  // Chart.js data objects
  const barData = genData(salaryData, 'bar', barLabels, isCustomPresentation);
  const lineData = genData(
    salaryData,
    'line',
    lineLabels,
    isCustomPresentation
  );
  const boxData = genData(salaryData, 'box', boxLabels, isCustomPresentation);

  const labelString = datasetLabel[0].REPORT_TEXT || '';

  console.log('salaryData:');
  console.log(salaryData);

  console.log('lineData:');
  console.log(lineData);

  console.log('lineLabels');
  console.log(lineLabels);

  // Returns a boolean, is there any chart data at all?
  const hasData = [
    chartHasData(barData, isCustomPresentation),
    chartHasData(lineData, isCustomPresentation),
    chartHasData(boxData, isCustomPresentation),
  ].some(bool => bool);

  // Stores max and min values for X and Y Axis from the current graph
  const setValues = (x, y) => {
    setXMin(typeof x.min === 'number' ? x.min : 0);
    setXMax(typeof x.max === 'number' ? x.max : 0);
    setYMin(typeof y.min === 'number' ? y.min : 0);
    setYMax(typeof y.max === 'number' ? y.max : 0);
  };

  /*
   * Not every presentation is suitable for line charts
   * This code checks if the middle value in salaryData looks like time units
   * Additionally it checks for substring "år" in the labelString variable
   * TODO: Consider this implementation
   */
  const showLineDiagram =
    isTimeUnit(salaryData[Math.floor(salaryData.length / 2)]?.breakby1) &&
    labelString.toLowerCase().search('år') > -1;

  // Configure Chart.js options object
  const addChartOptions = (obj, type) => {
    const otherLabel = 'kronor per månad';

    const newConfig = {
      ...obj,
      annotation: getAnnotations(salaryMarkerValue, activeTab),
    };

    if (type === 'box') {
      newConfig.scales.xAxes[0].scaleLabel.labelString = otherLabel;
      newConfig.scales.yAxes[0].scaleLabel.labelString = labelString;
      return newConfig;
    }

    newConfig.scales.xAxes[0].scaleLabel.labelString = labelString;
    newConfig.scales.yAxes[0].scaleLabel.labelString = otherLabel;

    return newConfig;
  };

  // Set a default chart type initially
  useEffect(() => {
    if (chartHasData(barData, isCustomPresentation)) {
      setActiveTab('bar');
      return;
    }

    if (chartHasData(boxData, isCustomPresentation)) {
      setActiveTab('box');
      return;
    }

    if (chartHasData(lineData, isCustomPresentation, 1) && showLineDiagram) {
      setActiveTab('line');
    }

    // eslint-disable-next-line
  }, [isCustomPresentation]);

  return (
    <>
      {hasData && (
        <div id="diagram" className={styles.salaryGraph}>
          <TabRow className={styles.tabRow}>
            {chartHasData(barData, isCustomPresentation) && (
              <Tab
                active={activeTab === 'bar'}
                label="Stapeldiagram"
                onClick={() => setActiveTab('bar')}
              />
            )}
            {chartHasData(boxData, isCustomPresentation) && (
              <Tab
                active={activeTab === 'box'}
                label="Lådagram"
                onClick={() => setActiveTab('box')}
              />
            )}
            {chartHasData(lineData, isCustomPresentation, 1) &&
              showLineDiagram &&
              !isCustomPresentation && (
                <Tab
                  active={activeTab === 'line'}
                  label="Linjediagram"
                  onClick={() => setActiveTab('line')}
                />
              )}
          </TabRow>

          {activeTab === 'box' && (
            <Chart
              data={boxData}
              id="salary-graph-boxplot"
              options={addChartOptions(
                isCustomPresentation
                  ? boxOptions
                  : { ...boxOptions, legend: { display: false } },
                'box'
              )}
              setValues={setValues}
              type="horizontalBoxplot"
            />
          )}
          {activeTab === 'bar' && (
            <Chart
              data={barData}
              id="salary-graph-bar"
              options={addChartOptions(
                isCustomPresentation
                  ? barOptions
                  : { ...barOptions, events: ['hover'], onHover: null },
                'bar'
              )}
              setValues={setValues}
              type="bar"
            />
          )}
          {activeTab === 'line' && (
            <Chart
              data={lineData}
              id="salary-graph-line"
              options={addChartOptions(lineOptions, 'line')}
              setValues={setValues}
              type="line"
            />
          )}
        </div>
      )}
    </>
  );
};

SalaryGraph.propTypes = {
  customPresentation: PropTypes.bool.isRequired,
  datasetLabel: PropTypes.arrayOf(PropTypes.object),
  salaryData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPresentation: PropTypes.arrayOf(PropTypes.shape({})),
  salaryMarkerValue: PropTypes.number,
};

SalaryGraph.defaultProps = {
  datasetLabel: [],
  selectedPresentation: [],
  salaryMarkerValue: null,
};

export default SalaryGraph;
