import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LoginInfo.module.css';

const LoginInfo = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Kommer du inte in?</h1>
      <p className={styles.text}>
        Är du på din arbetsplats och kommer inte in i Saco lönesök? Testa gärna hemifrån (med eventuell VPN avstängd) eller från din mobil.
      </p>

      <p className={styles.text}> Hjälpte det inte? Hör av dig till ditt förbund för hjälp med statistiken eller direkt till Saco för tekniska frågor.</p>

      <p className={styles.text}>  Obs! Du som är medlem eller förtroendevald i ett Sacoförbund ska aldrig behöva logga in två gånger utan komma direkt in i lönesök från ditt förbunds webbplats.</p>

    </div>


  );
};

export default LoginInfo;
