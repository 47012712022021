import Loader from 'atoms/loader/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SelectPreview.module.css';


function SelectPreview({
  defaultValue,
  disabled,
  loading,
  variant,
  label,
  id,
  onChange,
  options,
  value,
  compact,
  marginBottom,
  shaded,
}) {
  const filteredOptions = value
    ? options.filter(opt => opt.value !== null)
    : options;

  return (
    <div
      className={`${styles.selectBox} ${disabled && styles.disabled} ${marginBottom ? styles.hasMarginBottom : ''
        } ${compact ? styles.isCompact : ''}`}
    >
      {loading && (
        <div className={styles.loaderWrapper}>
          <Loader variant={variant} small className={styles.selectLoader} />
        </div>
      )}
      {label && (
        <label
          className={`${styles.selectLabel} ${disabled ? styles.disabled : ''
            } ${shaded ? styles.isShaded : ''}`}
          htmlFor={id}
        >
          {`${label}:`}
        </label>
      )}
      <div className={styles.selectWrapper}>
        <select
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={id}
          onChange={onChange}
          value={value}
        >
          {filteredOptions.map(
            ({ desc, key, value: optionValue, disabled: optionDisabled }) => (
              <option key={key} value={optionValue} disabled={optionDisabled}>
                {desc}
              </option>
            )
          )}
        </select>
      </div>
    </div>
  );
}

SelectPreview.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  compact: PropTypes.bool,
  marginBottom: PropTypes.bool,
  shaded: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(Object).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectPreview.defaultProps = {
  defaultValue: undefined,
  disabled: false,
  loading: false,
  compact: false,
  marginBottom: false,
  shaded: false,
  label: '',
  variant: 'primary',
  onChange: () => { },
  value: undefined,
};

export default SelectPreview;
