import React from 'react';
import PropTypes from 'prop-types';
import styles from './UnemploymentspinTab.module.css';

const UnemploymentspinTab = ({ active, label, altText, onClick }) => {
  const computedAltText = altText && altText !== '' ? altText : label;
  return (
    <button
      className={`${styles.UnemploymentspinTab} ${active ? styles.UnemploymentspinTabActive : styles.UnemploymentspinTabPassive}`}
      onClick={onClick}
      alt={computedAltText}
      type="button"
    >
      {label}
    </button>
  );
};

UnemploymentspinTab.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  altText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

UnemploymentspinTab.defaultProps = {
  altText: '',
};

export default UnemploymentspinTab;
