import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Child } from 'pym.js';

import {
  formatIncomeValue,
  setIncomeDistribution,
  calculateHouseholdIncome,
  getIncomeMarkerIndex,
  getMaxSumIndex,
  lineOptions,
  addChartOptions,
  positionMaximizeIncomeElements,
  sleep,
  calculateTotalDisposableIncomes,
} from 'lib/parentalLeaveSpin';

import Input from 'atoms/input/Input';

import { Line } from 'react-chartjs-2';
import Loader from 'atoms/loader/Loader';
import { IconChevronDown, IconChevronUp } from 'tabler-icons';
import SelectParentalLeaveSpin from './selectParentalLeaveSpin/SelectParentalLeaveSpin';
import ListIcon from '../../images/icons/svg/list.svg';
import BarchartIcon from '../../images/icons/svg/barchart.svg';
import PersonIcon from '../../images/icons/svg/person.svg';
import Crowns from '../../images/icons/svg/crowns.svg';
import MarkerIcon from '../../images/icons/svg/marker.svg';
import CircleIcon from '../../images/icons/svg/circle.svg';
import Slider from './Slider/Slider';
import styles from './ParentalLeavespin.module.css';

const ParentalLeavespin = () => {
  const [open] = useState(false);
  const [parent1Salary, setParent1Salary] = useState('32000');
  const [parent2Salary, setParent2Salary] = useState('42000');
  const [displayParent1Salary, setDisplayParent1Salary] = useState('32 000');
  const [displayParent2Salary, setDisplayParent2Salary] = useState('42 000');

  const minSalary = 0;
  const maxSalary = 150000;

  const displayMinSalary = '0';
  const displayMaxSalary = '150 000';

  const [isIconUp, setIsIconUp] = useState(false);
  const [isHoveringChatBubble, setIsHoveringChatBubble] = useState(false);

  const [parent1Months, setParent1Months] = useState('6');
  const [parent2Months, setParent2Months] = useState('6');
  const [parent1MaxMonths, setParent1MaxMonths] = useState('6');
  const [parent2MaxMonths, setParent2MaxMonths] = useState('6');
  const [parent2And10Months, setParent2And10Months] =  useState('');
  const [parent4And8Months, setParent4And8Months] =  useState('');
  const [parent6And6Months, setParent6And6Months] =  useState('');
  const [parent8And4Months, setParent8And4Months] =  useState('');
  const [parent10And2Months, setParent10And2Months] =  useState('');
  

  const [errorMessage, setErrorMessage] = useState('');


  const [selectedIncomeMarkerIndex, setSelectedIncomeMarkerIndex] = useState(5);

  const minMonths = 2;
  const maxMonths = 10;
  const stepMonths = 1;

  const [firstSelection, setFirstSelection] = useState({
    list: [
      { key: 'month_blank', value: '', desc: '' }, // Tom sträng för att representera "blankt"
      { key: 'month_10', value: '10', desc: '10 månader' },
      { key: 'month_6', value: '6', desc: '6 månader' },
      { key: 'month_3', value: '3', desc: '3 månader' },
      { key: 'month_0', value: '0', desc: '0 månader' },
    ],
  });

  const [secondSelection, setSecondSelection] = useState({
    list: [
      { key: 'month_blank', value: '', desc: '' }, // Tom sträng för att representera "blankt"
      { key: 'month_10', value: '10', desc: '10 månader' },
      { key: 'month_6', value: '6', desc: '6 månader' },
      { key: 'month_3', value: '3', desc: '3 månader' },
      { key: 'month_0', value: '0', desc: '0 månader' },
    ],
  });

  const [
    parent1ParentalSalaryMonths,
    setParent1ParentalSalaryMonths,
  ] = useState(firstSelection.list[1].value);
  const [
    parent2ParentalSalaryMonths,
    setParent2ParentalSalaryMonths,
  ] = useState(secondSelection.list[1].value);

  const [isLoading, setIsLoading] = useState(false);

  const [helpChatBubbleVisible, setHelpChatBubbleVisible] = useState(false);
  const [
    calculationInfoModalVisible,
    setcalculationInfoModalVisible,
  ] = useState(false);

  const [
    calculationInfoChatBubbleVisible,
    setCalculationInfoChatBubbleVisible,
  ] = useState(false);

  const [lineData, setLineData] = useState([]);
  const [householdIncomeData, setHouseholdIncomeData] = useState([]);
  const [
    selectedHouseholdIncomeData,
    setSelectedHouseholdIncomeData,
  ] = useState({});

  const [maxHouseholdIncomeData, setMaxHouseholdIncomeData] = useState({});

  const [hasData, setHasData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const chartRef = useRef();

  const mobile = window.innerWidth <= 600;
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  // This page is included as an iframe
  useEffect(() => {
    const pymChild = new Child({ polling: 1000, sendHeight: true });

    // Skicka antingen 0 eller annan höjd beroende på mobilläge
    if (mobile) {
      pymChild.sendHeight(0);
    } else {
      pymChild.sendHeight();
    }

    const intervalId = setInterval(() => {
      // Uppdatera höjden varje sekund, beroende på mobilläge
      if (mobile) {
        pymChild.sendHeight(0);
      } else {
        pymChild.sendHeight();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!isIconUp) return;

    calculateHouseholdIncome(
      parent1Salary,
      parent2Salary,
      parent1ParentalSalaryMonths,
      parent2ParentalSalaryMonths,
      setLineData,
      setHouseholdIncomeData,
      setHasData
    );
  }, [isIconUp]); // Endast kör vid montering

  useEffect(() => {
    //console.log('parent1Salary useEffect', parent1Salary);

    calculateHouseholdIncome(
      parent1Salary,
      parent2Salary,
      parent1ParentalSalaryMonths,
      parent2ParentalSalaryMonths,
      setLineData,
      setHouseholdIncomeData,
      setHasData
    );
  }, [parent1Salary]);

  useEffect(() => {
    //console.log('parent2Salary useEffect', parent2Salary);

    calculateHouseholdIncome(
      parent1Salary,
      parent2Salary,
      parent1ParentalSalaryMonths,
      parent2ParentalSalaryMonths,
      setLineData,
      setHouseholdIncomeData,
      setHasData
    );
  }, [parent2Salary]);

  useEffect(() => {
    // console.log(
    //   'parent1ParentalSalaryMonths useEffect',
    //   parent1ParentalSalaryMonths
    // );

    calculateHouseholdIncome(
      parent1Salary,
      parent2Salary,
      parent1ParentalSalaryMonths,
      parent2ParentalSalaryMonths,
      setLineData,
      setHouseholdIncomeData,
      setHasData
    );
  }, [parent1ParentalSalaryMonths]);

  useEffect(() => {
    //console.log('parent2ParentalSalaryMonths', parent2ParentalSalaryMonths);

    calculateHouseholdIncome(
      parent1Salary,
      parent2Salary,
      parent1ParentalSalaryMonths,
      parent2ParentalSalaryMonths,
      setLineData,
      setHouseholdIncomeData,
      setHasData
    );
  }, [parent2ParentalSalaryMonths]);

  useEffect(() => {
    console.log('parent2Months useEffect', parent2Months);

    positionMaximizeIncomeElements(parent2Months, lineData, chartRef);

    const newValueForParent1Months = 12 - parseInt(parent2Months, 10);
    setParent1Months(newValueForParent1Months);
  }, [parent2Months]);

  useEffect(() => {
    if (householdIncomeData && householdIncomeData.length > 0) {
      // Formatera siffrorna utan decimaler och med mellanrum som tusentalsavgränsare
      const formatNumber = num => num.toLocaleString('sv-SE', { maximumFractionDigits: 0 });
  
      setParent2And10Months(formatNumber(householdIncomeData[8].totalDisposableIncome));
      setParent4And8Months(formatNumber(householdIncomeData[6].totalDisposableIncome));
      setParent6And6Months(formatNumber(householdIncomeData[4].totalDisposableIncome));
      setParent10And2Months(formatNumber(householdIncomeData[0].totalDisposableIncome));
      setParent8And4Months(formatNumber(householdIncomeData[2].totalDisposableIncome));
    } else {
      console.log('householdIncomeData är tom eller undefined.');
    }
  }, [householdIncomeData]);
  

  useEffect(() => {
    //console.log('parent1Months useEffect', parent1Months);

    const incomeMarkerIndex = getIncomeMarkerIndex(lineData, parent2Months);
    //console.log('incomeMarkerIndex', incomeMarkerIndex);

    if (incomeMarkerIndex === -1) return;
    setSelectedIncomeMarkerIndex(incomeMarkerIndex);
    setSelectedHouseholdIncomeData(householdIncomeData[incomeMarkerIndex]);

    const maxSumIndex = getMaxSumIndex(lineData);
    //console.log('maxSumIndex', maxSumIndex);

    if (maxSumIndex === -1) return;

    setMaxHouseholdIncomeData(householdIncomeData[maxSumIndex]);
  }, [parent1Months]);

  useEffect(() => {
    //console.log('lineData useEffect', lineData);

    if (!isIconUp) return;

    let waitCount = 0;
    let running = true;

    (async () => {
      while (running) {
        //console.log('Kör kontroller...');

        if (chartRef.current?.chartInstance) {
          //console.log('Kör kontroller 1');
          const { chartInstance } = chartRef.current;

          // console.log('parent2Months', parent2Months);
          // console.log('lineData', lineData);

          const incomeMarkerIndex = getIncomeMarkerIndex(
            lineData,
            parent2Months
          );

          //console.log('incomeMarkerIndex', incomeMarkerIndex);

          if (incomeMarkerIndex === -1) return;

          setSelectedHouseholdIncomeData(
            householdIncomeData[incomeMarkerIndex]
          );

          const maxSumIndex = getMaxSumIndex(lineData);
          //console.log('maxSumIndex', maxSumIndex);

          if (maxSumIndex === -1) return;

          setMaxHouseholdIncomeData(householdIncomeData[maxSumIndex]);

          //console.log('Kör kontroller 2');

          const meta = chartInstance.getDatasetMeta(0);
          //console.log('meta', meta);

          const userSpecifiedSalaryModel = meta.data[incomeMarkerIndex]._model;

          // console.log('rosaprickx: ', userSpecifiedSalaryModel.x);
          // console.log('rosapricky: ', userSpecifiedSalaryModel.y);
          if (userSpecifiedSalaryModel.y > 0) {
            // console.log('Kör kontroller 3');
            // console.log('Parent2Months: ', parent2Months);
            positionMaximizeIncomeElements(parent2Months, lineData, chartRef);
            // Anropa setIncomeDistribution-metoden för att beräkna fördelningen
            const {
              parent1MaxMonths: newParent1MaxMonths,
              parent2MaxMonths: newParent2MaxMonths,
            } = setIncomeDistribution(lineData);

            // Uppdatera state-värdena för parent1MaxMonths och parent2MaxMonths med de nya värdena
            setParent1MaxMonths(newParent1MaxMonths.toString());
            setParent2MaxMonths(newParent2MaxMonths.toString());

            running = false;
          }
          if (waitCount > 50) {
            running = false;
          }
        }
        // console.log('Väntar i 1 sekund...');
        await sleep(100); // Vänta i 1 sekund
        waitCount += 1;
        // console.log('Väntat i en sekund!');
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      // console.log('Klart!');
      running = false;
    };
  }, [lineData]);

  useEffect(() => {
    // console.log('householdIncomeData: useEffect', householdIncomeData);
  }, [householdIncomeData]);

  useEffect(() => {
    // console.log(
    //   'selectedHouseholdIncomeData useEffect',
    //   selectedHouseholdIncomeData
    // );
  }, [selectedHouseholdIncomeData]);

  useEffect(() => {
    // console.log(
    //   'selectedIncomeMarkerIndex: useEffect',
    //   selectedIncomeMarkerIndex
    // );
  }, [selectedIncomeMarkerIndex]);

  useEffect(() => {
    //console.log('helpChatBubbleVisible useEffect', helpChatBubbleVisible);
  }, [helpChatBubbleVisible]);

  useEffect(() => {
    // console.log(
    //   'calculationInfoModalVisible useEffect',
    //   calculationInfoModalVisible
    // );
  }, [calculationInfoModalVisible]);

  const showOptimalDistribution = async () => {
    try {
      setIsIconUp(prevState => !prevState);
    } catch (error) {
      console.error('Show optimal distribution:', error);
    }
  };

  const showHelpChatBubble = async () => {
    try {
      //console.log('Show help chat bubble');
      setHelpChatBubbleVisible(true);
    } catch (error) {
      console.error('Show chat bubble:', error);
    }
  };

  const hideHelpChatBubble = async () => {
    try {
      //console.log('Hide help chat bubble');
      setHelpChatBubbleVisible(false);
    } catch (error) {
      console.error('Hide chat bubble:', error);
    }
  };

  const getHelpChatBubbletext = () => {
    return (
      <div>
      <span className={`${styles['text-16-black']}`}>
      Om du arbetar hos en arbetsgivare som har kollektivavtal
      har du rätt till föräldralön. Föräldralönen är en extra
      lön från arbetsgivaren utöver den ersättning som
      försäkringskassan betalar i form av föräldrapenning.{' '}
      <br />
      <br />
      Hur mycket föräldralön du kan få beror bland annat på
      vilket kollektivavtal du omfattas av och hur länge du
      har varit anställd.
      </span>
      <br />
      <br />
        <span
          className={`${styles['text-16-teal-light-underline']}`}
        >
          <a
            href="https://www.saco.se/yrkesliv/foralder/foraldralon-sa-fungerar-det/"
            target="_blank" // Öppna länken i en ny flik
            rel="noopener noreferrer" // Lägg till rel-attributet för säkerhet
            className={`${styles['text-16-teal-light-underline']}`}
          >
            Läs mer om föräldralön här.
          </a>
        </span>
      <br />
      <br />
    </div>
    )
  }

  const showCalculationInfoModal = async () => {
    try {
      setcalculationInfoModalVisible(true);
    } catch (error) {
      console.error('Show calculation info modal:', error);
    }
  };

  const hideCalculationInfoModal = async () => {
    try {
      //console.log('Hide calculation info modal');

      setcalculationInfoModalVisible(false);
    } catch (error) {
      console.error('Hide calculation info modal:', error);
    }
  };

  const handleSalaryChange = (e, setDisplaySalary, setParentSalary) => {
    const strNumber = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    setDisplaySalary(strNumber);
  };

  const handleSalaryBlur = (
    e,
    setDisplaySalary,
    setParentSalary,
    minSalary,
    maxSalary,
    displayMinSalary,
    displayMaxSalary
  ) => {
    let strNumber = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const parsedValue = parseInt(e.target.value.replace(/[^0-9]/g, '').trim());

    if (parsedValue < minSalary) {
      strNumber = displayMinSalary;
    }

    if (parsedValue > maxSalary) {
      strNumber = displayMaxSalary;
    }

    setDisplaySalary(strNumber);
    setParentSalary(Number(strNumber.replace(/ /g, '')));
  };

  // Användning:
  const handleParent1SalaryChange = e => {
    handleSalaryChange(e, setDisplayParent1Salary, setParent1Salary);
  };

  const handleParent1SalaryBlur = e => {
    handleSalaryBlur(
      e,
      setDisplayParent1Salary,
      setParent1Salary,
      minSalary,
      maxSalary,
      displayMinSalary,
      displayMaxSalary
    );
  };

  const handleParent2SalaryChange = e => {
    handleSalaryChange(e, setDisplayParent2Salary, setParent2Salary);
  };

  const handleParent2SalaryBlur = e => {
    handleSalaryBlur(
      e,
      setDisplayParent2Salary,
      setParent2Salary,
      minSalary,
      maxSalary,
      displayMinSalary,
      displayMaxSalary
    );
  };

  const handleFirstSelectionChange = (event, setParentalSalaryMonths) => {
    const selectedValue = event.target.value;

    // Filtrera bort den tomma strängen från listan efter att ett val har gjorts
    const filteredList = firstSelection.list.filter(
      option => option.value !== ''
    );

    // Uppdatera dropdown-listan med den filtrerade listan
    setFirstSelection({ list: filteredList });

    // Uppdatera state med det valda värdet
    setParent1ParentalSalaryMonths(selectedValue);
  };

  const handleSecondSelectionChange = (event, setParentalSalaryMonths) => {
    const selectedValue = event.target.value;

    // Filtrera bort den tomma strängen från listan efter att ett val har gjorts
    const filteredList = secondSelection.list.filter(
      option => option.value !== ''
    );

    // Uppdatera dropdown-listan med den filtrerade listan
    setSecondSelection({ list: filteredList });

    // Uppdatera state med det valda värdet
    setParent2ParentalSalaryMonths(selectedValue);
  };

  const showCalculationInfoContent = () =>{
    return(                
    <div className={styles['grid-parentalleave-r3-r9']}>
    <div
      id="myModal"
      className={`${styles.calculationInfoModal} ${
        calculationInfoModalVisible
          ? styles.calculationInfoModalVisible
          : styles.calculationInfoModalHidden
      }`}
    >
      <div className={styles['modal-content']}>
        <div className={styles['grid-calculationinfomodal']}>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-20-black-light']} ${styles['p-10-0-10-0']}`}
              >
                Så här räknar vi
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                onClick={hideCalculationInfoModal}
                className={styles.close}
              >
                &times;
              </span>
            </div>
          </div>
          <div
            className={
              styles['grid-calculationinfomodal-row-header']
            }
          >
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Förälder 1 (hemma{' '}
                {selectedHouseholdIncomeData &&
                  selectedHouseholdIncomeData.parent1LeaveMonths &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1LeaveMonths,
                    'månader'
                  )}
                )
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              {/* Om du vill lägga till annan innehåll här, lägg till det här */}
            </div>
          </div>

          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Arbetsinkomst (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1YearlyIncome
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Föräldrapenning (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1ParentalAllowance
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Föräldralön (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1ParentalSalary
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Barnbidrag
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1ChildAllowance
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Disponibel inkomst*
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1DisposableIncome
                  )}
              </span>
            </div>
          </div>
          <br />
          <div
            className={
              styles['grid-calculationinfomodal-row-header']
            }
          >
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Förälder 2 (hemma{' '}
                {selectedHouseholdIncomeData &&
                  selectedHouseholdIncomeData.parent2LeaveMonths &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2LeaveMonths,
                    'månader'
                  )}
                )
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              {/* Om du vill lägga till annan innehåll här, lägg till det här */}
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Arbetsinkomst (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2YearlyIncome
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Föräldrapenning (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2ParentalAllowance
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Föräldralön (före skatt)
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2ParentalSalary
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Barnbidrag
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1ChildAllowance
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Disponibel inkomst*
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2DisposableIncome
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Hushållet sammanräknat
              </span>
            </div>
            <div className={styles['flex-e-c']}>{}</div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Inkomster för förälder 1
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1DisposableIncome
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                Inkomster för förälder 2
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent2DisposableIncome
                  )}
              </span>
            </div>
          </div>
          <div className={styles['grid-calculationinfomodal-row']}>
            <div className={styles['flex-s-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                Totalt
              </span>
            </div>
            <div className={styles['flex-e-c']}>
              <span
                className={`${styles['text-16-black-light']} ${styles['p-10-0-5-0']}`}
              >
                {selectedHouseholdIncomeData &&
                  formatIncomeValue(
                    selectedHouseholdIncomeData.parent1DisposableIncome +
                      selectedHouseholdIncomeData.parent2DisposableIncome
                  )}
              </span>
            </div>
          </div>
          <br />
          <div
            className={
              styles['grid-calculationinfomodal-row-description']
            }
          >
            <span
              className={`${styles['text-16-black']} ${styles['p-30-0-10-0']}`}
            >
              {'*) Efter public service- och begravningsavgift.'}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>)
  }

  const showInfoModal = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
    console.log("Anna");
  };


  const closeModal = () => {
    setIsModalVisible(false);
  };

  const mobileDesign = () => {
    return (
        <div>
              <div className={styles['grid-parentalleave-r1']}>
                <img src={BarchartIcon} alt="barchart" className={styles.mobileicon} />
                <p className={`${styles['text-24-white']}`}>
                  Saco fakta: Hur ni bäst delar på föräldraledigheten
                </p>
              </div>
              <div className={styles.mobileMain}>
              <div className={styles.mobileContent}>
              <div>
              <p>I det här räkneverktyget kan du testa själv att 
              lägga in olika löner för att se vilken fördelning 
              som är mest ekonomisk. Lönar det sig för dig och 
              din partner att dela lika på föräldraledigheten?</p>
              </div>
              <div className={`${styles['margin-bottom-20']}`}>
                  <span className={`${styles['text-24-teal-light']}`}>
                  <span>1.</span> <span style={{ marginLeft: '7px' }}>Vad har ni för månadslön före skatt?</span>
                  </span>
              </div>
              <div className={styles.inputField}>
                  <img src={PersonIcon} alt="person" className={styles.icon} />
                  <span className={`${styles['text-20-teal-light']}`}>
                    Förälder 1
                  </span>
              </div>
              <div>
                <Input
                  className={`${styles['input-parent-salary']} ${styles['text-20-teal']}`}
                  id="parent1Salary"
                  onChange={handleParent1SalaryChange}
                  onBlur={handleParent1SalaryBlur}
                  type="string"
                  value={displayParent1Salary}
                />
                {errorMessage && (
                  <div className={styles.errorMessage}>{errorMessage}</div>
                )}
              </div>
              <div className={styles.inputField}>
                <img src={PersonIcon} alt="person" className={styles.icon} />
                <span className={`${styles['text-20-teal-light']}`}>
                  Förälder 2
                </span>
              </div>
              <div>
                <Input
                  className={`${styles['input-parent-salary']} ${styles['text-20-teal']}`}
                  id="parent2Salary"
                  onChange={handleParent2SalaryChange}
                  onBlur={handleParent2SalaryBlur}
                  type="string"
                  value={displayParent2Salary}
                />
              </div>
              <div className={`${styles.center}`}>               
              <svg
                  className={`${styles.iconContinue}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  >
                  <path
                    d="M7 10.5L14 17.5L21 10.5"
                    stroke="#058fa2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  </svg>
              </div>
              <div className={`${styles['margin-bottom-20']}`}>
                <div className={`${styles['text-24-teal-light']} ${styles['mr-12']}`}>
                      <span>2.</span> <span style={{ marginLeft: '7px' }}>I hur många månader får ni föräldralön?</span>
                </div>
            
                <div>
                  <a href="#" onClick={showInfoModal}>
                    <img
                      src={CircleIcon}
                      alt="circle"
                      className={`${styles.helpIcon} ${styles.hoverCursor}`}
                    />
                  </a>
                </div>
                {isModalVisible && (
                <div className={styles.modalOverlay}>
                <div className={styles.modalContent}>
                <span className={styles.closeButton} onClick={closeModal}>
                &times;
                </span>
                <div>
                {getHelpChatBubbletext()}
                </div>
                </div>
                </div>
                )}
            
              </div>
              <div className={styles.inputField}>
                <img src={PersonIcon} alt="person" className={styles.icon} />
                <span className={`${styles['text-20-teal-light']}`}>
                  Förälder 1
                </span>
              </div>
              <div className={styles['text-20-teal']}>
                <SelectParentalLeaveSpin
                  key="firstSelectionSelect"
                  labelId="firstSelectionLabel"
                  id="firstSelectionSelect"
                  onChange={event =>
                    handleFirstSelectionChange(
                      event,
                      setParent1ParentalSalaryMonths
                    )
                  }
                  options={firstSelection.list.map(opt => ({
                    key: opt.key,
                    value: opt.value,
                    desc: opt.desc,
                  }))}
                  value={parent1ParentalSalaryMonths}
                />
              </div>
              <div className={styles.inputField}>
                  <img src={PersonIcon} alt="person" className={styles.icon} />
                  <span className={`${styles['text-20-teal-light']} ${styles['mt-16']}`}>
                    Förälder 2
                  </span>
              </div>
              <div className={styles.selectBoxLeft}>
                      <SelectParentalLeaveSpin
                        key="secondSelectionSelect"
                        labelId="secondSelectionLabel"
                        id="secondSelectionSelect"
                        onChange={event =>
                          handleSecondSelectionChange(
                            event,
                            setParent2ParentalSalaryMonths
                          )
                        }
                        options={secondSelection.list.map(opt => ({
                          key: opt.key,
                          value: opt.value,
                          desc: opt.desc,
                        }))}
                        value={parent2ParentalSalaryMonths}
                      />
              </div>
              </div>
                <div className={styles.mobileResult}>
                  <div className={styles['grid-parentalleave-r2-r16']}>
                  <button
                  type="button"
                  className={styles.turquoisebutton}
                  onClick={showOptimalDistribution}
                  >

                  <div className={styles['align-icon-to-text']}>
                  <span
                  className={`${styles['text-18-white']} ${styles['mr-12']}`}
                  >
                  Se er optimala fördelning
                  </span>
                  <svg
                  className={`${styles.icon} ${styles.iconPil} ${isIconUp ? styles.up : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  >
                  <path
                    d="M7 10.5L14 17.5L21 10.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  </svg>
                  </div>
                  </button>
                  </div>
                  <div className={isIconUp ? styles.mobileResultContent : styles.hidden}>
                  <span
                  className={`${styles['text-28-pink']} ${styles['mr-12']}`}
                  >
                  Ni maximerar er inkomst <br /> med fördelningen<br /> {parent2MaxMonths} +{' '}
                  {parent1MaxMonths} månader!
                  </span>
                  <div className={styles.mobiletable}>
                    <table className={styles.incomeTable}>
                    <thead>
                    <tr>
                    <th><img src={PersonIcon} alt="person" className={styles.icon} /> <br /><span className={`${styles['text-20-teal-light']}`}>Förälder 1</span></th>
                    <th><img src={PersonIcon} alt="person" className={styles.icon} /> <br /><span className={`${styles['text-20-teal-light']}`}>Förälder 2</span></th>
                    <th><img src={Crowns} alt="person" className={styles.icon} /> <br /><span className={`${styles['text-20-teal-light']}`}>Inkomst</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>2 månader</td>
                    <td>10 månader</td>
                    <td>{parent2And10Months} kr</td>                   
                    </tr>
                    <tr>
                    <td>4 månader</td>
                    <td>8 månader</td>
                    <td>{parent4And8Months} kr</td>                  
                    </tr>
                    <tr className={`${styles['highlight-row']}`}>
                    <td>6 månader</td>
                    <td>6 månader</td>
                    <td>{parent6And6Months} kr</td>
                    </tr>
                    <tr>
                    <td>8 månader</td>
                    <td>4 månader</td>
                    <td>{parent8And4Months}  kr</td>
                    </tr>
                    <tr>
                    <td>10 månader</td>
                    <td>2 månader</td>
                    <td>{parent10And2Months} kr</td>
                    </tr>
                    
                    {/* Lägg till fler rader här om det behövs */}
                    </tbody>
                    </table>
                  </div>
                  {showCalculationInfoContent()}
                  <span className={styles.linkContainer}>
                    <a
                      className={styles.link}
                      href="#"
                      onClick={showCalculationInfoModal}
                    >
                      <img src={ListIcon} alt="list" className={styles.icon} />
                      <p className={styles.linkText}>Så har vi räknat</p>
                    </a>
                  </span>
                  </div>
                  
                </div>
              </div>
        </div>
      )
  }
    
  const desktopDesign = () => {
    return (
      <div className={styles.parentHide}>
        {isLoading ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            <div className={styles['grid-parentalleave']}>
              <div className={styles['grid-parentalleave-r1']}>
                <img src={BarchartIcon} alt="barchart" className={styles.icon} />
                <p className={`${styles['text-24-white']}`}>
                  Saco fakta: Hur ni bäst delar på föräldraledigheten
                </p>
              </div>
  
              <div
                className={`${styles['grid-parentalleave-r2']} ${
                  isIconUp ? styles['no-radius'] : ''
                }`}
              >
                <div className={styles['grid-parentalleave-r2-r1']} />
  
                <div className={styles['grid-parentalleave-r2-r2']}>
                  {/* <p className={`${styles['text-16-teal']}`}>
                    I det här räkneverktyget kan du testa själv att lägga in olika
                    löner för att se vilken fördelning som är mest ekonomisk.
                    Lönar det sig för dig och din partner att dela lika på
                    föräldraledigheten?
                  </p> */}
                </div>
                <div className={styles['grid-parentalleave-r2-r3']} />
                <div className={styles['grid-parentalleave-r2-r4']}>
                  <span className={`${styles['text-24-teal-light']}`}>
                    1. Vad har ni för månadslön före skatt?
                  </span>
                </div>
                <div className={styles['grid-parentalleave-r2-r5']} />
                <div className={styles['grid-parentalleave-r2-r6']}>
                  <div className={styles.inputField}>
                    <img src={PersonIcon} alt="person" className={styles.icon} />
                    <span className={`${styles['text-20-teal-light']}`}>
                      Förälder 1
                    </span>
                  </div>
                  <div className={styles.column} />
                  <div className={styles.inputField}>
                    <img src={PersonIcon} alt="person" className={styles.icon} />
                    <span className={`${styles['text-20-teal-light']}`}>
                      Förälder 2
                    </span>
                  </div>
                  <div className={styles.column} />
                </div>
                <div className={styles['grid-parentalleave-r2-r7']} />
                <div className={styles['grid-parentalleave-r2-r8']}>
                  <div className={styles.column}>
                    <Input
                      className={`${styles['input-parent-salary']} ${styles['text-20-teal']}`}
                      id="parent1Salary"
                      onChange={handleParent1SalaryChange}
                      onBlur={handleParent1SalaryBlur}
                      type="string"
                      value={displayParent1Salary}
                    />
                    {errorMessage && (
                      <div className={styles.errorMessage}>{errorMessage}</div>
                    )}
                  </div>
                  <div className={styles.column} />
                  <div className={styles.column}>
                    <Input
                      className={`${styles['input-parent-salary']} ${styles['text-20-teal']}`}
                      id="parent2Salary"
                      onChange={handleParent2SalaryChange}
                      onBlur={handleParent2SalaryBlur}
                      type="string"
                      value={displayParent2Salary}
                    />
                  </div>
                  <div className={styles.column} />
                </div>
                <div className={styles['grid-parentalleave-r2-r9']} />
                <div className={styles['grid-parentalleave-r2-r10']}>
                  <div className={styles.headerField}>
                    <span
                      className={`${styles['text-24-teal-light']} ${styles['mr-12']}`}
                    >
                      2. I hur många månader får ni föräldralön?
                    </span>
                    <span>
                      <a
                        href="#"
                        onMouseEnter={showHelpChatBubble}
                        onMouseLeave={() => setTimeout(hideHelpChatBubble, 500)} // Lägg till en timeout för att ge lite tid att nå bubblan
                      >
                        <img
                          src={CircleIcon}
                          alt="circle"
                          className={`${styles.helpIcon} ${styles.hoverCursor}`}
                        />
                      </a>
                    </span>
                    <span className={styles.helpChatBubbleContainer}>
                      <div
                        className={`${styles.helpChatBubble} ${
                          helpChatBubbleVisible || isHoveringChatBubble
                            ? styles.helpChatBubbleVisible
                            : ''
                        }`}
                        onMouseEnter={() => setIsHoveringChatBubble(true)} // Sätt flaggan när muspekaren är över pratbubblan
                        onMouseLeave={() => {
                          setIsHoveringChatBubble(false);
                          setTimeout(hideHelpChatBubble, 500); // Lägg till en timeout för att ge lite tid att lämna pratbubblan
                        }}
                      >
                        <span className={`${styles['text-16-black']}`}>
                          Om du arbetar hos en arbetsgivare som har kollektivavtal
                          har du rätt till föräldralön. Föräldralönen är en extra
                          lön från arbetsgivaren utöver den ersättning som
                          försäkringskassan betalar i form av föräldrapenning.{' '}
                          <br />
                          <br />
                          Hur mycket föräldralön du kan få beror bland annat på
                          vilket kollektivavtal du omfattas av och hur länge du
                          har varit anställd.
                        </span>
                        <br />
                        <br />
                        <span
                          className={`${styles['text-16-teal-light-underline']}`}
                        >
                          <a
                            href="https://www.saco.se/yrkesliv/foralder/foraldralon-sa-fungerar-det/"
                            target="_blank" // Öppna länken i en ny flik
                            rel="noopener noreferrer" // Lägg till rel-attributet för säkerhet
                            className={`${styles['text-16-teal-light-underline']}`}
                          >
                            Läs mer om föräldralön här.
                          </a>
                        </span>
                        <br />
                        <br />
                      </div>
                    </span>
                  </div>
                </div>
                <div className={styles['grid-parentalleave-r2-r11']} />
                <div className={styles['grid-parentalleave-r2-r12']}>
                  <div className={styles.inputField}>
                    <img src={PersonIcon} alt="person" className={styles.icon} />
                    <span className={`${styles['text-20-teal-light']}`}>
                      Förälder 1
                    </span>
                  </div>
                  <div className={styles.column} />
                  <div className={styles.inputField}>
                    <img src={PersonIcon} alt="person" className={styles.icon} />
                    <span className={`${styles['text-20-teal-light']}`}>
                      Förälder 2
                    </span>
                  </div>
                  <div className={styles.column} />
                </div>
                <div className={styles['grid-parentalleave-r2-r13']} />
                <div className={styles['grid-parentalleave-r2-r14']}>
                  <div className={styles.column}>
                    <div className={styles.selectBox}>
                      <div className={styles.selectBoxLeft}>
                        <div className={styles['text-20-teal']}>
                          <SelectParentalLeaveSpin
                            key="firstSelectionSelect"
                            labelId="firstSelectionLabel"
                            id="firstSelectionSelect"
                            onChange={event =>
                              handleFirstSelectionChange(
                                event,
                                setParent1ParentalSalaryMonths
                              )
                            }
                            options={firstSelection.list.map(opt => ({
                              key: opt.key,
                              value: opt.value,
                              desc: opt.desc,
                            }))}
                            value={parent1ParentalSalaryMonths}
                          />
                          <div className={styles.activeIcon}>
                            {open ? (
                              <IconChevronUp size={35} role="img" aria-label="" />
                            ) : (
                              <IconChevronDown
                                strokeWidth={3}
                                size={35}
                                role="img"
                                aria-label=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.column} />
                  <div className={styles.column}>
                    <div className={styles.selectBox}>
                      <div className={styles.selectBoxLeft}>
                        <SelectParentalLeaveSpin
                          key="secondSelectionSelect"
                          labelId="secondSelectionLabel"
                          id="secondSelectionSelect"
                          onChange={event =>
                            handleSecondSelectionChange(
                              event,
                              setParent2ParentalSalaryMonths
                            )
                          }
                          options={secondSelection.list.map(opt => ({
                            key: opt.key,
                            value: opt.value,
                            desc: opt.desc,
                          }))}
                          value={parent2ParentalSalaryMonths}
                        />
                        <div className={styles.activeIcon}>
                          {open ? (
                            <IconChevronUp size={35} role="img" aria-label="" />
                          ) : (
                            <IconChevronDown
                              strokeWidth={3}
                              size={35}
                              role="img"
                              aria-label=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['grid-parentalleave-r2-r15']} />
                <div className={styles['grid-parentalleave-r2-r16']}>
                  <button
                    type="button"
                    className={styles.turquoisebutton}
                    onClick={showOptimalDistribution}
                  >
                    <div className={styles['align-icon-to-text']}>
                      <span
                        className={`${styles['text-18-white']} ${styles['mr-12']}`}
                      >
                        Se er optimala fördelning
                      </span>
                      <svg
                        className={`${styles.icon} ${isIconUp ? styles.up : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M7 10.5L14 17.5L21 10.5"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className={styles['grid-parentalleave-r2-r17']} />
              </div>
  
              <div
                className={`${styles['grid-parentalleave-r3']} ${
                  isIconUp ? '' : styles.hidden
                }`}
              >
                <div className={styles['grid-parentalleave-r3-r1']} />
                <div
                  className={`styles['grid-parentalleave-r3-r2'] ${styles['p-40-0-0-100']}`}
                >
                  <span
                    className={`${styles['text-28-pink']} ${styles['mr-12']}`}
                  >
                    Ni maximerar er inkomst med fördelningen {parent2MaxMonths} +{' '}
                    {parent1MaxMonths} månader!
                  </span>
                </div>
                <div className={styles['grid-parentalleave-r3-r3']} />
                <div className={styles['grid-parentalleave-r3-r4']}>
                  {/* Rad3-Rad4-6+6 månader komponent */}
                  {hasData && lineData && (
                    <div style={{ position: 'relative', width: '95%' }}>
                      <div id="chartWrapper" style={{ height: '480px' }}>
                        <Line
                          ref={chartRef}
                          data={lineData}
                          id="salary-graph-line"
                          options={addChartOptions(lineOptions, lineData)}
                          setValues={() => {}}
                          type="line"
                        />
                      </div>
                      <div
                        id="maximizeIncomeChatBubbleDiv"
                        className={styles.divInomCanvas}
                      >
                        <div
                          id="maximizeIncomeChatBubbleInnerDiv"
                          className={
                            // eslint-disable-next-line no-nested-ternary
                            selectedIncomeMarkerIndex === 0
                              ? `${styles.maximizeIncomeChatBubble} ${styles.snibb1}`
                              : selectedIncomeMarkerIndex === 8
                              ? `${styles.maximizeIncomeChatBubble} ${styles.snibb2}`
                              : `${styles.maximizeIncomeChatBubble} ${styles.snibb}`
                          }
                        >
                          <div className={styles.maximizeIncomeHeaderRectangle}>
                            <span className={styles.maximizeIncomeHeaderText}>
                              {parent1Months} + {parent2Months} månader
                            </span>
                          </div>
                          <div className={styles.maximizeIncomeContentRectangle1}>
                            <span
                              className={
                                styles.maximizeIncomeContentRectangle1Text
                              }
                            >
                              {selectedHouseholdIncomeData &&
                              selectedHouseholdIncomeData.totalDisposableIncome
                                ? `${selectedHouseholdIncomeData.totalDisposableIncome.toLocaleString(
                                    'sv-SE',
                                    { maximumFractionDigits: 0 }
                                  )} kr`
                                : '-'}
                            </span>
                          </div>
                          <div className={styles.maximizeIncomeContentRectangle2}>
                            <span
                              className={
                                styles.maximizeIncomeContentRectangle2Text
                              }
                            >
                              {selectedHouseholdIncomeData &&
                              selectedHouseholdIncomeData.totalDisposableIncome
                                ? `Varav föräldralön: ${selectedHouseholdIncomeData.totalParentalSalary.toLocaleString(
                                    'sv-SE',
                                    { maximumFractionDigits: 0 }
                                  )} kr`
                                : '-'}
                            </span>
                          </div>
                          <div className={styles.maximizeIncomeContentRectangle3}>
                            <span
                              className={
                                styles.maximizeIncomeContentRectangle3Text1
                              }
                            >
                              {selectedHouseholdIncomeData &&
                              maxHouseholdIncomeData
                                ? maxHouseholdIncomeData.totalDisposableIncome !==
                                  selectedHouseholdIncomeData.totalDisposableIncome
                                  ? `Skillnad:`
                                  : ''
                                : '-'}
                            </span>
                            <span
                              className={
                                styles.maximizeIncomeContentRectangle3Text2
                              }
                            >
                              {selectedHouseholdIncomeData &&
                              maxHouseholdIncomeData
                                ? maxHouseholdIncomeData.totalDisposableIncome !==
                                  selectedHouseholdIncomeData.totalDisposableIncome
                                  ? ` -${(
                                      maxHouseholdIncomeData.totalDisposableIncome -
                                      selectedHouseholdIncomeData.totalDisposableIncome
                                    ).toLocaleString('sv-SE', {
                                      maximumFractionDigits: 0,
                                    })} kr/år`
                                  : ''
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        id="maximizeIncomeMarkerDiv"
                        className={styles.divInomCanvas2}
                      >
                        <img
                          src={MarkerIcon}
                          alt="marker"
                          className={styles.icon}
                        />
                      </div>
                      <div
                        id="maximizeIncomeMaxMarkerDiv"
                        className={styles.divInomCanvas3}
                      >
                        <img
                          src={MarkerIcon}
                          alt="marker"
                          className={styles.icon}
                        />
                        <span className={styles.maxIncomeText}>Ert max</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles['grid-parentalleave-r3-r5']} />
                <div className={styles['grid-parentalleave-r3-r6']}>
                  <span
                    className={`${styles['text-24-teal-light']} ${styles['mr-12']}`}
                  >
                    3. Testa hur fördelningen påverkar er inkomst
                  </span>
                </div>
                <div className={styles['grid-parentalleave-r3-r7']} />
                <div className={styles['grid-parentalleave-r3-r8']}>
                  <div className={styles['grid-parentalleave-r3-r8-r1']}>
                    <div className={styles['grid-parentalleave-r3-r8-r1-c1']}>
                      <div className={styles.inputField}>
                        <img
                          src={PersonIcon}
                          alt="person"
                          className={styles.icon}
                        />
                        <span className={`${styles['text-20-teal-light']}`}>
                          Förälder 1
                        </span>
                      </div>
                    </div>
  
                    <div className={styles['grid-parentalleave-r3-r8-r1-c2']} />
                    <div className={styles['grid-parentalleave-r3-r8-r1-c3']}>
                      <div className={styles.inputField}>
                        <img
                          src={PersonIcon}
                          alt="person"
                          className={styles.icon}
                        />
                        <span className={`${styles['text-20-teal-light']}`}>
                          Förälder 2
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.column} />
                  <div className={styles['grid-parentalleave-r3-r8-r2']}>
                    <div className={styles['grid-parentalleave-r3-r8-r2-c1']}>
                      <button type="button" className={styles.pinkbutton}>
                        {`${parent1Months} månader`}
                      </button>
                    </div>
                    <div className={styles['grid-parentalleave-r3-r8-r2-c2']}>
                      <span
                        className={`${styles['text-16-black']} ${styles['mr-12']}`}
                      >
                        Dra i reglaget för att fördela månader
                      </span>
                    </div>
                    <div className={styles['grid-parentalleave-r3-r8-r2-c3']}>
                      <button type="button" className={styles.pinkbutton}>
                        {`${parent2Months} månader`}
                      </button>
                    </div>
                  </div>
                  <div />
  
                  <div className={styles['grid-parentalleave-r3-r8-r3']}>
                    <div>
                      <Slider
                        id="salarySlider"
                        changeFunction={setParent2Months}
                        max={maxMonths}
                        min={minMonths}
                        step={stepMonths}
                        value={parent2Months}
                      />
                    </div>
                  </div>
                </div>
                {showCalculationInfoContent()}
                <div className={styles['grid-parentalleave-r3-r10']}>
                  <span className={styles.linkContainer}>
                    <a
                      className={styles.link}
                      href="#"
                      onClick={showCalculationInfoModal}
                    >
                      <img src={ListIcon} alt="list" className={styles.icon} />
                      <p className={styles.linkText}>Så har vi räknat</p>
                    </a>
                  </span>
                  <span className={styles.calculationInfoChatBubbleContainer}>
                    <div
                      className={`${styles.calculationInfoChatBubble} ${
                        calculationInfoChatBubbleVisible
                          ? styles.calculationInfoChatBubbleVisible
                          : ''
                      }`}
                    >
                      <div className="row ng-hide" ngShow="mode=='full'">
                        <div className="col-sm-6">
                          <h3 className="ng-binding">Inkomst för mamman</h3>
                          <table className="table income-spec">
                            <tbody>
                              <tr>
                                <td className="ng-binding">
                                  Arbetsinkomst (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 120 000 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent1YearlyIncome
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Föräldrapenning (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 93 069 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent1ParentalAllowance
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Föräldralön (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 13 479 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent1ParentalSalary
                                    )}
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="ng-binding">
                                  Föräldralön (efter skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  10 649 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Årsinkomst (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  226 548 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Jobbskatteavdrag (per år)
                                </td>
                                <td className="text-right ng-binding">
                                  15 108 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Grundavdrag (per år)
                                </td>
                                <td className="text-right ng-binding">
                                  34 100 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomstskatt före JSA
                                </td>
                                <td className="text-right ng-binding">
                                  62 026 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomstskatt efter avdrag
                                </td>
                                <td className="text-right ng-binding">
                                  47 571 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomst efter skatt
                                </td>
                                <td className="text-right ng-binding">
                                  178 978 kr
                                </td>
                              </tr> */}
                              <tr>
                                <td className="ng-binding">Barnbidrag</td>
                                <td className="text-right ng-binding">
                                  7 500 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Disponibel inkomst*
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 186 478 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent1DisposableIncome
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-sm-6">
                          <h3 className="ng-binding">Inkomst för pappan</h3>
                          <table className="table income-spec">
                            <tbody>
                              <tr>
                                <td className="ng-binding">
                                  Arbetsinkomst (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 120 000 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent2YearlyIncome
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Föräldrapenning (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 93 069 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent2ParentalAllowance
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Föräldralön (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 13 479 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent2ParentalSalary
                                    )}
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="ng-binding">
                                  Föräldralön (efter skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  10 649 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Årsinkomst (före skatt)
                                </td>
                                <td className="text-right ng-binding">
                                  226 548 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Jobbskatteavdrag (per år)
                                </td>
                                <td className="text-right ng-binding">
                                  15 108 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Grundavdrag (per år)
                                </td>
                                <td className="text-right ng-binding">
                                  34 100 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomstskatt före JSA
                                </td>
                                <td className="text-right ng-binding">
                                  62 026 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomstskatt efter avdrag
                                </td>
                                <td className="text-right ng-binding">
                                  47 571 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Inkomst efter skatt
                                </td>
                                <td className="text-right ng-binding">
                                  178 978 kr
                                </td>
                              </tr> */}
                              <tr>
                                <td className="ng-binding">Barnbidrag</td>
                                <td className="text-right ng-binding">
                                  7 500 kr
                                </td>
                              </tr>
                              <tr>
                                <td className="ng-binding">
                                  Disponibel inkomst*
                                </td>
                                <td className="text-right ng-binding">
                                  {/* 186 478 kr */}
                                  {selectedHouseholdIncomeData &&
                                    formatIncomeValue(
                                      selectedHouseholdIncomeData.parent2DisposableIncome
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-sm-8 col-sm-offset-2">
                          <div className="well ng-binding">
                            <strong>Totalt brutto:</strong> <br />
                            <strong>Totalt netto:</strong> 455 560 kr
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <div className={styles['grid-parentalleave-r3-r11']} />
              </div>
            </div>
          </>
      )}
      </div>
    );
  }

  return (

  
    <div>
      {mobile ? mobileDesign() : desktopDesign()}
    </div>
  );
};

export default ParentalLeavespin;
