import Const from 'const';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Panel from '../../../atoms/panel/Panel';
import Section from '../../../atoms/section/Section';
import ApiService from '../../../services/ApiService';
import { useAuth } from '../../../services/UseAuth';
import NotificationRow from './notificationrow/NotificationRow';
import AddNotification from './addnotification/AddNotification';
import Loader from '../../../atoms/loader/Loader';

const Notifications = () => {
  const [texts, setTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  useEffect(() => {
    setIsLoading(true);
    ApiService.getTexts(
      [
        {
          groupId: 'notifications',
        },
      ],
      token
    )
      .then(data => {
        setTexts(
          data.filter(txt =>
            user.forbund === 'SA' ? !txt.unionId : txt.unionId === user.unionId
          )
        );
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [token, update]);

  const saveNotification = (unionId, meta, message, id) => {
    ApiService.postText(
      {
        id,
        groupId: 'notifications',
        ...(unionId && { unionId }),
        textId: meta,
        value: message,
      },
      token
    ).then(() => setUpdate(!update));
  };
  const deleteNotification = id => {
    if (id) {
      ApiService.deleteText(id, token).then(() => setUpdate(!update));
    }
  };

  return (
    <Panel>
      <Section title={Const.admin.notifications}>
        <AddNotification createCb={saveNotification} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {isLoading && <Loader variant="primary" />}
        {!!texts.length &&
          texts.map(t => (
            <NotificationRow
              key={t.id}
              forbund={user.forbund}
              deleteCb={deleteNotification}
              updateCb={saveNotification}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...t}
            />
          ))}
      </Section>
    </Panel>
  );
};

Notifications.propTypes = {};

export default Notifications;
