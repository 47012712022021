import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Textarea.module.css';

const Textarea = forwardRef(
  (
    {
      className,
      classTextArea,
      disabled,
      id,
      label,
      onChange,
      placeholder,
      value
    },
    ref
  ) => {
    const extraClasses = className && className !== '' ? className : '';
    const extraTextAreaClasses = classTextArea && classTextArea !== '' ? classTextArea : '';
    return (
      <label
        className={`${styles.textareaLabel} ${extraClasses}`}
        htmlFor={id}
      >
        {`${label}:`}
        <textarea
          className={`${styles.textareaText} ${extraTextAreaClasses}`}
          id={id}
          disabled={disabled}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </label>
    );
  }
);

Textarea.propTypes = {
  className: PropTypes.string,
  classTextArea: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Textarea.defaultProps = {
  className: '',
  classTextArea: '',
  disabled: false,
  label: '',
  placeholder: null,
  value: ''
};

export default Textarea;
