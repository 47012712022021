import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { getWeek } from 'date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from '../VisitorStatistics.module.css';
import { Colors } from '../../../../assets/Colors';

const options = {
  title: {
    display: true,
    text: 'Antal besök per dag',
    fontSize: 16,
    fontColor: Colors.black,
  },
  responsive: true,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: 'black',
      formatter: value => {
        if (value === 0) {
          return '';
        } else {
          return value.toLocaleString();
        }
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: value => value.toLocaleString().replace(',', ' '),
        },
        afterDataLimits(scale) {
          var range = scale.max - scale.min;
          var grace = range * 0.05;
          scale.max += grace;
        },
      },
    ],
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
};

const dayLabelKeys = {
  1: 'Måndag',
  2: 'Tisdag',
  3: 'Onsdag',
  4: 'Torsdag',
  5: 'Fredag',
  6: 'Lördag',
  7: 'Söndag',
};

const DayChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const mapStatsToChartData = statsMap => {
    const currentDate = new Date();
    const currentWeek = getWeek(currentDate);
    const currentDay = new Date().getDay();
    const lastWeek = currentWeek - 1;

    const labels = [];
    const statsMapKeysArray = Array.from(statsMap.keys());

    for (let i = 0; i < statsMapKeysArray.length; i += 1) {
      const dayKey = statsMapKeysArray[i];

      let label = dayLabelKeys[dayKey];
      if (Number(dayKey) > currentDay) {
        label = `${label} v.${lastWeek}`;
      } else {
        label = `${label} v.${currentWeek}`;
      }
      labels.push(label);
    }

    const datasets = [
      {
        label: 'Medlem',
        data: Array.from(statsMap.values()).map(day => day.medlem),
        backgroundColor: Colors.turquoise,
        maxBarThickness: 70,
      },
      {
        label: 'Förtroendevald',
        data: Array.from(statsMap.values()).map(day => day.fortroendevald),
        backgroundColor: Colors.yellow,
        maxBarThickness: 70,
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      const currentDay = new Date().getDay();
      const entries = Object.entries(stats);
      entries.sort(([dayA], [dayB]) => Number(dayA) - Number(dayB));

      const currentWeekDays = entries.filter(
        ([day]) => Number(day) <= currentDay
      );
      const lastWeekDays = entries.filter(([day]) => Number(day) > currentDay);

      const sortedEntries = [...lastWeekDays, ...currentWeekDays];
      const sortedStats = new Map(sortedEntries);

      mapStatsToChartData(sortedStats);
    }
  }, [stats]);

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  return (
    <div>
      {chartData && (
        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
      {chartData && (
        <div className={styles.chartStats}>
          <div className={styles.tableToggle} onClick={toggleTable}>
            {isTableExpanded ? 'Dölj tabell' : 'Visa som tabell'}
          </div>
          {isTableExpanded && (
            <table>
              <thead>
                <tr>
                  <th></th>
                  {chartData.labels.map((label, index) => (
                    <th key={index}>{label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {chartData.datasets.map((dataset, index) => (
                  <tr key={dataset.label}>
                    <td>{dataset.label}</td>
                    {dataset.data.map((dataPoint, index) => (
                      <td key={index}>{dataPoint.toLocaleString()}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default DayChart;

DayChart.propTypes = {
  stats: PropTypes.any,
};
