import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Button from 'atoms/button/Button';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Input from 'atoms/input/Input';
import InputReadOnly from 'atoms/inputreadonly/InputReadOnly';
import { toast } from 'react-toastify';
import styles from './UnionLinkConfig.module.css';

const groupId = 'unionLink';
const textId = 'unionLink';

export default function UnionLinkConfig({ forbundList }) {
  const {
    user: { forbund, unionId, token },
  } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [header, setHeader] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [id, setId] = useState('');

  const updateState = link => {
    if (!link) {
      setLinkUrl('');
      setId('');
    } else {
      setHeader(link?.header);
      setLinkUrl(link?.value);
      setId(link?.id);
    }
    setEditMode(false);
  };

  const handleUpdate = () => {
    setIsSaving(true);

    const request = {
      id: id || null,
      groupId,
      textId,
      header,
      value: linkUrl,
      unionId,
    };

    ApiService.postText(request, token)
      .then(data => {
        updateState(data);
        toast.success('Förbundslänk sparad');
      })
      .catch(() => toast.error('Misslyckades att spara förbundslänken'))
      .finally(() => setIsSaving(false));
  };

  const handleDelete = () => {
    setIsSaving(true);
    if (id) {
      ApiService.deleteText(id, token)
        .then(() => {
          updateState();
          toast.success('Förbundslänk raderad');
        })
        .catch(() => toast.error('Misslyckades att spara förbundslänken'))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    if (forbundList.length === 0) return;
    const forbundName =
      forbundList.find(f => f.forbundskodSas === forbund?.toLocaleUpperCase())
        ?.name || '';

    setHeader(forbundName);
  }, [forbundList, forbund]);

  useEffect(() => {
    setIsLoading(true);
    const request = [
      {
        groupId,
        textId: [textId],
      },
    ];
    ApiService.getTexts(request, token)
      .then(data => {
        if (data && data[0]) return updateState(data[0]);
        return updateState();
      })
      .finally(() => setIsLoading(false));
  }, [token]);

  return (
    <SectionAdmin title="Länk till förbundets hemsida">
      <p>Här lägger du in en länk till förbundets hemsida. Visas i sidfoten.</p>
      {isLoading && (
        <div className={styles.loaderAnimation}>
          <Loader variant="primary" />
        </div>
      )}

      {!isLoading && (
        <Panel
          card
          legend={`${editMode ? 'Redigera förbundslänk' : 'Förbundslänk'}`}
        >
          <form>
            <div className={styles.linkBox}>
              <div className={styles.linkCol}>
                {editMode ? (
                  <Input
                    id="edit-unionLink-text"
                    label="Länktext"
                    placeholder="Länktext"
                    type="text"
                    disabled
                    value={header}
                  />
                ) : (
                  <InputReadOnly label="Länktext" text={header} />
                )}
              </div>
              <div className={styles.linkCol}>
                {editMode ? (
                  <Input
                    id="edit-http-link"
                    label="HTTP-länk"
                    onChange={evt => setLinkUrl(evt.target.value)}
                    placeholder="HTTP-länk"
                    type="text"
                    value={linkUrl}
                  />
                ) : (
                  <InputReadOnly label="HTTP-länk" text={linkUrl} />
                )}
              </div>
            </div>
          </form>
          <div>
            <ButtonBar>
              {editMode && (
                <>
                  <Button
                    variant="delete"
                    onClick={() => handleDelete()}
                    label="Ta bort"
                    icon="Trash"
                    altText="Ta bort länk"
                    disabled={isSaving || !id}
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleUpdate()}
                    label="Spara"
                    altText="Spara länk"
                    disabled={isSaving || !linkUrl}
                  />
                  <Button
                    variant="secondary"
                    onClick={() => setEditMode(prevState => !prevState)}
                    label="Avbryt"
                    disabled={isSaving}
                  />
                </>
              )}
              {!editMode && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => setEditMode(prevState => !prevState)}
                    label="Redigera"
                    icon="Edit"
                    altText="Redigera länk"
                  />
                </>
              )}
            </ButtonBar>
          </div>
        </Panel>
      )}
    </SectionAdmin>
  );
}

UnionLinkConfig.propTypes = {
  forbundList: PropTypes.arrayOf(PropTypes.shape({})),
};

UnionLinkConfig.defaultProps = {
  forbundList: [],
};
