/* eslint-disable react/prop-types */
import React, { useState, useContext, createContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import VisitorCounter from 'atoms/visitorcounter/VisitorCounter';
import ApiService from './ApiService';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  // If there's a user in localStorage, the state will set it's initial value to that on mount.
  // Or else null.
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const decodeUser = usr => {
    const data = usr.token.split('.')[1];
    const decodedJsonData = window.atob(data);
    const decodedData = JSON.parse(decodedJsonData);

    // FIXME: Anpassa när/om role-propertyn byter namn till role.
    return {
      token: usr.token,
      forbund: decodedData.fk,
      unionId: decodedData.unionid ? decodedData.unionid : '',
      role:
        decodedData[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ],
      exp: decodedData.exp,
    };
  };

  const signout = () => {
    // FIXME: User blir 'false' när man loggar ut? Borde kanske bli undefined/null.
    setUser(false);
    localStorage.removeItem('user');
  };

  const signin = (subscriberId, password, forbundsKodAdmin) => {
    return ApiService.login({ subscriberId, password, forbundsKodAdmin })
      .then(usr => {
        const decodedUser = decodeUser(usr);
        setUser(decodedUser);
        localStorage.setItem('user', JSON.stringify(decodedUser));
      })
      .catch(error => {
        throw error;
      });
  };

  const tokenSignin = (token, browser, device) => {
    return ApiService.tokenLogin({ token, browser, device }, signout)
      .then(usr => {
        const decodedUser = decodeUser(usr);
        setUser(decodedUser);
        localStorage.setItem('user', JSON.stringify(decodedUser));
      })
      .catch(error => {
        throw error;
      });
  };

  const forbundSignin = (forbundsId, browser, device) => {
    return ApiService.forbundsKodLogin({ forbundsId, browser, device })
      .then(usr => {
        const decodedUser = decodeUser(usr);
        setUser(decodedUser);
        localStorage.setItem('user', JSON.stringify(decodedUser));
      })
      .catch(error => {
        throw error;
      });
  };

  const storeUser = usr => {
    const decodedUser = decodeUser(usr);
    setUser(decodedUser);
    localStorage.setItem('user', JSON.stringify(decodedUser));
  };

  const autoRefreshToken = (token, minutes = 15) => {
    if (!token || !user) return;

    const now = new Date();
    const soon = new Date(now.getTime() + minutes * 60000);

    const decodedUser = decodeUser(user);

    // exp value comes in a shorter format for some reason
    const expires = new Date(decodedUser?.exp * 1000);
    const expiresSoon =
      now.getTime() <= expires.getTime() && soon.getTime() > expires.getTime();

    if (!expiresSoon) return;

    // use custom admin refresh endpoint, use current forbund
    if (user.role === 'admin') {
      ApiService.refreshAdminToken(
        { token, forbundsKodAdmin: user.forbund },
        token
      ).then(data => {
        storeUser(data);
      });
      return;
    }

    ApiService.tokenLogin({ token }).then(data => {
      storeUser(data);
    });
  };

  return {
    user,
    signin,
    storeUser,
    decodeUser,
    tokenSignin,
    forbundSignin,
    autoRefreshToken,
    signout,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <>
            <VisitorCounter path={rest.path} user={user} />
            <Component {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: '/inloggning-info' }} />
        )
      }
    />
  );
};

export const AdminRoute = ({
  component: Component,
  restrict = false,
  ...rest
}) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={props => {
        if (
          user &&
          (user.role === 'admin' || (!restrict && user.role === 'redaktor'))
        ) {
          return <Component {...props} {...rest} />;
        }
        if (user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              // pathname: '/bli-medlem',
              // FIXME: temporary fix, before launch
              pathname: '/logga-in',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
