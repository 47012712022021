import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Accordion from 'atoms/accordion/Accordion';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import MarkdownEditor from 'atoms/markdowneditor/MarkdownEditor';
import ExplanationText from 'atoms/explanationtext/ExplanationText';
import styles from './GeneralTextItem.module.css';

const GeneralTextItem = ({ txtGroup, updateCb }) => {
  const [editMode, setEditMode] = useState(false);
  const [txtGroupCopy, setTxtGroupCopy] = useState([...txtGroup]);

  const { user } = useAuth();
  const { unionId } = user;
  const forbundSA = user.forbund === 'SA';

  useEffect(() => {
    // Rerender on prop change
    setTxtGroupCopy([...txtGroup]);
  }, [txtGroup]);

  const formatHelpText = textIn => {
    if (editMode) {
      return (
        <>
          <MarkdownEditor
            key={`${textIn.id}-mkdwn`}
            value={textIn.value}
            onChange={value => {
              setTxtGroupCopy(prevState =>
                prevState.map(txtObj => {
                  return txtObj.id === textIn.id
                    ? { ...txtObj, value }
                    : txtObj;
                })
              );
            }}
          />
        </>
      );
    }
    return <ExplanationText textItem={textIn} />;
  };

  const performSave = () => {
    // Copy to union specific text
    const createCopy = !forbundSA && !txtGroup[0].unionId;

    const textsToUpdate = txtGroupCopy.filter(newTxt => {
      const comp = txtGroup.find(originalTxt => originalTxt.id === newTxt.id);
      return comp.value !== newTxt.value || createCopy;
    });

    textsToUpdate.forEach(updateTxt => {
      const request = {
        ...(!createCopy && { id: updateTxt.id }),
        value: updateTxt.value,
        header: updateTxt.header,
        groupId: updateTxt.groupId,
        textId: updateTxt.textId,
        isDefault: forbundSA,
        ...(!forbundSA && { unionId }),
      };

      // TODO: Add user feedback
      ApiService.postText(request, user.token).then(() => {
        updateCb(txtGroup[0].groupId);
      });
    });
    return setEditMode(prevState => !prevState);
  };

  const performDelete = () => {
    // Don't delete default texts
    const unionTexts = txtGroup.filter(txt => txt.unionId);

    return Promise.all(
      unionTexts.map(txt => ApiService.deleteText(txt.id, user.token))
    ).then(() => {
      updateCb(txtGroup[0].groupId);
      setEditMode(prevState => !prevState);
    });
  };

  const performCancel = () => {
    setEditMode(prevState => !prevState);
    setTxtGroupCopy(txtGroup);
  };

  const renderSubTexts = () => {
    const textsToPrint = txtGroupCopy.filter(txt => txt.textId !== 'main');

    return textsToPrint.map(txt => {
      return (
        <div key={txt.id}>
          <h3>{txt.header}</h3>
          {formatHelpText(txt)}
        </div>
      );
    });
  };

  return (
    <Accordion
      title={
        txtGroupCopy.find(txt => txt.textId === 'main').header +
        (!txtGroup[0].unionId ? '' : ' - förbundsspecifik')
      }
      slim
      border
      marginBottom
    >
      {!editMode && (
        <div className={styles.optionsList}>
          {formatHelpText(txtGroupCopy.find(txt => txt.textId === 'main'))}
          {renderSubTexts()}
          <ButtonBar>
            <Button
              variant="primary"
              onClick={() => setEditMode(prevState => !prevState)}
              label="Redigera"
              icon="Edit"
              altText="Redigera text"
            />
          </ButtonBar>
        </div>
      )}
      {editMode && (
        <div className={styles.optionsList}>
          {formatHelpText(txtGroupCopy.find(txt => txt.textId === 'main'))}
          {renderSubTexts()}
          <ButtonBar>
            {txtGroup[0].unionId && (
              <Button
                className={styles.defaultOption}
                label="Radera förbundsspecifik"
                onClick={performDelete}
                variant="delete"
                icon="Trash"
              />
            )}
            <Button
              variant="primary"
              onClick={() => performSave()}
              label="Spara"
              altText="Spara text"
            />
            <Button
              variant="secondary"
              onClick={() => performCancel()}
              label="Avbryt"
            />
          </ButtonBar>
        </div>
      )}
    </Accordion>
  );
};

GeneralTextItem.propTypes = {
  txtGroup: PropTypes.arrayOf(Object).isRequired,
  updateCb: PropTypes.func.isRequired,
};

export default GeneralTextItem;
