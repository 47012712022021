import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'atoms/loader/Loader';
import { isTotalRow } from 'lib/salaryTable';
import { numberOfRespondents } from 'lib/antal';
import styles from './ReportSection.module.css';
import SalaryTable from './salarytable/SalaryTable';
import SalaryGraph from './salarygraph/SalaryGraph';
import SalaryMarker from './salarygraph/salarymarker/SalaryMarker';

const ReportSection = ({
  activeDataSource,
  customPresentation,
  fetchingData,
  measureVar,
  selectedPresentation,
  setMeasureVar,
  tableData,
}) => {
  const [salaryMarkerValue, setSalaryMarkerValue] = useState(null);
  return (
    <div className={styles.reportSection}>
      {fetchingData ? (
        <Loader variant="secondary" />
      ) : (
        <>
          {tableData && tableData.length > 0 ? (
            <>
              {tableData.find(row => {
                return (
                  isTotalRow(
                    row,
                    customPresentation && selectedPresentation.length > 1
                  ) && numberOfRespondents(row.antal)[1] <= 30
                );
              }) ? (
                <div className={styles.noData}>
                  <p>
                    <b>
                      Statistikunderlaget är för litet. Resultat visas inte om
                      urvalet är färre än 30.
                    </b>
                  </p>
                  <p>
                    Bocka för fler likartade val, tag bort något val eller gör
                    ett helt nytt val.
                  </p>
                </div>
              ) : (
                <>
                  <SalaryTable
                    selectedPresentation={selectedPresentation}
                    tableData={tableData}
                    activeDataSource={activeDataSource}
                    measureVar={measureVar}
                    setMeasureVar={setMeasureVar}
                  />
                  <SalaryMarker
                    salaryMarkerValue={salaryMarkerValue}
                    setSalaryMarkerValue={setSalaryMarkerValue}
                  />
                  <SalaryGraph
                    customPresentation={customPresentation}
                    datasetLabel={selectedPresentation}
                    salaryData={
                      selectedPresentation.some(i => i.isReverse)
                        ? [...tableData].reverse()
                        : tableData
                    }
                    selectedPresentation={selectedPresentation}
                    salaryMarkerValue={salaryMarkerValue}
                  />
                </>
              )}
            </>
          ) : (
            <div className={styles.noData}>Data saknas för denna sökning.</div>
          )}
        </>
      )}
    </div>
  );
};

ReportSection.propTypes = {
  activeDataSource: PropTypes.shape({}),
  customPresentation: PropTypes.bool.isRequired,
  fetchingData: PropTypes.bool.isRequired,
  measureVar: PropTypes.string.isRequired,
  selectedPresentation: PropTypes.arrayOf(Object),
  setMeasureVar: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(Object).isRequired,
};

ReportSection.defaultProps = {
  activeDataSource: null,
  selectedPresentation: [],
};

export default ReportSection;
