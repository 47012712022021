import React from 'react';
import { bool, shape, string } from 'prop-types';
import styles from './UnionLink.module.css';

export default function UnionLink({ unionLink, capitalize }) {
  return (
    <>
      {unionLink ? (
        <a
          href={unionLink.value}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.unionLink}
        >
          {capitalize ? 'K' : 'k'}ontakta {unionLink.header}
        </a>
      ) : (
        `${capitalize ? 'K' : 'k'}ontakta ditt förbund`
      )}{' '}
    </>
  );
}

UnionLink.propTypes = {
  unionLink: shape({
    value: string,
    header: string,
  }),
  capitalize: bool,
};

UnionLink.defaultProps = {
  unionLink: null,
  capitalize: false,
};
