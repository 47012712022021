import React from 'react';
import PropTypes from 'prop-types';
import { IconChevronRight } from 'tabler-icons';
import styles from './ExternalLink.module.css';

function ExternalLink({ header, value }) {
  return (
    <a
      href={value}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.externalLink}
      alt={header}
    >
      {header}
      <IconChevronRight role="img" aria-label="" />
    </a>
  );
}

ExternalLink.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ExternalLink;
