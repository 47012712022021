/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import useScrollTo from 'hooks/useScrollTo';
import styles from './Section.module.css';

const Section = ({ title, children, className, dashed, id }) => {
  const ref = useScrollTo(id);
  const extraClasses = className && className !== '' ? className : '';
  return (
    <section
      className={`${styles.sectionElement} ${
        dashed ? styles.isDashed : ''
      } ${extraClasses}`}
    >
      {title !== '' && (
        <h2 className={styles.sectionHeading} ref={ref}>
          {title}
        </h2>
      )}
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  dashed: PropTypes.bool,
};

Section.defaultProps = {
  id: '',
  title: '',
  className: '',
  dashed: false,
};

export default Section;
