import ButtonLink from 'atoms/buttonlink/ButtonLink';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Tab from 'atoms/tab/Tab';
import TabRow from 'atoms/tabrow/TabRow';
import Const from 'const';
import useScrollTo from 'hooks/useScrollTo';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import PreviewDataSourceConfig from 'components/admin/previewdatasourceconfig/PreviewDataSourceConfig';
import LinksConfig from '../linksconfig/LinksConfig';
import PreviewSalaryProfileConfig from '../previewsalaryprofileconfig/PreviewSalaryProfileConfig';
import HelpBox from './helpbox/HelpBox';
import styles from './PreviewConfig.module.css';

const PreviewConfig = () => {
  const history = useHistory();
  const ref = useScrollTo('hjalp');
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  const [activeTab, setActiveTab] = useState('situation');
  const [situations, setSituations] = useState();
  const [loadingSituations, setLoadingSituations] = useState(false);

  useEffect(() => {
    //if (!(user.role === 'admin' && user.forbund.toLowerCase() === 'sa')) return;
    setLoadingSituations(true);
    console.log('getPreviewSituations');
    ApiService.getPreviewSituations(token)
      .then(data => {
        setSituations(data);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => setLoadingSituations(false));
    // eslint-disable-next-line
  }, [token, signout, history]);

  // admins
  return (
    <Panel>
      <Section title={Const.admin.previewConfigHeader}>
        <TabRow className={styles.tabRow}>
          <Tab
            active={activeTab === 'situation'}
            label="1. Statistikkällor"
            onClick={() => setActiveTab('situation')}
          />
          <Tab
            active={activeTab === 'statistics'}
            label="2. Statistik & urval"
            onClick={() => setActiveTab('statistics')}
          />

          <ButtonLink
            label="Hjälp"
            altText="Hjälpinformation"
            className={styles.helpLink}
            icon="Help"
            onClick={() => history.push('#hjalp')}
          />
        </TabRow>

        {loadingSituations ? (
          <div className={styles.loaderAnimation}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            {situations && (
              <>
                {activeTab === 'situation' && situations && (
                  <PreviewDataSourceConfig
                    situations={situations}
                    setSituations={setSituations}
                  />
                )}
                {activeTab === 'statistics' && situations && (
                  <PreviewSalaryProfileConfig
                    situations={situations}
                    setSituations={setSituations}
                  />
                )}
                {activeTab === 'links' && <LinksConfig />}
              </>
            )}
          </>
        )}
        <div ref={ref} id="hjalp">
          <HelpBox />
        </div>
      </Section>
    </Panel>
  );
};

export default PreviewConfig;
