import React from 'react';
import PropTypes from 'prop-types';
import { IconUser } from 'tabler-icons';
import styles from './SalaryInterval.module.css';

const SalaryInterval = ({ salaryLimit, salaryMid, selectedSalary }) => {
  const calcPercent = salary => {
    const a = salaryLimit[1] - salaryLimit[0];
    const b = salary - salaryLimit[0];

    let c = (b / a) * 100;

    // TODO: Någon bättre smooth overgång inte att allting bara blir max/min.
    if (c < -7) c = -7;
    if (c > 107) c = 107;

    return `${c}%`;
  };

  const dinLonPercentage = calcPercent(selectedSalary);
  const salaryMidPercent = calcPercent(salaryMid);

  return (
    <div className={styles.main}>
      <div className={styles.salaryLineOuter}>
        <div className={styles.lineFiller} />
        <div
          className={styles.salaryLineInner}
          style={{
            background: `linear-gradient(
              90deg,
              rgba(185, 222, 228, 1) 0%,
              rgba(72, 158, 173, 1) ${salaryMidPercent},
              rgba(185, 222, 228, 1) 100%
            )`,
          }}
        >
          <div className={styles.percentileBound} style={{ left: '0%' }} />
          <div className={styles.percentileBound} style={{ left: '100%' }} />
          <div
            className={styles.midLine}
            style={{
              left: `${salaryMidPercent}`,
            }}
          />
          {selectedSalary && (
            <div
              className={styles.yourSalary}
              style={{ left: `${dinLonPercentage}` }}
            >
              <div className={styles.yourSalaryCircle}>
                <IconUser aria-label="" role="img" strokeWidth={3} size={16} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.lineFiller} />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.percentileText}>
          {Number(salaryLimit[0]).toLocaleString('sv')} kr.
        </p>
        <div className={styles.examples}>
          <div className={styles.yourSalaryCircle}>
            <IconUser aria-label="" role="img" strokeWidth={3} size={16} />
          </div>
          <p>Din lön</p>
          <div className={styles.midLineExplain} />
          <p>Medellön</p>
        </div>
        <p className={styles.percentileText}>
          {Number(salaryLimit[1]).toLocaleString('sv')} kr.
        </p>
      </div>
    </div>
  );
};

SalaryInterval.propTypes = {
  salaryLimit: PropTypes.arrayOf(Number).isRequired,
  salaryMid: PropTypes.number.isRequired,
  selectedSalary: PropTypes.string,
};

SalaryInterval.defaultProps = {
  selectedSalary: null,
};

export default SalaryInterval;
