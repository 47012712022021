/* eslint-disable camelcase */
import { isTotalRow } from './salaryTable';

// Returns a solid array of data for graphs
export function getSolidData(data, type = '') {
  return data
    .filter(entry => !isTotalRow(entry) && entry.breakby1)
    .filter(entry => {
      const { lon_p25, lon_p50, lon_medel, lon_p75, lon_p90 } = entry;

      if (type === 'box')
        return !Object.values({
          lon_p25,
          lon_p50,
          lon_p75,
          lon_p90,
        }).includes(null);

      if (type === 'bar') {
        return !Object.values({ lon_medel }).includes(null);
      }

      if (type === 'line')
        return !Object.values({ lon_p25, lon_p50, lon_p75 }).includes(null);

      return !Object.values(entry).includes(null);
    });
}

// Format chart data
export function calcChartData(data, type) {
  const result = getSolidData(data, type);

  if (type === 'box') {
    return result.map(x => ({
      min: x.lon_p10,
      q1: x.lon_p25,
      median: x.lon_p50,
      q3: x.lon_p75,
      max: x.lon_p90,
      outliers: [x.lon_medel],
    }));
  }

  if (type === 'line') {
    return result.map(x => ({
      q1: x.lon_p25,
      median: x.lon_p50,
      q3: x.lon_p75,
    }));
  }

  return result.map(x => x.lon_medel);
}

// Returns labels for charts
export function genLabels(data, type) {
  return getSolidData(data, type).map(x => Object.values(x)[0]);
}

// [1, 1, 2, 2] => [1, 2]
export function unique(value, index, self) {
  return self.indexOf(value) === index;
}

export function uniqueByProp(arr, property) {
  return arr.filter(
    (e, i) => arr.findIndex(a => a[property] === e[property]) === i
  );
}

export function filterCustomData(row) {
  return (
    row.breakby1 &&
    row.breakby2 &&
    !['inget värde', 'okänd'].includes(row.breakby2.toLowerCase()) &&
    !['inget värde', 'okänd'].includes(row.breakby1.toLowerCase())
  );
}

export function getCustomLabels(data, type) {
  return getSolidData(data, type)
    .filter(row => filterCustomData(row))
    .map(row => row.breakby1)
    .sort((a, b) => a.localeCompare(b, 'sv'))
    .filter(unique);
}

// To be sure, we need at least 21 unique colors for customReport
const customColors = [
  '#DA8BBA', // secondary
  '#99D2D9', // primary
  '#FFFAFA', // white
  '#708090', // grey
  '#EEE8AA', // gold
  '#F08080', // red
  '#90EE90', // green
  '#6495ED', // blue
  '#FFB6C1', // pink
  '#BA55D3', // purple
  // These colors will most likely not be used, but in case:
  '#F4A460', // orange
  '#00BFFF', // deepskyblue
  '#696969', // dimgray
  '#DAA520', // goldenrod
  '#9400D3', // darkviolet
  '#00008B', // darkblue
  '#006400', // darkgreen
  '#8B0000', // darkred
  '#FF1493', // deeppink
  '#FF8C00', // darkorange
  '#4B0082', // indigo
  '#FFFFFF', // white
];

// TODO: Refactor this
export function calcCustomChartData(data, type) {
  const solidData = getSolidData(data, type)
    .filter(row => filterCustomData(row))
    .sort((a, b) => a.breakby1.localeCompare(b.breakby1, 'sv'));

  const groups = uniqueByProp(solidData, 'breakby2').map((row, i) => {
    const color = customColors[i % customColors.length];

    if (type === 'box') {
      return {
        data: {},
        label: row.breakby2,
        backgroundColor: color,
        hoverBackgroundColor: color,
        medianColor: '#333333',
        outlierColor: '#333333',
        borderColor: '#333333',
        borderWidth: 2,
        maxBarThickness: 40,
      };
    }

    return {
      data: {},
      label: row.breakby2,
      type,
      backgroundColor: color,
      hoverBackgroundColor: color,
      fill: false,
      borderColor: '#333333',
      borderWidth: 2,
      maxBarThickness: 80,
    };
  });

  solidData.forEach(item => {
    groups.map(group => {
      const tempGroup = { ...group };
      // It's a match
      if (group.label === item.breakby2) {
        if (type === 'box') {
          tempGroup.data[item.breakby1] = {
            min: item.lon_p10,
            q1: item.lon_p25,
            median: item.lon_p50,
            q3: item.lon_p75,
            max: item.lon_p90,
            outliers: [item.lon_medel],
          };
        }

        if (type === 'bar') {
          tempGroup.data[item.breakby1] = item.lon_medel;
        }
      }

      // Its not a match
      if (group.label !== item.breakby2) {
        // key does not exist
        if (!(item.breakby1 in group.data)) {
          tempGroup.data[item.breakby1] = null;
        }
      }
      return tempGroup;
    });
  });

  return groups.map(group => ({
    ...group,
    data: Object.values(group.data),
  }));
}

// Returns a mapped data object for a chart.js instance
export function genData(data, type, labels, customPresentation = false) {
  if (type === 'box') {
    return {
      labels: customPresentation ? getCustomLabels(data, type) : labels,
      datasets: customPresentation
        ? calcCustomChartData(data, type, customPresentation)
        : [
            {
              label: 'Löner',
              backgroundColor: '#DA8BBA',
              borderColor: '#333333',
              borderWidth: 2,
              medianColor: '#333333',
              outlierColor: '#333333',
              data: calcChartData(data, type),
              maxBarThickness: 40,
            },
          ],
    };
  }

  if (type === 'line') {
    const defaultOptions = {
      borderWidth: 2,
      fill: false,
      type,
      tension: 0.1,
    };

    return {
      labels,
      datasets: [
        {
          backgroundColor: '#33a0a0',
          borderColor: '#33a0a0',
          borderWidth: 0,
          data: calcChartData(data, type).map(({ q1 }) => q1),
          label: 'Undre kvartil',
          pointStyle: 'rect',
          ...defaultOptions,
        },
        {
          backgroundColor: '#DA8BBA',
          borderColor: '#DA8BBA',
          data: calcChartData(data, type).map(({ median }) => median),
          label: 'Median',
          pointStyle: 'circle',
          ...defaultOptions,
        },
        {
          backgroundColor: '#333333',
          borderColor: '#333333',
          data: calcChartData(data, type).map(({ q3 }) => q3),
          label: 'Övre kvartil',
          pointStyle: 'rectRounded',
          ...defaultOptions,
        },
      ],
    };
  }

  return {
    labels: customPresentation ? getCustomLabels(data, type) : labels,
    datasets: customPresentation
      ? calcCustomChartData(data, type, customPresentation)
      : [
          {
            type,
            label: 'Medellön',
            backgroundColor: '#DA8BBA',
            data: calcChartData(data, type),
            fill: false,
            maxBarThickness: 80,
          },
        ],
  };
}

// Returns configuration object for "Ange din lön"
export function getAnnotations(
  salaryMarkerValue,
  activeTab,
) {
  if (!salaryMarkerValue) return {};
  return {
    annotations: [
      {
        type: 'line',
        mode: activeTab === 'box' ? 'vertical' : 'horizontal',
        scaleID: activeTab === 'box' ? 'x-axis-0' : 'y-axis-0',
        value: salaryMarkerValue,
        borderColor: '#333333',
        borderWidth: 2,
        borderDash: [2, 2],
        label: {
          enabled: true,
          content: 'Din lön',
          backgroundColor: 'rgba(0,0,0,0.75)',
        },
      },
    ],
  };
}

// Format labels to Swedish locale
function formatLabelNumber(value) {
  if (typeof value === 'number') {
    return value.toLocaleString('sv');
  }
  return value;
}

// Check if chart has data to display
export function chartHasData(data, customPresentation, min = 0, max = 21) {
  if (customPresentation && data && data.datasets[0]) {
    let allData = [];

    data.datasets.forEach(dataset => {
      allData = [...allData, ...dataset.data.filter(Boolean)];
    });

    return allData.length > min && allData.length <= max;
  }

  return (
    data &&
    data.datasets[0] &&
    data.datasets[0].data.length > min &&
    data.datasets[0].data.length <= max
  );
}

export const boxOptions = {
  responsive: true,
  legend: {
    position: 'bottom',
  },
  title: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: false,
          callback: value => formatLabelNumber(value),
        },
        gridLines: { display: true },
        scaleLabel: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: { display: false },
        ticks: {
          beginAtZero: false,
          callback: value => formatLabelNumber(value),
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  events: ['hover'],
  onHover: null,
};

export const barOptions = {
  responsive: true,
  title: {
    display: false,
  },
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        scaleLabel: {
          display: true,
        },
        ticks: {
          callback: value => formatLabelNumber(value),
        },
      },
    ],
    yAxes: [
      {
        gridLines: { display: true },
        ticks: {
          beginAtZero: true,
          callback: value => formatLabelNumber(value),
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, d) {
        let label = d.datasets[tooltipItem.datasetIndex].label || '';

        if (label) {
          label += ': ';
        }

        label += Number(tooltipItem.yLabel).toLocaleString('sv');
        return `${label} kronor per månad`;
      },
    },
  },
};

export const lineOptions = {
  responsive: true,
  title: {
    display: false,
  },
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: true },
        scaleLabel: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 32,
          callback: value => formatLabelNumber(value),
        },
      },
    ],
    yAxes: [
      {
        gridLines: { display: true },
        ticks: {
          beginAtZero: true,
          callback: value => formatLabelNumber(value),
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
  events: ['hover'],
  onHover: null,
};
