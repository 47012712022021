/* eslint-disable default-case */
import React from 'react';
import  {Link} from 'react-router-dom';


export function getStateText(monthlySalary, displayMonthlySalary, salaryWithoutAgreement, selectableSector) {
    let text;
    let kollektivavtal;
    let sektor;

    switch (selectableSector) {
        case 'Statlig':
            kollektivavtal = "(PSA)"
            sektor = "statlig sektor";
            break;
        case 'Kommunal':
            kollektivavtal = "(TFA-KL)"
            sektor = "kommunal- eller regional sektor";
            break;
        case 'Privat':
            kollektivavtal = "(TFA)"
            sektor = "privat sektor";
            break;
    }

    text = (

        <div>
            <br />
            <p> Du som arbetar i {sektor} och tjänar <b>{displayMonthlySalary}</b> kronor i månaden får <b>{salaryWithoutAgreement}</b> kronor i sjukpenning från Försäkringskassan om du råkar ut för en arbetsskada.</p>
            <p>Med din kollektivavtalade arbetsskadeförsäkring {kollektivavtal} förlorar du ingenting på att vara sjukskriven efter en godkänd arbetsskada. Du får <b>{displayMonthlySalary}</b> kronor, alltså hela din lön, redan från första dagen. 
            Dessutom kan du få kompensation för kostnader på grund av skadan, ersättning för sveda och värk och ersättning för invaliditet, om skadan blir bestående.
            Pengarna kommer från Försäkringskassan och från dina kollektivavtalade försäkringar.</p>
            <p>Utan arbetsskadeförsäkring hade du fått nöja dig med pengarna från Försäkringskassan. {monthlySalary > 27500 && (
            <span style={{ color: 'black' }}> Det blir aldrig mer än <b>{salaryWithoutAgreement}</b> kronor, oavsett hur mycket du tjänar.</span>)}</p>
        </div>
    );
    return text;
}

export function getParentText(monthlySalary,displayMonthlySalary, salaryWithoutAgreement,salaryWithAgreement,selectableSector,parentalSupplement, pbb) {
    let text;
    let kollektivavtal;
    let sektor;

    switch (selectableSector) {
        case 'Statlig':
            kollektivavtal = "(PSA)"
            sektor = "statlig sektor";
            break;
        case 'Kommunal':
            kollektivavtal = "(TFA-KL)"
            sektor = "kommunal- eller regional sektor";
            break;
        case 'Privat':
            kollektivavtal = "(TFA)"
            sektor = "privat sektor";
            break;
    }

    text = (
    <div>
        <br />
        <p>Du som arbetar i {sektor} och tjänar <b>{displayMonthlySalary}</b> kronor i månaden får <b>{salaryWithoutAgreement}</b> kronor om du tar ut full föräldrapenning från Försäkringskassan när du är föräldraledig.</p>
        <p>Med kollektivavtalat föräldrapenningtillägg får du dessutom <b>{parentalSupplement}</b> kronor från din arbetsgivare. Föräldralönen blir extra viktig för dig som tjänar över Försäkringskassans lönetak på <b>{pbb.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</b> kronor.</p>
        <p>Det blir totalt <b>{salaryWithAgreement}</b> kronor, alltså ungefär 90 procent av lönen. Utan föräldrapenningtillägg hade du fått nöja dig med pengarna från Försäkringskassan, och det blir aldrig mer än <b>{salaryWithoutAgreement}</b> kronor, oavsett hur mycket du tjänar.</p>
    </div>
    )

    return text;
}

export function getSickText(monthlySalary,displayMonthlySalary,salaryWithAgreement,salaryWithoutAgreement,selectableSector) {
    let text;
    let kollektivavtal;
    let sektor;
    let ersattning = ((salaryWithAgreement.replace(/\s/g, '')) - (salaryWithoutAgreement.replace(/\s/g, '')))
    .toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');


    switch (selectableSector) {
        case 'Statlig':
            kollektivavtal = "(PSA)"
            sektor = "statlig sektor";
            break;
        case 'Kommunal':
            kollektivavtal = "(TFA-KL)"
            sektor = "kommunal- eller regional sektor";
            break;
        case 'Privat':
            kollektivavtal = "(TFA)"
            sektor = "privat sektor";
            break;
    }
    return (
    <div>
        <br />
        <p> Du som arbetar i {sektor} och tjänar <b>{displayMonthlySalary}</b> kronor i månaden får <b>{salaryWithoutAgreement}</b> kronor i sjukpenning från Försäkringskassan om du är sjuk i mer än 14 dagar.</p>
        <p>Med din kollektivavtal får du dessutom <b>{ersattning}</b> kronor i sjuklön från din arbetsgivare.</p>
        <p>Det blir totalt <b>{salaryWithAgreement}</b> kronor. Med kollektivavtal får du alltid ungefär 90 procent av din lön när du är sjuk, oavsett lön. Ju mer du tjänar, desto viktigare blir alltså det kollektivavtalade sjukpenningtillägget.</p>
        <p>Utan arbetsskadeförsäkring hade du fått nöja dig med pengarna från Försäkringskassan.{monthlySalary > 27500 && (
    <span style={{ color: 'black' }}> Det blir aldrig mer än <b>{salaryWithoutAgreement}</b> kronor, oavsett hur mycket du tjänar.</span>)}
</p>

    </div>
    );
        
}



