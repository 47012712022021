import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './InstructionsVideo.module.css';


const InstructionsVideo = ({ level }) => {

  const [videoError, setVideoError] = useState(false);
  const video = `${process.env.PUBLIC_URL}/instructions_video/instructionsVideo.mp4`;
  const videoAdvanced = `${process.env.PUBLIC_URL}/instructions_video/instructionsVideoAdvanced.mp4`;

  return (
    <>
      {level == 'enkel' &&
        <div className={styles.instructionsVideo}>
          <video preload="none" width="100%" autoplay="autoplay" controls="controls">
            <track src="" kind="captions" srcLang="sv" label="" />
            <source
              src={video}
              type="video/mp4"
              onError={() => setVideoError(true)}
            />
            <ul className="generalList">
              <li>Video stöds inte av din webbläsare</li>
              <li>Your browser does not support video.</li>
            </ul>
          </video>
        </div>
      }
      {level == 'avancerad' &&
        <div className={styles.instructionsVideo}>
          <video preload="none" width="100%" controls="controls" autoplay="autoplay">
            <track src="" kind="captions" srcLang="sv" label="" />
            <source
              src={videoAdvanced}
              type="video/mp4"
              onError={() => setVideoError(true)}
            />
            <ul className="generalList">
              <li>Video stöds inte av din webbläsare</li>
              <li>Your browser does not support video.</li>
            </ul>
          </video>
        </div>
      }
    </>
  );
};

export default InstructionsVideo;
