import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioButton.module.css';

const RadioButton = ({ disabled, value, name, onChange, checked }) => {
  return (
    <div className={styles.container}>
      <input
        disabled={disabled}
        id={value}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
        checked={checked}
      />
      <span className={styles.checkmarkWrapper}>
        <span className={styles.customCheckmark} />
      </span>
    </div>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default RadioButton;
