import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import MarkdownEditor from 'atoms/markdowneditor/MarkdownEditor';
import styles from './FilterExplanationEdit.module.css';

const FilterExplanationEdit = ({ text, setEditMode, setFilterTexts }) => {
  const [hideEditor, setHideEditor] = useState(false);
  const [filterText, setFilterText] = useState(text.value);

  const { user } = useAuth();
  const isDefault = user.forbund === 'SA';

  const performSave = () => {
    const request = {
      ...((isDefault || text.unionId === user.unionId) && { id: text.id }),
      header: text.header,
      textId: text.textId,
      groupId: text.groupId,
      value: filterText,
      isDefault,
      unionId: isDefault ? null : user.unionId,
    };

    ApiService.postText(request, user.token).then(data => {
      setFilterTexts(prevState => {
        const index = prevState.findIndex(i => i.textId === data.textId);
        const isDuplicate = prevState[index].id === data.id;
        return [
          ...prevState.slice(0, index),
          data,
          ...prevState.slice(isDuplicate ? index + 1 : index),
        ];
      });
    });

    setEditMode(false);
  };

  const performDelete = () => {
    setHideEditor(true);
    ApiService.deleteText(text.id, user.token).then(() =>
      setFilterTexts(prevState => prevState.filter(txt => txt.id !== text.id))
    );
    setEditMode(prevState => !prevState);
  };

  const performReset = () => {
    setFilterText(text.value);

    setEditMode(prevState => !prevState);
  };

  return (
    <div className={styles.optionsList}>
      {!hideEditor && (
        <MarkdownEditor
          value={filterText}
          onChange={value => {
            setFilterText(value);
          }}
        />
      )}
      <ButtonBar>
        {(isDefault || text.unionId === user.unionId) && (
          <Button
            className={styles.left}
            label={`Radera ${text.unionId ? 'förbundsspecifik' : 'delad text'}`}
            onClick={performDelete}
            variant="delete"
            icon="Trash"
          />
        )}
        <Button
          variant="primary"
          onClick={() => performSave()}
          label="Spara"
          altText="Spara filtertext"
        />
        <Button
          variant="secondary"
          style={{ marginLeft: '10px' }}
          onClick={() => performReset()}
          label="Avbryt"
        />
      </ButtonBar>
    </div>
  );
};

FilterExplanationEdit.propTypes = {
  text: PropTypes.shape({
    id: PropTypes.string,
    groupId: PropTypes.string,
    header: PropTypes.string,
    textId: PropTypes.string,
    value: PropTypes.string,
    unionId: PropTypes.string,
    isDefault: PropTypes.bool,
  }).isRequired,
  setEditMode: PropTypes.func.isRequired,
  setFilterTexts: PropTypes.func.isRequired,
};

export default FilterExplanationEdit;
