import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Loader from 'atoms/loader/Loader';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import Select from 'atoms/select/Select';
import Const from 'const';
import { getAvailableYears, months, today } from 'lib/time';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import styles from './ApplicationLog.module.css';
import LogItem from './logitem/LogItem';

const ApplicationLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const logsPerPage = 50;
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [monthFrom, setMonthFrom] = useState(today.getMonth() + 1);
  const [monthTo, setMonthTo] = useState(today.getMonth() + 1);
  const [yearFrom, setYearFrom] = useState(today.getFullYear());
  const [yearTo, setYearTo] = useState(today.getFullYear());
  const [logLevel, setLogLevel] = useState('');

  const [refresh, setRefresh] = useState(true);

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  const logLevels = [
    { key: 'all', value: '', desc: 'Alla' },
    { key: 'warning', value: 'Warning', desc: 'Varning' },
    { key: 'error', value: 'Error', desc: 'Fel' },
    { key: 'info', value: 'Information', desc: 'Information' },
  ];

  useEffect(() => {
    if (!refresh) return;
    setLoading(true);

    const request = {
      level: logLevel || null,
      yearFrom: yearFrom || null,
      yearTo: yearTo || null,
      monthFrom: monthFrom || null,
      monthTo: monthTo || null,
    };

    ApiService.getLogs(request, token)
      .then(data => {
        setCurrentPage(0);
        setLogs(data);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => {
        setLoading(false);
        setRefresh(false);
      });
    // eslint-disable-next-line
  }, [token, logLevel, yearFrom, yearTo, monthFrom, monthTo, refresh]);

  useEffect(() => {
    setPages(Math.ceil(logs.length / logsPerPage));
  }, [logs]);

  const filteredLogs = logs.slice(
    currentPage * logsPerPage,
    currentPage * logsPerPage + logsPerPage
  );

  return (
    <Panel>
      <Section title={Const.admin.applicationLog}>
        <div className={styles.logTools}>
          <div className={styles.filterItem}>
            <Select
              id="year_from"
              label="År"
              compact
              onChange={e => {
                setYearFrom(Number(e.target.value));
                setYearTo(Number(e.target.value));
                setRefresh(true);
              }}
              options={[
                { key: 'year_from_null', value: '', desc: 'Alla loggar' },
                ...getAvailableYears().map(year => ({
                  key: year,
                  value: year,
                  desc: year,
                })),
              ]}
              value={yearFrom}
            />
          </div>
          <div className={styles.filterItem}>
            <Select
              disabled={!(yearFrom && yearTo)}
              id="month_from"
              label="Månad"
              compact
              onChange={e => {
                setMonthFrom(Number(e.target.value));
                setMonthTo(Number(e.target.value));
                setRefresh(true);
              }}
              options={[
                { key: 'month_from_null', value: '', desc: 'Samtliga månader' },
                ...months.map((month, i) => ({
                  key: month,
                  value: i + 1,
                  desc: month,
                  disabled:
                    i > today.getMonth() && yearFrom >= today.getFullYear(),
                })),
              ]}
              value={yearFrom && yearTo ? monthFrom : ''}
            />
          </div>
          <div className={styles.filterItem}>
            <Select
              id="log_level"
              label="Loggnivå"
              compact
              onChange={e => {
                setLogLevel(e.target.value);
                setRefresh(true);
              }}
              options={logLevels.map(({ key, value, desc }) => ({
                key,
                value,
                desc,
              }))}
              value={logLevel}
            />
          </div>
          <div />
          <Button
            disabled={loading}
            variant="secondary"
            onClick={() => setRefresh(true)}
            label="Uppdatera"
            altText="Uppdatera logg"
            icon="Refresh"
          />
        </div>
      </Section>

      {loading ? (
        <div className={styles.loaderAnimation}>
          <Loader variant="primary" />
        </div>
      ) : (
        <div className={styles.logPanel}>
          {filteredLogs.length > 0 ? (
            filteredLogs.map(log => <LogItem log={log} key={log.id} />)
          ) : (
            <span className={styles.errorText}>
              Hittade inga loggar, prova att justera filtret
            </span>
          )}
        </div>
      )}

      {pages > 0 && (
        <ButtonBar prevNext>
          <Button
            disabled={currentPage === 0}
            variant="primary"
            icon="Previous"
            style={{ marginLeft: 'auto' }}
            onClick={() => setCurrentPage(prevState => prevState - 1)}
            label="Föregående sida"
          />
          <span className={styles.pageIndicator}>
            {`${currentPage + 1} / ${pages}`}
          </span>
          <Button
            disabled={currentPage === pages - 1}
            variant="primary"
            icon="Next"
            flipIcon
            style={{ marginLeft: 'auto' }}
            onClick={() => setCurrentPage(prevState => prevState + 1)}
            label="Nästa sida"
          />
        </ButtonBar>
      )}
    </Panel>
  );
};

export default ApplicationLog;
