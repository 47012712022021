import Button from 'atoms/button/Button';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconChevronDown, IconChevronUp } from 'tabler-icons';
import styles from './LogItem.module.css';

const LogItem = ({ log }) => {
  const { message, exception, properties } = log;
  const timeStamp = `[${format(
    new Date(log.timeStamp),
    'yyyy-MM-dd HH:mm.ss'
  )}]:`;

  const [isExpanded, setIsExpanded] = useState(false);
  const [copied, setCopied] = useState(false);

  const flipBackState = () =>
    setTimeout(() => {
      setCopied(false);
    }, 2000);

  // IE support
  const fallbackCopyLogMessage = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      setCopied(true);
    } catch (err) {
      // TODO: Handle errors?
    }

    document.body.removeChild(textArea);
    flipBackState();
  };

  // All other browsers
  const copyLogMessage = e => {
    e.preventDefault();
    e.stopPropagation();

    const text = `${timeStamp || ''} ${message || ''} \n\n ${exception || ''} ${
      properties || ''
    }`;

    if (!navigator.clipboard) {
      fallbackCopyLogMessage(text);
      return;
    }

    navigator.clipboard
      .writeText(text)
      .then(() => setCopied(true))
      .catch(err => {
        // TODO: Handle errors?
      })
      .finally(() => flipBackState());
  };

  return (
    <div
      className={`${styles.logItem} ${styles[log.level]}`}
      role="button"
      onClick={() => setIsExpanded(prevState => !prevState)}
      onKeyUp={() => null}
      tabIndex={0}
    >
      <div className={styles.logRow}>
        <span className={styles.logChevron}>
          {isExpanded ? <IconChevronDown /> : <IconChevronUp />}
        </span>
        <span>
          {timeStamp}
          {message}
        </span>
      </div>
      {isExpanded && (
        <>
          <div className={styles.extraInfo}>
            <div className={styles.extraContent}>
              <p>{exception}</p>
              <p>{properties}</p>
            </div>
          </div>
          <div className={styles.buttonRow}>
            <Button
              variant="ghost"
              onClick={e => copyLogMessage(e)}
              icon="Copy"
              label={copied ? 'Meddelande kopierat' : 'Kopiera'}
              altText="Kopiera loggmeddelande"
            />
          </div>
        </>
      )}
    </div>
  );
};

LogItem.propTypes = {
  log: PropTypes.shape({
    level: PropTypes.string,
    timeStamp: PropTypes.string,
    message: PropTypes.string,
    exception: PropTypes.string,
    properties: PropTypes.string,
  }).isRequired,
};

export default LogItem;
