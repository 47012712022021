import Breadcrumbs from 'atoms/breadcrumbs/Breadcrumbs';
import React from 'react';
import { IconLink } from 'tabler-icons';
import FlowchartSymbol from '../../testdata/flowchart_token-authentication.svg';
import styles from './PageDeveloper.module.css';

const PageDeveloper = () => {
  return (
    <section className={styles.main}>
      <div className={styles.innerContainerSlim}>
        <div className={styles.topNav}>
          <Breadcrumbs />
        </div>
        <div className={styles.developerContainer}>
          <h1>För utvecklare</h1>
          <p className={styles.preamble}>
            Denna information riktar sig till dig som är utvecklare och som ska
            autentisera en webbtjänst mot Saco Lönesök.
          </p>
          <p>För autentisering krävs:</p>
          <ul className="generalList">
            <li>Kontouppgifter (förbundsspecifika)</li>
            <ul>
              <li>Medlem</li>
              <li>Förtroendevald</li>
            </ul>
            <li>
              En implementation på den tjänst som ska autentisera sig mot Saco
              lönesök. Se exempel nedan.
            </li>
          </ul>
          <div className={styles.box.attentionBox}>
            <p>
              För att erhålla kontouppgifter måste du representera ett
              medlemsförbund och kontakta Saco på &nbsp;
              <a className="generalLink" href="mailto:lonesok@saco.se">
                lonesok@saco.se
              </a>
            </p>
          </div>
          <div />
          <h2>Autentisering</h2>
          <p>
            Autentisering bygger på användarkonton där varje konto representerar
            ett förbund och en roll (medlem eller förtroendevald). För att
            autentisera sig måste den anropande tjänsten hämta ut en token åt
            användaren och haka på länken/anropet mot Saco lönesök.
          </p>
          <figure className={styles.flowchartSymbolContainer}>
            <img src={FlowchartSymbol} alt="Flödesschema illustration" />
          </figure>
          <p>
            För att hämta ut en token (Token A), anger tjänsten användarnamn och
            lösenord maskinellt, dvs det är inte den besökande användaren som
            fyller i uppgifterna utan systemet som ska skicka dem vidare till
            Saco lönesök. Denna token är kortlivad och hämtas ut inför att en
            användare ska skickas vidare till Saco lönesök.
          </p>
          <p>
            När användaren når Saco lönesök får de en ny token (Token B) som
            lever under deras session. Skulle en token komma på avvägar är den
            inte användbar särskilt länge och tjänsten är därmed fortfarande
            säkrad. Det räcker alltså inte längre med att skicka länken till en
            extern part (ej medlem), utan de måste också få tag på en token,
            vilket är svårt om kontouppgifterna aldrig är tillgängliga för
            besökaren.
          </p>
          <h2>Implementationskrav</h2>
          <p>
            För att autentiseringsförfarandet ska vara säkert måste den tjänst
            som hämtar ut tokens hantera kontouppgifterna säkert. Praxis är att
            hantera dessa precis som man gör med andra hemligheter, dvs där man
            normalt förvarar nycklar och annan konfiguration. Detta kan vara i
            miljövariabler, app-settings eller andra platser som tjänsten kan
            läsa ifrån men som inte är exponerat på internet.
          </p>
          <p>
            Olika webblösningar har olika sätt att hantera hemligheter, men de
            flesta stora ramverk och verktyg (.NET, Java Spring, Wordpress,
            Umbraco, EPiServer, Sitecore) har ett standardiserat
            tillvägagångssätt för hur man hanterar och kommer åt hemligheter.
          </p>
          <h2>Implementation</h2>
          <p>
            För att atutentisera sig mot Saco lönesök behöver man göra följande.
          </p>
          <h3>Steg 1 – Hämta en inloggningstoken</h3>
          <p>
            En inloggningstoken kan hämtas med hjälp av ett anrop mot Saco
            lönesök REST API. Ersätt värderna i anropet med
            autentiseringsuppgifter för ert förbunds ordinarie medlemskonto
            eller kontot för förtroendevalda/rådgivare som ger utökade
            analysmöjligheter.
          </p>
          <div className={styles.noticeBox}>
            <span>
              <IconLink aria-label="" role="img" />
            </span>
            <a href="https://lonesok.saco.se:8080/api/token/login">
              https://lonesok.saco.se:8080/api/token/login
            </a>
            <p>
              <strong>Metod: POST</strong>
            </p>
            <p>
              <strong>Headers: Content-Type: application/json</strong>
            </p>
            <p>
              <strong>Accept: text/plain</strong>
            </p>
          </div>
          <p>
            API:et kan även hantera content-type header med värdet ”text/json”
            eller ”application/*+json” samt accept header med värdet
            ”application/json” eller ”text/json”.
          </p>
          <h4>Utseende på JSON-body i anrop</h4>
          <div className={styles.noticeBox}>
            <p className={styles.noMarginTopBottom}>{'{'}</p>
            <p className={styles.noMarginTopBottom}>
              &quot;subscriberId&quot;:
              &quot;BC771628-768B-4986-9EF9-65ED60FD4BC1&quot;,
              &quot;password&quot;: &quot;Lösenord&quot;
            </p>
            <p className={styles.noMarginTopBottom}>{'}'}</p>
          </div>
          <h4>Utseende på JSON-svar från anrop</h4>
          <div className={styles.noticeBox}>
            <p className={styles.noMarginTopBottom}>{'{'}</p>
            <p className={styles.noMarginTopBottom}>
              &quot;token&quot;:
              &quot;eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJwT3JySS1PT2pVQ2R0b3RWUms5ZjVnIiwiZXhwIjoxNjEyMzM3NTI5LCJzdWIiOiJzVkIzaTBPSWVFQ1pvN2F3UTdfNm13In0.ic4relqmwaI4UcyEbXvZOsVlTJ5blMZ0A_w48RXCssgO-r7BSOxJkl0KltZZewX7V4PvH6pl2aa0XoUaSGRrIg&quot;
            </p>
            <p className={styles.noMarginTopBottom}>{'}'}</p>
          </div>
          <h3>
            Steg 2 – Kombinera inloggningstoken med webbadressen till Lönesök
          </h3>
          <p>
            Extrahera värdet från variabeln ”token” ur svaret på HTTP-anropet
            och haka på det i slutet av webbadressen till nya Saco lönesök.
          </p>
          <div className={styles.noticeBox}>
            <span>
              <IconLink aria-label="" role="img" />
            </span>
            <a href="https://lonesok.saco.se/token/">
              https://lonesok.saco.se/token/
            </a>
            <span>
              <strong>+ TOKEN</strong>
            </span>
            <p>
              Exempel:
              &quot;https://lonesok.saco.se/token/eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJwT3JySS1PT2pVQ2R0b3RWUms5ZjVnIiwiZXhwIjoxNjEyMzM3NTI5LCJzdWIiOiJzVkIzaTBPSWVFQ1pvN2F3UTdfNm13In0.ic4relqmwaI4UcyEbXvZOsVlTJ5blMZ0A_w48RXCssgO-r7BSOxJkl0KltZZewX7V4PvH6pl2aa0XoUaSGRrIg&quot;
            </p>
          </div>
          <p>
            Du får därmed en unik besöksadress per inloggad användare. Observera
            att länken måste ha en ny token för varje inloggning, och att en
            token är bara giltig i några minuter.
          </p>
          <h2>Felsökning</h2>
          <p>
            Eventuella fel kan uppstå både i anropande tjänst och i Saco
            lönesök.
          </p>
          <p>Kontrollera följande:</p>
          <ul className="generalList">
            <li>Kontrollera kontouppgiter</li>
            <li>Kontrollera URL för API-anropet</li>
            <li>Kontrollera JSON-svaret</li>
            <li>
              Om du inte hittar felet kontakta:&nbsp;
              <a className="generalLink" href="mailto:lonesok@saco.se">
                lonesok@saco.se
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PageDeveloper;
