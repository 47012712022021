/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Panel.module.css';

const Panel = ({children, className, legend, admin, card}) => {
  const extraClasses = className && className !== '' ? className : '';
  const hasFieldset = card && card !== '';
  return (
    <div className={styles.panel}>
      {hasFieldset ? (
        <fieldset className={`${styles.panelFieldset} ${extraClasses}`}>
          <legend className={styles.panelLegend}>{legend}</legend>
          {children}
        </fieldset>
      ) : (
        <div className={`${styles.regularPanel} ${extraClasses} ${admin ? styles.isAdmin : ''}`}>
          {children}
        </div>
      )}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  legend: PropTypes.string,
  card: PropTypes.bool,
  admin: PropTypes.bool
};

Panel.defaultProps = {
  className: '',
  legend: '',
  card: false,
  admin: false
};

export default Panel;
