import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonTransparent.module.css';

const ButtonTransparent = ({
  children,
  altText,
  className,
  disabled,
  onClick,
  style,
}) => {
  const buttonClassName = className && className !== '' ? className : '';
  return (
    <button
      className={`${styles.buttonTransparent} ${buttonClassName}`}
      disabled={disabled}
      onClick={onClick}
      style={style}
      alt={altText}
      type="button"
    >
      {children}
    </button>
  );
};

ButtonTransparent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  altText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

ButtonTransparent.defaultProps = {
  className: undefined,
  disabled: false,
  onClick: () => {},
  style: undefined,
};

export default ButtonTransparent;
