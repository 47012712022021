import React, { useState, useEffect, useRef } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation, Link } from 'react-router-dom';
import Input from 'atoms/input/Input';
import ButtonGroup from 'atoms/buttonGroup/ButtonGroup';
import { Child } from 'pym.js';
import styles from './Careerspin.module.css';
import Slider from './Slider/Slider';
import { getStateText, getParentText, getSickText } from 'lib/careerspin';

const Careerspin = () => {
  const location = useLocation();
  const [salaryWithoutAgreement, setSalaryWithoutAgreement] = useState('');
  const [salaryWithAgreement, setSalaryWithAgreement] = useState('');
  const [withAgreementValue, setWithAgreementValue] = useState('');
  const [monthlySalary, setMonthlySalary] = useState('20000');
  const [parentalSupplement, setParentalSupplement] = useState('0');
  const [displayMonthlySalary, setDisplayMonthlySalary] = useState('20 000');
  const [howWeCounted, setHowWeCounted] = useState('');
  const [selectableSector, setSelectableSector] = useState('Statlig');
  const [buttonColor, setButtonColor] = useState('#001c20');
  const [summationText, setSummationText] = useState('');
  const min = 0;
  const max = 100000;
  const step = 100;
  const BreakpointOne = 27800;
  const BreakpointTwo = 45000;
  const now = displayMonthlySalary;
  const now2 = monthlySalary;
  const selectableSectors = ['Statlig', 'Kommunal', 'Privat'];
  const searchParams = new URLSearchParams(location.search);
  const alternativ = searchParams.get('alt');
  //const pymParentRef = useRef(null); // Referens till komponentens root-element

  const pbb = 57300;
  const arslon = monthlySalary * 12;
  const tak = pbb * 10;
  const calcSjukpenning = ((Math.min(arslon, tak) * 0.776) / 365) * 30;
  const sjukpenning = Math.round(calcSjukpenning / 100) * 100;


  const changeSector = id => {
    setSelectableSector(selectableSectors[id]);
  };

  const openTextFile = async () => {
    try {
      const response = await fetch(`/texter/${alternativ}/helptext.txt`);
      const textContent = await response.text();

      const blob = new Blob([textContent], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);

      const newWindow = window.open(url, '_blank', 'width=600,height=400');
      newWindow.focus();
    } catch (error) {
      console.error('Error fetching or opening the text file:', error);
    }
  };

  const calculateParent = () => {
    const Calcforaldrapenning = ((Math.min(arslon, tak) / 365)) * 0.776 * 30;
    const foraldrapenning = Math.round(Calcforaldrapenning / 100) * 100;

    let foraldralon = 0;

    switch (selectableSector) {
      case 'Privat':
        if (arslon > tak) {
          console.log( "Privat - lön som överskrider brytpunkten för föräldrapenning");
          console.log(" monthlySalary - (pbb * 10 / 365 * 0,9 * 30) - ((monthlySalary * 12 - pbb * 10) / 365 * 0.1 * 30)");
          foraldralon = monthlySalary - (pbb * 10 / 365 * 0.9 * 30) - (monthlySalary * 12 - pbb * 10) / 365 * 0.1 * 30; 
        } else {
          console.log("Privat - lön som underskrider brytpunkten för föräldrapenning");
          console.log("monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30)");
          foraldralon = monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30);
        }
        break;
      case 'Kommunal':
        if (arslon > tak) {
          console.log( "Kommunal -  lön som överskrider brytpunkten för föräldrapenning");
          console.log("(monthlySalary / 30 * 0.1 * 30) + ((monthlySalary / 30 * 0.776 - pbb * 10 / 365 * 0.776) * 30)");
          foraldralon = (monthlySalary / 30 * 0.1 * 30) + ((monthlySalary / 30 * 0.776 - pbb * 10 / 365 * 0.776) * 30);
        } else {
          console.log("Kommunal - lön som underskrider brytpunkten för föräldrapenning");
          console.log("monthlySalary / 30 * 0.1 * 30");
          foraldralon = monthlySalary / 30 * 0.1 * 30;
        }
        break;
      case 'Statlig':
        if (arslon > tak) {
          console.log( "Statlig - lön som överskrider brytpunkten för föräldrapenning");
          console.log("(pbb * 0.0274 * 0.1 * 30) + ((monthlySalary * 0.033 - pbb * 0.0274) * 0.9 * 30)");
          foraldralon = (pbb * 0.0274 * 0.1 * 30) + ((monthlySalary * 0.033 - pbb * 0.0274) * 0.9 * 30);
        } else {
          console.log("Statlig - lön som underskrider brytpunkten för föräldrapenning");
          console.log("monthlySalary * 0.033 * 0.1 * 30");
          foraldralon = monthlySalary * 0.033 * 0.1 * 30;
        }
        break;
    }

    const roundedAddition = Math.round(foraldralon / 100) * 100;
    const displayAddition = displaySalary(Math.floor(roundedAddition).toString());
    setParentalSupplement(displayAddition);

    const displaySalaryWithoutAgreement = displaySalary(foraldrapenning.toString());
    setSalaryWithoutAgreement(displaySalaryWithoutAgreement);

    const calculateSalaryWithAgreement = foraldralon + foraldrapenning;
    const roundedNr = Math.round(calculateSalaryWithAgreement / 100) * 100;
    const displaySalaryWithAgreement = displaySalary(roundedNr.toString());
    setSalaryWithAgreement(displaySalaryWithAgreement);
  };

  const calculateSick = () => {
    /*let calcSjukpenning = ((Math.min(arslon, tak) * 0.776) / 365) * 30;
    let sjukpenning = Math.round(calcSjukpenning / 100) * 100;*/
        
    let sjuklon = 0;

    // eslint-disable-next-line default-case
    switch (selectableSector) {
      case 'Privat':
        if (arslon > tak) {
          console.log("Privat - över tak");
          console.log(" monthlySalary - (pbb * 10 / 365 * 0.9 * 30) - (((monthlySalary * 12) - (pbb * 10)) / 365 * 0,1 * 30)");
          sjuklon = monthlySalary - (pbb * 10 / 365 * 0.9 * 30) - (((monthlySalary * 12) - (pbb * 10)) / 365 * 0.1 * 30);
        } else {
          console.log("Privat - under tak");
          console.log("monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30)");
          sjuklon = monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30);
        }
        break;
      case 'Kommunal':
        if (arslon > tak) {
          console.log("Kommunal - över tak");
          console.log("(monthlySalary / 30 * 0.1 * 30) + ((monthlySalary / 30 * 0.776 - pbb * 10 / 365 * 0.776) * 30)");
          sjuklon = (monthlySalary / 30 * 0.1 * 30) + ((monthlySalary / 30 * 0.776 - pbb * 10 / 365 * 0.776) * 30);
        } else {
          console.log("Kommunal - under tak");
          console.log("monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30)");
          sjuklon = monthlySalary / 30 * 0.1 * 30;
        }
        break;
      case 'Statlig':
        if (arslon > tak) {
          console.log("Statlig - över tak");
          console.log("pbb * 0.0274 * 0.1 * 30 + (monthlySalary * 0.033 - pbb * 0.0274) * 0.876 * 30");
          sjuklon = pbb * 0.0274 * 0.1 * 30 + ((monthlySalary * 0.033 - pbb * 0.0274) * 0.876 * 30);
        } else {
          console.log("Statlig - under tak");
          console.log("monthlySalary - (monthlySalary * 12 / 365 * 0.9 * 30)");
          sjuklon = monthlySalary * 0.033 * 0.1 * 30;
        }

        break;
    }

    const roundedNr = Math.round((sjukpenning + sjuklon) / 100) * 100;
    const displaySalaryWithAgreement = displaySalary(roundedNr.toString());
    setSalaryWithAgreement(displaySalaryWithAgreement);

    const displaySalaryWithoutAgreement = displaySalary(sjukpenning.toString());
    setSalaryWithoutAgreement(displaySalaryWithoutAgreement);
  };

  const calculateWorkInjury = () => {    

    const calculateSalaryWithAgreement = (Math.round(monthlySalary / 100) * 100).toFixed(0);

    const displaySalaryWithAgreement = displaySalary(calculateSalaryWithAgreement);
    setSalaryWithAgreement(displaySalaryWithAgreement);

    let calculateSalaryWithoutAgreement = Math.round(sjukpenning / 100) * 100;

    if (calculateSalaryWithoutAgreement > 36500) {calculateSalaryWithoutAgreement = 36500;}

    const displaySalaryWithoutAgreement = displaySalary(calculateSalaryWithoutAgreement.toFixed(0));
    setSalaryWithoutAgreement(displaySalaryWithoutAgreement);
  };

  const displaySalary = value => {
    return value.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const calculateCompensation = alt => {
    switch (alt) {
      case (alt = 'foralder'):
        calculateParent();
        break;
      case (alt = 'arbetsskada'):
        calculateWorkInjury();
        break;
      case (alt = 'sjuk'):
        calculateSick();
        break;
    }
  };

  const setBackgroundColor = alt => {
    const root = document.documentElement;
    let textcolor = '#000000';
    let color = '';
    switch (alt) {
      case (alt = 'foralder'):
        color = '#008ea1';
        textcolor = '#ffffff';
        break;
      case (alt = 'arbetsskada'):
        color = '#33a5b4';
        break;
      case (alt = 'sjuk'):
        color = '#80c7d0';
        break;
    }
    root.style.setProperty('--color-background', color);
    root.style.setProperty('--color-text', textcolor);
    setButtonColor(color);
  };

  // This page is included as an iframe
  useEffect(() => {
    const pymChild = new Child({ polling: 1000, sendHeight: true });
    pymChild.sendHeight();

    const intervalId = setInterval(() => {
      pymChild.sendHeight();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setBackgroundColor(alternativ);
    const fetchData = async () => {
      try {
        const response = await fetch(`/texter/${alternativ}/undertext.txt`);
        const text = await response.text();

        let newText = text.replace(/{monthlySalary}/g, monthlySalary);
        newText = newText.replace(/{sicknessBenefit}/g, 'sicknessBenefit');
        newText = newText.replace(
          /{salaryWithAgreement}/g,
          salaryWithAgreement
        );
        // Log the text from the file
        setSummationText(newText); // Set the text in the state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    calculateCompensation(alternativ);
  }, [monthlySalary, selectableSector]);

  const handleSalaryChange = e => {
    const strNumber = e.target.value
      .replace(/[^0-9]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    setDisplayMonthlySalary(strNumber);
    setMonthlySalary(Number(strNumber.replace(/ /g, '')));
  };

  const handleSliderChange = value => {
    const strNumber = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    updateSalaryValues(strNumber);
  };

  const updateSalaryValues = formattedNumber => {
    setDisplayMonthlySalary(formattedNumber);
    setMonthlySalary(Number(formattedNumber.replace(/ /g, '')));
  };

  return (
    <div className={styles.CareerspinContainer}>
      <div className={styles.selectContainer}>
        <span>Välj den sektor du arbetar inom</span>
        <ButtonGroup
          buttons={selectableSectors}
          doSomethingAfterClick={changeSector}
          color={buttonColor}
          initialSelectedIndex={0}
          whiteText={false}
        />
      </div>
      <div className={styles.transparentArrow}>
        <div></div>
        <div></div>
      </div>
      <div className={styles.spinContainer}>
        <p>Se vad du får</p>
        <div className={styles.salary_module_container}>
          <div>
            <h3>Din månadslön</h3>
          </div>
          <Input
            className={styles.Input}
            id="displayMonthlySalary"
            max={max}
            min={min}
            onChange={handleSalaryChange}
            step={step}
            type="string"
            value={now}
            displayInline
          />
          <Slider
            changeFunction={handleSliderChange}
            id="salarySlider"
            max={max}
            min={min}
            step={step}
            value={now2}
            type="string"
          />
        </div>
        <div>
          <p>Med kollektivavtal</p>
          <span>{salaryWithAgreement}</span>
        </div>
        <div>
          <p>Utan kollektivavtal</p>
          <span>{salaryWithoutAgreement}</span>
        </div>
      </div>
      <div className={styles.transparentArrowBottom}>
        <div></div>
        <div></div>
      </div>

      <div className={styles.textContent}>
        <h3>Det här får du</h3>
        <br />
        {alternativ === 'arbetsskada' && (
          <div>
            {getStateText(
              monthlySalary,
              displayMonthlySalary,
              salaryWithoutAgreement,
              selectableSector
            )}
          </div>
        )}
        {alternativ === 'foralder' && (
          <div>
            {getParentText(
              monthlySalary,
              displayMonthlySalary,
              salaryWithoutAgreement,
              salaryWithAgreement,
              selectableSector,
              parentalSupplement,
              pbb, 
            )}
          </div>
        )}
        {alternativ === 'sjuk' && (
          <div>
            {getSickText(
              monthlySalary,
              displayMonthlySalary,
              salaryWithAgreement,
              salaryWithoutAgreement,
              selectableSector
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Careerspin;
