export const Colors = {
  black: '#000000', // Saco svart
  darkBlue: '#383F82', // Saco mörkblå
  lightGrey: '#C7C6C6', // Saco ljusgrå
  grey: '#878786', // Saco grå
  darkGrey: '#575656', // Saco mörkgrå
  orange: '#E27648', // Saco orange
  pink: '#C13D8C', // Saco mangenta
  turquoise: '#008EA1', // Saco turkos - primär
  yellow: '#FFCC33', // Saco gul
  lightGreen: '#90EE90', // Grön
};
