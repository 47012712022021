import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import { useAuth } from 'services/UseAuth';
import useVisible from 'hooks/useVisible';
import styles from './Menu.module.css';

const Menu = () => {
  const { ref, isVisible: showMenu, setIsVisible: setShowMenu } = useVisible(
    false
  );

  const { user } = useAuth();
  const history = useHistory();

  const goToPage = page => {
    setShowMenu(false);
    history.push(page);
  };

  const logout = () => {
    setShowMenu(false);
    history.push('/logga-ut');
  };

  return (
    <div ref={ref} className={styles.menuButtonContainer}>
      {user && (
        <>
          <ButtonLink
            label="Meny"
            className={styles.menuButton}
            icon="ChevronDown"
            large
            onClick={() => setShowMenu(!showMenu)}
          />

          {showMenu && (
            <div className={styles.menuOptions}>
              <ButtonLink
                label="Enkel sökning"
                className={styles.optionBtn}
                menuItem
                onClick={() => goToPage('/enkel')}
              />
              <ButtonLink
                label="Avancerad sökning"
                className={styles.optionBtn}
                menuItem
                onClick={() => goToPage('/avancerad')}
              />

              <ButtonLink
                label="Om Saco lönesök"
                className={styles.optionBtn}
                menuItem
                onClick={() => goToPage('/#om-saco-lonesok')}
              />
              <ButtonLink
                label="Teknisk info och GDPR"
                className={styles.optionBtn}
                menuItem
                onClick={() => goToPage('/#teknisk-info')}
              />
              {(user.role === 'admin' || user.role === 'redaktor') && (
                <ButtonLink
                  label="Inställningar"
                  className={styles.optionBtn}
                  menuItem
                  onClick={() => goToPage('/admin/nivå2')}
                />
              )}
              <ButtonLink
                label="Logga ut"
                className={styles.optionBtn}
                menuItem
                lastChild
                onClick={() => logout()}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Menu;
