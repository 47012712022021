/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputReadOnly.module.css';

const InputReadOnly = ({label, text, className, useHyphens}) => {
  const extraClasses = className && className !== '' ? className : '';
  
  return (
    <div className={`${styles.inputReadOnly} ${extraClasses} ${useHyphens ? styles.useHyphens : ''}`}>
      <div className={styles.inputLabel}>{label}:</div>
      <div className={styles.textField}>{text}</div>
    </div>
  );
};

InputReadOnly.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  useHyphens: PropTypes.bool
};

InputReadOnly.defaultProps = {
  className: '',
  useHyphens: false
};

export default InputReadOnly;
