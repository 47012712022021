import React from 'react';
import PropTypes from 'prop-types';
import { IconFilter } from 'tabler-icons';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import styles from './Filters.module.css';
import FilterList from './filterlist/FilterList';

const Filters = ({
  filterList,
  activeFilters,
  setActiveFilters,
  disableMenuselection,
}) => {
  return (
    <div className={styles.userFilters}>
      <div className={styles.filterHeading}>
        <div>
          <h2>
            <IconFilter
              className={styles.indicatorSymbol}
              role="img"
              aria-label=""
            />
            Filter
          </h2>
        </div>
        <div>
          <ButtonLink
            label="Rensa"
            altText="Rensa valda filter"
            className={styles.resetFiltersBtn}
            disabled={activeFilters.length === 0}
            onClick={() => setActiveFilters([])}
          />
        </div>
      </div>
      <FilterList
        filterList={filterList}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        disableMenuselection={disableMenuselection}
      />
    </div>
  );
};

Filters.propTypes = {
  filterList: PropTypes.arrayOf(Object).isRequired,
  activeFilters: PropTypes.arrayOf(Object).isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
};

export default Filters;
