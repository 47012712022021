import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from '../../../../atoms/select/Select';
import { useAuth } from '../../../../services/UseAuth';
import ApiService from '../../../../services/ApiService';
import ButtonBar from '../../../../atoms/buttonbar/ButtonBar';
import Button from '../../../../atoms/button/Button';
import styles from './AddNotification.module.css';
import Panel from '../../../../atoms/panel/Panel';
import Textarea from '../../../../atoms/textarea/Textarea';

const AddNotification = ({ createCb }) => {
  const [availableSources, setAvailableSources] = useState([]);

  const history = useHistory();
  const { user, signout, autoRefreshToken } = useAuth();
  const { token } = user;

  const [niva, setNiva] = useState();
  const [dsl1, setDsl1] = useState();
  const [dsl2, setDsl2] = useState();
  const [year, setYear] = useState();
  const [text, setText] = useState('');

  useEffect(() => {
    const request = {
      forbund: [user.forbund],
    };
    ApiService.getActiveFds(request, token)
      .then(data => {
        return data.reduce(
          (acc, { DSL1, DSL1_DESC, DSL2, DSL2_DESC, YEAR }) => ({
            dsl1: {
              ...acc.dsl1,
              [DSL1]: {
                ...acc.dsl1?.[DSL1],
                key: `dsl1_${DSL1}`,
                value: DSL1,
                desc: DSL1_DESC,
                dsl2: {
                  ...acc.dsl1?.[DSL1]?.dsl2,
                  [DSL2]: {
                    ...acc.dsl1?.[DSL1]?.dsl2?.[DSL2],
                    key: `dsl2_${DSL2}`,
                    value: DSL2,
                    desc: DSL2_DESC,
                  },
                },
              },
            },
            year: {
              ...acc.year,
              [YEAR]: {
                key: `year_${YEAR}`,
                value: YEAR,
                desc: YEAR,
              },
            },
          })
        );
      })
      .then(data => {
        setAvailableSources(data);
      })
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      });

    // eslint-disable-next-line
  }, [token]);

  const parseForSelect = object => {
    // Takes objects and returns all key, value and desc
    return Object.values(object).map(o => ({
      key: o.key,
      value: o.value,
      desc: o.desc,
    }));
  };

  const clear = () => {
    setNiva('');
    setDsl1('');
    setDsl2('');
    setYear('');
    setText('');
  };

  const handleText = e => {
    // Return if user presses the enter key
    if (e.nativeEvent.inputType === 'insertLineBreak') return;

    setText(e.target.value);
  };

  const removeEmpty = object => {
    const entries = Object.entries(object).filter(([k, v]) => !!v);
    return Object.fromEntries(entries);
  };

  const handleSubmit = e => {
    e.preventDefault();
    createCb(
      user.forbund === 'SA' ? '' : user.unionId,
      JSON.stringify(removeEmpty({ niva, dsl1, dsl2, year })),
      text
    );
    clear();
  };

  return (
    <Panel card legend="Lägg till notis">
      <form onSubmit={handleSubmit}>
        <Select
          label="Vy"
          id="niva"
          onChange={e => setNiva(e.target.value)}
          options={[
            { key: 'niva_null', value: '', desc: 'Samtliga vyer' },
            { key: 'simple', value: 'simple', desc: 'Enkel' },
            { key: 'advanced', value: 'advanced', desc: 'Avancerad' },
          ]}
          value={niva}
          marginBottom
        />
        {availableSources.dsl1 && (
          <Select
            label="Insamlingskälla"
            id="dsl1"
            onChange={e => setDsl1(e.target.value)}
            options={[
              { key: 'dsl1_null', value: '', desc: 'Samtliga källor' },
              ...parseForSelect(availableSources.dsl1),
            ]}
            value={dsl1}
            marginBottom
          />
        )}
        {availableSources.dsl1?.[dsl1]?.dsl2 && (
          <Select
            label="Alternativ"
            id="dsl2"
            onChange={e => setDsl2(e.target.value)}
            options={[
              { key: 'dsl2_null', value: '', desc: 'Samtliga lönetyper' },
              ...parseForSelect(availableSources.dsl1[dsl1].dsl2),
            ]}
            value={dsl2}
            marginBottom
          />
        )}
        {availableSources.year && (
          <Select
            label="År"
            id="year"
            onChange={e => setYear(e.target.value)}
            options={[
              { key: 'year_null', value: '', desc: 'Samtliga år' },
              ...parseForSelect(availableSources.year),
            ]}
            value={year}
            marginBottom
          />
        )}
        <Textarea
          className={styles.textInput}
          onChange={handleText}
          value={text}
          label="Meddelande"
          placeholder="..."
          id="notification-input"
        />
        <ButtonBar>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            icon="Plus"
            altText="Skapa notis"
            label="Skapa"
            disabled={!text}
          />
        </ButtonBar>
      </form>
    </Panel>
  );
};

AddNotification.propTypes = {
  createCb: PropTypes.func.isRequired,
};

export default AddNotification;
