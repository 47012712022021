import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckboxAdmin.module.css';

const CheckboxAdmin = ({ id, label, checked, disabled, onChange }) => {
  return (
    <label
      htmlFor={id}
      className={`${styles.checkboxAdmin} ${disabled ? styles.disabled : ''}`}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
      {label !== null && <span className={styles.labelText}>{label}</span>}
    </label>
  );
};

CheckboxAdmin.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckboxAdmin.defaultProps = {
  checked: false,
  disabled: false,
  label: null,
};

export default CheckboxAdmin;
