import isEmpty from 'lib/emptyValues';
import { Colors } from '../assets/Colors';

export function barColorBasedOnPercentage(chart, salary, color) {
  if (chart.dataset.data.length > 0) {
    const indexSalary = chart.dataset.data[chart.dataIndex];
    return salary > indexSalary.Bin ? color : '#9c9b9b';
  }
  return color;
}

export function calculatePercentBelowSalary(salaryStat, givenSalary) {
  salaryStat.sort((a, b) => a.Bin - b.Bin);

  let percentBelow = 0;
  for (let i = 0; i < salaryStat.length; i += 1) {
    if (salaryStat[i].Bin <= givenSalary) {
      percentBelow += salaryStat[i].Pct;
    }
  }
  return percentBelow;
}

export function getSalaryMarkerValue(salaryStat, givenSalary) {
  salaryStat.sort((a, b) => a.Bin - b.Bin);

  let salaryMarkerValue = 0;
  for (let i = 0; i < salaryStat.length; i += 1) {
    if (salaryStat[i].Bin >= givenSalary) {
      salaryMarkerValue = salaryStat[i].Bin;
      return salaryMarkerValue;
    }
  }

  return salaryMarkerValue;
}

export function getSalaryForFirstMarker(salaryStat) {
  salaryStat.sort((a, b) => a.Bin - b.Bin);
  const salaryMarkerValue = salaryStat[0].Bin;
  return salaryMarkerValue;
}

export function getSalaryMarkerIndex(salaryStat, givenSalary) {
  salaryStat.sort((a, b) => a.Bin - b.Bin);

  for (let i = 0; i < salaryStat.length; i += 1) {
    if (salaryStat[i].Bin >= givenSalary) {
      return i;
    }
  }

  return -1;
}

export function calculateYAdjust(barHeight) {
  const pixelsAbove = 5;
  return -barHeight - pixelsAbove;
}

export function mapData(salaryStat) {
  return salaryStat.map(salaryStatistic => {
    return { y: salaryStatistic.Pct, ...salaryStatistic };
  });
}

export function formatLabelNumberyAxes(value) {
  return `${value} %`;
}

export function formatLabelNumberxAxes(value) {
  if (typeof value === 'number') {
    return value.toLocaleString('sv');
  }
  return value;
}

export function getAnnotations(salaryMarkerValue, previewConfiguration) {
  if (!salaryMarkerValue) return {};

  const color = previewConfiguration
    ? previewConfiguration.textColor
    : Colors.pink;

  return {};
}

export function generateOptions(salaryStat, salary, previewConfiguration) {
  const options = {
    responsive: true,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    parsing: {
      xAxisKey: 'Pct',
      yAxisKey: 'Pct',
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#000000',
            autoSkip: true,
            maxRotation: 0,
            // maxTicksLimit: chartData.labels.length / 3,
            maxTicksLimit: 6.333333333333333,
            beginAtZero: true,
            callback: value => formatLabelNumberxAxes(value),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#000000',
            beginAtZero: true,
            callback: value => formatLabelNumberyAxes(value),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const { datasetIndex } = context;
          const index = context.dataIndex;

          // Hämta stapelhöjden genom att anropa funktionen
          const barHeight = context.chart.controller
            .getDatasetMeta(datasetIndex)
            .data[index].height();

          const yAdjustValue = calculateYAdjust(barHeight);

          return '';
        },
      },
    },
    annotation: getAnnotations(
      getSalaryMarkerValue(salaryStat, salary.actualNumberValue),
      previewConfiguration
    ),
    tooltips: {
      enabled: false, // Stäng av standard tooltips
      custom(tooltipModel) {
        let tooltipEl = document.getElementById('custom-tooltip');
        console.log('tooltipEl', tooltipEl);
        // Skapa element vid första renderingen
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'custom-tooltip';
          tooltipEl.classList.add('custom-tooltip');
          document.body.appendChild(tooltipEl);
        }
        // Lägg till HTML för anpassad tooltip

        if (
          tooltipModel &&
          tooltipModel.title &&
          tooltipModel.body &&
          tooltipModel.body[0] &&
          tooltipModel.body[0].lines
        ) {
          const stringValue = tooltipModel.title[0];
          const numberValue = parseInt(stringValue, 10); // Konvertera strängen till ett nummer
          const formattedNumber = numberValue.toLocaleString(); // Formatera numret med mellanrum
          const formattedString = formattedNumber.toString(); // Konvertera tillbaka till en sträng

          tooltipEl.innerHTML = `
            <div class="tooltip-body">${tooltipModel.body[0].lines[0]} % tjänar ${formattedString} kr.</div>
          `;

          const position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';

          tooltipEl.style.fontFamily = 'Arial, sans-serif'; // Set the font family
          tooltipEl.style.fontSize = '12px'; // Set the font size
          tooltipEl.style.fontStyle = 'normal'; // Set the font style
          tooltipEl.style.backgroundColor = '#424242'; // Set the background color
          tooltipEl.style.color = '#ffffff'; // Set the text color

          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.borderRadius = '8px'; // Set the border-radius for rounded corners
        } else {
          // Handle the case when tooltipModel, tooltipModel.title, or tooltipModel.body is undefined
        }
      },
    },
  };

  return options;
}

export function handleSelectionData(
  selectionData,
  setSelectedDataSource,
  setFirstSelection,
  setSecondSelection,
  setFirstSelectedOption,
  setSecondSelectedOption
) {
  if (selectionData[0]) {
    setSelectedDataSource(selectionData[0]);

    console.log('selectionData[0].selections:', selectionData[0].selections);

    // Process selections
    const selections = selectionData[0].selections
      .filter(selection => selection.isActive)
      .sort((a, b) => a.SELECTION_ORDER - b.SELECTION_ORDER)
      .reduce((acc, curr) => {
        if (acc[curr.sel_var] == null) {
          acc[curr.sel_var] = {
            options: curr.options
              .map(opt => {
                return {
                  ...opt,
                  sel_var: curr.sel_var,
                  SELECTION_DATATYPE: curr.SELECTION_DATATYPE,
                  name: curr.SELECTION_TEXT,
                  value: opt.value,
                };
              })
              .filter(opt => !isEmpty(opt.value))
              .filter(opt => opt.selectable)
              .sort((a, b) =>
                curr.sortOptionsByNumber
                  ? Number(a.value) - Number(b.value)
                  : a.description.localeCompare(b.description, 'sv')
              ),
            name: curr.SELECTION_TEXT,
            sel_var: curr.sel_var,
          };
        } else {
          acc[curr.sel_var].options.push(...curr.options);
        }
        return acc;
      }, {});

    const selectionObjects = Object.values(selections).map(obj => ({
      list: [...obj.options],
      name: obj.name,
      sel_var: obj.sel_var,
    }));

    const firstSelectionObject = selectionObjects[0];
    const secondSelectionObject = selectionObjects[1];
    const firstSelectedOption = selectionObjects[0].list[0];
    const secondSelectedOption = selectionObjects[1].list[0];

    // console.log('firstSelectionObject', firstSelectionObject);
    // console.log('secondSelectionObject', secondSelectionObject);
    // console.log('firstSelectedOption', firstSelectedOption);
    // console.log('secondSelectedOption', secondSelectedOption);

    setFirstSelection(firstSelectionObject);
    setSecondSelection(secondSelectionObject);
    setFirstSelectedOption(firstSelectedOption);
  }
}

export function mapStatsToChartData(
  selectedUnion,
  salaryStat,
  salary,
  setChartData
) {
  //console.log('7');

  const color = selectedUnion.previewConfiguration
    ? selectedUnion.previewConfiguration.background
    : '#008ea1';

  const labels = salaryStat.map(s => s.Bin);

  const datasets = [
    {
      datasetIdKey: 'Pct',
      label: '',
      backgroundColor: c =>
        barColorBasedOnPercentage(c, salary.actualNumberValue, color),
      data: mapData(salaryStat),
    },
  ];

  setChartData({ labels, datasets });
}

export function handleSalaryChange(e, setSalary) {
  const strNumber = e.target.value
    .replace(/[^0-9]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  setSalary({
    displayValue: strNumber,
    actualNumberValue: Number(strNumber.replace(/ /g, '')),
  });
}
