import React from 'react';
import styles from './Page404.module.css';

const Page404 = () => {
  return (
    <div className={styles.main}>
      <h3>Sidan du försöker besöka finns inte.</h3>
      <p> Kontrollera att url:en stämmer och ladda om sidan.</p>
    </div>
  );
};

export default Page404;
