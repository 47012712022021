import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DataSourceSummary.module.css';
import { useAuth } from '../../../../services/UseAuth';
import ApiService from '../../../../services/ApiService';

const DataSourceSummary = ({
  activeDataSource,
  availableDSL1,
  availableDSL2,
}) => {
  const { year, dsl1, dsl2, forbund } = activeDataSource;
  const [forbundName, setForbundName] = useState('samtliga Sacoförbund');
  const {
    user: { token },
  } = useAuth();

  useEffect(() => {
    ApiService.getUnion(token).then(res => setForbundName(res.name));
  }, []);

  const dslToString = (dsl, availableDsl) => {
    return availableDsl.find(item => item.dsl === dsl)?.desc;
  };

  return (
    <>
      <p className={styles.summary}>
        {`Du tittar på statistik från ${year} från ${
          forbund === 'SA' ? 'samtliga Sacoförbund' : forbundName
        }.`}
        {!!availableDSL1.length && (
          <span className={styles.print}>
            {` Statistiken baseras på ${dslToString(dsl1, availableDSL1)}`}
            {!!availableDSL2.length && ` - ${dslToString(dsl2, availableDSL2)}`}
            .
          </span>
        )}
      </p>
    </>
  );
};

DataSourceSummary.propTypes = {
  activeDataSource: PropTypes.shape({
    year: PropTypes.number,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
  }).isRequired,
  availableDSL1: PropTypes.arrayOf(Object),
  availableDSL2: PropTypes.arrayOf(Object),
};

DataSourceSummary.defaultProps = {
  availableDSL1: [],
  availableDSL2: [],
};

export default DataSourceSummary;
