// Returns array of numbers
// "1200 - 1299" => [1200, 1299]
// " > 200000" => [0, 200000]
// " < 5" => [0, 5]
export function numberOfRespondents(str) {
  if (!str || typeof str !== 'string') return [0, 0];

  const splitChars = ['-', '<', '>'];

  let result = [];

  splitChars.forEach(char => {
    const found = str.search(char);

    if (found > -1) {
      const numbers = str.split(char);
      if (numbers.length > 1) {
        result = [Number(numbers[0]), Number(numbers[1])];
      } else {
        result = [0, 0];
      }
    }
  });

  return result;
}

// Returns boolean based on number of respondents
// enoughRespondents("1200 - 1299", 30) => true
export function enoughRespondents(str, limit) {
  if (!str || typeof str !== 'string') return false;
  const numbers = str.split('-');
  return Number(numbers[0]) >= limit;
}

// Returns antal string formatted to Swedish locale
// "1200 - 1299" => "1 200 - 1 299"
export function formatRespondents(str) {
  if (!str || typeof str !== 'string') return '–';
  const numbers = str.split('-');
  if (numbers.length < 2) return str;
  return `${Number(numbers[0]).toLocaleString('sv')}–${Number(
    numbers[1]
  ).toLocaleString('sv')}`;
}

function roundToNearest(number, nearest, direction) {
  if (direction === 'up') return Math.ceil(number / nearest) * nearest;
  if (direction === 'down') return Math.floor(number / nearest) * nearest;
  return Math.round(number / nearest) * nearest;
}

// Contains hard coded numbers to round to, just to imitate the censoring of respondents
// that is going on inside of sas.

// Returns a censored range of respondents
// 12349 => "12 300 - 12 399"
export function censorReponsondants(number) {
  if (number < 1) return '';
  if (number < 5) return '< 5';
  if (number < 16) return '5–15';
  if (number < 30) return '16–29';
  if (number < 50) return '30–49';
  if (number < 100) return '50–99';
  if (number < 150) return '100–149';

  return `${roundToNearest(number - 1, 100, 'down').toLocaleString('sv')}–${(
    roundToNearest(number, 100, 'up') - 1
  ).toLocaleString('sv')}`;
}
