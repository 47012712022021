import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from '../VisitorStatistics.module.css';
import { Colors } from '../../../../assets/Colors';

const options = {
  title: {
    display: true,
    text: 'Antal besök per månad',
    fontSize: 16,
    fontColor: Colors.black,
  },
  responsive: true,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: 'black',
      formatter: value => {
        if (value === 0) {
          return '';
        } else {
          return value.toLocaleString();
        }
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          callback: value => value.toLocaleString().replace(',', ' '),
        },
        afterDataLimits(scale) {
          var range = scale.max - scale.min;
          var grace = range * 0.05;
          scale.max += grace;
        },
      },
    ],
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
};

const monthLabelKeys = {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December',
};

const MonthChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [currentYearMonthsCount, setCurrentYearMonthsCount] = useState(0);
  const [lastYearMonthsCount, setLastYearMonthsCount] = useState(0);
  const currentYear = new Date().getFullYear().toString();
  const lastYear = (currentYear - 1).toString();

  const mapStatsToChartData = (statsMap, lastYearMonths, currentYearMonths) => {
    const labels = [];

    const statsMapKeysArray = Array.from(statsMap.keys());
    for (let i = 0; i < statsMapKeysArray.length; i += 1) {
      if (i < lastYearMonths.length) {
        labels[i] = `${
          monthLabelKeys[statsMapKeysArray[i]]
        } ${lastYear.substring(0, 4)}`;
      } else {
        labels[i] = `${
          monthLabelKeys[statsMapKeysArray[i]]
        } ${currentYear.substring(0, 4)}`;
      }
    }

    const datasets = [
      {
        label: 'Medlem',
        data: Array.from(statsMap.values()).map(month => month.medlem),
        backgroundColor: Colors.turquoise,
        maxBarThickness: 70,
      },
      {
        label: 'Förtroendevald',
        data: Array.from(statsMap.values()).map(month => month.fortroendevald),
        backgroundColor: Colors.yellow,
        maxBarThickness: 70,
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      const currentMonth = new Date().getMonth() + 1;

      const entries = Object.entries(stats);
      entries.sort(([monthA], [monthB]) => Number(monthA) - Number(monthB));

      const currentYearMonths = entries.filter(
        ([month]) => Number(month) <= currentMonth
      );
      const lastYearMonths = entries.filter(
        ([month]) => Number(month) > currentMonth
      );

      setCurrentYearMonthsCount(currentYearMonths.length);
      setLastYearMonthsCount(lastYearMonths.length);
      const sortedEntries = [...lastYearMonths, ...currentYearMonths];
      const sortedStats = new Map(sortedEntries);
      mapStatsToChartData(sortedStats, lastYearMonths, currentYearMonths);
    }
  }, [stats]);

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  return (
    <div>
      {chartData && (
        <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
      {chartData && (
        <div className={styles.chartStats}>
          <div className={styles.tableToggle} onClick={toggleTable}>
            {isTableExpanded ? 'Dölj tabell' : 'Visa som tabell'}
          </div>
          {isTableExpanded && (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th
                    colSpan={lastYearMonthsCount}
                    text-align="center"
                    key={lastYear}
                  >
                    År {lastYear}
                  </th>
                  <th
                    colSpan={currentYearMonthsCount}
                    text-align="center"
                    key={currentYear}
                  >
                    År {currentYear}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  {chartData.labels.map((label, index) => (
                    <th key={index}>{label.substring(0, label.length - 4)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {chartData.datasets.map((dataset, index) => (
                  <tr key={dataset.label}>
                    <td>{dataset.label}</td>
                    {dataset.data.map((dataPoint, index) => (
                      <td key={index}>{dataPoint.toLocaleString()}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default MonthChart;

MonthChart.propTypes = {
  stats: PropTypes.any,
};
