import Input from 'atoms/input/Input';
import Panel from 'atoms/panel/Panel';
import Section from 'atoms/section/Section';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Const from 'const';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import FilterExplanationNoText from './filterexplanationnotext/FilterExplanationNoText';
import FilterExplanationItem from './filterexplanationitem/FilterExplanationItem';
import styles from './FilterExplanations.module.css';

const FilterExplanations = () => {
  const { user, signout, autoRefreshToken } = useAuth();
  const { token, forbund } = user;
  const history = useHistory();

  const [loadingText, setLoadingText] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [dataSources, setDataSources] = useState();
  const [filterTexts, setFilterTexts] = useState([]);
  const [filteredFilterTexts, setFilteredFilterTexts] = useState([]);

  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    const request = [
      {
        groupId: 'menuselection',
      },
    ];

    setLoadingText(true);
    ApiService.getTextsBoth(request, token)
      .then(data => setFilterTexts(data))
      .then(() => autoRefreshToken(token))
      .catch(error => {
        if (error.status === 401) {
          signout();
          history.push('/logga-ut');
        }
      })
      .finally(() => {
        setLoadingText(false);
      });

    const getDataSources = () => {
      const req = { forbund: [forbund] };
      setLoadingData(true);
      ApiService.getFds(req, token)
        .then(data => setDataSources(data))
        .finally(() => {
          setLoadingData(false);
        });
    };

    getDataSources();
    // eslint-disable-next-line
  }, [token, forbund]);

  useEffect(() => {
    if (!filterTexts.length) return;

    let temp = filterTexts;
    if (searchString.length < 3) {
      temp = temp.reduce(
        (acc, curr) =>
          acc.find(i => i.textId === curr.textId)
            ? [...acc, curr].filter(i => i.textId !== curr.textId || i.unionId)
            : [...acc, curr],
        []
      );
    } else {
      temp = temp.filter(
        obj =>
          obj.textId.toLowerCase().indexOf(searchString) > -1 ||
          obj.value.toLowerCase().indexOf(searchString) > -1 ||
          obj.header.toLowerCase().indexOf(searchString) > -1
      );
    }

    setFilteredFilterTexts(temp);
  }, [searchString, filterTexts]);

  return (
    <Panel>
      <Section title={Const.admin.filterExplanations}>
        <p>
          Här kan du ändra beskrivningarna av de filter som Saco lönesök
          använder sig av.
        </p>
        <FilterExplanationNoText
          dataSources={dataSources}
          filterTexts={filterTexts}
          setFilterTexts={setFilterTexts}
          loadingYears={loadingText || loadingData}
        />
        <SectionAdmin title="Beskrivning av lönesöks olika filter">
          <p>
            Beskrivningarna av filter kan vara gemensamma för alla förbund eller
            förbundsspecifika. Som grund har alla filter Sacos
            standardbeskrivningar.
          </p>
          <div className={styles.searchContainer}>
            <Input
              autoComplete="off"
              id="search"
              label="Sök"
              className={styles.search}
              type="text"
              placeholder="Ange minst 3 tecken"
              onChange={evt => setSearchString(evt.target.value.toLowerCase())}
              value={searchString}
            />
          </div>
          <div className={styles.textList}>
            {filteredFilterTexts.length > 0 &&
              filteredFilterTexts.map((text, i) => (
                <FilterExplanationItem
                  key={i}
                  text={text}
                  setFilterTexts={setFilterTexts}
                />
              ))}
            {Object.keys(filteredFilterTexts).length === 0 && (
              <div className={styles.filterTextsMissing}>
                Filterförklaringar saknas
              </div>
            )}
          </div>
        </SectionAdmin>
      </Section>
    </Panel>
  );
};

FilterExplanations.propTypes = {};

export default FilterExplanations;
