import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconChevronRight } from 'tabler-icons';
import styles from './AnalysisCard.module.css';

const AnalysisCard = ({ description, link, title, type }) => {
  const path = `${process.env.PUBLIC_URL}/illustrations`;

  return (
    <Link
      to={link}
      className={`${styles.analysisCard} ${
        type === 'simple' ? styles.simpleOption : styles.advancedOption
      }`}
    >
      <img
        className={styles.cardSymbol}
        src={`${path}/${
          type === 'simple'
            ? 'level-two_illustration.svg'
            : 'level-three_illustration.svg'
        }`}
        alt={`${type === 'simple' ? 'Simple' : 'Advanced'} analysis`}
      />
      <h2>{title}</h2>
      <p>{description}</p>
      <span className={styles.continueLink}>
        Gå vidare
        <IconChevronRight aria-label="" role="img" />
      </span>
    </Link>
  );
};

AnalysisCard.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['simple', 'advanced']).isRequired,
};

export default AnalysisCard;
