import React, { useEffect, useState } from 'react';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import styles from './Logout.module.css';
import ButtonLink from '../../atoms/buttonlink/ButtonLink';

const Logout = () => {
  const { signout, user } = useAuth();
  const history = useHistory();
  // FIXME: Tar nu del av att user sätts tills false när användaren loggar ut, kan komma att ändras..
  const [auto] = useState(user === false);
  const [role] = useState(user?.role);

  useEffect(() => {
    signout();
  }, [signout]);

  return (
    <div className={styles.main}>
      <h1 className={styles.header}>Du är utloggad.</h1>
      <p className={styles.text}>
        Tack för ditt besök och för att du tog del av Sveriges bästa
        lönestatistik!
      </p>
      <p className={styles.text}>
        Glöm inte att du som medlem måste logga in via ditt förbund om du vill besöka
        oss igen.
      </p>
      {(role === 'admin' || role === 'fortroendevald') && (
        <ButtonLink
          label="Logga in igen"
          onClick={() => history.push('/logga-in')}
          large
        />
      )}
    </div>
  );
};

export default Logout;
