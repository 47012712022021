/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from 'atoms/breadcrumbs/Breadcrumbs';
import SalaryAnalysis from 'components/simple/salaryanalysis/SalaryAnalysis';
import Situation from 'components/simple/situation/Situation';
import SalarySlider from 'components/simple/salaryslider/SalarySlider';
import SalaryProfile from 'components/simple/salaryprofile/SalaryProfile';
import Loader from 'atoms/loader/Loader';
import ApiService from 'services/ApiService';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'services/UseAuth';
import { numberOfRespondents } from 'lib/antal';
import isEmpty from 'lib/emptyValues';
import styles from './PageSimple.module.css';
import Notification from '../../atoms/notification/Notification';
import ErrorNotification from '../../atoms/errornotification/ErrorNotification';

// FIXME: När en situation väljs görs en onödig request till menuselection.
const PageSimple = () => {
  const [dataSources, setDataSources] = useState([]);

  const [selectionList, setSelectionList] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState();

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedSituation, setSelectedSituation] = useState();
  const [situations, setSituations] = useState();

  const [salaryData, setSalaryData] = useState();
  const [salaryLimit, setSalaryLimit] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const [totalRespondents, setTotalRespondents] = useState();
  const [currentRespondents, setCurrentRespondents] = useState();

  const [disableMenuselection, setDisableMenuselection] = useState(false);

  const [links, setLinks] = useState([]);

  const [, setState] = useState();

  const { user, signout, autoRefreshToken } = useAuth();
  const { token, forbund } = user;

  const history = useHistory();

  // TODO: Handle network errors
  const handleError = useCallback(
    error => {
      if (error.status === 401) {
        signout();
        history.push('/logga-ut');
      } else {
        setState(() => {
          throw error;
        });
      }
    },
    [signout, history]
  );

  useEffect(() => {
    if (!selectedSituation) return;

    // FIXME: Borde nollställa variabler som beror på val.
    setSelectedValues([]);
    setSalaryData(null);
    setSelectionList(
      situations[selectedSituation.index].selections
        .filter(selection => selection.isActive)
        .map(selection => {
          const options = selection.options
            .filter(opt => !isEmpty(opt.value))
            .sort((a, b) =>
              selection.sortOptionsByNumber
                ? Number(a.value) - Number(b.value)
                : a.description.localeCompare(b.description, 'sv')
            );
          return {
            ...selection,
            options,
          };
        })
        .sort((a, b) => a.SELECTION_ORDER - b.SELECTION_ORDER)
    );
    // eslint-disable-next-line
  }, [selectedSituation, situations]);

  // Get menu selections if selectedValues changes
  // Also get report
  useEffect(() => {
    if (!selectedSituation) return;
    setDisableMenuselection(true);
    let filter;

    if (selectedValues.length > 0) {
      filter = selectedValues.map(selectedValue => {
        const s = selectedValue.values.reduce((acc, curr) => {
          return `${
            selectedValue.selection_datatype === 'N'
              ? curr.value
              : `'${curr.value}'`
          }${acc ? `, ${acc}` : ''}`;
        }, null);

        return `${selectedValue.sel_var} in (${s})`;
      });
    }

    const request = {
      Forbund: forbund,
      Dsl1: selectedSituation.DSL1,
      Dsl2: selectedSituation.DSL2,
      Year: selectedSituation.year,
      filter,
    };

    ApiService.getMenuselections(request, token)
      .then(data => {
        const selVars = selectionList.map(selection => selection.sel_var);
        const filters = data
          .filter(obj => selVars.includes(obj.sel_var))
          .reduce((acc, curr) => {
            acc[curr.sel_var] = [...(acc[curr.sel_var] || []), curr];
            return acc;
          }, {});

        const filterObjects = Object.values(filters).map(obj => ({
          // TODO: Se över om datamodellen ska ändras
          // Då slipper man göra följande map
          options: obj
            .map(o => {
              const newObj = o;
              newObj.description = newObj.desc;
              return newObj;
            })
            // Rensar tomma värden, dom som heter Okänd/Inget värde.
            .filter(opt => !isEmpty(opt.value)),
          SELECTION_TEXT: obj[0].SELECTION_TEXT,
          sel_var: obj[0].sel_var,
          SELECTION_ORDER: obj[0].SELECTION_ORDER,
          SELECTION_DATATYPE: obj[0].SELECTION_DATATYPE,
        }));

        setSelectionList(prevState => {
          return prevState.map(prevItem => {
            const matchingItem = filterObjects.find(
              filterItem => filterItem.sel_var === prevItem.sel_var
            );
            if (matchingItem) {
              matchingItem.id = prevItem.id;
              matchingItem.isActive = prevItem.isActive;
              matchingItem.sortOptionsByNumber = prevItem.sortOptionsByNumber;
              matchingItem.options = matchingItem.options.sort((a, b) =>
                matchingItem.sortOptionsByNumber
                  ? Number(a.value) - Number(b.value)
                  : a.description.localeCompare(b.description, 'sv')
              );
              return matchingItem;
            }
            return prevItem;
          });
        });
      })
      .catch(error => handleError(error))
      .finally(() => setDisableMenuselection(false));

    ApiService.getReport(request, token)
      .then(data => {
        // TODO: Bättre felhantering.
        // Kolla igenom resultatdata och se om vi har något att visa.
        const { lon_p10, lon_p90, lon_medel } = data[0];
        if (
          data.length === 0 ||
          [lon_p10, lon_p90, lon_medel].some(item => item == null)
        ) {
          setHasErrors(true);
          setSalaryLimit([]);
          setSalaryData(null);
        } else {
          setHasErrors(false);
          setSalaryLimit([data[0].lon_p10, data[0].lon_p90]);
          setSalaryData(data[0]);
        }
      })
      // See if token is about to expire and keep the session alive
      .then(() => autoRefreshToken(token))
      .catch(error => handleError(error))
      .finally(() => setIsLoadingReport(false));
    // eslint-disable-next-line
  }, [selectedValues]);

  // Get total respondents and report for selected situation
  useEffect(() => {
    if (selectedSituation) {
      const request = {
        forbund: [user.forbund],
        year: selectedSituation.year,
        dsl1: selectedSituation.DSL1,
        dsl2: selectedSituation.DSL2,
      };

      ApiService.getFds(request, token).then(data => {
        setDataSources(data);
        const total = data.reduce((a, b) => a + b.total, 0);
        setTotalRespondents(total);
        setCurrentRespondents(total);
      });

      if (user == null) return;
      const linkRequest = [
        {
          groupId: 'level2link',
        },
      ];
      ApiService.getTexts(linkRequest, token).then(data => setLinks(data));
    }
    // eslint-disable-next-line
  }, [selectedSituation]);

  // Set current respondents when selectedValues changes
  // TODO: Refactor this code
  useEffect(() => {
    if (selectedValues.length > 0) {
      const { values, sel_var } = selectedValues[selectedValues.length - 1];
      const matchingItem = selectionList.find(
        selection => selection.sel_var === sel_var
      );
      const listOfValues = values.map(val => val.value);
      const actualValues = matchingItem.options.filter(val =>
        listOfValues.includes(val.value)
      );
      const current = actualValues.reduce((a, b) => {
        const low = numberOfRespondents(b.antal)[0];
        const high = numberOfRespondents(b.antal)[1];
        const diff = (high - low) / 2;

        if (Number(low) && Number(high)) {
          return a + low + diff;
        }

        return a;
      }, 0);
      setCurrentRespondents(current);
    } else {
      setCurrentRespondents(totalRespondents);
    }
  }, [selectionList, selectedValues, totalRespondents]);

  // Initial setup.
  useEffect(() => {
    ApiService.getSituations(token)
      .then(data => {
        const activeSituations = data.filter(d => d.isActive);
        setSituations(activeSituations);
        if (activeSituations.length === 1) {
          setSelectedSituation({
            index: 0,
            DSL1: activeSituations[0].DSL1,
            DSL2: activeSituations[0].DSL2,
            year: activeSituations[0].year,
          });
        }
      })
      .catch(error => handleError(error));

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.innerContainerSlim}>
        <Notification token={token} forbund={forbund} niva="simple" />
        <ErrorNotification token={token} forbund={forbund} niva="simple" />
        <div className={styles.topNav}>
          <Breadcrumbs />
        </div>
        <div className={styles.mainContent}>
          {situations ? (
            <>
              {situations.length > 1 && (
                <Situation
                  loading={disableMenuselection}
                  setSelectedSituation={setSelectedSituation}
                  selectedSituation={selectedSituation}
                  situations={situations}
                />
              )}
            </>
          ) : (
            <div className={styles.loaderAnimation}>
              <Loader variant="primary" />
            </div>
          )}

          {selectedSituation && (
            <>
              {totalRespondents > 0 && currentRespondents > 0 ? (
                <SalaryProfile
                  dataSources={dataSources}
                  loading={disableMenuselection}
                  selectedSituation={selectedSituation}
                  setSelectedSituation={setSelectedSituation}
                  selectedValues={selectedValues}
                  selectionList={selectionList}
                  setSelectedValues={setSelectedValues}
                />
              ) : (
                <div className={styles.loaderAnimation}>
                  <Loader variant="primary" />
                </div>
              )}
            </>
          )}

          {salaryData &&
            !hasErrors &&
            totalRespondents > 0 &&
            currentRespondents > 0 && (
              <>
                {!hasErrors && (
                  <div>
                    {isLoadingReport && (
                      <div className={styles.loaderAnimation}>
                        <Loader variant="primary" />
                      </div>
                    )}
                    {!isLoadingReport && salaryData && (
                      <>
                        <section>
                          <SalarySlider
                            selectedSalary={selectedSalary}
                            setSelectedSalary={setSelectedSalary}
                            salaryLimit={salaryLimit}
                          />
                        </section>
                        <SalaryAnalysis
                          salaryData={salaryData}
                          selectedSalary={selectedSalary}
                          salaryLimit={salaryLimit}
                          links={links}
                          currentRespondents={currentRespondents}
                          variant="simple"
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          {hasErrors && (
            <>
              <section className={styles.errorText}>
                Urvalet gav inga resultat, testa sök bredare.
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageSimple;
