import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { Colors } from '../../../../assets/Colors';
import styles from '../VisitorStatistics.module.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  title: {
    display: true,
    text: 'Antal procent av besöken uppdelat på enkel och avancerad sökning',
    fontSize: 16,
    fontColor: Colors.black,
  },
  legend: {
    labels: {
      fontSize: 13,
      fontColor: Colors.black,
    },
  },
  plugins: {
    datalabels: {
      anchor: 'center',
      align: 'center',
      color: 'white',
      font: {
        size: 14,
        weight: 'bold',
      },
      formatter: value => {
        if (value === 0) {
          return '';
        } else {
          return `${value.toLocaleString()} %`;
        }
      },
    },
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, d) {
        let label = d.labels[tooltipItem.index] || '';
        if (label) {
          label += ': ';
        }
        const value =
          d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
        label += Number(value).toLocaleString('sv');
        return `${label} %`;
      },
    },
  },
};

const keyLabels = {
  'Nivå 2': 'Enkel sökning',
  'Nivå 3': 'Avancerad sökning',
};

const ViewChart = ({ stats }) => {
  const [chartData, setChartData] = useState();
  const { pink, turquoise } = Colors;

  const mapStatsToChartData = () => {
    const labels = Object.keys(stats).map(viewKey => keyLabels[viewKey]);
    const datasets = [
      {
        data: Object.values(stats),
        backgroundColor: [turquoise, pink],
      },
    ];
    setChartData({ labels, datasets });
  };

  useEffect(() => {
    if (stats) {
      mapStatsToChartData();
    }
  }, [stats]);

  return (
    <div>
      {chartData && (
        <Pie data={chartData} options={options} plugins={[ChartDataLabels]} />
      )}
    </div>
  );
};

export default ViewChart;

ViewChart.propTypes = {
  stats: PropTypes.any,
};
