export default {
  admin: {
    level2Header: 'Enkel sökning',
    level3Header: 'Avancerad sökning',
    level1Header: 'Konfiguration',
    previewConfigHeader: 'Öppen sökning',
    filterExplanations: 'Filter',
    commonTexts: 'Informationstexter',
    union: 'Länk till förbundet',
    notifications: 'Notiser',
    accounts: 'Användarkonton',
    visitiorStatistics: 'Besöksstatistik',
    applicationLog: 'Applikationslogg',
    copyConfigurationHeader: 'Kopiera konfiguration',
    monitoringHeader: 'Monitorering',
    monitoringRunInfoHeader: 'Körningsinformation',
  },
};
