/* eslint-disable camelcase, no-underscore-dangle, no-param-reassign */

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatNumber(value) {
  if (!value) return '-';
  return value.toLocaleString('sv');
}

export function formatAccessor(str) {
  return str && str.toLowerCase().replace(/\s/g, '-').toLocaleString('en');
}

export function generateCustomColumns(acc, curr, groupingCol) {
  if (!acc.find(item => item[groupingCol] === curr[groupingCol]))
    acc.push({ [groupingCol]: curr[groupingCol] });
  return acc;
}

export function createOrSetObject({ acc, curr, prevObj, keys, id, totalind }) {
  if (prevObj) {
    keys.forEach(({ key, accessor }) => {
      prevObj[formatAccessor(accessor)] = curr[key];
    });

    return acc;
  }

  acc.push(
    Object.assign(
      {},
      {
        breakby1: id,
        _TYPE_: curr._TYPE_,
        totalind: totalind ? 'Y' : 'N',
      },
      ...keys.map(({ key, accessor }) => ({
        [formatAccessor(accessor)]: curr[key],
      }))
    )
  );

  return acc;
}

export function generateCustomData(acc, curr) {
  const prevObj = acc.find(row => row.breakby1 === curr.breakby1);
  const totalRow = acc.find(row => row.breakby1 === formatAccessor('total'));

  // Hämta totalrad
  if (curr._TYPE_ === 0) {
    createOrSetObject({
      acc,
      curr,
      prevObj: totalRow,
      keys: [
        { key: 'antal', accessor: 'total-antal-samtliga' },
        { key: 'lon_medel', accessor: 'total-lon_medel-samtliga' },
      ],
      id: 'total',
      totalind: true,
    });
  }

  // Hämta samtliga-kolumner
  if (curr._TYPE_ === 1) {
    createOrSetObject({
      acc,
      curr,
      prevObj,
      keys: [
        { key: 'antal', accessor: 'antal-samtliga' },
        { key: 'lon_medel', accessor: 'lon_medel-samtliga' },
      ],
      id: curr.breakby1,
      totalind: false,
    });
  }

  // Hämta deltotalrader
  if (curr._TYPE_ === 2) {
    createOrSetObject({
      acc,
      curr,
      prevObj: totalRow,
      keys: [
        { key: 'antal', accessor: `total-antal-${curr.breakby2}` },
        { key: 'lon_medel', accessor: `total-lon_medel-${curr.breakby2}` },
      ],
      id: 'total',
      totalind: true,
    });
  }

  // Hämta vanliga rader
  if (curr._TYPE_ === 3) {
    createOrSetObject({
      acc,
      curr,
      prevObj,
      keys: [
        { key: 'antal', accessor: `antal-${curr.breakby2}` },
        { key: 'lon_medel', accessor: `lon_medel-${curr.breakby2}` },
      ],
      id: curr.breakby1,
      totalind: false,
    });
  }

  return acc;
}

export function getTotalCol(arr, accessor) {
  return formatNumber(
    arr
      .filter(row => row.original.totalind === 'Y')
      .map(row => row.original[accessor])[0]
  );
}

export function getWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}

export function isTotalRow(obj, customPresentation) {
  const { _TYPE_, totalind } = obj;
  if (customPresentation) {
    return totalind === 'Y' && _TYPE_ === 0;
  }
  return totalind === 'Y' && (_TYPE_ === 0 || _TYPE_ === 1);
}
