import React from 'react';
import AnalysisCard from 'components/start/analysiscard/AnalysisCard';
import About from 'components/start/about/About';
import TechnicalInformation from 'components/start/privacypolicy/TechnicalInformation';
import styles from './PageStart.module.css';

const PageStart = () => {
  return (
    <div className={styles.main}>
      <section>
        <div className={styles.innerContainer}>
          <div className={styles.optionContainer}>
            <AnalysisCard
              description="Få en överblick av ditt löneläge"
              link="/enkel"
              title="Enkel"
              type="simple"
            />
            <AnalysisCard
              description="Se all tillgänglig statistik"
              link="/avancerad"
              title="Avancerad"
              type="advanced"
            />
          </div>
        </div>
      </section>
      <div>
        <div className={styles.aboutCurveBackground}>
          <div className={styles.aboutDropshadow}>
            <div className={styles.aboutClippath} />
          </div>
        </div>
        <About id="om-saco-lonesok" />
        <TechnicalInformation id="teknisk-info" />
      </div>
    </div>
  );
};

export default PageStart;
