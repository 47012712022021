import React from 'react';
import AppSettings from '../../appsettings.json';

const PageErrorProvocation = () => {
  const ENVIRONMENT = AppSettings.APP_ENVIRONMENT;

  if (ENVIRONMENT !== 'production') {
    // Komponentens innehåll för att provocera fram ett fel i utvecklingsmiljön
    throw new Error('This is an error for testing purposes.');
  }

  // Returnera null om det är produktion
  return null;
};

export default PageErrorProvocation;
