/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import CheckboxAdmin from 'atoms/checkboxadmin/CheckboxAdmin';
import { IconCheck } from 'tabler-icons';
import Accordion from 'atoms/accordion/Accordion';
import isEmpty from 'lib/emptyValues';
import styles from './SortableList.module.css';

const VARIANTS = {
  filter: {
    order: 'filterOrder',
    title: 'SELECTION_TEXT',
    id: 'sel_var',
  },
  breakby: {
    order: 'breakbyOrder',
    title: 'breakbyText',
    id: 'breakbyVar',
  },
  selection: {
    order: 'SELECTION_ORDER',
    title: 'SELECTION_TEXT',
    id: 'sel_var',
  },
};

const SortableList = ({ data, isSaving, updateFunction, variant }) => {
  const { order, title, id } = VARIANTS[variant];
  const activeItems = data.filter(a => a.isActive).length;

  const performMoveUp = (index, listItem) => {
    if (index === 0 && listItem[order] != null) {
      return;
    }

    const itemList = data.filter(item => item.isActive);
    const unsortedItems = data.filter(item => !item.isActive);

    if (index > 0 && itemList[index - 1][order] != null)
      itemList[index - 1][order] += 1;

    if (listItem[order] == null) {
      listItem[order] = index;
    } else {
      listItem[order] -= 1;
    }

    updateFunction([
      ...itemList.sort((a, b) => (a[order] > b[order] ? 1 : -1)),
      ...unsortedItems,
    ]);
  };

  const performMoveDown = (index, listItem) => {
    const itemList = data.filter(item => item.isActive);
    const unsortedItems = data.filter(item => !item.isActive);

    if (index === itemList.length - 1) return;

    if (itemList[index + 1][order] != null) itemList[index + 1][order] -= 1;

    if (listItem[order] == null) {
      listItem[order] = index;
    } else {
      listItem[order] += 1;
    }

    updateFunction([
      ...itemList.sort((a, b) => (a[order] > b[order] ? 1 : -1)),
      ...unsortedItems,
    ]);
  };

  const handleItemUpdate = (index, listItem, property, value = null) => {
    const newList = data.map(item => {
      if (item[id] === listItem[id]) {
        const updatedItem = {
          ...item,
          // TODO: Consider this
          [property]: value !== null ? value : !item[property],
          [order]: property === 'isActive' ? activeItems : item[order],
        };

        return updatedItem;
      }
      if (item[order] > index && item[property]) {
        item[order] -= 1;
      }
      return item;
    });

    updateFunction(newList);
  };

  const filterType = variant === 'filter' || variant === 'selection';

  return (
    <table className={styles.configTable}>
      <thead>
        <tr>
          <th colSpan={filterType ? 3 : 4} />
          <th colSpan="3" className={styles.center}>
            Ordning för{' '}
            {filterType ? 'filtervariabler' : 'presentationsvariabler'}
          </th>
        </tr>
        <tr>
          {filterType ? <th>Filter</th> : <th>Presentationsvariabel</th>}
          {!filterType && <th>Förvalt alternativ</th>}
          <th>Ordning</th>
          <th>Aktiv</th>
          <th>Alfabetisk</th>
          <th>Nummerordning</th>
          {variant === 'breakby' && <th>Omvänd ordning (diagram)</th>}
        </tr>
      </thead>
      <tbody>
        {data
          .sort((a, b) => a[order] - b[order])
          .sort((a, b) => {
            if (a.isActive === b.isActive) return 0;
            if (a.isActive) return -1;
            return 1;
          })
          .map((item, i) => (
            <tr key={item[id]}>
              <td>
                {/* TODO: Merge with: components/admin/salaryprofileconfig/selectionitem/SelectionItem' */}
                {variant === 'filter' || variant === 'selection' ? (
                  <Accordion title={item[title]} slim border>
                    <div className={styles.optionsList}>
                      {item.options ? (
                        item.options
                          .filter(({ value }) => !isEmpty(value))
                          .sort((a, b) =>
                            item.sortOptionsByNumber
                              ? Number(a.value) - Number(b.value)
                              : a.description.localeCompare(b.description, 'sv')
                          )
                          .map(({ description }, index) => (
                            // eslint-disable-next-line
                            <span key={index + description}>{description}</span>
                          ))
                      ) : (
                        <span />
                      )}
                    </div>
                  </Accordion>
                ) : (
                  item[title]
                )}
              </td>
              {!filterType && (
                <td>
                  {i === 0 && (
                    <IconCheck
                      aria-label="Förvalt alternativ"
                      role="img"
                      size={20}
                    />
                  )}
                </td>
              )}
              <td>
                <Button
                  variant="secondary"
                  onClick={() => performMoveUp(i, item)}
                  icon="ArrowUp"
                  label=""
                  altText={`Flytta upp ${item[title]}`}
                  disabled={!item.isActive || isSaving || i === 0}
                />
                <Button
                  variant="secondary"
                  onClick={() => performMoveDown(i, item)}
                  icon="ArrowDown"
                  label=""
                  altText={`Flytta ner ${item[title]}`}
                  disabled={
                    !item.isActive ||
                    isSaving ||
                    i === data.filter(a => a.isActive).length - 1
                  }
                />
              </td>
              <td>
                <CheckboxAdmin
                  id={
                    variant === 'filter' || variant === 'selection'
                      ? `filter-${item[id]}`
                      : `bb-${item[id]}`
                  }
                  checked={item.isActive}
                  disabled={
                    isSaving ||
                    (activeItems === 1 && i === 0 && variant === 'breakby')
                  }
                  onChange={() => handleItemUpdate(i, item, 'isActive')}
                />
              </td>
              <td>
                <label
                  htmlFor={`alfabeticSort-${item[id]}`}
                  className={`${styles.radioLabel} ${
                    !item.isActive ? styles.disabled : ''
                  }`}
                >
                  {filterType ? (
                    <input
                      type="radio"
                      id={`alfabeticSort-${item[id]}`}
                      value="alfabetic"
                      name={`sortOptionsByNumber-${item[id]}`}
                      onChange={() => {
                        return handleItemUpdate(
                          i,
                          item,
                          'sortOptionsByNumber',
                          false
                        );
                      }}
                      disabled={!item.isActive}
                      checked={item.sortOptionsByNumber === false}
                    />
                  ) : (
                    <input
                      type="radio"
                      id={`alfabeticSort-${item[id]}`}
                      value="alfabetic"
                      name={`sortOptionsByLetters-${item[id]}`}
                      onChange={() => {
                        return handleItemUpdate(
                          i,
                          item,
                          'sortOptionsByLetters',
                          true
                        );
                      }}
                      disabled={!item.isActive}
                      checked={item.sortOptionsByLetters === true}
                    />
                  )}
                </label>
              </td>
              <td>
                <label
                  htmlFor={`numericSort-${item[id]}`}
                  className={`${styles.radioLabel} ${
                    !item.isActive ? styles.disabled : ''
                  }`}
                >
                  {filterType ? (
                    <input
                      type="radio"
                      id={`numericSort-${item[id]}`}
                      value="numeric"
                      name={`sortOptionsByNumber-${item[id]}`}
                      onChange={() => {
                        return handleItemUpdate(
                          i,
                          item,
                          'sortOptionsByNumber',
                          true
                        );
                      }}
                      disabled={!item.isActive}
                      checked={item.sortOptionsByNumber === true}
                    />
                  ) : (
                    <input
                      type="radio"
                      id={`numericSort-${item[id]}`}
                      value="numeric"
                      name={`sortOptionsByLetters-${item[id]}`}
                      onChange={() => {
                        return handleItemUpdate(
                          i,
                          item,
                          'sortOptionsByLetters',
                          false
                        );
                      }}
                      disabled={!item.isActive}
                      checked={item.sortOptionsByLetters === false}
                    />
                  )}
                </label>
              </td>
              {variant === 'breakby' && (
                <td>
                  <CheckboxAdmin
                    id={`reverse-${item[id]}`}
                    checked={item.isReverse}
                    disabled={
                      isSaving ||
                      (activeItems === 1 && i === 0 && variant === 'breakby')
                    }
                    onChange={() =>
                      handleItemUpdate(i, item, 'isReverse', !item.isReverse)
                    }
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

SortableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSaving: PropTypes.bool.isRequired,
  updateFunction: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['filter', 'breakby', 'selection']).isRequired,
};

export default SortableList;
