import React, { useState, useEffect } from 'react';
import { Route, Redirect, useParams, useHistory } from 'react-router-dom';
import Bowser from 'bowser';
import { useAuth } from './UseAuth';

const ForbundsKodRedirect = ({ ...rest }) => {
  const browserData = Bowser.parse(window.navigator.userAgent);

  const browser = `${browserData?.browser?.name} ${browserData?.browser?.version}`;
  const device = browserData?.platform?.type;

  const auth = useAuth();
  const { forbund } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [, setState] = useState();

  const history = useHistory();

  useEffect(() => {
    auth
      .forbundSignin(forbund, browser || '', device || '')
      .then(() => setIsLoading(false))
      .catch(error => {
        setIsLoading(false);
        if (error.status === 401) {
          auth.signout();
          history.push('/logga-ut');
        } else {
          setState(() => {
            throw error;
          });
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading && (
        <Route
          {...rest}
          render={props =>
            auth.user ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            ) : (
              <Redirect to={{ pathname: '/inloggning-info' }} />
            )
          }
        />
      )}
    </>
  );
};

export default ForbundsKodRedirect;
