import Button from 'atoms/button/Button';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import Loader from 'atoms/loader/Loader';
import SectionAdmin from 'atoms/sectionadmin/SectionAdmin';
import Select from 'atoms/select/Select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import FilterExplanationCreate from '../filterexplanationcreate/FilterExplanationCreate';
import styles from './FilterExplanationNoText.module.css';

const FilterExplanationNoText = ({
  dataSources,
  filterTexts,
  setFilterTexts,
  loadingYears,
}) => {
  const [dsl1, setDsl1] = useState('');
  const [dsl2, setDsl2] = useState('');
  const [year, setYear] = useState();

  const [availableYears, setAvailableYears] = useState([]);
  const [availableDSL1, setAvailableDSL1] = useState([]);
  const [availableDSL2, setAvailableDSL2] = useState([]);

  const [isCreatingNewText, setIsCreatingNewText] = useState(false);
  const [selvars, setSelvars] = useState();

  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (dataSources == null) return;
    setAvailableYears(
      dataSources
        .reduce((acc, curr) => {
          if (!acc.includes(curr.YEAR)) acc.push(curr.YEAR);
          return acc;
        }, [])
        .sort()
    );
    // eslint-disable-next-line
  }, [dataSources]);

  useEffect(() => {
    if (year == null) {
      // Nollställ
      setAvailableDSL1([]);
      setAvailableDSL2([]);
      return;
    }

    const dsl1Temp = dataSources
      .filter(dataSource => dataSource.YEAR === year)
      .reduce((acc, curr) => {
        if (!acc.some(a => a.dsl === curr.DSL1))
          acc.push({ dsl: curr.DSL1, desc: curr.DSL1_DESC });
        return acc;
      }, []);
    setAvailableDSL1(dsl1Temp);
    // eslint-disable-next-line
  }, [year, dataSources]);

  useEffect(() => {
    if (dsl1 === '') return;
    setDsl2('');
    setAvailableDSL2(
      dataSources
        .filter(dataSource => dataSource.YEAR === year)
        .filter(dataSource => dataSource.DSL1 === dsl1)
        .map(dataSource => {
          return { dsl: dataSource.DSL2, desc: dataSource.DSL2_DESC };
        })
        .filter(datasource => datasource.dsl.length)
    );
    // eslint-disable-next-line
  }, [dsl1, dataSources, year]);

  useEffect(() => {
    if (dataSources == null) return;

    let temp;
    if (dsl2 == null) {
      temp = dataSources.find(
        source =>
          source.DSL1 === dsl1 && source.YEAR === year && source.DSL2 === ''
      );
    } else {
      temp = dataSources.find(
        source =>
          source.DSL1 === dsl1 && source.YEAR === year && source.DSL2 === dsl2
      );
    }

    if (temp == null) return;

    const request = {
      Forbund: user.forbund,
      dsl1,
      dsl2: dsl2 || null,
      year, // År.
    };

    setLoading(true);
    ApiService.getMenuselections(request, user.token)
      .then(data => {
        // TODO: hämta ut alla unika sel_vars
        // TODO: Jämför unika sel_vars med vilka itemIds som finns i filterTexts.
        const uniqueSelvar = data.reduce((acc, curr) => {
          if (
            !acc.some(a => a.sel_var === curr.sel_var) &&
            !filterTexts.some(txt => txt.textId === curr.sel_var)
          )
            acc.push({ sel_var: curr.sel_var, desc: curr.SELECTION_TEXT });
          return acc;
        }, []);

        setSelvars(uniqueSelvar);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [year, dsl1, dsl2, user, dataSources]);

  return (
    <div className={styles.main}>
      <SectionAdmin title="Visa de filter som saknar beskrivning">
        <div className={styles.searchInput}>
          <p>För att hitta önskade filter behöver du välja år och datakälla.</p>
          <div className={styles.selectBox}>
            <Select
              disabled={availableYears.length === 0}
              loading={loadingYears}
              id="year"
              label="År"
              compact
              value={year}
              onChange={e => {
                e.persist();
                setYear(Number(e.target.value));
              }}
              options={[
                { key: 'year_null', value: '', desc: 'Inget valt' },
                ...availableYears.map(availableYear => ({
                  key: availableYear,
                  value: availableYear,
                  desc: availableYear,
                })),
              ]}
            />
          </div>
          <div className={styles.selectBox}>
            <Select
              disabled={!year}
              compact
              id="dsl1"
              label="Datakälla 1"
              value={dsl1.dsl}
              onChange={e => {
                e.persist();
                setDsl1(e.target.value);
              }}
              options={[
                { key: 'dsl1_null', value: '', desc: 'Inget valt' },
                ...availableDSL1.map(dsl => ({
                  key: dsl.dsl,
                  value: dsl.dsl,
                  desc: dsl.desc,
                })),
              ]}
            />
          </div>
          <div className={styles.selectBox}>
            <Select
              disabled={!year || !dsl1 || !availableDSL2.length}
              compact
              id="dsl2"
              label="Datakälla 2"
              value={dsl2.dsl}
              onChange={e => {
                e.persist();
                setDsl2(e.target.value);
              }}
              options={[
                { key: 'dsl2_null', value: '', desc: 'Inget valt' },
                ...availableDSL2.map(dsl => ({
                  key: dsl.dsl,
                  value: dsl.dsl,
                  desc: dsl.desc,
                })),
              ]}
            />
          </div>
        </div>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Loader variant="primary" />
          </div>
        ) : (
          <>
            <div className={styles.searchResult}>
              {selvars && (
                <ul className={styles.missingFilterExplanations}>
                  {selvars.map(selvar => (
                    <li
                      key={selvar.sel_var}
                      className={styles.missingTextContainer}
                    >
                      <span
                        key={selvar.sel_var}
                      >{`${selvar.desc} - ${selvar.sel_var}`}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {selvars && !isCreatingNewText && (
              <ButtonBar marginBottom>
                <Button
                  variant="primary"
                  onClick={() => setIsCreatingNewText(prevState => !prevState)}
                  icon="Plus"
                  label="Lägg till"
                  altText="Lägg till förklaring"
                />
              </ButtonBar>
            )}
          </>
        )}
        {isCreatingNewText && (
          <FilterExplanationCreate
            selvars={selvars}
            setFilterTexts={setFilterTexts}
            setEditMode={setIsCreatingNewText}
          />
        )}
      </SectionAdmin>
    </div>
  );
};

FilterExplanationNoText.propTypes = {
  dataSources: PropTypes.arrayOf(Object),
  filterTexts: PropTypes.arrayOf(Object),
  setFilterTexts: PropTypes.func.isRequired,
  loadingYears: PropTypes.bool,
};

FilterExplanationNoText.defaultProps = {
  dataSources: [],
  filterTexts: [],
  loadingYears: false,
};

export default FilterExplanationNoText;
