export default function downloadExcel(
  name,
  endpoint,
  errorFunction,
  payload,
  token
) {
  // TODO: Access response headers and read fileName from there
  const fileName = `${name}_${new Date().toLocaleDateString('sv')}.xlsx`;

   // Download excel file
  endpoint(payload, token)
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); // afterwards we remove the element again
    })
    .catch(error => errorFunction(error));
}
