import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart as Graph } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot';
import styles from './Chart.module.css';

const Chart = ({ data, id, options, setValues, type }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  Graph.plugins.unregister(ChartDataLabels);

  useEffect(() => {
    if (chartContainer && chartContainer.current && !chartInstance) {
      const newChartInstance = new Graph(chartContainer.current, {
        data,
        options,
        type,
      });
      setChartInstance(newChartInstance);
    }
    // eslint-disable-next-line
  }, [chartContainer, data, options, type, chartInstance]);

  useEffect(() => {
    if (chartInstance && data) {
      chartInstance.data = data;
      chartInstance.update();
      setValues(
        chartInstance.scales['x-axis-0'],
        chartInstance.scales['y-axis-0']
      );
    }
    // eslint-disable-next-line
  }, [data, chartInstance]);

  useEffect(() => {
    if (chartInstance && options) {
      chartInstance.options = options;
      chartInstance.update({ duration: 0 });
    }
    // eslint-disable-next-line
  }, [options, chartInstance]);

  return (
    <canvas
      id={id}
      ref={chartContainer}
      className={styles.chart}
      aria-label="graph"
    />
  );
};

Chart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  options: PropTypes.shape({}),
  setValues: PropTypes.func,
  type: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  id: '',
  options: PropTypes.shape({}),
  setValues: () => {},
};

export default Chart;
