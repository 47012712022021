import Accordion from 'atoms/accordion/Accordion';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FilterItem from './filteritem/FilterItem';
import styles from './FilterList.module.css';

const FILTER_LIMIT = 4;

const FilterList = ({
  filterList,
  activeFilters,
  setActiveFilters,
  disableMenuselection,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);

  // FIXME: Borde lösa snyggare.
  // TODO: Behöver tillgång till namn på filter och namn på option.
  // TODO: Fixa datamodell överallt.
  const handleSelectedFilters = filterOption => {
    const index = activeFilters.findIndex(
      activeFilter => activeFilter.sel_var === filterOption.sel_var
    );

    const item = activeFilters[index];

    let newItem;

    if (item == null) {
      newItem = {
        name: filterOption.name,
        sel_var: filterOption.sel_var,
        selection_datatype: filterOption.SELECTION_DATATYPE,
        values: [
          {
            value: filterOption.value,
            desc: filterOption.description,
            antal: filterOption.antal,
          },
        ],
      };
    } else if (item.values.some(obj => obj.value === filterOption.value)) {
      newItem = {
        name: filterOption.name,
        sel_var: filterOption.sel_var,
        selection_datatype: filterOption.SELECTION_DATATYPE,
        values: item.values.filter(opt => opt.value !== filterOption.value),
      };
    } else {
      newItem = item;
      newItem.values.push({
        value: filterOption.value,
        desc: filterOption.description,
        antal: filterOption.antal,
      });
    }

    if (item) {
      setActiveFilters(prevState => {
        const tempState = [...prevState];
        tempState[index] = newItem;
        return tempState.filter(element => element.values.length > 0);
      });
      return;
    }

    setActiveFilters(prevState => [
      ...prevState.filter(prev => prev.sel_var !== newItem.sel_var),
      newItem,
    ]);
  };

  // TODO: Kanske bara borde visa alla filter som default och fixa scrollen istället?
  return (
    <div
      className={`${styles.filterListWrapper} ${
        filterList.length > 0 ? styles.hasItems : ''
      }`}
    >
      <div className={styles.filterScrollArea}>
        <div className={styles.filterListPadding}>
          {filterList.length > 0 &&
            filterList
              .slice(0, showAllFilters ? filterList.length : FILTER_LIMIT)
              .map(listItem => (
                <Accordion
                  key={listItem.name}
                  slim
                  stacked
                  title={listItem.name}
                  className={
                    listItem.list.some(option => option.antal !== ' < 5')
                      ? ''
                      : styles.mobileEmpty
                  }
                >
                  <FilterItem
                    activeFilters={activeFilters}
                    disableMenuselection={disableMenuselection}
                    handleSelectedFilters={handleSelectedFilters}
                    listItem={listItem}
                  />
                </Accordion>
              ))}
        </div>
      </div>
      {!showAllFilters && filterList.length > FILTER_LIMIT ? (
        <div className={styles.showAllFilterWrapper}>
          <ButtonLink
            label="Visa fler filter"
            altText="Visa fler filter"
            icon="Plus"
            accordionItem
            className={styles.showAllFilters}
            onClick={() => setShowAllFilters(true)}
          />
        </div>
      ) : (
        <div className={styles.showAllFilterWrapper}>
          <ButtonLink
            label="Visa färre filter"
            altText="Visa färre filter"
            accordionItem
            className={styles.showAllFilters}
            onClick={() => setShowAllFilters(false)}
          />
        </div>
      )}
    </div>
  );
};

FilterList.propTypes = {
  filterList: PropTypes.arrayOf(Object).isRequired,
  activeFilters: PropTypes.arrayOf(Object).isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
};

export default FilterList;
