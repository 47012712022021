import React from 'react';
import styles from './SellingPoints.module.css';

const SellingPoints = () => {
  return (
    <section className={styles.main}>
      <div className={styles.innerContainer}>
        <h1>Bli medlem för statistik i toppklass</h1>
        <p>
          För att kunna föra en bra lönediskussion måste man kunna lita på sina
          argument. Då behöver man högkvalitativ lönestatistik att luta sig mot.
          Det får du med Saco lönesök, som bygger på löneuppgifter från över 370
          000 akademiker.
        </p>
        <p>
          I Saco lönesök kan du kan göra lönejämförelser för att se hur
          ingångslönerna eller löneutvecklingen ser ut, både för din egen
          utbildning, ditt yrke och/eller för andra yrkesgrupper. Du kan också
          bryta ner statistiken på olika åldersgrupper, examensår, befattningar,
          regioner och arbetsmarknadssektorer.
        </p>
        <h3>Som medlem får du tillgång till:</h3>
        <ul className="generalList">
          <li>Branschspecifik lönestatistik från arbetsgivare och medlemmar</li>
          <li>Möjlighet till analys i två nivåer</li>
          <ul>
            <li>Enkel</li>
            <li>Avancerad</li>
          </ul>
          <li>Möjlighet att spara dina resultat</li>
        </ul>
      </div>
    </section>
  );
};

export default SellingPoints;
