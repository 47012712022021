/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Child } from 'pym.js';
import SalaryAnalysis from 'components/simple/salaryanalysis/SalaryAnalysis';
import Situation from 'components/simple/situation/Situation';
import SalarySlider from 'components/simple/salaryslider/SalarySlider';
import SalaryProfile from 'components/simple/salaryprofile/SalaryProfile';
import Loader from 'atoms/loader/Loader';
import ApiService from 'services/ApiService';
import SellingPoints from 'components/simple/sellingpoints/SellingPoints';
import ExternalLink from 'components/simple/externallink/ExternalLink';
import Welcome from 'components/simple/welcome/Welcome';
import isEmpty from 'lib/emptyValues';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import styles from './PageSimple.module.css';

const PageSimpleFree = () => {
  const [selectionList, setSelectionList] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState();

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedSituation, setSelectedSituation] = useState();
  const [situations, setSituations] = useState();

  const [salaryData, setSalaryData] = useState();
  const [salaryLimit, setSalaryLimit] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const [links, setLinks] = useState([]);

  const [disableMenuselection, setDisableMenuselection] = useState(false);

  const [, setState] = useState();

  const { signout } = useAuth();
  const history = useHistory();

  // TODO: Handle network errors
  const handleError = useCallback(
    error => {
      if (error.status === 401) {
        signout();
        history.push('/logga-ut');
      } else {
        setState(() => {
          throw error;
        });
      }
    },
    [signout, history]
  );

  // This page is included as an iframe
  useEffect(() => {
    let pymChild = new Child({ polling: 1000 });
    pymChild.sendHeight();

    return () => {
      pymChild = null;
    };
  }, []);

  useEffect(() => {
    if (!selectedSituation) return;

    // FIXME: Borde nollställa variabler som beror på val.
    setSelectedValues([]);
    setSalaryData(null);
    setSelectionList(
      situations[selectedSituation.index].selections
        .filter(selection => selection.isActive)
        .map(selection => ({
          ...selection,
          // filter out "empty" options initially
          options: selection.options.filter(opt => !isEmpty(opt.value)),
        }))
        .sort((a, b) => a.SELECTION_ORDER - b.SELECTION_ORDER)
    );
  }, [selectedSituation, situations]);

  // Get menu selections if selectedValues changes
  // Also get report
  useEffect(() => {
    if (!selectedSituation) return;
    setDisableMenuselection(true);
    let filter;

    if (selectedValues.length > 0) {
      filter = selectedValues.map(selectedValue => {
        const s = selectedValue.values.reduce((acc, curr) => {
          return `${
            selectedValue.selection_datatype === 'N'
              ? curr.value
              : `'${curr.value}'`
          }${acc ? `, ${acc}` : ''}`;
        }, null);

        return `${selectedValue.sel_var} in (${s})`;
      });
    }

    const request = {
      Dsl1: selectedSituation.DSL1,
      Dsl2: selectedSituation.DSL2,
      Year: selectedSituation.year,
      filter,
    };

    ApiService.getMenuselectionsFree(request)
      .then(data => {
        const selVars = selectionList.map(selection => selection.sel_var);
        const filters = data
          .filter(obj => selVars.includes(obj.sel_var))
          .reduce((acc, curr) => {
            acc[curr.sel_var] = [...(acc[curr.sel_var] || []), curr];
            return acc;
          }, {});

        const filterObjects = Object.values(filters).map(obj => ({
          // TODO: Se över om datamodellen ska ändras
          // Då slipper man göra följande map
          options: obj
            .map(o => {
              const newObj = o;
              newObj.description = newObj.desc;
              return newObj;
            })
            // Rensar tomma värden, dom som heter Okänd/Inget värde.
            .filter(opt => !isEmpty(opt.value)),
          SELECTION_TEXT: obj[0].SELECTION_TEXT,
          sel_var: obj[0].sel_var,
          SELECTION_ORDER: obj[0].SELECTION_ORDER,
          SELECTION_DATATYPE: obj[0].SELECTION_DATATYPE,
        }));

        setSelectionList(prevState => {
          return prevState.map(prevItem => {
            const matchingItem = filterObjects.find(
              filterItem => filterItem.sel_var === prevItem.sel_var
            );
            if (matchingItem) {
              matchingItem.id = prevItem.id;
              matchingItem.isActive = prevItem.isActive;
              return matchingItem;
            }
            return prevState;
          });
        });
      })
      .catch(error => handleError(error))
      .finally(() => setDisableMenuselection(false));

    ApiService.getReportFree(request)
      .then(data => {
        // TODO: Bättre felhantering.
        // Kolla igenom resultatdata och se om vi har något att visa.
        const { lon_p10, lon_p90, lon_medel } = data[0];
        if (
          data.length === 0 ||
          [lon_p10, lon_p90, lon_medel].some(item => item == null)
        ) {
          setHasErrors(true);
          setSalaryLimit([]);
          setSalaryData(null);
        } else {
          setHasErrors(false);
          setSalaryLimit([data[0].lon_p10, data[0].lon_p90]);
          setSalaryData(data[0]);
        }
      })
      .catch(error => handleError(error))
      .finally(() => setIsLoadingReport(false));
    // eslint-disable-next-line
  }, [handleError, selectedValues]);

  // Initial setup (situations and links).
  useEffect(() => {
    ApiService.getSituationsFree()
      .then(data => {
        const activeSituations = data.filter(d => d.isActive);
        setSituations(activeSituations);
        if (activeSituations.length === 1) {
          setSelectedSituation({
            index: 0,
            DSL1: activeSituations[0].DSL1,
            DSL2: activeSituations[0].DSL2,
            year: activeSituations[0].year,
          });
        }
      })
      .catch(error => handleError(error));
    const linkRequest = [
      {
        groupId: 'level1link',
      },
    ];
    ApiService.getTexts(linkRequest).then(data => setLinks(data));
  }, [handleError]);

  return (
    <div className={styles.main}>
      <div className={styles.innerContainerSlim}>
        {/* <div className={styles.topNav}>
          <Breadcrumbs />
        </div> */}
        <div className={styles.mainContent}>
          <Welcome />
          {situations ? (
            <>
              {situations.length > 1 && (
                <Situation
                  loading={disableMenuselection}
                  setSelectedSituation={setSelectedSituation}
                  selectedSituation={selectedSituation}
                  situations={situations}
                />
              )}
            </>
          ) : (
            <div className={styles.loaderAnimation}>
              <Loader variant="primary" />
            </div>
          )}

          {selectedSituation && (
            <SalaryProfile
              loading={disableMenuselection}
              selectedValues={selectedValues}
              selectionList={selectionList}
              setSelectedValues={setSelectedValues}
            />
          )}

          {salaryData && !hasErrors && (
            <>
              {!hasErrors && (
                <div>
                  {isLoadingReport && (
                    <div className={styles.loaderAnimation}>
                      <Loader variant="primary" />
                    </div>
                  )}
                  {!isLoadingReport && salaryData && (
                    <>
                      <section>
                        <SalarySlider
                          selectedSalary={selectedSalary}
                          setSelectedSalary={setSelectedSalary}
                          salaryLimit={salaryLimit}
                        />
                      </section>
                      <SalaryAnalysis
                        salaryData={salaryData}
                        selectedSalary={selectedSalary}
                        salaryLimit={salaryLimit}
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {hasErrors && (
            <>
              <section className={styles.errorText}>
                Urvalet gav inga resultat, testa sök bredare.
              </section>
            </>
          )}
        </div>
        <SellingPoints />
        {links[0] &&
          links.map(({ header, id, value }) => (
            <ExternalLink header={header} key={id} value={value} />
          ))}
      </div>
    </div>
  );
};

export default PageSimpleFree;
