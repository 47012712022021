import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ApiService from '../../services/ApiService';
import styles from './Notification.module.css';

const Notification = ({ token, forbund, niva, dataSource }) => {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    ApiService.getTextsBoth(
      [
        {
          groupId: 'notifications',
          unionId: [null, forbund],
        },
      ],
      token
    )
      .then(data => data.map(t => ({ ...t, textId: JSON.parse(t.textId) })))
      .then(data => setNotifications(data))
      .catch(error => {});
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    const { dsl1, dsl2, year } = dataSource;
    if (notifications.length) {
      setFilteredNotifications(
        notifications.filter(
          t =>
            (dataSource.forbund === 'SA' ? !t.unionId : !!t.unionId) &&
            (!t.textId.niva || niva === t.textId.niva) &&
            (!t.textId.dsl1 || dsl1 === t.textId.dsl1) &&
            (!t.textId.dsl2 || dsl2 === t.textId.dsl2) &&
            (!t.textId.year || year === Number(t.textId.year))
        )
      );
    }
  }, [notifications, dataSource, niva]);

  if (!notifications.length || !filteredNotifications.length) return null;
  return (
    <div className={styles.container}>
      {filteredNotifications.length &&
        filteredNotifications.map(t => (
          <p key={t.id} className={styles.notification}>
            {t.value}
          </p>
        ))}
    </div>
  );
};

Notification.propTypes = {
  token: PropTypes.string.isRequired,
  forbund: PropTypes.string,
  niva: PropTypes.string.isRequired,
  dataSource: PropTypes.shape({
    forbund: PropTypes.string,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
    year: PropTypes.number,
  }),
};

Notification.defaultProps = {
  forbund: '',
  dataSource: {
    forbund: 'SA',
    dsl1: null,
    dsl2: '',
    year: null,
  },
};

export default Notification;
