import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from 'atoms/loader/Loader';
import Accordion from 'atoms/accordion/Accordion';
import ApiService from 'services/ApiService';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import styles from './ExplanationGroup.module.css';
import ExplanationItem from './explanationitem/ExplanationItem';

const measurementsSortOrder = [
  'main',
  'average',
  'median',
  'lowquartile',
  'highquartile',
  '10perc',
  '90perc',
];

const graphsSortOrder = ['bar', 'line', 'box'];

const ExplanationGroup = ({ available, groupId, textId, title }) => {
  // TODO: Refactor this component, it now holds data about its toggled state
  // This could maybe be moved to the accordion compoonent somehow.

  const { user, signout } = useAuth();
  const { token } = user;

  const [, setState] = useState();
  const [items, setItems] = useState([]);
  const [clicks, setClicks] = useState(0);
  const [toggled, setToggled] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const disabled = !toggled && fetchingData;

  const history = useHistory();

  const handleError = useCallback(
    error => {
      if (error.status === 401) {
        signout();
        history.push('/logga-ut');
      } else {
        setState(() => {
          throw error;
        });
      }
    },
    [signout, history]
  );

  const handleClick = () => {
    setToggled(!toggled);

    if (!toggled) {
      setClicks(prevState => prevState + 1);
    }
  };

  useEffect(() => {
    if (clicks === 1 || (clicks > 0 && items.length < 1 && !fetchingData)) {
      setFetchingData(true);
      if (!available) return;
      const request = [
        {
          groupId,
          textId,
        },
      ];

      ApiService.getTexts(request, token)
        .then(responseData => {
          const mainIdx = responseData.findIndex(a => a.textId === 'main');
          const mainText = responseData.splice(mainIdx, 1);
          responseData.sort((a, b) => a.header.localeCompare(b.header, 'sv'));

          if (mainText.length > 0) {
            responseData.splice(0, 0, mainText[0]);
          }

          setItems(responseData);
        })
        .catch(error => handleError(error))
        .finally(() => setFetchingData(false));
    }
    // eslint-disable-next-line
  }, [available, clicks, groupId, handleError, token]);

  // Reset accordion if data source changes
  useEffect(() => {
    setClicks(0);
    setToggled(false);
  }, [textId]);

  const sortMeasurements = () =>
    groupId === 'measurements' &&
    items.length > 0 &&
    items.sort(
      (a, b) =>
        measurementsSortOrder.indexOf(a.textId) -
        measurementsSortOrder.indexOf(b.textId)
    );

  const sortGraphs = () =>
    groupId === 'graph' &&
    items.length > 0 &&
    items.sort(
      (a, b) =>
        graphsSortOrder.indexOf(a.textId) - graphsSortOrder.indexOf(b.textId)
    );

  useEffect(() => {
    sortMeasurements();
    sortGraphs();
  }, [items]);

  return (
    <Accordion
      id={groupId}
      disabled={disabled}
      onClick={handleClick}
      title={title}
      close={clicks === 0}
      boxShadow
      marginBottom
    >
      {fetchingData ? (
        <div className={styles.loaderContainer}>
          <Loader variant="secondary" />
        </div>
      ) : (
        <>
          {items.length > 0 &&
            items.map(item => (
              <ExplanationItem
                key={item.id}
                header={item.header}
                value={item.value}
                textId={item.textId}
                textItem={item}
              />
            ))}
        </>
      )}
    </Accordion>
  );
};

ExplanationGroup.propTypes = {
  available: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  textId: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

ExplanationGroup.defaultProps = {
  available: true,
  textId: [],
};

export default ExplanationGroup;
