import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActiveSelections.module.css';

const FilterString = (item, index, length) => {
  let itemName = '';
  if (length === 1 || index === 0) {
    itemName = item.name;
    return itemName;
  }
  if (index === length - 1) {
    itemName = `&  ${item.name}`;
    return itemName;
  }
  itemName = `,  ${item.name}`;
  return itemName;
};

const ActiveUnionSelections = ({ unions }) => {
  return (
    <div id="active-filters" className={styles.activeFilters}>
      <span className={styles.activeFiltersHeading}>{'Gjorda urval: '}</span>
      {unions
        .filter(union => union.isSelected === true)
        .map((union, index, { length }) => (
          <div style={{ display: 'inline-block' }} key={union.id}>
            <React.Fragment key={union.id}>
              {FilterString(union, index, length)}
            </React.Fragment>
          </div>
        ))}
    </div>
  );
};

ActiveUnionSelections.propTypes = {
  unions: PropTypes.arrayOf(Object).isRequired,
};

export default ActiveUnionSelections;
