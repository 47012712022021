import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useScrollTo from 'hooks/useScrollTo';
import { IconChevronDown, IconChevronUp } from 'tabler-icons';
import styles from './Accordion.module.css';
import ButtonTransparent from '../buttontransparent/ButtonTransparent';

const Accordion = ({
  border,
  boxShadow,
  children,
  className,
  close,
  dark,
  disabled,
  id,
  initialOpen,
  marginBottom,
  onClick,
  slim,
  stacked,
  title,
}) => {
  const [open, setOpen] = useState(initialOpen);

  const ref = useScrollTo(id, () => {
    setOpen(true);
    onClick();
  });

  useEffect(() => {
    if (close) setOpen(false);
  }, [close]);

  // TODO: Maybe change this variable name
  // It does'nt imply interactivity.. but at the same time it serves two purposes.. ¯\_(ツ)_/¯
  useEffect(() => {
    if (initialOpen) setOpen(true);
  }, [initialOpen]);

  return (
    <div
      ref={ref}
      id={id}
      className={`${styles.accordionWrapper} ${stacked ? styles.isStacked : ''} 
      ${slim ? styles.isSlim : ''} ${border ? styles.hasBorder : ''} ${
        boxShadow ? styles.hasBoxShadow : ''
      } ${marginBottom ? styles.hasMarginBottom : ''} ${className || ''}`}
    >
      <fieldset className={styles.accordion}>
        <legend
          className={`${styles.heading} ${disabled ? styles.disabled : ''} ${
            open ? styles.open : ''
          }`}
        >
          <ButtonTransparent
            disabled={disabled}
            altText={`Visa/dölj ${title}`}
            onClick={() => {
              setOpen(prevState => !prevState);
              onClick();
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...(dark && {
              style: { backgroundColor: 'var(--bg-grey-darker)' },
            })}
          >
            <span className={styles.accordionTitle}>{title}</span>
            {open ? (
              <IconChevronUp
                color={`${
                  disabled
                    ? 'var(--text-color-disabled)'
                    : 'var(--primary-color-dark)'
                }`}
                size={20}
                role="img"
                aria-label=""
              />
            ) : (
              <IconChevronDown
                color={`${
                  disabled
                    ? 'var(--text-color-disabled)'
                    : 'var(--primary-color-dark)'
                }`}
                size={20}
                role="img"
                aria-label=""
              />
            )}
          </ButtonTransparent>
        </legend>
        <>
          <div
            className={`${styles.accordionContent} ${open ? styles.open : ''}`}
          >
            {children}
          </div>
        </>
      </fieldset>
    </div>
  );
};

Accordion.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  close: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  initialOpen: PropTypes.bool,
  marginBottom: PropTypes.bool,
  onClick: PropTypes.func,
  slim: PropTypes.bool,
  stacked: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  border: false,
  boxShadow: false,
  className: '',
  close: false,
  dark: false,
  disabled: false,
  id: '',
  initialOpen: false,
  marginBottom: false,
  onClick: () => {},
  slim: false,
  stacked: false,
};

export default Accordion;
