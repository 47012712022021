import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'atoms/buttonlink/ButtonLink';
import useVisible from 'hooks/useVisible';
import styles from './DownloadSection.module.css';
import DownloadExcel from './downloadexcel/DownloadExcel';

const DownloadSection = ({
  selectedPresentation,
  tableData,
  activeDataSource,
  activeFilters,
  dataSources,
  measureVar,
}) => {
  const {
    ref,
    isVisible: showOptions,
    setIsVisible: setShowOptions,
  } = useVisible(false);

  return (
    <div ref={ref} className={styles.downloadSection}>
      <ButtonLink
        label="Spara"
        altText="Ladda ner statistiken"
        className={styles.downloadBtn}
        icon="Download"
        onClick={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <div className={styles.downloadOptions}>
          <DownloadExcel
            tableData={tableData}
            selectedPresentation={selectedPresentation}
            activeDataSource={activeDataSource}
            activeFilters={activeFilters}
            dataSources={dataSources}
            measureVar={measureVar}
          />
        </div>
      )}
    </div>
  );
};

DownloadSection.propTypes = {
  selectedPresentation: PropTypes.arrayOf(Object),
  tableData: PropTypes.arrayOf(Object),
  activeDataSource: PropTypes.shape({
    forbund: PropTypes.string,
    dsl1: PropTypes.string,
    dsl2: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  dataSources: PropTypes.arrayOf(Object),
  activeFilters: PropTypes.arrayOf(Object),
  measureVar: PropTypes.string.isRequired,
};

DownloadSection.defaultProps = {
  tableData: [],
  selectedPresentation: null,
  dataSources: [],
  activeFilters: [],
};

export default DownloadSection;
