import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Input from 'atoms/input/Input';
import Select from 'atoms/select/Select';
import Button from 'atoms/button/Button';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import styles from './PageLogin.module.css';

const PageLogin = () => {
  // TODO:
  // Den här komponenten ska egentligen bara ta hand om Admin login (eventuellt Förtroendevald).
  // I dagsläget används den också för att simulera/testa inloggningssättet som förbund ska implementera
  // på deras sajter. De delarna av koden ska senare tas bort!

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginType, setLoginType] = useState('medlemTest');
  const [forbundList, setForbundList] = useState([]);
  const [loginForbund, setLoginForbund] = useState();
  // FIXME: Ska egentligen ske på förbundet, endast för test.
  const [loginLink, setLoginLink] = useState();

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  const [, setState] = useState();

  const handleError = error => {
    if (error.status === 401) {
      auth.signout();
      history.push('/logga-ut');
    } else {
      setState(() => {
        throw error;
      });
    }
  };
  
  const handleLogin = event => {
    event.preventDefault();
    // TODO: Handle forbundskodadmin.
    if (loginType === 'admin' || loginType === 'redaktor')
      auth
        .signin(userName, password, loginForbund)
        .then(() => {
          history.replace(from);
        })
        .catch(error => handleError(error));
    // if (loginType === 'redaktor')
    //   ApiService.testLogin({ subscriberId: userName, password })
    //     .then(() => {
    //       history.replace(from);
    //     })
    //     .catch(error => handleError(error));
    else
      ApiService.testLogin({ subscriberId: userName, password })
        .then(data =>
          setLoginLink(`${window.origin.toString()}/token/${data.token}`)
        )
        .catch(error => handleError(error));
  };

  // Initially, get list of forbund
  useEffect(() => {
    ApiService.getUnions().then(data => {
      setForbundList(
        data
          .filter(a => a.forbundskodSas !== 'XX')
          .sort((a, b) => {
            if (a.forbundskodSas === 'SA') return -1;
            if (b.forbundskodSas === 'SA') return 1;
            return `${a.name}`.localeCompare(b.name, 'sv');
          })
      );
    });
  }, []);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.main}>
        <div className={styles.loginOptions}>
          <Select
            label="Inloggningstyp"
            id="login-type-input"
            onChange={e => setLoginType(e.target.value)}
            options={[
              {
                key: 'medlemTest',
                value: 'medlemTest',
                desc: 'Medlem',
              },
              { key: 'special', value: 'special', desc: 'Förtroendevald' },
              { key: 'redaktor', value: 'redaktor', desc: 'Redaktör' },
              { key: 'admin', value: 'admin', desc: 'Administratör' },
            ].map(({ key, value, desc }) => ({ key, value, desc }))}
            value={loginType}
          />
          {loginType !== 'medlemTest' &&
            loginType !== 'redaktor' &&
            forbundList.length > 0 && (
              <div className={styles.loginUnion}>
                <Select
                  label="Förbund"
                  id="login-forbund-input"
                  onChange={e => setLoginForbund(e.target.value)}
                  options={[
                    {
                      key: 'loginForbund_null',
                      value: '',
                      desc: 'Välj förbund...',
                    },
                    ...forbundList.map(({ id, forbundskodSas, name }) => ({
                      key: id,
                      value: forbundskodSas,
                      desc: name,
                    })),
                  ]}
                  value={loginForbund}
                />
              </div>
            )}
        </div>
        <br />
        <form className={styles.loginForm} onSubmit={handleLogin}>
          <Input
            id="username"
            label="Användarnamn"
            value={userName}
            onChange={e => setUserName(e.target.value)}
            type="text"
            placeholder="Användarnamn"
            className={styles.loginTextInput}
          />
          <Input
            id="password"
            label="Lösenord"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="Lösenord"
            className={styles.loginTextInput}
          />
          {loginType === 'medlemTest' ? (
            <Button
              className={styles.loginButton}
              disabled={!userName || !password}
              label="Generera länk"
              onClick={handleLogin}
              type="submit"
              variant="primary"
            />
          ) : (
            <Button
              className={styles.loginButton}
              disabled={
                !userName ||
                !password ||
                (loginType === 'admin' && !loginForbund)
              }
              label="Logga in"
              onClick={handleLogin}
              type="submit"
              variant="primary"
            />
          )}
        </form>
        {/* FIXME: Ska egentligen ske på förbundet, endast för test. */}
        {loginLink && (
          <a
            className={styles.unionLink}
            href={loginLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Till Saco lönesök...
          </a>
        )}
      </div>
    </div>
  );
};

export default PageLogin;
