/* eslint-disable camelcase */
import React from 'react';
import styles from './HelpBox.module.css';

const HelpBox = () => {
  return (
    <div className={styles.helpBox}>
      <h3>Hjälp</h3>
      <p>
        För att kopiera konfiguration från testmiljö till produktionsmiljö för
        valt förbund gör följande:
      </p>
      <ul className="generalList">
        <li>Enkel sökning</li>
        <ul>
          <li>Välj datakällor som ska kopieras.</li>
        </ul>
        <li>Avancerad sökning</li>
        <ul>
          <li>Välj förbund för vilka konfiguration ska kopieras.</li>
          <li>Välj datakällor som ska kopieras.</li>
        </ul>
        <li>Öppen sökning</li>
        <ul>
          <li>Välj datakällor som ska kopieras.</li>
        </ul>
        <li>Klicka på Kopiera konfiguration</li>
        <ul>
          <li>
            Tjänsten kopierar konfiguration från testmiljö till produktionsmiljö
            för de vyer som har valts samt för valda förbund.
          </li>
          <li>
            All befintlig konfiguration för valda vyer och datakällor kommer att
            tas bort i produktionsmiljön och ersättas av konfiguration gjord i
            testmiljön. (Gäller konfiguration för samtliga år)
          </li>
        </ul>
      </ul>
    </div>
  );
};

HelpBox.propTypes = {};

HelpBox.defaultProps = {};

export default HelpBox;
