import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import Loader from 'atoms/loader/Loader';
import Select from 'atoms/select/Select';
import Input from 'atoms/input/Input';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import Panel from 'atoms/panel/Panel';
import styles from './CreateAccount.module.css';

const CreateAccount = ({ setAccountCreated, setAddAccount, setUserList }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unionList, setUnionList] = useState([]);

  // Refs
  const passwordRef = useRef();

  // User input.
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('medlem');
  const [selectedUnion, setSelectedUnion] = useState({});
  const [label, setLabel] = useState();

  const { user } = useAuth();

  const performSubmit = () => {
    if (!passwordRef.current.reportValidity()) return;
    setIsSaving(true);
    const request = {
      name: selectedUnion.name,
      password,
      forbundskodSas: selectedUnion.forbundskodSas,
      isFortroendeVald: role === 'fortroendevald',
      isRedaktor: role === 'redaktor',
      isAdmin: role === 'admin',
      label,
    };

    ApiService.postSubscriber(request, user.token)
      .then(data => {
        setUserList(prevState => [
          ...prevState,
          // TODO: Endpoint needs to return name of created account
          { ...data, name: selectedUnion.name },
        ]);
        setAccountCreated(true);
      })
      .finally(() => {
        setAddAccount(prevState => !prevState);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (user.role === 'redaktor') {
      ApiService.getUnion(user.token).then(data => setSelectedUnion(data));
      return;
    }
    setIsLoading(true);

    ApiService.getUnions(user.token)
      .then(data => {
        setUnionList(
          data
            .filter(a => a.forbundskodSas !== 'XX')
            .sort((a, b) => `${a.name}`.localeCompare(b.name, 'sv'))
        );
      })
      .finally(() => setIsLoading(false));
  }, [user.token]);

  useEffect(() => {
    if (unionList.length > 0) {
      setSelectedUnion(unionList[0]);
    }
  }, [unionList]);

  return (
    <Panel card legend="Skapa nytt konto">
      {isLoading ? (
        <div className={styles.loaderAnimation}>
          <Loader variant="primary" />
        </div>
      ) : (
        <>
          <form>
            {user.role === 'admin' && (
              <Select
                disabled={isSaving}
                compact
                marginBottom
                label="Förbund"
                id="forbund"
                onChange={evt => setSelectedUnion(JSON.parse(evt.target.value))}
                options={[
                  ...unionList.map(union => ({
                    key: union.id,
                    value: JSON.stringify(union),
                    desc: union.name,
                  })),
                ]}
                value={JSON.stringify(selectedUnion)}
              />
            )}
            <Select
              id="role"
              label="Roll"
              onChange={e => setRole(e.target.value)}
              value={role}
              options={[
                { key: 'medlem', value: 'medlem', desc: 'Medlem' },
                {
                  key: 'fortroendevald',
                  value: 'fortroendevald',
                  desc: 'Förtroendevald',
                },
                {
                  key: 'redaktor',
                  value: 'redaktor',
                  desc: 'Redaktör',
                },
              ]}
            />
            <Input
              disabled={isSaving}
              id="user-label"
              label="Etikett"
              onChange={evt => setLabel(evt.target.value)}
              placeholder="Etikett"
              type="text"
              value={label}
            />
            <Input
              autoComplete="new-password"
              disabled={isSaving}
              id="user-password"
              label="Lösenord"
              minLength={10}
              onChange={evt => setPassword(evt.target.value)}
              placeholder="Lösenord"
              ref={passwordRef}
              type="password"
              value={password}
            />
            <br />
            <ButtonBar>
              <Button
                variant="primary"
                onClick={() => performSubmit()}
                label="Spara"
                altText="Spara konto"
                disabled={isSaving || password.length < 10}
              />
              <Button
                variant="secondary"
                onClick={() => setAddAccount(false)}
                label="Avbryt"
                disabled={isSaving}
              />
            </ButtonBar>
          </form>
        </>
      )}
    </Panel>
  );
};

CreateAccount.propTypes = {
  setAccountCreated: PropTypes.func.isRequired,
  setAddAccount: PropTypes.func.isRequired,
  setUserList: PropTypes.func.isRequired,
};

export default CreateAccount;
