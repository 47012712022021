import React from 'react';
import PageErrorProvocation from 'pages/errorboundary/PageErrorProvocation';
import PageErrorBoundary from 'pages/errorboundary/PageErrorBoundary';
import { Switch, Route, useLocation } from 'react-router-dom';
import PageStart from 'pages/start/PageStart';
import PageSimple from 'pages/simple/PageSimple';
import PageSimpleFree from 'pages/simple/PageSimpleFree';
import PageAdvanced from 'pages/advanced/PageAdvanced';
import PageAdmin from 'pages/admin/PageAdmin';
import Page404 from 'pages/404/Page404';
import PageLogin from 'pages/login/PageLogin';
import PageDeveloper from 'pages/developer/PageDeveloper';
import PageChangePort from 'pages/developer/PageChangePort';
import Logout from 'pages/logout/Logout';
import CopyConfiguration from 'pages/copyconfiguration/CopyConfiguration';
import { PrivateRoute, AdminRoute } from './services/UseAuth';
import TokenRedirect from './services/TokenRedirect';
import ForbundsKodRedirect from './services/ForbundsKodRedirect';
import LoginInfo from './pages/loginInfo/LoginInfo';
import Preview from './pages/preview/Preview';
import PreviewLab from './pages/previewlab/PreviewLab';
import Instructionvideo from './pages/instructionvideo/Instructionvideo';
import Careerspin from './pages/careerspin/Careerspin';
import ParentalLeavespin from './pages/parentalleavespin/ParentalLeavespin';
import Unemploymentspin from './pages/unemploymentspin/Unemploymentspin.js';

export default function AppRouter() {
  const location = useLocation();
  return (
    <Switch>
      <AdminRoute component={PageAdmin} path="/admin" />
      <PrivateRoute component={PageSimple} path="/enkel" />
      <PrivateRoute component={PageAdvanced} path="/avancerad" />
      <PrivateRoute
        component={CopyConfiguration}
        path="/kopiera-konfiguration"
      />
      <PrivateRoute
        component={Instructionvideo}
        path="/instruktionsfilm/:level"
      />
      <PrivateRoute exact component={PageStart} path="/" />
      <Route component={PageLogin} path="/logga-in" />
      <Route component={LoginInfo} path="/inloggning-info" />
      <Route component={TokenRedirect} path="/token/:token" />
      <Route component={ForbundsKodRedirect} path="/forbund/:forbund" />
      <Route component={PageDeveloper} path="/for-utvecklare" />
      <Route component={PageChangePort} path="/byte-av-port" />
      <Route component={Logout} path="/logga-ut" />

      <Route component={Unemploymentspin} path="/unemploymentspin" />
      <Route component={Careerspin} path="/careerspin" />
      <Route component={Careerspin} path="/careerspin/:alt" />
      <Route component={ParentalLeavespin} path="/parentalleavespin" />
      <Route component={Preview} path="/oppensokning/:forbund/:chef" />
      <Route component={Preview} path="/oppensokning/:forbund" />
      <Route component={Preview} path="/oppensokning" />
      <Route component={PreviewLab} path="/oppensokninglab" />

      <Route component={PageErrorProvocation} path="/provocera-fel" />

      {/* <Route
          render={props => <PageSimpleFree {...props} />}
          path="/bli-medlem"
        /> */}
      {/* FIXME: temporary fix, before launch */}
      <Route component={PageSimpleFree} path="/bli-medlem" />
      <Route path="*" component={Page404} />
    </Switch>
  );
}
