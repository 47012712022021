/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonBar.module.css';

const ButtonBar = ({children, prevNext, marginBottom}) => {
  return (
    <div className={`${styles.buttonBar} ${prevNext ? styles.prevNext : ''} ${marginBottom ? styles.hasMarginBottom : ''}`}>
      {children}
    </div>
  );
};

ButtonBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  prevNext: PropTypes.bool,
  marginBottom: PropTypes.bool
};

ButtonBar.defaultProps = {
  prevNext: false,
  marginBottom: false
};

export default ButtonBar;
