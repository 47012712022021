import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'atoms/select/Select';
import styles from './CustomPresentation.module.css';

const CustomPresentation = ({ presentationList, setSelectedPresentation }) => {
  const [breakby1, setBreakby1] = useState();
  const [breakby2, setBreakby2] = useState();

  useEffect(() => {
    if (breakby1 != null && breakby2 != null) {
      setSelectedPresentation([breakby1, breakby2]);
    }
    // eslint-disable-next-line
  }, [breakby1, breakby2]);

  const handleSelectBreakby1 = e => {
    try {
      setBreakby1(JSON.parse(e.target.value));
    } catch (err) {}
  };

  const handleSelectBreakby2 = e => {
    try {
      setBreakby2(JSON.parse(e.target.value));
    } catch (err) {}
  };

  return (
    <div className={styles.customPresentation}>
      <h4 className={styles.customPresentationHeading}>Skapa egen rapport</h4>
      <div className={styles.customPresFlex}>
        {presentationList && (
          <>
            <div>
              <Select
                label="Urval 1"
                id="breakby1"
                onChange={e => handleSelectBreakby1(e)}
                options={[
                  {
                    key: 'null_breakby1',
                    value: null,
                    desc: 'Välj ett alternativ',
                  },
                  ...presentationList
                    .filter(
                      element => element?.BREAKBY_VAR !== breakby2?.BREAKBY_VAR
                    )
                    .map(p => ({
                      key: p.BREAKBY_VAR,
                      value: JSON.stringify(p),
                      desc: p.REPORT_TEXT,
                    })),
                ]}
              />
            </div>
            <div>
              <Select
                label="Urval 2"
                id="breakby2"
                onChange={e => handleSelectBreakby2(e)}
                options={[
                  {
                    key: 'null_breakby2',
                    value: null,
                    desc: 'Välj ett alternativ',
                  },

                  ...presentationList
                    .filter(
                      element => element?.BREAKBY_VAR !== breakby1?.BREAKBY_VAR
                    )
                    .map(p => ({
                      // ...presentationList.map(p => ({
                      key: p.BREAKBY_VAR,
                      value: JSON.stringify(p),
                      desc: p.REPORT_TEXT,
                    })),
                ]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CustomPresentation.propTypes = {
  presentationList: PropTypes.arrayOf(Object).isRequired,
  setSelectedPresentation: PropTypes.func.isRequired,
};

export default CustomPresentation;
