import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import Input from 'atoms/input/Input';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import Panel from 'atoms/panel/Panel';

const LinkCreate = ({
  setLinks,
  configureLvl1,
  setCreateNewLink,
  setLinkCreated,
}) => {
  const [linkName, setLinkName] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { user } = useAuth();

  const handleSubmit = () => {
    setIsSaving(true);
    const request = {
      unionId: configureLvl1 ? null : user.unionId,
      groupId: configureLvl1 ? 'level1link' : 'level2link',
      textId: linkName,
      header: linkName,
      value: linkUrl,
    };
    ApiService.postText(request, user.token)
      .then(data => {
        setLinks(prevState => [...prevState, data]);
        setLinkCreated(true);
        setCreateNewLink(false);
      })
      .finally(() => setIsSaving(false));
  };
  return (
    <Panel card legend="Skapa ny länk">
      <form onSubmit={handleSubmit}>
        <Input
          id="link-text"
          label="Länktext"
          onChange={evt => setLinkName(evt.target.value)}
          placeholder="Länktext"
          type="text"
          value={linkName}
        />
        <Input
          id="http-link"
          label="HTTP-länk"
          onChange={evt => setLinkUrl(evt.target.value)}
          placeholder="HTTP-länk"
          type="text"
          value={linkUrl}
        />
      </form>
      <ButtonBar>
        <Button
          variant="primary"
          label="Spara"
          altText="Spara länk"
          onClick={() => handleSubmit()}
          disabled={isSaving}
        />
        <Button
          variant="secondary"
          label="Avbryt"
          disabled={isSaving}
          onClick={() => setCreateNewLink(prevState => !prevState)}
        />
      </ButtonBar>
    </Panel>
  );
};

LinkCreate.propTypes = {
  setLinks: PropTypes.func.isRequired,
  configureLvl1: PropTypes.bool.isRequired,
  setCreateNewLink: PropTypes.func.isRequired,
  setLinkCreated: PropTypes.func.isRequired,
};

export default LinkCreate;
