import React from 'react';
import PropTypes from 'prop-types';
import Input from 'atoms/input/Input';
import Section from 'atoms/section/Section';
import styles from './SalarySlider.module.css';
import Slider from './slider/Slider';

const SalarySlider = ({ selectedSalary, setSelectedSalary, salaryLimit }) => {
  const min = salaryLimit[0] - 5000 >= 20000 ? 20000 : 10000;
  const max = salaryLimit[1] + 20000;
  const step = 100;
  const now = selectedSalary || '';

  return (
    <>
      <h2>Din lön</h2>
      <Section dashed>
        <div>
          <div className={styles.selectedSalary}>
            <Input
              id="selectedSalary"
              label="Ange din lön"
              className={styles.number}
              max={max}
              min={min}
              onChange={e => setSelectedSalary(e.target.value)}
              step={step}
              type="number"
              value={now}
              displayInline
            />
            kronor per månad
          </div>
          <Slider
            changeFunction={setSelectedSalary}
            id="salarySlider"
            max={max}
            min={min}
            step={step}
            value={now}
          />
        </div>
      </Section>
    </>
  );
};

SalarySlider.propTypes = {
  selectedSalary: PropTypes.string,
  setSelectedSalary: PropTypes.func.isRequired,
  salaryLimit: PropTypes.arrayOf(Number).isRequired,
};

SalarySlider.defaultProps = {
  selectedSalary: null,
};

export default SalarySlider;
