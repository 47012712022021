/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import Input from 'atoms/input/Input';
import ButtonBar from 'atoms/buttonbar/ButtonBar';
import { useAuth } from 'services/UseAuth';
import ApiService from 'services/ApiService';
import Panel from 'atoms/panel/Panel';
import styles from './PreviewDataSourceCreate.module.css';
import DataSourceSelect from '../../datasourceselect/DataSourceSelect';

const PreviewDataSourceCreate = ({
  configureLvl1,
  dataSources,
  setCreateNewSituation,
  setSituationCopied,
  setSituationCreated,
  setSituations,
  situationToCopy,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [dsl1, setDsl1] = useState('');
  const [dsl2, setDsl2] = useState('');
  const [year, setYear] = useState();

  const [saveDisabled, setSaveDisabled] = useState(true);

  const { user } = useAuth();

  const handleSubmit = evt => {
    setSaveDisabled(true);
    evt.preventDefault();

    // Send network request.
    const request = {
      name,
      description,
      dsl1,
      dsl2,
      year,
      isActive: situationToCopy === null, // FIXME: Ska isActive defaulta till true när man skapar en ny?, åtminstone inte när man kopierar
      isPreview: true,
      forbundsKod: configureLvl1 ? 'sa' : null,
    };

    const createSituation = new Promise((resolve, reject) =>
      ApiService.createSituation(request, user.token)
        .then(data => resolve(data))
        .catch(error => reject(error))
    );

    if (situationToCopy == null) {
      createSituation.then(data => {
        setSituations(prevState => [...prevState, data]);
        setCreateNewSituation(prevState => !prevState);
        setSituationCreated(true);
      });
    } else {
      createSituation.then(createdSituation => {
        const activeSelections = situationToCopy.selections.filter(
          situation => situation.isActive
        );

        const updateRequest = {
          selections: createdSituation.selections.map(selection => {
            const index = [...activeSelections]
              .map(({ sel_var }) => sel_var)
              .findIndex(selVar => selVar === selection.sel_var);

            return {
              id: selection.id,
              selectionOrder:
                index > -1
                  ? activeSelections[index].SELECTION_ORDER
                  : selection.SELECTION_ORDER,
              isActive: index > -1,
            };
          }),
        };

        ApiService.updateSelections(
          createdSituation.id,
          updateRequest,
          user.token
        ).then(updatedSituation => {
          setSituations(prevState => [...prevState, updatedSituation]);
          setCreateNewSituation(prevState => !prevState);
          setSituationCopied(true);
        });
      });
    }
  };

  useEffect(() => {
    if (name.length === 0 || description.length === 0) {
      setSaveDisabled(true);
      return;
    }

    if (
      dataSources.some(
        ds => ds.DSL1 === dsl1 && ds.DSL2 === dsl2 && ds.YEAR === year
      )
    ) {
      setSaveDisabled(false);
    } else setSaveDisabled(true);
  }, [name, description, year, dsl1, dsl2, dataSources]);

  useEffect(() => {
    if (situationToCopy != null) {
      setName(situationToCopy.name);
      setDescription(situationToCopy.description);
      setYear(situationToCopy.year);
      setDsl1(situationToCopy.DSL1);
      setDsl2(situationToCopy.DSL2 || '');
    }
  }, [situationToCopy]);

  return (
    <Panel card legend="Skapa ny statistikkälla">
      <div className={styles.formWrapperHalf}>
        <form onSubmit={handleSubmit}>
          <Input
            id="name"
            label="Namn"
            onChange={evt => setName(evt.target.value)}
            placeholder="Namn"
            type="text"
            value={name}
          />
          <Input
            id="description"
            label="Beskrivning"
            onChange={evt => setDescription(evt.target.value)}
            placeholder="Beskrivning"
            type="text"
            value={description}
          />
          <DataSourceSelect
            dataSources={dataSources}
            year={year}
            setYear={setYear}
            dsl1={dsl1}
            setDsl1={setDsl1}
            dsl2={dsl2}
            setDsl2={setDsl2}
          />
        </form>
      </div>
      <br />
      <ButtonBar>
        <Button
          variant="primary"
          onClick={evt => handleSubmit(evt)}
          label="Spara"
          altText="Spara statistikkälla"
          disabled={saveDisabled}
        />
        <Button
          variant="secondary"
          label="Avbryt"
          onClick={() => setCreateNewSituation(prevState => !prevState)}
        />
      </ButtonBar>
    </Panel>
  );
};

PreviewDataSourceCreate.propTypes = {
  configureLvl1: PropTypes.bool.isRequired,
  dataSources: PropTypes.arrayOf(Object).isRequired,
  setCreateNewSituation: PropTypes.func.isRequired,
  setSituationCopied: PropTypes.func.isRequired,
  setSituationCreated: PropTypes.func.isRequired,
  setSituations: PropTypes.func.isRequired,
  situationToCopy: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    year: PropTypes.number,
    DSL1: PropTypes.string,
    DSL2: PropTypes.string,
    selections: PropTypes.arrayOf(Object),
  }),
};

PreviewDataSourceCreate.defaultProps = {
  situationToCopy: null,
};

export default PreviewDataSourceCreate;
